import React from 'react'
import { Popover, Overlay } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import styles from './stylesNew.less'
import { FuelGradeSvg, GripGradeSvg, NoiseGradeSvg, SnowGripSvg, IceGripSvg} from '../SvgIcon'
import classnames from 'classnames'
import Image from 'components/components/Image'

export default class EuGrading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showResistancePopover: false,
      showWetGripPopover: false,
      showNoiseDecibelPopover: false,
      showSnowGripPopover: false,
      showIceGripPopover: false,
    }
  }

  onEnter(attr) {
    if (attr) {
      this.setState({
        [attr]: true
      })
    }
  }

  onLeave(attr) {
    if (attr) {
      this.setState({
        [attr]: false
      })
    }
  }

  render() {
    const { product, className } = this.props
    const rollingResistance = product.attrs.rollingResistance || '?'
    const wetGrip = product.attrs.wetGrip || '?'
    const noiseDecibel = product.attrs.noiseDecibel || '?'
    const hasEuGrading = !!product.attrs.eprelLabelImage
    const popoverClass = `${styles.extendedDescription} background-normal ${hasEuGrading && styles.hasEuGrading}`
    return (
      <div ref={el => this.euGradeTarget = el}>
        <div className={classnames(className, styles.euGrading, 'eu-grading', 'text-colored')} onMouseEnter={() => this.onEnter(hasEuGrading && 'showEuGradePopover')} onMouseLeave={() => this.onLeave(hasEuGrading && 'showEuGradePopover')}>
          {!!product.attrs.rollingResistance && (
            <div className={classnames(styles.accentPositive, 'rolling-resistance')} ref={el => this.rollingResistanceTarget = el}>
                <div onMouseEnter={() => this.onEnter(!hasEuGrading && 'showResistancePopover')} onMouseLeave={() => this.onLeave(!hasEuGrading && 'showResistancePopover')}>
                  <div className={classnames(styles.icon)}><FuelGradeSvg className="svg-colored"/></div>
                  <div className={classnames(styles.label, 'btn')}>{product.attrs.rollingResistance}</div>
                </div>
              {!hasEuGrading && (
                <Overlay placement="bottom" show={this.state.showResistancePopover} target={this.rollingResistanceTarget}>
                  <Popover placement="bottom" id="rolling-resistance-popover" className={popoverClass}>
                    <strong><T id="Rullmotstånd"/> {rollingResistance}</strong><br />
                    <T id="Indicates there tyre's rolling resistance and hence its fuel efficiency. Between classes, fuel consumption increases by approximately 0.1 liter for every 100 km driven."/>
                  </Popover>
                </Overlay>
              )}
            </div>
          )}
          {!!product.attrs.wetGrip && (
            <div className={classnames(styles.accentPositive, 'wet-grip')} ref={el => this.wetGripTarget = el}>
              <div onMouseEnter={() => this.onEnter(!hasEuGrading && 'showWetGripPopover')} onMouseLeave={() => this.onLeave(!hasEuGrading && 'showWetGripPopover')}>
                <div className={classnames(styles.icon)}><GripGradeSvg className="svg-colored"/></div>
                <div className={classnames(styles.label, 'btn')}>{wetGrip}</div>
              </div>
              {!hasEuGrading && (
                <Overlay placement="bottom" show={this.state.showWetGripPopover} target={this.wetGripTarget}>
                  <Popover placement="bottom" id="wet-grip-popover" className={popoverClass}>
                  <strong><T id="Wet grip"/> {product.attrs.wetGrip}</strong><br />
                  <T id="Indicates how well the tire will perform in wet conditions. Braking at 80 km/h, a class A tire will come to a stop in wet conditions after 28 meters, while a class F tire requires 46.5 meters."/>
                  </Popover>
                </Overlay>
              )}
            </div>
          )}
          {!!product.attrs.noiseDecibel && (
            <div className={classnames(styles.accentPositive, 'noise-decibel')} ref={el => this.noiseDecibelTarget = el}>
              <div onMouseEnter={() => this.onEnter(!hasEuGrading && 'showNoiseDecibelPopover')} onMouseLeave={() => this.onLeave(!hasEuGrading && 'showNoiseDecibelPopover')}>
                <div className={classnames(styles.icon)}><NoiseGradeSvg className="svg-colored"/></div>
                <div className={classnames(styles.label, 'btn')}>{product.attrs.noiseRating}</div>
              </div>
              {!hasEuGrading && (
                <Overlay placement="bottom" show={this.state.showNoiseDecibelPopover} target={this.noiseDecibelTarget}>
                  <Popover placement="bottom" id="noise-decibel-popover" className={popoverClass}>
                    <strong><T id="Noise emission (db)" /> {product.attrs.noiseRating}, {noiseDecibel} dB</strong><br />
                    <T id="Rolling noise generated by the tire as measured outside the car. The lowest noise level is around 67 dB while the highest is around 76 dB."/>
                  </Popover>
                </Overlay>
              )}
            </div>
          )}
          {!!product.attrs.tyreSnowGrip && (
            <div className={classnames(styles.accentPositive, 'snow-grip')} ref={el => this.snowGripTarget = el}>
              <div onMouseEnter={() => this.onEnter(!hasEuGrading && 'showSnowGripPopover')} onMouseLeave={() => this.onLeave(!hasEuGrading && 'showSnowGripPopover')}>
                <div className={classnames(styles.icon)}><SnowGripSvg className="svg-colored" /></div>
                <div style={{height: "26px"}} />
              </div>
              {!hasEuGrading && (
                <Overlay placement="bottom" show={this.state.showSnowGripPopover} target={this.snowGripTarget}>
                  <Popover placement="bottom" id="snow-grip-popover" className={popoverClass}>
                    <T id="Three-peak mountain snowflake (3PMSF) symbol indicates the tire has good grip in severe snow conditions."/>
                  </Popover>
                </Overlay>
              )}
            </div>
          )}
          {!!product.attrs.tyreIceGrip && (
            <div className={classnames(styles.accentPositive, 'ice-grip')} ref={el => this.iceGripTarget = el}>
              <div onMouseEnter={() => this.onEnter(!hasEuGrading && 'showIceGripPopover')} onMouseLeave={() => this.onLeave(!hasEuGrading && 'showIceGripPopover')}>
                <div className={classnames(styles.icon)}><IceGripSvg className="svg-colored" /></div>
                <div style={{height: "26px"}} />
              </div>
              {!hasEuGrading && (
                <Overlay placement="bottom" show={this.state.showIceGripPopover} target={this.iceGripTarget}>
                  <Popover placement="bottom" id="ice-grip-popover" className={popoverClass}>
                    <T id="The ice symbol indicates the tire has good grip in icy conditions."/>
                  </Popover>
                </Overlay>
              )}
            </div>
          )}
        </div>
        {hasEuGrading && (
          <Overlay placement="bottom" show={this.state.showEuGradePopover} target={this.euGradeTarget}>
            <Popover placement="bottom" id="eu-grade-popover" className={popoverClass}>
              <Image src={product.attrs.eprelLabelImage} width="244px" minHeight="350px" alt="EPREL" color="text-normal" />
            </Popover>
          </Overlay>
        )}
      </div>
    )
  }
}
