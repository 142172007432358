// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__inlineForm___iwMRD {
  display: flex;
}
.styles__numeric___vIaIZ {
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchResults/ProductsListCompact/Product/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEA;EACE,iBAAA;AAAF","sourcesContent":[".inlineForm {\n  display: flex;\n}\n\n.numeric {\n  text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inlineForm": `styles__inlineForm___iwMRD`,
	"numeric": `styles__numeric___vIaIZ`
};
export default ___CSS_LOADER_EXPORT___;
