import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Row,
  Col,
  FormControl,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Popover
} from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { addItem } from '../../../../../../actions/cart'
import { CATEGORIES } from '../../../../../../helpers/categories'
import EuGrading from '../../../../EuGrading'
import styles from './styles.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import TyreTypeIcon from '../../TyreTypeIcon'
import TestResults from '../../TestResults'
import ProductProperties from '../../ProductProperties'
import { calculatePrice } from '../../../../../../helpers/price'
import ProductInfoPopover from '../../ProductInfoPopover'
import SupplierPopover from '../../SupplierPopover'
import StockAmountMessage from '../../StockAmountMessage'
import DeliveryTime from '../../../../DeliveryTime'
import RimTypeLabel from '../../RimTypeLabel'
import CommentInput from 'components/components/Form/CommentInput'
import { getUsedStockInfo } from 'helpers/order'
import { isMobile } from 'helpers/general'

export class CompleteWheelProduct extends React.Component {
  constructor(props) {
    super(props)
    // NOTE: Temp solution to test how users perceive this behaviour
    //       where when USED complete wheel is low on stock (<= 4)
    //       user can only select all quantity
    const {
      isLockQuantity,
      lockedStockValue,
    } = getUsedStockInfo(
      this.props.product?.condition?.type,
      this.props.product?.stock,
      this.props.initialCount
    )

    this.state = {
      count: lockedStockValue,
      comment: '',
      isLockQuantity,
      lockedStockValue
    }
  }

  onButtonClick() {
    const { product, shop, cartItems, isWheelBuilder, addToCart, setTyre, setRim, category } = this.props
    if (!isWheelBuilder)
      addToCart(product.id, this.state.count, shop, cartItems, this.state.comment)
    else if (category.id === CATEGORIES.TYRE.id)
      setTyre(product, this.state.count, this.state.comment)
    else if (category.id === CATEGORIES.RIM.id)
      setRim(product, this.state.count, this.state.comment)
  }

  onCountChange(event) {
    this.setState({
      count: parseInt(event.target.value, 10)
    })
  }

  onCommentChange(event) {
    this.setState({
      comment: event.target.value
    })
  }

  render() {
    const { product, shop, includeVat, selectedNumberOfPcs, category, isWheelBuilder, cartItems, priceType, currency } = this.props
    const productLinkUrl = isWheelBuilder ? `/hjul/${category.slug}/${product.id}/${product.slug}` : `/${category.slug}/${product.id}/${product.slug}`
    const imageSrc = product.image && product.image.webshop_thumb ? product.image.webshop_thumb : '/images/default-product-thumbnail.png'

    let productDescription
    if (shop.showFullProductDescriptionOnSearch)
      productDescription = product.description

    let stockAmount
    if (!!shop.showStockAmountsOnSearch) {
      const stockAmountMessage = <StockAmountMessage stock={product.stock} showActualStockAmounts={shop.showActualStockAmounts} />
      stockAmount = <div>{stockAmountMessage}</div>
    }
    let priceValue
    if (priceType === 'consumer')
      priceValue = product.consumerPrice
    else
      priceValue = product.price
    const vatPercent = includeVat ? product.vatPercent : null
    priceValue = calculatePrice(priceValue, selectedNumberOfPcs, vatPercent)
    const price = (
      <FormattedNumber
        style={`currency`}
        value={priceValue}
        currency={currency}
        minimumFractionDigits={0}
        maximumFractionDigits={0}
      />
    )

    let supplier
    if (!!shop.showSupplierInSearchResults) {
      const productSource = product.supplier.id ? product.supplier : product.location
      if (!productSource.hideIt)
        supplier = <SupplierPopover supplier={product.supplier} supplierNameComponent={productSource.name} />
    }

    let delivery, deliveryClass
    switch(product.estimatedDeliveryType) {
      case 0:
      case 5:
        deliveryClass = 'text-danger font-weight-bold'
        break
      case 1:
        deliveryClass = 'text-success font-weight-bold'
        break
      case 2:
        deliveryClass = 'text-info font-weight-bold'
        break
      case 3:
        deliveryClass = 'text-warning font-weight-bold'
        break
      default:
        deliveryClass = ''
    }
    if (product.deliveryTime && !product.deliveryTime.hideIt) {
      if (shop.deliveryDisplayMode === 1) {
        let deliveryTime
        deliveryTime = <DeliveryTime deliveryInfo={product.deliveryTime} />
        delivery = <div className={deliveryClass}>{deliveryTime}</div>
      } else {
        delivery = (
          <FontAwesomeIcon
            className={deliveryClass}
            icon={faTruck}
          />
        )
      }
    }

    const euGrading = <EuGrading product={product.tyre} className={styles.euGrading}/>
    const type = <TyreTypeIcon product={product.tyre} className={styles.tyreTypeIcon}/>
    const testResults = <TestResults product={product.tyre} className={styles.testResults}/>
    let bonus
    if (product.bonus !== 0)
      bonus = (
        <div>
          <FormattedNumber
            style={`percent`}
            value={product.bonus/100}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />&nbsp;<T id="bonus"/>
        </div>
      )
    const itemsAlreadyInCart = cartItems.filter(item => item.id === product.id)
    let quantityInCartMessage
    const sum = itemsAlreadyInCart.reduce((sum, item) => (sum + item.count), 0);
    if (sum > 0)
      quantityInCartMessage = <Link to="/kassa">{sum} <T id="pcs already in cart"/></Link>

    let button
    if (!shop.prohibitPurchase)
      button = (
        <div className={styles.colFullSize}>
          <ButtonGroup className={styles.buttonGroup}>
            <Button type="submit" className={styles.smFull}style={{ backgroundColor: shop.style.color1 }} bsStyle="success" onClick={this.onButtonClick.bind(this)}>
              <FontAwesomeIcon icon={faShoppingCart} /> <T id="Buy" />
            </Button>
          </ButtonGroup>
        </div>
      )

    const productTitle = [
      <Link to={productLinkUrl} className={`${styles.productTitle} text-colored`} key="tyre-name">
        {product.tyre.brand.name} <em>{product.tyre.model.name}</em>
      </Link>,
      <div className={styles.flexIt} key="tyre-dimension">
        {product.tyre.attrs.dimension} {product.tyre.attrs.treadDepths && `(${product.tyre.attrs.treadDepths})`}
      </div>,
      <Link to={productLinkUrl} className={`${styles.productTitle} text-colored`} key="rim-name">
        {product.rim.brand.name} <em>{product.rim.model.name}</em>
      </Link>,
      <div className={styles.rimDescription} key="rim-dimension">{product.rim.attrs.dimension}</div>
    ]

    return (
      <li className={`${styles.product} complete-wheel-product`}>
        <Row className={styles.row}>
          <Col sm={12} md={5} className={styles.titleColumn}>
            <Link to={productLinkUrl} className={styles.productThumbnail}>
              <img src={imageSrc} alt="" className={styles.img}/>
            </Link>
            <div className={styles.titleWidth}>
              <ProductInfoPopover product={product} productTitleElements={productTitle} />
              <div>{productDescription}</div>
              <div className={styles.comment}>{product.comment}</div>
            </div>
          </Col>
          <Col sm={12} md={2} style={{ padding: 0 }}>
            <div className={styles.topItem}>{euGrading}</div>
            <div className={styles.typeAndTestResults}>
              {type}
              <RimTypeLabel rimType={product.rim.attrs.rimType.id} />
            </div>
            <ProductProperties
              product={product.tyre}
              className={styles.productPropertiesWrapper}
              condition={product.condition}
              hasTpms={product.hasTpms}
            />
          </Col>
          <Col md={2} sm={12} className={styles.properties}>
            {stockAmount}
            {delivery}
            {supplier}
            {testResults}
          </Col>
          <Col md={3} sm={12} className={styles.bottomItem}>
            <Row className={styles.controls}>
              <Col md={7} sm={12}>
                <T id="Note">
                  {
                    msg => (
                      <CommentInput
                        placeholder={msg}
                        className={`${styles.commentInput} ${styles.marginBottom}`}
                        onChange={this.onCommentChange.bind(this)}
                      />
                    )
                  }
                </T>
                <div className={styles.inline}>
                  <span className={styles.infoPropertyName}>
                    <T id="Items"/>:
                  </span>
                  {!this.state.isLockQuantity && (
                    <FormControl type="number"
                      className={styles.commentInput}
                      name="count"
                      value={this.state.count}
                      onChange={this.onCountChange.bind(this)}
                      min="1"
                      max="99"
                    />
                  )}
                  {this.state.isLockQuantity && (
                    <React.Fragment>
                      <OverlayTrigger
                        placement={isMobile() ? 'top' : 'right'}
                        overlay={
                          <Popover>
                            <T id="This item is sold in sets." />
                          </Popover>
                        }
                      >
                        <span className={styles.infoPropertyName}>{this.state.lockedStockValue} <T id="pc"/></span>
                      </OverlayTrigger>
                      <input type="hidden" name="count" value={this.state.lockedStockValue} />
                    </React.Fragment>
                  )}
                </div>
              </Col>
              <Col md={5} sm={12} className={styles.priceColumn}>
                {bonus}
                <div className={styles.priceInfo}><strong className={styles.price}>{price}</strong></div>
                <div className={styles.quantityInCartSm}>{quantityInCartMessage}</div>
                {button}
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={styles.quantityInCartMd}>
          {shop.limitPurchaseQuantityToStock && product.stock < parseInt(this.state.count, 10) && (
            <div className={styles.limitedQuantityMessage}>
              <T id="Limited quantity available" />
            </div>
          )}
          {quantityInCartMessage}
        </div>
      </li>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    includeVat: state.price.includeVat,
    selectedNumberOfPcs: state.price.selectedNumberOfPcs,
    priceType: state.price.priceType,
    shop: state.shop,
    cartItems: state.cart.items,
    category: state.categories.activeCategory,
    currency: state.intl.currency,
    initialCount: state.shop.limitPurchaseQuantityToStock ? Math.min(props.product.stock, 4) : 4,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  addToCart: (itemId, count, shop, cartItems, comment) => addItem(itemId, count, shop, cartItems, comment)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteWheelProduct)
