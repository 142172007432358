import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import StarRatingComponent from '../../StarRatingComponent'
import { FormGroup, ControlLabel, Row, Col } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import TyreSearchAdvanced from './TyreSearchAdvanced'
import BrandsSelection from '../BrandsSelection'
import SearchMode from './SearchMode'
import styles from './styles.less'
import TyreType from './TyreType'
import AxleType from './AxleType'
import Quality from './Quality'
import Checkbox from '../../Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { SEARCH_MODES, SEARCH_MODE_BY_VEHICLE_TYPE } from '../../../../constants/searchModes'
import { SUBTYPES } from '../../../../constants/tyreTypes'
import { CATEGORIES } from '../../../../helpers/categories'
import { changeSearchMode } from '../../../../actions/search'
import SearchButton from '../SearchButton'
import Condition from '../Condition'
import TyreAlternatives from './TyreAlternatives'
import TyreAlternativesDefault from './TyreAlternatives/default'
import VehicleType from '../VehicleType'
import { getTyreAlternatives } from 'helpers/tyreAlternatives'
import { filteredSubTyreTypes } from 'helpers/tyreTypes'

export class TyreSearch extends React.Component {
  constructor(props) {
    super(props)
    const vehicleType = this.props.shop.vehicleTypeGroups.find(group => group.types.toString() === this.props.searchParams.vehicleType)
    this.state = {
      showAdvancedFields: false,
      selectedVehicleTypeSearchMode: vehicleType ? vehicleType.searchMode : SEARCH_MODE_BY_VEHICLE_TYPE.PERSONAL,
    }
  }

  componentDidMount() {
    if (this.props.shop.searchModesTyres.indexOf(this.props.selectedSearchMode) === -1) {
      this.props.changeSearchMode(this.props.shop.defaultSearchModeTyres, this.props.query)
    }
  }

  onShowAdvancedSearchClick(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      showAdvancedFields: !this.state.showAdvancedFields
    })
  }

  onSearchClick(params) {
    this.props.onSearchClick(this.props.searchParams)
  }

  onAttributeChange(event) {
    this.props.onSearchParamChange(event.target.name, event.target.value)
  }

  onBrandSelect(brands) {
    this.props.onSearchParamChange('brandId', brands.map(brand => brand.value))
  }

  onAdvancedFieldChange(name, value) {
    this.props.onSearchParamChange(name, value, this.props.category)
  }

  onStarClick(value) {
    this.props.onSearchParamChange('minimumTestScore', value)
  }

  onLinkClick(event) {
    event.preventDefault()
    event.stopPropagation()
    const unSelectedMode = this.swapSearchMode(this.props.shop.searchModesTyres, this.props.selectedSearchMode)
    this.props.changeSearchMode(unSelectedMode, this.props.query)
  }

  onVehicleTypeChange(option) {
    this.setState({
      selectedVehicleTypeSearchMode: option.searchMode,
    })
    this.onAdvancedFieldChange('vehicleType', option.value)
  }

  onAxleTypeChange(option) {
    this.onAdvancedFieldChange('axleType', option.value)
  }

  handleWinterOnly(name, value) {
    this.onAdvancedFieldChange(name, value)
    if (value) {
      // Winter only
      this.props.onSearchParamChange('tyreType', filteredSubTyreTypes(this.props.shop.enabledTyreTypes).map(key => SUBTYPES[key].id))
    } else {
      // All tyre types
      this.props.onSearchParamChange('tyreType', [null])
    }
  }

  swapSearchMode(searchModesTyres, selectedSearchMode) {
    const unSelectedMode = searchModesTyres.filter(mode => mode !== selectedSearchMode).pop()
    return unSelectedMode
  }

  render () {
    const { showAdvancedFields, selectedVehicleTypeSearchMode } = this.state
    const { shop, brands, isWheelBuilder, vehicle, tyreSizes, selectedDiameter, searchDisabled } = this.props
    if (!shop.id)
      return null
    let additionalFields, additionalFieldsText = <T id="Show more filters"/>
    if (showAdvancedFields) {
      additionalFields = (
        <TyreSearchAdvanced
          shop={shop}
          onAdvancedFieldChange={this.onAdvancedFieldChange.bind(this)}
          searchParams={this.props.searchParams}/>
      )
      additionalFieldsText = <T id="Show less filters"/>
    }

    const brandOptions = brands.map(brand => {
      return { label: brand.name, value: brand.id.toString() }
    })
    const selectedBrandValues = brandOptions.filter(brand => this.props.searchParams.brandId.indexOf(brand.value) > -1)

    let linkText = null
    if (this.props.shop.searchModesTyres.length > 1) {
      const unSelectedMode = this.swapSearchMode(this.props.shop.searchModesTyres, this.props.selectedSearchMode)
      // eslint-disable-next-line default-case
      switch (unSelectedMode) {
        case SEARCH_MODES.carModel:
          linkText = <T id="Search car model" />
          break
        case SEARCH_MODES.combined:
          linkText = <T id="Search dimension/license plate number" />
          break
        case SEARCH_MODES.dimension:
          linkText = <T id="Search dimension" />
          break
        case SEARCH_MODES.licensePlate:
          linkText = <T id="Search license plate number" />
          break
      }
    }
    const conditionConfig = this.props.shop.condition && this.props.shop.condition.tyre ? this.props.shop.condition.tyre : {}

    const vehicleComment = this.props.vehicle && this.props.vehicle.comment ? (
      <div style={{marginTop: '10px', marginBottom: '10px', color: 'red'}}>
        {this.props.vehicle.comment}
      </div>
    ) : ''

    const TyreAlternativesComponent = (
      [
        // SEARCH_MODES.carModel,
        SEARCH_MODES.dimension
      ].indexOf(this.props.selectedSearchMode)
    ) >= 0
      ? <TyreAlternativesDefault car={vehicle} onSearchParamChange={this.onAdvancedFieldChange.bind(this)} />
      : <TyreAlternatives car={vehicle} onSearchParamChange={this.onAdvancedFieldChange.bind(this)} />

    const showTyreAlternatives = tyreSizes && tyreSizes.length !== 0

    return (
      <div className="tyre-search">
        {isWheelBuilder && this.props.vehicle && (
          <div className={styles.vehicleInfo}>
            {this.props.vehicle.description}
            {vehicleComment}
          </div>
        )}
        {(!isWheelBuilder && this.props.shop.searchModesTyres.length > 1) && (
          <Link to="" onClick={this.onLinkClick.bind(this)} className={styles.link}>
            {linkText}
          </Link>
        )}
        <Row className={styles.filters}>
          <Col lg={3} md={6} sm={12} xs={12}>
            {!isWheelBuilder && <SearchMode
              urlQuery={this.props.query}
              onEnterPress={this.onSearchClick.bind(this)}
              selectedSearchMode={this.props.selectedSearchMode}
              onFieldChange={this.onAdvancedFieldChange.bind(this)}
              query={this.props.searchParams.query}
            />}
            {!!(isWheelBuilder && selectedDiameter) && (
              <span><b><T id="Selected diameter" />: {selectedDiameter}</b></span>
            )}
            {showTyreAlternatives && TyreAlternativesComponent}
            {(!showTyreAlternatives && !searchDisabled && !!(this.props.vehicle && this.props.vehicle.vehicleId)) && (
              <div className={styles.noInfo}><T id="We currently don't have enough info for this model." /></div>
            )}
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            {
              selectedVehicleTypeSearchMode === SEARCH_MODE_BY_VEHICLE_TYPE.PERSONAL && (
                <TyreType
                  shop={this.props.shop}
                  onChange={this.onAdvancedFieldChange.bind(this)}
                  selectedTypes={this.props.searchParams.tyreType}
                />
              )
            }
            {
              selectedVehicleTypeSearchMode === SEARCH_MODE_BY_VEHICLE_TYPE.HEAVYTRUCK && (
                <React.Fragment>
                  <Checkbox
                    onChange={this.handleWinterOnly.bind(this)}
                    value={!Boolean(this.props.searchParams.isWinterOnly)}
                    label={<T id="Winter only"/>}
                    name="isWinterOnly"
                    isChecked={Boolean(this.props.searchParams.isWinterOnly)}
                  />
                  <div>{Boolean(this.props.searchParams.isWinterOnly)}</div>
                  <AxleType
                    onChange={this.onAxleTypeChange.bind(this)}
                    selectedType={this.props.searchParams.axleType}
                    vehicleSearchMode={selectedVehicleTypeSearchMode}
                  />
                </React.Fragment>
              )
            }
            {
              selectedVehicleTypeSearchMode === SEARCH_MODE_BY_VEHICLE_TYPE.TRACTOR && (
                <AxleType
                  onChange={this.onAxleTypeChange.bind(this)}
                  selectedType={this.props.searchParams.axleType}
                  vehicleSearchMode={selectedVehicleTypeSearchMode}
                />
              )
            }
          </Col>
          <Col lg={2} md={6} sm={12} xs={12}>
            <Condition selectedOptions={this.props.searchParams.condition} onChange={this.onAdvancedFieldChange.bind(this)} config={conditionConfig}/>
            <Quality defaultQualityOptions={this.props.searchParams.quality} onChange={this.onAdvancedFieldChange.bind(this)}/>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <FormGroup className={styles.testResults}>
              <ControlLabel className={`${styles.inline} tyreSearch_inline`}>
                <T id="Test results"/>
              </ControlLabel>
              <StarRatingComponent
                className={styles.starsComponent}
                starCount={5}
                name="minimumTestScore"
                value={this.props.searchParams.minimumTestScore}
                height="18"
                width="18"
                onChange={this.onStarClick.bind(this)}
              />
            </FormGroup>
            <VehicleType
              onChange={this.onVehicleTypeChange.bind(this)}
              vehicleTypeGroups={this.props.shop.vehicleTypeGroups}
              selectedType={this.props.searchParams.vehicleType}
            />
            <FormGroup>
              <ControlLabel>
                <T id="Brand"/>
              </ControlLabel>
              <BrandsSelection
                category={CATEGORIES.TYRE}
                onBrandSelect={this.onBrandSelect.bind(this)}
                options={brandOptions}
                selectedValues={selectedBrandValues}
              />
            </FormGroup>
          </Col>
        </Row>
        {additionalFields}
        <div className={`${styles.advancedLink} tyreSearch_advancedLink`}>
          <Link to="" className={`${styles.inlineLabel} tyreSearch_inlineLabel`} onClick={this.onShowAdvancedSearchClick.bind(this)}>
            {additionalFieldsText} <FontAwesomeIcon  icon={showAdvancedFields ? faCaretUp : faCaretDown} />
          </Link>
        </div>
        <SearchButton
          onClick={this.onSearchClick.bind(this)}
          disabled={this.props.searchDisabled}
          className={styles.button}
          error={this.props.error}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  changeSearchMode: (searchMode, query) => changeSearchMode(searchMode, query)
}, dispatch)
const mapStateToProps = state => {
  const selectedSearchMode = state.tyreSearchParams.searchMode
  return {
    activeSlug: state.menu.activeSlug,
    shop: state.shop,
    brands: state.brands.tyreBrands,
    category: state.categories.activeCategory,
    searchDisabled: state.carPicker.loadingVehicle
      || (selectedSearchMode === SEARCH_MODES.carModel && !state.carPicker.isComplete),
    searchParams: state.tyreSearchParams,
    selectedSearchMode,
    isWheelBuilder: state.wheelBuilder.showWheelProgress,
    vehicle: state.vehicle.vehicle || state.carPicker.vehicle,
    tyreSizes: getTyreAlternatives(state),
    error: state.search.error,
    selectedDiameter: state.wheelBuilder.selectedDiameter || null
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TyreSearch)
