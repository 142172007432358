import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { PREORDER_STEPS } from '../../../constants/preOrderSteps'
import { T } from 'components/components/i18n'
import ChooseSupplierStep from "./ChooseSupplierStep"
import ChooseTargetStep from './ChooseTargetStep'
import Spinner from '../Spinner'
import styles from './styles.less'

export class PreOrder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSupplier: null,
      step: !!props.targetId ? PREORDER_STEPS.PREORDER_STARTED : PREORDER_STEPS.SELECT_SUPPLIER,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.targetId !== nextProps.targetId) {
      this.setState({
        step: !!nextProps.targetId ? PREORDER_STEPS.PREORDER_STARTED : PREORDER_STEPS.SELECT_SUPPLIER,
      })
    }
  }
  setStep(step) {
    this.setState({ step })
  }

  setSupplier(selectedSupplier) {
    this.setState({ selectedSupplier, step: PREORDER_STEPS.SELECT_TARGET });
  }

  render() {
    const { isLoading, initialized, footer } = this.props
    const { step, selectedSupplier } = this.state
    if(isLoading || !initialized)
      return <Spinner />
    return (
      <div className={`${styles.container} container preorder`}>
        {step === PREORDER_STEPS.SELECT_SUPPLIER && <ChooseSupplierStep setSupplier={this.setSupplier.bind(this)} />}
        {((step === PREORDER_STEPS.SELECT_TARGET) || (step === PREORDER_STEPS.PREORDER_STARTED)) && <ChooseTargetStep supplierId={selectedSupplier} goBack={() => this.setStep(PREORDER_STEPS.SELECT_SUPPLIER)} />}
        <div>
          <span><T id="You can find your completed pre orders under" /> </span>
          <Link to="/ordrar"><T id="my orders" /></Link>
        </div>
        {step === PREORDER_STEPS.SELECT_SUPPLIER && <div dangerouslySetInnerHTML={{ __html: footer }} />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    targetId: state.preOrder.targetId,
    isLoading: state.preOrder.loading,
    initialized: state.preOrder.initialized,
    footer: state.preOrder.footer,
  }
}

export default connect(
  mapStateToProps,
)(PreOrder)
