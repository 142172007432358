import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedNumber } from 'react-intl'
import styles from './styles.less'
import { CATEGORIES, getCategoryByTypeId } from '../../../../helpers/categories'
import EuGrading from '../../EuGrading/new'
import SupplierPopover from '../../Search/SearchResults/SupplierPopover'

const OrderLine = (props) => {
  const { line, hasDirectDelivery, hasDiscount, showSupplier } = props

  let productShipments
  if (hasDirectDelivery) {
    productShipments = line.shipments.map((shipment, index) => {
      if (shipment.tracking_url)
        return <a href={shipment.tracking_url} target="_blank" rel="noopener noreferrer">{shipment.tracking_number}{index !== line.shipments.length - 1 && ', '}</a>
      else
        return <span key={shipment.tracking_number}>{shipment.tracking_number}</span>
    })
    productShipments = <td key="product-shipments">{productShipments}</td>
  }
  let discount
  if (hasDiscount) {
    discount = line.discount_type === 'amount' ?
      <FormattedNumber style={`decimal`} value={line.discount_value} maximumFractionDigits={2} minimumFractionDigits={2} /> :
      <FormattedNumber style={`percent`} value={line.discount_value} maximumFractionDigits={2} minimumFractionDigits={2} />
    discount = [
          <td key="unit-price" className={styles.number}>
            <FormattedNumber
              style={`decimal`}
              value={line.unit_price}
              maximumFractionDigits={2}
              minimumFractionDigits={2}
            />
          </td>,
          <td key="discount" className={styles.number}>
            {discount}
          </td>
    ]
  }
  let supplier
  if (showSupplier) {
    supplier = [
      <td key="supplier-name"><SupplierPopover supplierNameComponent={line.supplier_name} /></td>,
      <td key="supplier-id">{ line.supplier_order_id }</td>,
    ]
  }

  const productCategory = getCategoryByTypeId(line.product_type).slug
  let euGrading
  if (productCategory === CATEGORIES.TYRE.slug)
    euGrading = (
      <EuGrading
        product={
          {
            attrs: {
              rollingResistance: line.rolling_resistance,
              wetGrip: line.wet_grip,
              noiseDecibel: line.noise_emission_decibel,
              noiseRating: line.noise_emission_rating,
              tyreSnowGrip: line.tyre_snow_grip,
              tyreIceGrip: line.tyre_ice_grip,
            }
          }
        }
      />
    )
  return (
    <tr className="order-line">
      <td key="product-id" width="12%"><Link to={`/${productCategory}/${line.product_webshop_id}`}>{line.product_id}</Link></td>
      <td key="product-name">{line.product_name}</td>
      <td>{euGrading}</td>
      {productShipments}
      {supplier}
      <td key="comment" className={styles.comment}>{line.comment}</td>
      <td key="quantity" className={styles.number}>{line.quantity}</td>
      {discount}
      <td key="nett-price" className={styles.number} width="8%">
        <FormattedNumber
          style={`decimal`}
          value={line.nett_price}
          maximumFractionDigits={2}
          minimumFractionDigits={2}
        />
      </td>
    </tr>
  )
}

export default OrderLine
