import { validationError, FIELDS_VALIDATION_SUCCESS } from '../actions/signUp'
import { validationErrors, isValid }    from '../helpers/signUp'

export const signUpValidation = ({ dispatch, getState}) => next => action => {  
  if (action.type !== FIELDS_VALIDATION_SUCCESS) {
    return next(action)
  }
  const { signUp } = getState()
  let errors = validationErrors(signUp.params, signUp.step, signUp.customFields)
  if (!isValid(errors)) {
    dispatch(validationError(errors))
  } else {
    next(action)
  }
}

