import {
  RETRIEVE_SEARCH_RESULTS,
  SEARCH_RESULTS_RETRIEVED,
  SET_RIM_SEARCH_PARAMETERS,
  SET_TYRE_SEARCH_PARAMETERS,
  SET_COMPLETE_WHEEL_SEARCH_PARAMETERS,
  RESET_SEARCH,
  SEARCH_ERROR,
  SET_IS_SEARCH_FORM_VALID,
  RESULTS_PER_PAGE
} from '../actions/search'
import {
  PRODUCT_RESET,
  PRODUCT_RETRIEVED
} from '../actions/product'

import { CATEGORIES } from '../helpers/categories'
import { formatCurrency, calculatePrice } from '../helpers/price'
import { RESET_WHEEL_BUILDER } from '../actions/wheelBuilder'

const getPaginationOptions = (count, currentPage) => {
  const pagination = []
  const maxPage = Math.ceil(count / RESULTS_PER_PAGE)
  const start = Math.max(1, currentPage - 3)
  const end = Math.min(start + 7, maxPage)

  pagination.push({
    text: '«',
    page: currentPage - 1,
    disabled: currentPage <= 1
  })
  for (let i = start; i <= end; i++) {
    pagination.push({
      text: i,
      page: i,
      active: currentPage === i
    })
  }
  pagination.push({
    text: '»',
    page: currentPage + 1,
    disabled: currentPage >= maxPage
  })
  return pagination
}

const mapProducts = (products) => {
  return products.map(product => {
    if (!product.stock) {
      product.estimatedDeliveryType      = 0;
    } else {
      let deliv = product.deliveryTime;
      if (!deliv) {
        if (product.source === 'stock') {
          deliv = { min: 1, max: 1, 'class': 1 };
        } else {
          deliv = { min: 3, max: 5, 'class': 2 };
        }
      }
      product.deliveryTime = deliv
      product.estimatedDeliveryType = deliv['class'];
    }

    product.formattedPrice = formatCurrency(product.price)
    product.formattedPriceInclVat = calculatePrice(product.price, 1, product.vatPercent)
    product.slug = product.brand ? `${product.brand.name} ` : ''
    product.slug = `${product.slug}${product.name}`
      .toLowerCase()
      .replace(/[åä]/, 'a')
      .replace('ö', 'o')
      .replace(/[^\w\d\s]*/g, '')
      .replace(/\s+/g, '-')
    return product
  })
}

const mapRearProducts = (products) => {
  const productsRearGroup = {}
  products.forEach(product => {
    const supplierId = product.supplier.id
    const locationId = product.location.id
    const brandKey   = `${product.brand.id}-${product.model.id}`

    if (!productsRearGroup.hasOwnProperty(brandKey)) {
      productsRearGroup[brandKey] = []
    }

    if (supplierId) {
      if (!productsRearGroup[brandKey].hasOwnProperty(supplierId)) {
        productsRearGroup[brandKey][supplierId] = []
      }
      if (!productsRearGroup.hasOwnProperty(supplierId)) {
        productsRearGroup[`supplier-${supplierId}`] = []
      }

      productsRearGroup[brandKey][supplierId].push(product)
      productsRearGroup[`supplier-${supplierId}`].push(product)
    } else if (locationId) {
      if (!productsRearGroup[brandKey].hasOwnProperty(locationId)) {
        productsRearGroup[brandKey][locationId] = []
      }
      if (!productsRearGroup.hasOwnProperty(locationId)) {
        productsRearGroup[`location-${locationId}`] = []
      }

      productsRearGroup[brandKey][locationId].push(product)
      productsRearGroup[`location-${locationId}`].push(product)
    }
  })

  productsRearGroup['all'] = products

  return productsRearGroup
}

const combineFrontRear = (products, rearProducts, sortBy) => {
  const combined = {}
  let newList = []

  const createProduct = (front, rear, sortingWeight) => {
    const _key = `${front.productId}-${rear.productId}`
    const _keyReverse = `${rear.productId}-${front.productId}`
    if (!combined[_key] && !combined[_keyReverse]) { // NOTE: Prevent duplication
      const _product = JSON.parse(JSON.stringify(front))
      const isSameWithRear = _product.productId === rear.productId
      _product['rear'] = rear
      _product['isSameWithRear'] = isSameWithRear
      _product['sortingWeight'] = sortingWeight + _product.deliveryTime.max + rear.deliveryTime.max
      _product['sortingPrice'] = (_product.consumerPrice + rear.consumerPrice) / 100
      _product['_key'] = _key

      if (isSameWithRear && _product.stock <= 2)  {
        // NOTE: Alternatively, we could add more weight so that it shows at bottom if we still
        //       want it to appear on list
        // _product['sortingWeight'] = _product['sortingWeight'] + 1000000
        return
      }
      combined[_key] = _product
    }
  }

  products.forEach(
    product => {
      const brandKey =  `${product.brand.id}-${product.model.id}`
      const sourceId = product.supplier.id || product.location.id
      // const sourceKey = product.supplier.id
      //   ? `supplier-${product.supplier.id}`
      //   : (product.location.id ? `location-${product.location.id}` : null)

      // TODO: Rim, complete wheels too...

      // NOTE: Check if there are other non-empty sourceIds but same brand
      // const sameBrandOtherSourceIds = (
      //   rearProducts[brandKey] &&
      //     Object.keys(rearProducts[brandKey])
      //       .filter(id =>
      //         id !== sourceId &&
      //         Array.isArray(rearProducts[brandKey]) &&
      //         Array.isArray(rearProducts[brandKey][id]) &&
      //         rearProducts[brandKey][id].length > 0
      //       )
      //   ) || []

      if (
        Array.isArray(rearProducts[brandKey]) &&
        Array.isArray(rearProducts[brandKey][sourceId]) &&
        rearProducts[brandKey][sourceId].length
      ) {
        // NOTE: Same brand and same source
        rearProducts[brandKey][sourceId].forEach(rear => {
          createProduct(product, rear, 0)
        })
      }

      // NOTE: If no products from same brand and source,
      //       and if we want to show mix brand, and mix source

      // NOTE: Maybe don't need to return all
      // if (!Object.values(combined).length === 0) {
      // if (!Object.values(combined).length < 10) {
        // if (sameBrandOtherSourceIds.length) {
        //   // NOTE: Same brand and different source
        //   sameBrandOtherSourceIds.forEach(_id => {
        //     rearProducts[brandKey][_id].forEach(rear => {
        //       createProduct(product, rear, 10000)
        //     })
        //   })
        // }
      // }

      // if (!Object.values(combined).length < 10) {
        // if (Array.isArray(rearProducts[sourceKey]) && rearProducts[sourceKey].length) {
        //   // NOTE: Fallback to different brand same source
        //   rearProducts[sourceKey].forEach(rear => {
        //     createProduct(product, rear, 100000)
        //   })
        // }
      // }

      // if (!Object.values(combined).length < 10) {
        // rearProducts['all'].forEach(rear => { // Catch all
        //   // NOTE: Fallback to different brand different source
        //   createProduct(product, rear, 1000000)
        // })
      // }
    }
  )

  // NOTE: Sorting based on newly calculated weight
  newList = Object.values(combined)
  if (sortBy === 'price') {
    newList.sort((a, b) => a.sortingPrice - b.sortingPrice)
  } else {
    newList.sort((a, b) => (a.sortingWeight + a.sortingPrice) - (b.sortingWeight + b.sortingPrice))
  }

  return newList
}

const resetResultsByCategory = () => {
  const resultsByCategory = {}
  Object.keys(CATEGORIES).forEach(key => {
    resultsByCategory[CATEGORIES[key].id] = { products: [], pagination: [] }
  })
  return resultsByCategory
}

const getInitialState = () => {
  return {
    isFormValid: true,
    resultsByCategory: {
      completeWheel: {products: [], pagination: [], searchMode: null },
      tyre: {products: [], pagination: [], searchMode: null },
      rim: {products: [], pagination: [], searchMode: null }
    },
    activeProduct: null,
    error: ''
  }
}

const search = (state = getInitialState(), action) => {
    switch (action.type) {
      case SET_IS_SEARCH_FORM_VALID:
        return { ...state, isFormValid: action.isFormValid }
      case RESET_SEARCH:
        return getInitialState()
      case SET_TYRE_SEARCH_PARAMETERS:
      case SET_RIM_SEARCH_PARAMETERS:
      case SET_COMPLETE_WHEEL_SEARCH_PARAMETERS:
        let resultsByCategory = resetResultsByCategory()
        resultsByCategory[action.category.id] = Object.assign({}, resultsByCategory[action.category.id], {
          searchParamsInitialized: true,
          initialSearchDone: false,
          searchMode: action.parameters.searchMode
        })
        return Object.assign({},
          state,
          {
            resultsByCategory
          }
        )
      case RETRIEVE_SEARCH_RESULTS:
        resultsByCategory = Object.assign({}, state.resultsByCategory)
        resultsByCategory[action.category.id] = Object.assign({}, resultsByCategory[action.category.id], {
          isLoading: true
        })
        return Object.assign({},
          state,
          {
            resultsByCategory,
            error: ''
          }
        )
      case SEARCH_RESULTS_RETRIEVED: {
        const isPair = !!(action.criteria && action.criteria.isPair)
        const rearProducts = Array.isArray(action.rearProducts) ? mapRearProducts(mapProducts(action.rearProducts)) : []

        let isStaggeredFitment = isPair
        const { criteria } = action
        // TODO: Return from API instead?
        // TODO: Helper and better typecheck when object properties are incomplete

        if (criteria) {
          const loadIndexFront = Array.isArray(criteria.load_index) ? criteria.load_index[0] : criteria.load_index
          const loadIndexRear = Array.isArray(criteria.load_index_rear) ? criteria.load_index_rear[0] : criteria.load_index_rear
          if (
            isPair && criteria.tyre_dimensions && criteria.tyre_dimensions_rear &&
            criteria.tyre_dimensions[0][0] === criteria.tyre_dimensions_rear[0][0] &&
            criteria.tyre_dimensions[0][1] === criteria.tyre_dimensions_rear[0][1] &&
            criteria.tyre_dimensions[0][2] === criteria.tyre_dimensions_rear[0][2] &&
            (
              (!criteria.load_index && !criteria.load_index_rear) ||
              (loadIndexFront === loadIndexRear)
            )
          ) {
            isStaggeredFitment = false
          }
        }

        resultsByCategory = Object.assign({}, state.resultsByCategory)
        resultsByCategory[action.category.id] = Object.assign({}, resultsByCategory[action.category.id], {
          initialSearchDone: true,
          isLoading: false,
          products: isPair && isStaggeredFitment /* && Array.isArray(action.rearProducts) */
            // NOTE: Only combine if this is tyre search have rear products
            ? combineFrontRear(mapProducts(action.products), rearProducts, action.sortBy)
            : mapProducts(action.products),
          rearProducts,
          sortBy: action.sortBy,
          isStaggeredFitment,
          isPair,
          // products: mapProducts(COMPLETE_WHEEL_SEARCH_RESPONSE.data.products),
          pagination:  getPaginationOptions(action.count, action.page),
          count: action.count
        })
        return Object.assign({},
          state,
          {
            resultsByCategory
          }
        )
      }
      case SEARCH_ERROR:
        resultsByCategory = Object.assign({}, state.resultsByCategory)
        resultsByCategory[action.category.id] = Object.assign({}, resultsByCategory[action.category.id], {
          initialSearchDone: true,
          isLoading: false,
          products: [],
          pagination:  getPaginationOptions(0, 1),
          count: 0
        })
        return Object.assign({},
          state,
          {
            resultsByCategory,
            error: action.error
          }
        )

      case PRODUCT_RETRIEVED:
        return { ...state, activeProduct: mapProducts(action.product)[0] }
      case PRODUCT_RESET:
        return { ...state, activeProduct: null }
      case RESET_WHEEL_BUILDER:
        return { ...state, ...getInitialState() }
      default:
        return state
  }
}

export default search
