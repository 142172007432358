import {
  SET_SHOW_WHEEL_PROGRESS,
  SET_RIM,
  SET_TYRE,
  SET_CURRENT_STEP,
  INITIALIZE_WHEEL_BUILDER,
  RESET_WHEEL_BUILDER,
} from '../actions/wheelBuilder'
import CATEGORIES from '../helpers/categories'

const wheelBuilder = (state = {
  isInitialized: false,
}, action) => {
  switch (action.type) {
    case INITIALIZE_WHEEL_BUILDER:
      return {
        ...state,
        isInitialized: true,
        showWheelProgress: true
      }
    case SET_SHOW_WHEEL_PROGRESS:
      return Object.assign({}, state, {
        showWheelProgress: action.showWheelProgress,
      })
    case SET_RIM:
      return Object.assign({}, state, {
        [CATEGORIES.RIM.id]: action.rim,
        selectedDiameter: action.rim.attrs.diameter.toString(),
        currentStep: 1
      })
    case SET_TYRE:
      return Object.assign({}, state, {
        [CATEGORIES.TYRE.id]: action.tyre,
        currentStep: 2
      })
    case SET_CURRENT_STEP:
      return Object.assign({}, state, {
        currentStep: action.step
      })
    case RESET_WHEEL_BUILDER:
      return {
        ...state,
        currentStep: 0,
        rim: null,
        tyre: null,
        selectedDiameter: null,
      }
    default:
      return state
  }
}

export default wheelBuilder
