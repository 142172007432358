// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__header___doxBa {
  border-bottom: 2px solid #d8d8d8;
  font-weight: bold;
  padding-bottom: 16px;
}
.styles__headerCellRight___M6GkN {
  display: flex;
  justify-content: flex-end;
}
.styles__mainContent___y7l6O {
  background: white;
  border-radius: 4px;
  padding: 36px;
}
@media (max-width: 991px) {
  .styles__mainContent___y7l6O {
    padding: 16px;
  }
}
.styles__buttonRow___X017P {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.styles__tabContent___Xwman {
  padding: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/CustomerPreferences/ProfitMargins/styles.less"],"names":[],"mappings":"AAEA;EACE,gCAAA;EACA,iBAAA;EACA,oBAAA;AADF;AAIA;EACE,aAAA;EACA,yBAAA;AAFF;AAKA;EACE,iBAAA;EACA,kBAAA;EACA,aAAA;AAHF;AAIE;EAAA;IACE,aAAA;EADF;AACF;AAIA;EACE,WAAA;EACA,aAAA;EACA,yBAAA;AAFF;AAKA;EACE,aAAA;AAHF","sourcesContent":["@import '../../../../shared/variables.less';\n\n.header {\n  border-bottom: 2px solid @eon-border-color;\n  font-weight: bold;\n  padding-bottom: 16px;\n}\n\n.headerCellRight {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.mainContent {\n  background: white;\n  border-radius: 4px;\n  padding: 36px;\n  @media (max-width: 991px) {\n    padding: 16px;\n  }\n}\n\n.buttonRow {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.tabContent {\n  padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles__header___doxBa`,
	"headerCellRight": `styles__headerCellRight___M6GkN`,
	"mainContent": `styles__mainContent___y7l6O`,
	"buttonRow": `styles__buttonRow___X017P`,
	"tabContent": `styles__tabContent___Xwman`
};
export default ___CSS_LOADER_EXPORT___;
