import React from 'react'
import { T } from 'components/components/i18n'
import {
  FormGroup,
  ControlLabel
} from 'react-bootstrap'
import Selectbox from '../../../Form/Selectbox'
import { HEAVYTRUCK_AXLES, TRACTOR_AXLES } from '../../../../../constants/axleTypes'
import { SEARCH_MODE_BY_VEHICLE_TYPE } from '../../../../../constants/searchModes'

const AxleType = ({ vehicleSearchMode, selectedType, onChange }) => {
  let axleTypes
  switch (vehicleSearchMode) {
    case SEARCH_MODE_BY_VEHICLE_TYPE.HEAVYTRUCK:
      axleTypes = HEAVYTRUCK_AXLES.map(o => {
          return {
            value: o.value,
            label: o.label
          }
        })
      break;
    case SEARCH_MODE_BY_VEHICLE_TYPE.TRACTOR:
      axleTypes = TRACTOR_AXLES.map(o => {
          return {
            value: o.value,
            label: o.label
          }
        })
      break;
    default:
      // do nothing
  }

  const defaultOption = axleTypes.find(o => o.value === 0);

  return (
    <FormGroup className="axle-type">
      <ControlLabel>
        <T id="Axle type"/>
      </ControlLabel>
      <Selectbox
        onChange={onChange}
        options={axleTypes}
        value={axleTypes.find(option => option.value === selectedType) || defaultOption}
        name="axleType"
      />
    </FormGroup>
  )
}

export default AxleType
