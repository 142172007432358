import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { T } from 'components/components/i18n'
import StepsList from './StepsList'
import styles from './styles.less'
import classnames from 'classnames'

const ChooseSupplierStep = ({ setSupplier, suppliers, header }) => {
  const selectSupplier = (id) => {
    setSupplier(id);
  }

  return (
    <div className={classnames(styles.sections, 'preorder-choose-supplier')}>
      <div className={classnames(styles.suppliersList, 'preorder-suppliers-list')}>
        <h3><T id="Preorder season" /></h3>
        <div dangerouslySetInnerHTML={{ __html: header }} />
        {suppliers.map(supplier => <div className={styles.supplierButton} variant="light" onClick={() => selectSupplier(supplier.id)}>{supplier.title}</div>)}
      </div>
      <div><StepsList /></div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

const mapStateToProps = state => {
  return {
    suppliers: state.preOrder.suppliers,
    header: state.preOrder.header,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseSupplierStep)
