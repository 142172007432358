import { api } from '../../helpers/api'

export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS'

export const startPasswordReset = (email, shop) => {
  return dispatch => {
    dispatch({
      type: REQUEST_PASSWORD_RESET
    })
    api(`${shop.apiUrl}/forgot-password/start`, 'post',
      {
        email,
        webshopId: shop.webshopId
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.err)
        dispatch({
          type: PASSWORD_RESET_ERROR,
          error: response.err
        })
      else
        dispatch({
          type: REQUEST_PASSWORD_RESET_SUCCESS
        })
    })
    .catch(() => {
      dispatch({
        type: PASSWORD_RESET_ERROR,
        error: 'Unknown error'
      })
    })
  }
}

export const TOKEN_VALID = 'TOKEN_VALID'
export const CHECKING_TOKEN = 'CHECKING_TOKEN'
export const TOKEN_ERROR = 'TOKEN_ERROR'

export const checkTokenValidity = (token, shop) => {
  return dispatch => {
    dispatch({
      type: CHECKING_TOKEN
    })
    api(`${shop.apiUrl}/forgot-password/check`, 'post', {
        token,
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response.err)
        dispatch({
          type: TOKEN_ERROR,
          error: response.err
        })
      else
        dispatch({
          type: TOKEN_VALID,
          token
        })
    })
    .catch(() => dispatch({
      type: TOKEN_ERROR,
      error: 'Token error'
    }))
  }
}

export const START_PASSWORD_CHANGE = 'START_PASSWORD_CHANGE'
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR'
export const PASSWORD_CHANGE_DONE = 'PASSWORD_CHANGE_DONE'

export const setPassword = (params, shop) => {
  return dispatch => {
    dispatch({
      type: START_PASSWORD_CHANGE
    })
    if (params.password !== params.confirmedPassword)
      dispatch({
        type: PASSWORD_CHANGE_ERROR,
        error: "Fields don't match"
      })
    else {
      api(`${shop.apiUrl}/forgot-password/change`,
        'post',
        {
          password: params.password,
          webshopId: shop.webshopId,
          token: params.token
        }
      )
      .then(response => response.json())
      .then(response => {
        if (response.err)
          dispatch({
            type: PASSWORD_CHANGE_ERROR,
            error: response.err
          })
        else
          dispatch({
            type: PASSWORD_CHANGE_DONE
          })
      })
      .catch(() => dispatch({
        type: PASSWORD_CHANGE_ERROR,
        error: 'Unknown error'
      }))
    }
  }
}

export const SET_FORGOT_PASSWORD_PARAMETER = 'SET_FORGOT_PASSWORD_PARAMETER'

export const setParameter = (name, value) => {
  return dispatch => dispatch({
    type: SET_FORGOT_PASSWORD_PARAMETER,
    name,
    value
  })
}

export const RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD'
export const resetForgotPassword = () => {
  return dispatch => {
    dispatch({
      type: 'RESET_FORGOT_PASSWORD'
    })
  }
}
