export const api = (url, method = null, params = null) => {
  const headers = { 'Authorization': `Session ${localStorage.getItem('sessionToken')}`}
  if (params)
    headers['Content-Type'] = 'application/json'

  const options = {
    method: method || 'get',
    headers,
  }
  if (params)
    options.body = JSON.stringify(params)
  return fetch(url, options)
}
