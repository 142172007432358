import { push } from 'react-router-redux'
import qs from 'query-string'
import { api } from '../../helpers/api'

export const INIT_ORDERS = 'INIT_ORDERS'
export const SET_ORDER_SEARCH_PARAMS = 'SET_ORDER_SEARCH_PARAMS'
export const RETRIEVE_ORDERS = 'RETRIEVE_ORDERS'
export const ORDERS_RETRIEVED = 'ORDERS_RETRIEVED'
export const RETRIEVE_ORDER = 'RETRIEVE_ORDER'
export const ORDER_RETRIEVED = 'ORDER_RETRIEVED'
export const SET_DELIVERED = 'SET_DELIVERED'
export const UNDO_DELIVERED = 'UNDO_DELIVERED'
export const RESULTS_PER_PAGE = 25;

const ordersRetrieved = (orders, page, count) => {
  return {
    type: ORDERS_RETRIEVED,
    orders,
    page,
    count
  }
}

const orderRetrieved = (order) => {
  return {
    type: ORDER_RETRIEVED,
    order
  }
}

export const initOrders = (shop, isReload = false) => {
  return dispatch => {
    dispatch({ type: INIT_ORDERS })
    const search = window.location.search || qs.stringify({page: 1})
    const _search = qs.parse(search)
    dispatch(
      getOrders(
        shop,
        parseInt(_search.page, 10) || 1,
        _search.query,
        _search.delivered,
        true,
        isReload
      )
    )
  }
}

export const getOrders = (shop, page, query, delivered, isInitialize = false, isReload = false) => {
  return dispatch => {
    dispatch({ type: RETRIEVE_ORDERS })
    if (!isReload) {
      dispatch(push({
        pathname: '/ordrar',
        search: qs.stringify({
          page, query, delivered
        })
      }))
    }
    dispatch({
      type: SET_ORDER_SEARCH_PARAMS,
      page: page, query, delivered, isInitialize
    })
    return api(`${shop.apiUrl}/orders?page=${page}&limit=${RESULTS_PER_PAGE}&query=${query || ''}&delivered=${delivered || ''}`)
      .then(response => {
        return response.json()
      })
      .then(response => {
          dispatch(ordersRetrieved(response.data, page, response.count))
      })
  }
}

export const setDelivered = (shop, orderId) => {
  return async (dispatch) => {
    dispatch({ type: SET_DELIVERED })
    const res = await api(`${shop.apiUrl}/orders/${orderId}/delivered`, 'post')
      .then(response => {
        return response.json()
      })
      .then(response => {
        if (response.data) {
          return true
        }
        return false
      })
      .catch(() => {
        return false
      })
    return res
  }
}

export const undoDelivered = (shop, orderId) => {
  return async (dispatch) => {
    dispatch({ type: UNDO_DELIVERED })
    const res = await api(`${shop.apiUrl}/orders/${orderId}/delivered/undo`, 'delete')
      .then(response => {
        return response.json()
      })
      .then(response => {
        if (response.data) {
          return true
        }
        return false
      })
      .catch(() => {
        return false
      })
    return res
  }
}

export const getOrder = (orderId, shop) => {
  return dispatch => {
    dispatch({ type: RETRIEVE_ORDER })
    return api(`${shop.apiUrl}/orders/${orderId}`)
      .then(response => {
        return response.json()
      })
      .then(response => {
        	dispatch(orderRetrieved(response.data))
      })
  }
}
