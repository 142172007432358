import React from 'react'
import { RadioCheckedSvg, RadioUncheckedSvg } from '../SvgIcon'
import styles from './styles.less'

export default class RadioButton extends React.Component {
  constructor(props) {
    super(props)
    this.changeHandler = this.onChange.bind(this)
  }

  onChange(event) {
    const { onChange, name, value } = this.props
    onChange(name, value)
  }

  render() {
    const { isChecked, label, className, small } = this.props
    let icon
    if (isChecked)
      icon = <RadioCheckedSvg className="svg-colored" height={small ? 16 : 20}/>
    else
      icon = <RadioUncheckedSvg className="svg-muted" height={small ? 16 : 20}/>

    let radioClassName = className ? `${styles.radio} ${className}` : `${styles.radio}`
    radioClassName = isChecked ? `${radioClassName} radio-checked` : `${radioClassName} radio-unchecked`
    return (
      <div
        className={`${radioClassName} radioButton`}
        onClick={this.changeHandler}>
        {icon}
        <span className={`${styles.label} radioButton_label`}>{label}</span>
      </div>
    )
  }
}
