import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from './styles.less'
import { logout } from '../../../actions/user'
import { setActiveItem } from '../../../actions/menu'
import { replaceDynamicValues } from '../../../helpers/string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.handleButtonClick = this.onOpen.bind(this)
    this.handleClose = this.onClose.bind(this)
    this.sidebarRef = null
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClose)
  }

  onOpen() {
    this.setState((prevState) => {
      return {
        isOpen: !prevState.isOpen
      }
    })
  }

  onClose(event) {
    if (this.sidebarRef && !this.sidebarRef.contains(event.target)) {
      this.setState({
        isOpen: false
      })
    }
  }

  getOnClick(item) {
    if (item.type === 'External') {
      return () => window.location.href = item.url
    }
    if (item.slug === 'logout')
      return (event) => {
        event.preventDefault()
        this.props.logout()
      }
    return () => this.props.setActiveItem(item.slug, item.url)
  }

  getItemTitle(item) {
    return replaceDynamicValues(item.title, { email: '' })
  }

  isItemVisible(item) {
    const { isUserLoggedIn } = this.props
    if (item.slug === 'login' && isUserLoggedIn)
      return false
    if ((item.slug === 'logout' || item.slug === 'order-list') && !isUserLoggedIn)
      return false
    return true
  }


  render() {
    const { options, className, user } = this.props
    const { isOpen } = this.state
    let content
    if (isOpen) {
      const items = []
      options.forEach((option, index) => {
        if (this.isItemVisible(option))
          items.push(
            <div className={styles.item} onClick={this.getOnClick(option)} key={index}>{this.getItemTitle(option)}</div>
          )
      })
      content = (
        <div className={`${styles.sidebar} text-normal`}>
          {!!user ? (
            <div className={`${styles.header} background-normal`}>
              {user.user ? user.user.email : null}
            </div>
          ) : null}
          {items}
        </div>
      )
    }

    return (
      <div className={`${className} sidebar`} ref={el => this.sidebarRef = el}>
        <span onClick={this.handleButtonClick} style={{"cursor":"pointer"}}>
          <FontAwesomeIcon icon={faBars} />
        </span>
        {content}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    isUserLoggedIn: state.user.isLoggedIn
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: () => logout(),
  setActiveItem: (slug, url) => setActiveItem(slug, url)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)
