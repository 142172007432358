import React from 'react'
import { ControlLabel, Modal, Button, FormControl, Alert } from 'react-bootstrap'
import { getCategoryById } from '../../../../../helpers/categories'
import { T } from 'components/components/i18n'
import Selectbox from '../../../Form/Selectbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default class NewMarginModal extends React.Component {
  constructor(props) {
    super(props)
    const { margin } = this.props
    this.state = {
      group: margin ? margin.group : '',
      diameter: margin ? margin.diameter : '',
      percentage: margin ? margin.percentage : 0,
      amount: margin ? margin.amount : 0
    }
    this.handleInputChange = this.onInputChange.bind(this)
    this.handleGroupChange = this.onGroupChange.bind(this)
  }

  onGroupChange(option) {
    this.setState({
      group: option.value
    })
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    const { onButtonClick, closeModal, selectedType, margin, isLoading, errorMessage } = this.props
    const category = getCategoryById(selectedType)
    const spinner = isLoading ? <FontAwesomeIcon icon={faSpinner} /> : null
    const alert = !!errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null
    const loadingMessage = isLoading ? (
      <div>
        <T
          id="NewMarginModal.loadingMessage"
          defaultMessage="It may take a few moments before the prices are updated in the product search"
        />
      </div>) : null
    const title = selectedType === 'tyre' ? <T id="New price margin for tyres"/> : <T id="New price margin for rims"/>
    return (
      <Modal show onHide={closeModal} backdrop="static" className="profit-margins-new-modal">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingMessage}
          {alert}
          <ControlLabel>
            <T id="Type"/>
          </ControlLabel>
          <Selectbox
            name="group"
            onChange={this.handleGroupChange}
            options={category.types}
            value={category.types.find(option => option.value === this.state.group)}
          />
          <ControlLabel><T id="Diameter"/></ControlLabel><FormControl name="diameter" onChange={this.handleInputChange} value={this.state.diameter}/>
          <ControlLabel><T id="Percent"/></ControlLabel><FormControl type="number" name="percentage" onChange={this.handleInputChange} value={this.state.percentage}/>
          <ControlLabel><T id="Amount"/></ControlLabel><FormControl type="number" name="amount" onChange={this.handleInputChange} value={this.state.amount}/>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isLoading} variant="primary" onClick={() => onButtonClick(this.state, margin)}>
            {spinner} <T id="Save changes"/>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
