// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchInfo__infoTable___QkjCk {
  margin-bottom: 0 !important;
}
.searchInfo__infoTable___QkjCk tr.searchInfo__infoLabel___MZOMz td {
  width: 100%;
  padding: 0.1em;
}
.searchInfo__infoTable___QkjCk tr.searchInfo__infoData___A2uag td {
  width: 100%;
  padding: 0.1em;
}
.searchInfo__infoTable___QkjCk tr.searchInfo__infoLabel___MZOMz,
.searchInfo__infoTable___QkjCk tr.searchInfo__infoData___A2uag {
  display: block;
  width: 100%;
}
.searchInfo__infoTable___QkjCk tr.searchInfo__infoData___A2uag {
  margin-bottom: 0.65em;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchButton/searchInfo.less"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;AAEA;EACI,WAAA;EACA,cAAA;AAAJ;AAGA;EACI,WAAA;EACA,cAAA;AADJ;AAIA;;EAEI,cAAA;EACA,WAAA;AAFJ;AAKA;EACI,qBAAA;AAHJ","sourcesContent":[".infoTable {\n    margin-bottom: 0 !important;\n}\n\n.infoTable tr.infoLabel td {\n    width: 100%;\n    padding: 0.1em;\n}\n\n.infoTable tr.infoData td {\n    width: 100%;\n    padding: 0.1em;\n}\n\n.infoTable tr.infoLabel,\n.infoTable tr.infoData {\n    display: block;\n    width: 100%;\n}\n\n.infoTable tr.infoData {\n    margin-bottom: 0.65em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoTable": `searchInfo__infoTable___QkjCk`,
	"infoLabel": `searchInfo__infoLabel___MZOMz`,
	"infoData": `searchInfo__infoData___A2uag`
};
export default ___CSS_LOADER_EXPORT___;
