import { TYRE_TYPES, TYPES, SUBTYPES, SUBTYPES_FRICTION, SUBTYPES_STUD } from 'constants/tyreTypes'

export const filteredMainTyreTypes = (enabledTyreTypes) => {
  return Object.keys(TYPES)
    .filter(
      key => key === 'ALL' ||
      enabledTyreTypes.includes(parseInt(TYPES[key].id, 10))
    )
}

export const filteredSubTyreTypes = (enabledTyreTypes) => {
  return Object.keys(SUBTYPES)
    .filter(
      key => enabledTyreTypes.includes(parseInt(SUBTYPES[key].id, 10))
    )
}

export const filteredSubTyreTypesFriction = (enabledTyreTypes) => {
  return Object.keys(SUBTYPES_FRICTION)
    .filter(
      key => enabledTyreTypes.includes(parseInt(SUBTYPES_FRICTION[key].id, 10))
    )
}

export const filteredSubTyreTypesStud = (enabledTyreTypes) => {
  return Object.keys(SUBTYPES_STUD)
    .filter(
      key => enabledTyreTypes.includes(parseInt(SUBTYPES_STUD[key].id, 10))
    )
}

export const filteredTyreTypes = (enabledTyreTypes) => {
  return Object.keys(TYRE_TYPES)
    .filter(
      key => enabledTyreTypes.includes(parseInt(TYRE_TYPES[key].id, 10))
    )
}
