import React, { useState } from 'react'
import { FormControl } from 'react-bootstrap'
import styles from './styles.less'

// TODO: Update with input mask capabilities
function InputDecimal(props) {
  const [value, setValue] = useState(props.defaultValue)
  const [formValue, setFormValue] = useState(props.defaultValue)
  const [isEditing, setIsEditing] = useState(false)

  const complexFormatRegex = /^(\d+([.,]\d+)?)([xX-]{1})(\d+([.,]\d+)?)$/
  const numberFormatRegex = /^\d+([.,]\d+)?$/
  const language = navigator.language

  const formatNumber = (number) => {
    if (number) {
      const matchComplex = String(number).match(complexFormatRegex)

      if (matchComplex) {
        const firstNumber = parseFloat(matchComplex[1])
        const secondNumber = parseFloat(matchComplex[4])
        const formattedFirst = !isNaN(firstNumber)
          ? Intl.NumberFormat(
              language,
              {
                style: 'decimal',
                maximumFractionDigits: 2,
              }
            ).format(firstNumber)
          : matchComplex[1]

        const formattedSecond = !isNaN(secondNumber)
          ? Intl.NumberFormat(
              language,
              {
                style: 'decimal',
                maximumFractionDigits: 2,
              }
            ).format(secondNumber)
          : matchComplex[3]

        return `${formattedFirst}${matchComplex[3]}${formattedSecond}`
      }

      const matchNumber = String(number).match(numberFormatRegex)
      if (matchNumber) {
        return Intl.NumberFormat(
          language,
          {
            style: 'decimal',
            maximumFractionDigits: 2,
          }
        ).format(number)
      }

      return number
    }
    return props.placeholder
  }

  const handleChange = (event) => {
    const inputValue = event.target.value
    const sanitizedValue = inputValue
      ? inputValue.replace(/[^0-9.,xX-]/g, '')
      : ''
    setValue(sanitizedValue)
    if (sanitizedValue) {
      const _value = sanitizedValue.replace(/\s+/g, '').replace(/,/g, '.')
      const matchComplex = String(_value).match(complexFormatRegex)
      const matchNumber = String(_value).match(numberFormatRegex)

      if (matchComplex || matchNumber) {
        event.target.value = _value
        setFormValue(_value)
      } else {
        event.target.value = sanitizedValue
        setFormValue(sanitizedValue)
      }
    } else {
      event.target.value = ''
      setFormValue('')
    }

    if (props.onChange) {
      props.onChange(event)
    }
  }

  const handleFocus = () => {
    setIsEditing(true)
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleBlur = (event) => {
    setIsEditing(false)

    if (props.onBlur) {
      props.onBlur(event)
    }
  }

  const placeholder = !value ? styles.placeholder : ''

  return (
    <div>
      {isEditing ? (
        <React.Fragment>
          <input
            {...props}
            type="hidden"
            value={formValue}
          />
          <FormControl
            type="text"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={props.placeholder}
            autoFocus
          />
        </React.Fragment>
      ) : (
        <span
          tabIndex={0}
          onFocus={handleFocus}
          onClick={handleEdit}
          className={`${placeholder} ${styles['inputDisplay']}`}
        >
          {formatNumber(formValue)}
        </span>
      )}
    </div>
  )
}

export default InputDecimal
