import React from 'react'
import { T } from 'components/components/i18n'

const HEAVYTRUCK_AXLES = [
  {
    label: <T id="All" />,
    value: 0
  },
  {
    label: <T id="Steer" />,
    value: 1
  },
  {
    label: <T id="Drive" />,
    value: 2
  },
  {
    label: <T id="Trailer" />,
    value: 3
  }
]

const TRACTOR_AXLES = [
  {
    label: <T id="All" />,
    value: 0
  },
  {
    label: <T id="Front" />,
    value: 4
  },
  {
    label: <T id="Rear" />,
    value: 5
  }
]

export { TRACTOR_AXLES, HEAVYTRUCK_AXLES }