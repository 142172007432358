import React from 'react'
import { T } from 'components/components/i18n'

const DeliveryTime = (props) => {
  const { deliveryInfo, short } = props
  if (deliveryInfo.freeText) {
    const freeTextHtml = <div dangerouslySetInnerHTML={{ __html: deliveryInfo.freeText }}/>
    return <span>{freeTextHtml}</span>
  }

  const isRange = deliveryInfo.min !== deliveryInfo.max
  if (!isRange && deliveryInfo.min === 0) {
    const deliveryTime = (short ?
      <T id="Immediate del."/>  :
      <T id="Delivery within 24h"/>
    )
    return deliveryTime
  }
  let deliveryDaysSuffix = short ? <T id="d"/> : <T id="arb.dgr. lev.tid"/>
  const deliveryDays = isRange ? `${deliveryInfo.min}-${deliveryInfo.max}` : deliveryInfo.min
  return <span className="delivery-time">{deliveryDays} {deliveryDaysSuffix}</span>
}

export default DeliveryTime
