import React from 'react'
import { T } from 'components/components/i18n'

const RIM_MATERIAL_TYPES = [
  {
    value: '2',
    label: <T id="Aluminum"/>
  },
  {
    value: '1',
    label: <T id="Steel"/>
  },
  {
    value: '',
    label: <T id="Both"/>
  }
]

export { RIM_MATERIAL_TYPES }