// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__euGrading___XcrNM {
  display: flex;
  align-items: center;
}
.styles__euGrading___XcrNM div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.styles__text___ZX1HR {
  padding-left: 2px;
  padding-right: 8px;
}
.styles__accentPositive___4ypBR {
}
.styles__extendedDescription___dRWsr {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/EuGrading/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;AADF;AADA;EAII,aAAA;EACA,sBAAA;EACA,mBAAA;AAAJ;AAIA;EACE,iBAAA;EACA,kBAAA;AAFF;AAKA;AAFA;AAMA;EACE,WAAA;AAJF","sourcesContent":["@import '../../../shared/variables.less';\n\n.euGrading {\n  display: flex;\n  align-items: center;\n  div {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n}\n\n.text {\n  padding-left: 2px;\n  padding-right: 8px;\n}\n\n.accentPositive {\n  composes: text;\n}\n\n.extendedDescription {\n  color: @eon-header-text-color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"euGrading": `styles__euGrading___XcrNM`,
	"text": `styles__text___ZX1HR`,
	"accentPositive": `styles__accentPositive___4ypBR styles__text___ZX1HR`,
	"extendedDescription": `styles__extendedDescription___dRWsr`
};
export default ___CSS_LOADER_EXPORT___;
