import XRegExp from 'xregexp'

export const isValid = (errors) => {
  return !Object.values(errors).some(error => !!error)
}

const getValidationRulesByStep = (customerType) => {
  return [
    {
      userEmail: {
        isRequired: true,
        regex: '^[\\p{L}\\d-_+.]{2,}@[\\p{L}\\d-_+.]{2,}\\.[\\p{L}\\d-_+.]{2,}$',
      },
      userPassword: {
        isRequired: true
      }
    },
    {
      customerName: {
        isRequired: true
      },
      customerAddress: {
        isRequired: true
      },
      customerZipcode: {
        isRequired: true
      },
      customerCity: {
        isRequired: true
      },
      customerPhoneMobile: {
        oneOf: ['customerPhoneMobile', 'customerPhoneLandline']
      },
      customerEmail: {
        regex: '^[\\p{L}\\d-_+.]{2,}@[\\p{L}\\d-_+.]{2,}\\.[\\p{L}\\d-_+.]{2,}$'
      },
      companyRegistrationNumber: {
        isRequired: customerType === 'business'
      },
      companyEmail: {
        isRequired: customerType === 'business',
        regex: '^[\\p{L}\\d-_+.]{2,}@[\\p{L}\\d-_+.]{2,}\\.[\\p{L}\\d-_+.]{2,}$'
      },
      invoiceEmail: {
        regex: '^[\\p{L}\\d-_+.]{2,}@[\\p{L}\\d-_+.]{2,}\\.[\\p{L}\\d-_+.]{2,}$',
      }
    }
  ]
}

export const validationErrors = (fields, step, customRules) => {
  const errors = {}
  if (step === 2) {
    customRules.forEach(rule => {
      let isRequired = rule.required
      let value = (fields[rule.name] || '').toString()
      if (isRequired && !value.length) {
        errors[rule.name] = 'Field is required'
      }
    })
    return errors
  }
  const rules = getValidationRulesByStep(fields.customerType)[step]
  Object.keys(rules).forEach(key => {
    let rule = rules[key]
    let isRequired = rule.isRequired
    let value = (fields[key] || '').toString()
    const regex = rule.regex
    const isNotValidValue = value.length && !XRegExp.cache(regex, 'i').test(value)
    if (isRequired && !value.length)
      errors[key] = 'Field is required'
    else if (isNotValidValue)
      errors[key] = 'Field is not valid'
    if (rule.oneOf) {
      for (let i = 0; i < rule.oneOf.length; i++) {
        const val = (fields[rule.oneOf[i]] || '').toString()
        if (val.length)
          break
        else if (!val.length && i === rule.oneOf.length - 1)
          errors[key] = 'One of the fields is required'
      }
    }
  })
  return errors
}
