import { api } from '../../helpers/api'
import { push } from 'react-router-redux'
import { updateCart } from '../../actions/cart'
import { setPreorderTargetId } from '../../actions/preOrder'

export const CHECKOUT_START = 'CHECKOUT_START'
export const CHECKOUT_DONE = 'CHECKOUT_DONE'
export const ORDER_CREATED = 'ORDER_CREATED'
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR'
export const SET_LOADING_EXACT_DELIVERY_TIMES = 'SET_LOADING_EXACT_DELIVERY_TIMES'
export const SET_EXACT_DELIVERY_TIMES = 'SET_EXACT_DELIVERY_TIMES'
export const SET_CHECKOUT_PARAMS = 'SET_CHECKOUT_PARAMS'
export const SET_GARAGES = 'SET_GARAGES'

const orderCreated = (params) => {
  return {
    type: ORDER_CREATED,
    order: params
  }
}

const checkoutStart = (params) => {
  return {
    type: CHECKOUT_START,
    order: params
  }
}

export const checkoutDone = () => {
  return dispatch => {
    dispatch({
      type: CHECKOUT_DONE
    })
  }
}

export const setCheckoutParams = (params) => {
  return dispatch => {
    dispatch({
      type: SET_CHECKOUT_PARAMS,
      params,
    })
  }
}

export const fetchAvailableDeliveryTimes = (params) => {
  return (dispatch, getState) => {
    const shop = getState().shop
    const products = {}
    getState().cart.items.filter(item => !item.autoProductId).forEach(item => {
      if (products[item.id])
        products[item.id].quantity = products[item.id].quantity + item.count
      else
        products[item.id] = {
          id: item.id,
          quantity: item.count,
          wheel: item.wheel,
          name: item.name,
          comment: item.comment,
          car: item.car,
        }
    })
    dispatch({
      type: SET_LOADING_EXACT_DELIVERY_TIMES,
      loading: true,
    })
    return api(`${shop.apiUrl}/availability`, 'post', { ...params, products: Object.values(products) })
      .then(response => {
        return response.json()
      })
      .then(response => {
        const exactDeliveryTimes = response.data.map(item => ({
          available: item.response.available,
          information: item.response.information,
          text: item.response.text,
          color: item.response.color,
          supplierId: item.request.supplierId,
          locationId: item.request.locationId,
          productId: item.request.productId,
          completeDeliveryDate: item.response.completeDeliveryDate,
          deliveries: item.response.deliveries,
          deliveryInformation: item.response.deliveryInformation,
          status: item.response.statusDescription,
          source: item.response.sourceDescription,
          deliveryInformationIcon: item.response.deliveryInformationIcon,
          deliveryInformationColor: item.response.deliveryInformationColor,
        }))
        dispatch(setDeliveryTimes(exactDeliveryTimes))
        dispatch({
          type: SET_LOADING_EXACT_DELIVERY_TIMES,
          loading: false,
        })
      })
      .catch(e => console.log(e))
  }
}

export const setDeliveryTimes = exactDeliveryTimes => {
  return dispatch => {
    dispatch({
      type: SET_EXACT_DELIVERY_TIMES,
      exactDeliveryTimes,
    })
  }
}

export const externalCheckout = () => {
  return (dispatch, getState) => {
    const { items } = getState().cart
    const payload = {
      linkTemplate: `${getState().shop.url}/{{type}}/{{productId}}`,
      products: items.map(item => ({
        licenseplate: item.licenseplate,
        articleNumber: item.productId,
        productId: item.id,
        quantity: item.count,
        unitPrice: item.price,
        description: item.description,
        type: item.category.id,
        autoProductId: item.autoProductId,
        included: item.included,
      })),
    }
    dispatch(updateCart([]))
    window.location = `${getState().shop.externalCheckout.url}?eontyreorder=${JSON.stringify(payload)}`
  }
}

export const checkout = (order, shop) => {
  const params = Object.assign({
    successUrl: `${shop.url}/kassa/tack`,
    failUrl: `${shop.url}/kassa/avbruten`,
  }, order)
  return (dispatch, getState) => {
    dispatch(checkoutStart({}))
    const targetId = getState().preOrder.targetId
    if (targetId)
      params.preorder = targetId
    return api(`${shop.apiUrl}/orders`, 'post', params)
      .then(response => {
        return response.json()
      })
      .then(response => {
        if (response.err) {
          dispatch({
            type: CHECKOUT_ERROR,
            error: response.err
          })
        } else {
          dispatch(updateCart([]))
          dispatch(setPreorderTargetId(null))
          dispatch(orderCreated(Object.assign({}, params, response.data)))
          if (response.data.redirectUrl) {
            window.location.href = response.data.redirectUrl
          } else {
            dispatch(push('/kassa/tack'))
          }
        }
      })
  }
}

export const fetchGarages = (postalCode) => {
  return (dispatch, getState) => {
    const shop = getState().shop
    return api(`${shop.apiUrl}/garages?postalCode=${postalCode}`)
      .then(response => {
        return response.json()
      })
      .then(response => {
        dispatch({
          type: SET_GARAGES,
          garages: response.data.garages,
        })
      })
  }
}
