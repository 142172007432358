import React from 'react'
import { connect } from 'react-redux'
import { CheckedSvg, UncheckedSvg } from '../SvgIcon'
import styles from './styles.less'
import classnames from 'classnames'

export class Checkbox extends React.Component {
  constructor(props) {
    super(props)
    this.changeHandler = this.onChange.bind(this)
  }

  onChange(event) {
    const { onChange, name, isChecked } = this.props
    onChange(name, !isChecked)
  }

  render() {
    const { isChecked, label, className, svgClass } = this.props
    let icon
    if (isChecked)
      icon = <CheckedSvg className={classnames('svg-colored', svgClass)}/>
    else
      icon = <UncheckedSvg className="svg-muted"/>

    let componentClassname = className ? `${className} ${styles.checkbox}` : styles.checkbox
    componentClassname = isChecked ? `${componentClassname} text-colored checked` : `${componentClassname} text-muted unchecked`
    return (
      <div className={classnames(componentClassname, 'checkbox-input')} onClick={this.changeHandler}>
        {icon}
        <span className={classnames('checkbox-label', styles.label)}>{label}</span>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    shop: state.shop
  }
}

export default connect(
  mapStateToProps
)(Checkbox)
