// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__carInfoTable___TWyiT {
  margin-bottom: 0 !important;
}
.styles__carInfoTable___TWyiT tr,
.styles__carInfoTable___TWyiT tr td {
  border: none !important;
}
.styles__carInfoTable___TWyiT tr.styles__infoLabel___AB_fL td {
  width: 100%;
  padding: 0.1em;
}
.styles__carInfoTable___TWyiT tr.styles__infoData___No7W4 td {
  width: 100%;
  padding: 0.1em;
}
.styles__carInfoTable___TWyiT tr.styles__infoLabel___AB_fL,
.styles__carInfoTable___TWyiT tr.styles__infoData___No7W4 {
  display: block;
  width: 100%;
}
.styles__carInfoTable___TWyiT tr.styles__infoData___No7W4 {
  margin-bottom: 0.65em;
}
.styles__front___x7bK4 {
  padding-right: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/CarInfo/styles.less"],"names":[],"mappings":"AAMA;EACI,2BAAA;AALJ;AAQA;;EAEI,uBAAA;AANJ;AASA;EACI,WAAA;EACA,cAAA;AAPJ;AAUA;EACI,WAAA;EACA,cAAA;AARJ;AAWA;;EAEI,cAAA;EACA,WAAA;AATJ;AAaA;EACI,qBAAA;AAXJ;AAeA;EACI,oBAAA;AAbJ","sourcesContent":["@import '../../../../shared/variables.less';\n\n.carInfo {\n\n}\n\n.carInfoTable {\n    margin-bottom: 0 !important;\n}\n\n.carInfoTable tr,\n.carInfoTable tr td {\n    border: none !important;\n}\n\n.carInfoTable tr.infoLabel td {\n    width: 100%;\n    padding: 0.1em;\n}\n\n.carInfoTable tr.infoData td {\n    width: 100%;\n    padding: 0.1em;\n}\n\n.carInfoTable tr.infoLabel,\n.carInfoTable tr.infoData {\n    display: block;\n    width: 100%;\n    // background-color: rgba(0,0,0,0.15);\n}\n\n.carInfoTable tr.infoData {\n    margin-bottom: 0.65em;\n    // background-color: rgba(0,0,0,0.1);\n}\n\n.front {\n    padding-right: 0.5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carInfoTable": `styles__carInfoTable___TWyiT`,
	"infoLabel": `styles__infoLabel___AB_fL`,
	"infoData": `styles__infoData___No7W4`,
	"front": `styles__front___x7bK4`
};
export default ___CSS_LOADER_EXPORT___;
