import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { autologin } from '../../../actions/user'
import qs from 'query-string'

class Autologin extends Component {
  componentDidMount() {
    const { autologin, location } = this.props
    const params = qs.parse(location.search)
    autologin(params.token)
  }

  render() {
    const { isLoggedIn, location } = this.props
    if (isLoggedIn) {
      const params = qs.parse(location.search)
      const url = params.to || '/'
      return <Redirect to={url} />
    }
    return <div>spinner</div>
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn
})

const mapDispatchToProps = dispatch => bindActionCreators({
  autologin: token => autologin(token)
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Autologin)