import React from 'react'
import { T } from 'components/components/i18n'
import styles from './styles.less'

const rimTypes = [
  { id: 1, label: 'S', name: <T id="Steel"/> },
  { id: 2, label: 'A', name: <T id="Aluminum"/> }
];

const RimTypeLabel = ({ rimType, fullName }) => {
  const type = rimTypes.find(t => t.id === rimType)
  if (!type)
    return null
  return fullName ? type.name : (
    <div className={`${styles.rimTypeLabel} background-normal rim-type`}>
      {type.label}
    </div>
  )
}

export default RimTypeLabel;
