import { api } from '../../helpers/api'
import { push } from 'react-router-redux'
import { fetchCart } from "../cart"
import { fetchPriceOptions } from '../price'
import { initializePreorderData, removeTargetId } from '../preOrder'

export const SET_SESSION = 'SET_SESSION'
export const SET_USER = 'USER_LOGGED_OUT'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const setUser = (user) => {
  return {
    type: SET_USER,
    user
  }
}

export const login = (params, shop, path) => {
  return (dispatch, getState) => {
    if (params.email === '' || params.password === '')
      return dispatch({
        type: LOGIN_ERROR,
        error: 'Username and password required'
      })
    return api(`${shop.apiUrl}/login`, 'post', params)
      .then(response => {
        return response.json()
      })
      .then(response => {
        if (response.err) {
          dispatch({
            type: LOGIN_ERROR,
            error: response.err,
            domainUrl: response.data && response.data.domain,
            domainTitle: response.data && response.data.domainTitle,
          })
        } else {
          localStorage.setItem('user', JSON.stringify(response.data))
          dispatch(setUser(response.data))
          dispatch(fetchCart())
          dispatch(fetchPriceOptions())
          dispatch(initializePreorderData())
          dispatch(push(path))
        }
      }).catch(error => {
          dispatch({
            type: LOGIN_ERROR,
            error: 'Unknown error'
          })
      })
  }
}

export const logout = () => {
  return (dispatch, getState) => {
    return api(`${getState().shop.apiUrl}/logout`, 'post')
      .then(response => {
        localStorage.removeItem('user')
        dispatch(setUser(null))
        dispatch(fetchCart())
        dispatch(fetchPriceOptions())
        dispatch(removeTargetId())
        dispatch(push('/login'))
      })
  }
}

export const sendEmail = (data, next) => {
  fetch('https://sfb-01.eonbit.com/api/submit', {
    method: 'post',
    body: JSON.stringify(data)
  }).then(() => {
    console.log('Mail sent', data)
    next()
  })
  .catch(err => console.error(err))
}

export const autologin = (token) => {
  return (dispatch, getState) => {
    return api(`${getState().shop.apiUrl}/autologin`, 'post', { token })
      .then(response => response.json())
      .then(response => {
          localStorage.setItem('user', JSON.stringify(response.data))
          dispatch(setUser(response.data))
          dispatch(fetchCart())
          dispatch(fetchPriceOptions())
          dispatch(initializePreorderData())
      })
  }
}
