import React from 'react'
import { connect } from 'react-redux'
import { T } from 'components/components/i18n'
import { APP_URL } from '../../../config'
import crypto from 'crypto'

const getFormFields = (shop, order, acceptUrl, cancelUrl) => {
  if (!order || !order.id) {
    throw new Error('Missing valid order, got: ' + JSON.stringify(order))
  }

  let formFields = {
    acceptReturnUrl: acceptUrl,
    cancelReturnUrl: cancelUrl,
    callbackUrl: shop.paymentProvider.callbackUrl,
    amount: order.total,
    currency: 'SEK',
    merchant: shop.paymentProvider.merchantId,
    language: 'sv',
    orderId: order.id,
    oiTypes: 'ITEMID;DESCRIPTION;QUANTITY;UNITCODE;AMOUNT;VATPERCENT',
  }

  order.products.forEach((item, index) => {
    formFields[`oiROw${index + 1}`] = [item.id, item.name.replace(/[^\w\d-., ]+/, ''), item.count, 'st', item.price, 2500].join(';')
  })

  formFields.MAC = calculateHmac(formFields, shop.paymentProvider.merchantSecret)

  return formFields
}

const calculateHmac = (fields, key) => {
  let keys = Object.keys(fields).sort()
  let hmac = crypto.createHmac('sha256', new Buffer(key, 'hex'))
  let parts = keys.map(k => `${k}=${fields[k]}`)
  hmac.update(parts.join('&'))
  return hmac.digest('hex')
}

// const validateHmac = (hmac, fields, key) => {
//   return hmac === calculateHmac(fields, key)
// }

// const validateHmacRequest = (fields, shop) => {
//   let hmac = fields.MAC;
//   delete fields.MAC;

//   return validateHmac(hmac, fields, shop.paymentProvider.merchantSecret)
// }

export class DibsPayment extends React.Component {
  componentDidMount() {
    document.getElementById('payment').submit()
  }
  render() {
    const { shop, order } = this.props
    const acceptUrl = `${APP_URL}/kassa/tack`
    const cancelUrl = `${APP_URL}/kassa/avbruten`
    const formFields = getFormFields(shop, order, acceptUrl, cancelUrl)
    const formFieldsComponent = Object.keys(formFields).map(key => (
      <input key={key} type="hidden" name={key} value={formFields[key]}/>)
    )
    return (
      <form id="payment" className="dibs-payment" action="https://sat1.dibspayment.com/dibspaymentwindow/entrypoint" method="post">
        {formFieldsComponent}
        <button type="submit"><T id="Click here to continue"/></button>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    order: state.order,
    shop: state.shop
  }
}

export default connect(
  mapStateToProps
)(DibsPayment)
