import React from 'react'
import { FormGroup, ControlLabel } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import Checkbox from '../../Checkbox'
import styles from './styles.less'

const conditionOptions = [
  {
    id: 'new',
    title: <T id="New" />
  },
  {
    id: 'used',
    title: <T id="Used" />
  },
  {
    id: 'olddot',
    title: <T id="Old DOT" />
  }
]

const Condition = (props) => {
  const { selectedOptions, onChange, config } = props
  const onCheckboxChange = (value) => {
    let  selectedOptionsNew
    const checked = selectedOptions.indexOf(value) !== -1
    if (checked)
      selectedOptionsNew = selectedOptions.filter(option => option !== value)
    else {
      selectedOptionsNew = selectedOptions.slice()
      selectedOptionsNew.push(value)
    }
    onChange('condition', selectedOptionsNew)
  }

  let conditionCheckboxes = []
  conditionOptions.forEach(option => {
    if (config[option.id] && config[option.id].show)
      conditionCheckboxes.push(
        <Checkbox
          inline
          onChange={onCheckboxChange}
          value={option.id}
          name={option.id}
          key={option.id}
          label={option.title}
          className={styles.checkbox}
          isChecked={selectedOptions.indexOf(option.id) > -1}
        />
      )
  })

  if (!conditionCheckboxes.length)
    return null
  return (
    <FormGroup className={`${styles.wrapper} condition`}>
      <ControlLabel><T id="Condition"/></ControlLabel>
      {conditionCheckboxes}
    </FormGroup>
  )
}

export default Condition
