// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__image___FDEu0 {
  width: 100%;
  height: auto;
}
.styles__banner___UzVEh {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Banner/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AAEA;EACE,gBAAA;AAAF","sourcesContent":[".image {\n  width: 100%;\n  height: auto;\n}\n\n.banner {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `styles__image___FDEu0`,
	"banner": `styles__banner___UzVEh`
};
export default ___CSS_LOADER_EXPORT___;
