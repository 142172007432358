import React from 'react'
import { ConnectedRouter } from 'react-router-redux'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { IntlProvider } from 'react-intl'
import { history } from '../store'
import { init } from '../actions'
import { categoryChanged } from '../actions/category'
import Login from './components/Login'
import CheckoutComponent from './components/CheckoutComponent'
import Order from './components/Order'
import OrdersList from './components/OrdersList'
import Search from './components/Search'
import Product from './components/Product'
import DibsPayment from './pages/DibsPayment'
import CancelPayment from './pages/CancelPayment'
import PaymentSuccess from './pages/PaymentSuccess'
import Page from './components/Page'
import Header from './components/Header'
import Spinner from './components/Spinner'
import styles from './styles.less'
import FrontPage from './components/FrontPage'
import PrivateRoute from './PrivateRoute'
import CustomerPreferences from './components/CustomerPreferences'
import FeedbackWidget from './components/FeedbackWidget'
import SignUp from './components/SignUp'
import SignUpConfirmation from './components/SignUp/SignUpConfirmation'
import Helmet from 'react-helmet'
import Parser from 'html-react-parser';
import ForgotPassword from './components/ForgotPassword'
import ForgotPasswordReturn from './components/ForgotPassword/ForgotPasswordReturn'
import WheelBuilderSearch from './components/WheelBuilderSearch'
import TyreSearch from './components/TyreSearch'
import RimSearch from './components/RimSearch'
import Autologin from './components/Autologin'
import PreOrder from './components/PreOrder'

const appendFooterHTML = (htmlString) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  const ELEMENT_NODE_TYPE = 1
  for(let i = 0; i < div.childNodes.length; i++) {
    let newElementName = null
    if (div.childNodes[i].nodeType === ELEMENT_NODE_TYPE)
      newElementName = div.childNodes[i].nodeName
    if (newElementName) {
      const newElement = document.createElement(newElementName)
      const attributes = div.childNodes[i].attributes
      for (let j = 0; j < attributes.length; j++) {
        newElement.setAttribute(attributes[j].name, attributes[j].value)
      }
      newElement.innerHTML = div.childNodes[i].innerHTML
      document.body.appendChild(newElement)
    }
  }
}

const handleHeadScripts = (headScript) => {
  // handle script without src (with executable code javascript)
  const parsedHeadScript = Parser(headScript)
  let parsedContent
  const handleScriptTag = (element) => {
    if (element.type === 'script') {
      return element.props.dangerouslySetInnerHTML ? <script>{element.props.dangerouslySetInnerHTML.__html}</script> : element
    }
    return element
  }
  if (Array.isArray(parsedHeadScript))
    parsedContent = parsedHeadScript.map(script => handleScriptTag(script))
  else
    parsedContent = handleScriptTag(parsedHeadScript)
  return parsedContent
}

export class App extends React.Component {
  componentDidMount() {
    this.props.init()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category)
      this.props.categoryChanged()
  }

  componentWillReceiveProps(newProps) {
    const { shop } = newProps
    // When shop is retrieved, append footer html and set locale from shop config
    if (this.props.shop.id !== shop.id) {
      if (shop.allPagesHtmlFooter)
        appendFooterHTML(shop.allPagesHtmlFooter)
    }
  }

  render() {
    const {
      shop, isLoggedIn, isSessionRetrieved, locale, messages, isPreorderDataInitialized,
    } = this.props
    if (!shop.id || !isSessionRetrieved || (isLoggedIn && !isPreorderDataInitialized))
      return <div className={styles.noContent}><Spinner /></div>

    if (process.env.NODE_ENV === 'development') {
      console.log('Index.Render: this.props:', this.props)
    }

    const customPages = shop.pages.map(page => <Route exact path={`/page/${page.slug}`} render={(props) => <Page config={page}/>} key={page.slug} />)
    const redirectToLogin = shop.requireLogin && !isLoggedIn
    let feedbackWidget
    if (shop.enableSuperFeedback)
      feedbackWidget = <FeedbackWidget />
    let allPagesHtmlHeader
    if (shop.allPagesHtmlHeader)
      allPagesHtmlHeader = handleHeadScripts(shop.allPagesHtmlHeader)
    let preferencesRoute
    if (shop.canUseConsumerPrices && isLoggedIn)
      preferencesRoute = <PrivateRoute exact path="/preferences" component={CustomerPreferences} redirectToLogin={redirectToLogin}/>
    return (
      <IntlProvider mmm="mm" locale={locale} messages={messages}>
        <ConnectedRouter history={history}>
          <main className={`${styles.app} app text-normal`}>
            <style>{shop.customStyle}</style>
            <Helmet>
              <title>{shop.title || 'Webshop'}</title>
              {allPagesHtmlHeader}
            </Helmet>
            <Header></Header>
            <PrivateRoute exact path="/" component={FrontPage} redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/hem" component={FrontPage} redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/hem/:category" component={FrontPage} redirectToLogin={redirectToLogin}/>
            <Route exact path="/login" component={Login} slug="login"/>
            <PrivateRoute exact path="/kassa" component={CheckoutComponent} redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/ordrar/:orderId" component={Order} slug="order-list" redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/ordrar" component={OrdersList} slug="order-list" redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/dack" component={TyreSearch} slug="home.dack" redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/falg" component={RimSearch} slug="home.falg" redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/hjul/:step?/:id?/:slug?" component={WheelBuilderSearch} slug="home.hjul" redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/dack/:id/:slug" component={Product} redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/falg/:id/:slug" component={Product} redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/dack/:id" component={Product} redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/falg/:id" component={Product} redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/hjulpaket/:id" component={Product} redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/hjulpaket/:id/:slug" component={Product} redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/annat/:id/:slug" component={Product} redirectToLogin={redirectToLogin} />
            <PrivateRoute exact path="/kassa/betalning" component={DibsPayment} redirectToLogin={redirectToLogin} />
            <PrivateRoute exact path="/kassa/avbruten" component={CancelPayment} redirectToLogin={redirectToLogin} />
            <PrivateRoute exact path="/kassa/tack" component={PaymentSuccess} redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/hjulpaket" component={Search} slug="home.hjulpaket" redirectToLogin={redirectToLogin}/>
            <PrivateRoute exact path="/forgot-password" component={ForgotPassword} />
            <PrivateRoute exact path="/forgot-password-return" component={ForgotPasswordReturn}/>
            <PrivateRoute exact path="/signup" component={SignUp} slug="signup" />
            <PrivateRoute exact path="/signup/confirm" component={SignUpConfirmation} slug="signup" />
            <PrivateRoute exact path="/autologin" component={Autologin} />
            <PrivateRoute exact path="/preorder" component={PreOrder} redirectToLogin={!isLoggedIn}/>
            {preferencesRoute}
            {customPages}
            {feedbackWidget}
          </main>
        </ConnectedRouter>
      </IntlProvider>
    )
  }
}

const mapStateToProps = state => {
  return {
    shop: state.shop,
    isLoggedIn: state.user.isLoggedIn,
    category: state.categories.activeCategory,
    isWheelBuilder: state.wheelBuilder.showWheelProgress,
    locale: state.intl.locale,
    messages: state.intl.messages,
    isSessionRetrieved: !!state.user.sessionToken,
    isPreorderDataInitialized: state.preOrder.initialized,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  init: () => init(),
  categoryChanged: () => categoryChanged()
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
