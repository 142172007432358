export const formatCurrency = (money) => {
  return Math.ceil(money / 100)
}

export const calculatePrice = (price, numberOfPieces, vatPercent) => {
  if (vatPercent) {
    return formatCurrency((price + price * vatPercent / 100) * numberOfPieces)
  }
  return formatCurrency(price * numberOfPieces)
}
