import React from 'react'
import { T } from 'components/components/i18n'

export const CATEGORIES = {
  TYRE: {
    id: 'tyre',
    name: 'Däck',
    label: <T id="Tire" />,
    slug: 'dack',
    filters: {
      typeId: 1
    },
    types: [
      {
        value: '',
        label: <T id="All" />

      },
      {
        value: '2',
        label: <T id="Summer" />
      },
      {
        value: '3',
        label: <T id="Winter" /> // Unknown whether Nordisk or Europeisk
      },
      {
        value: '5',
        label: <T id="Studded" />
      }
    ]
  },
  RIM: {
    id: 'rim',
    name: 'Fälg',
    label: <T id="Rim" />,
    slug: 'falg',
    filters: {
      typeId: 2
    },
    types: [
      {
        value: '',
        label: <T id="All" />
      },
      {
        value: '2',
        label: <T id="Aluminum" />
      },
      {
        value: '1',
        label: <T id="Steel" />
      }
    ]
  },
  COMPLETE_WHEEL: {
    id: 'completeWheel',
    name: 'Kompletta hjul',
    label: <T id="Complete wheel" />,
    slug: 'hjulpaket',
    filters: {
      typeId: 28
    }
  },
  OTHER: {
    id: 'other',
    name: 'Annat',
    slug: 'annat',
    label: <T id="Other" />,
    filters: {
      typeId: -1
    },
    children: []
  }
}

export const getCategoryBySlug = (slug) => {
  const categories = Object.keys(CATEGORIES)
  for (let i = 0; i < categories.length; i++) {
    if (CATEGORIES[categories[i]].slug === slug) {
      return CATEGORIES[categories[i]]
    }
  }
  return CATEGORIES.OTHER
}

export const getCategoryById = (id) => {
  const categories = Object.keys(CATEGORIES)
  for (let i = 0; i < categories.length; i++) {
    if (CATEGORIES[categories[i]].id === id) {
      return CATEGORIES[categories[i]]
    }
  }
  return CATEGORIES.OTHER
}

export const getCategoryByTypeId = (typeId) => {
const categories = Object.keys(CATEGORIES)
  for (let i = 0; i < categories.length; i++) {
    if (CATEGORIES[categories[i]].filters.typeId === typeId) {
      return CATEGORIES[categories[i]]
    }
  }
  return CATEGORIES.OTHER
}

export default CATEGORIES
