import React from 'react'
import styles from './styles.less'
import { T } from 'components/components/i18n'
import Select, { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props} className="sortyByIcon">
        <FontAwesomeIcon icon={faCaretDown} className={styles.icon} />
      </components.DropdownIndicator>
    )
  )
}

const customStyles = {
  menu: (base) => ({ ...base, width: '100%', borderRadius: 'none' }),
  control: (base) => ({ ...base, borderRadius: 0, border: 'none', boxShadow: 'none', background: 'transparent', minHeight: '34px' }),
  indicatorSeparator: () => ({ display: 'none' })
}

export const SortBy = (props) => {
  const { selectedSortOption, onSortByClick } = props

  const onOptionChange = (option) => {
    onSortByClick(option.value)
  }

  const options = [{
    value: 'source',
    label: <T id="Sort by availability and price"/>
  }, {
    value: 'price',
    label:  <T id="Sort by price only"/>
  }]

  return (
    <div className={`${styles.sortBy} sort-by`}>
      <Select
        className={styles.select}
        options={options}
        value={options.find(option => option.value === selectedSortOption) || options[0]}
        onChange={onOptionChange}
        styles={customStyles}
        components={{ DropdownIndicator }}
      />
    </div>
  )
}

export default SortBy
