// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__navItem___ICHyy {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 991px) {
  .styles__navItem___ICHyy {
    text-align: center;
    width: 100%;
  }
  .styles__navItem___ICHyy button {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.styles__navItem___ICHyy button {
  padding: 18px 15px !important;
  color: #fff !important;
  text-transform: uppercase;
  cursor: pointer;
}
.styles__hoveredNavItem___NVidl {
}
.styles__hoveredNavItem___NVidl button {
  text-decoration: none;
  color: #1ec188 !important;
}
.styles__selectedNavItem___Fr1qN {
  font-weight: 900;
  border-radius: 2px;
}
.styles__selectedNavItem___Fr1qN button {
  padding-bottom: 16px !important;
  font-weight: 600;
  border-bottom: 3px solid #fff;
}
.styles__border___lgQvp {
  height: 2px;
  background: #fff;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/HeaderNavItem/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAEE;EAAA;IACE,kBAAA;IACA,WAAA;EACF;EAHA;IAII,gBAAA;IACA,uBAAA;EAEJ;AACF;AAbA;EAeI,6BAAA;EACA,sBAAA;EACA,yBAAA;EACA,eAAA;AACJ;AAGA;AAAA;AAAA;EAGI,qBAAA;EACA,yBAAA;AAAJ;AAIA;EAEE,gBAAA;EAMA,kBAAA;AAPF;AADA;EAII,+BAAA;EACA,gBAAA;EACA,6BAAA;AAAJ;AAKA;EACE,WAAA;EACA,gBAAA;EACA,WAAA;AAHF","sourcesContent":["@import '../../../shared/variables.less';\n\n.navItem {\n  display: flex;\n  flex-direction: column;\n  white-space: nowrap;\n  overflow: hidden;\n  @media (max-width: @screen-sm-max) {\n    text-align: center;\n    width: 100%;\n    button {\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  }\n\n  button {\n    padding: 18px 15px !important;\n    color: @eon-header-text-color !important;\n    text-transform: uppercase;\n    cursor: pointer;\n  }\n}\n\n.hoveredNavItem {\n  composes: navItem;\n  button {\n    text-decoration: none;\n    color: @success !important;\n  }\n}\n\n.selectedNavItem {\n  composes: navItem;\n  font-weight: 900;\n  button {\n    padding-bottom: 16px !important;\n    font-weight: 600;\n    border-bottom: 3px solid @eon-header-text-color;\n  }\n  border-radius: 2px;\n}\n\n.border {\n  height: 2px;\n  background: @eon-header-text-color;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navItem": `styles__navItem___ICHyy`,
	"hoveredNavItem": `styles__hoveredNavItem___NVidl styles__navItem___ICHyy`,
	"selectedNavItem": `styles__selectedNavItem___Fr1qN styles__navItem___ICHyy`,
	"border": `styles__border___lgQvp`
};
export default ___CSS_LOADER_EXPORT___;
