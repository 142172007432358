import React, { Component } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetVehicle } from '../../../actions/vehicle'
import Search from '../Search';

class RimSearch extends Component {
  componentWillUnmount() {
    this.props.resetVehicle()
  }

  render() {
    return <Search location={this.props.location} slug="home.falg" />
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  resetVehicle: () => resetVehicle()
}, dispatch)

export default connect(null, mapDispatchToProps)(RimSearch)
