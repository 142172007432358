import { api } from '../../helpers/api'
import { CATEGORIES } from '../../helpers/categories'

export const RIM_BRANDS_RETRIEVED = 'RIM_BRANDS_RETRIEVED'
export const TYRE_BRANDS_RETRIEVED = 'TYRE_BRANDS_RETRIEVED'


export const retrieveBrands = (category, shop) => {
  const type = category.id === CATEGORIES.RIM.id ? RIM_BRANDS_RETRIEVED : TYRE_BRANDS_RETRIEVED
  return (dispatch, getState) => {
    let url = `${shop.apiUrl}/brands?&typeId=${category.filters.typeId}`
    if (
      getState().shop.includeBrands &&
      getState().shop.includeBrands.length
    ) {
      const includeBrandsParams = new URLSearchParams()
      getState().shop.includeBrands.map(include => (
        includeBrandsParams.append('include[]', include)
      ))
      url += `&${includeBrandsParams.toString()}`
    } else if (
      getState().shop.excludeBrands &&
      getState().shop.excludeBrands.length
    ) {
      const excludeBrandsParams = new URLSearchParams()
      getState().shop.excludeBrands.map(exclude => (
        excludeBrandsParams.append('exclude[]', exclude)
      ))
      url += `&${excludeBrandsParams.toString()}`
    }
    return api(url, 'get')
      .then(response => {
        return response.json()
      })
      .then(response => {
        dispatch({
          type,
          brands: response.data
        })
      })
  }
}
