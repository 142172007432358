import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.less'
import WheelProgressItem from '../WheelProgressItem'
import { T } from 'components/components/i18n'

const steps = [
  {
    step: 0,
    label: <T id="CHOOSE RIMS"/>,
    url: '/hjul/falg'
  },
  {
    step: 1,
    label: <T id="CHOOSE TYRES"/>,
    url: '/hjul/dack'
  },
  {
    step: 2,
    label: <T id="CONFIRM SELECTION" />,
    url: '/hjul/klar'
  }
]

const WheelProgress = (props) => {
  const { wheelBuilder, tyre, tyreRear } = props
  if (!wheelBuilder)
    return null

  let progressItems = [], labels = []
  const rimTitle = wheelBuilder.rim ? wheelBuilder.rim.brand.name || <T id="Other"/> : <T id="Choose rims"/>
  const rimDescription = wheelBuilder.rim ? wheelBuilder.rim.name : <T id="Nothing selected"/>
  progressItems.push(
    <WheelProgressItem
      key='rim'
      title={rimTitle}
      description={rimDescription}
      hasProductAssigned={!!wheelBuilder.rim}
      step={steps[0]}
      isClickable={wheelBuilder.currentStep !== steps[0].step}
    />
  )
  labels.push(
    <div className={`${styles.label} wheelProgress_label`} key="label-0">
      <div>{steps[0].label}</div>
      <div className={styles.itemDescription}>{!!wheelBuilder.rim ? `${rimTitle} ${rimDescription}` : <T id="Nothing selected"/>}</div>
    </div>
  )
  const tyreTitle = tyre ? `${tyre.brand.name} ${tyre.name}` : <T id="Choose tyres"/>
  const tyreTitleRear = tyreRear ? `${tyreRear.brand.name} ${tyreRear.name}` : <T id="Choose tyres"/>
  const tyreDescription = tyre ? tyre.attrs.dimension : <T id="Nothing selected"/>
  const tyreDescriptionRear = tyreRear ? tyreRear.attrs.dimension : <T id="Nothing selected"/>
  progressItems.push(
    <WheelProgressItem
      key='tyre'
      title={tyreTitle}
      description={tyreDescription}
      hasProductAssigned={!!tyre}
      step={steps[1]}
      isClickable={wheelBuilder.currentStep !== steps[1].step && !!wheelBuilder.rim}
    />
  )
  labels.push(
    <div className={`${styles.label} wheelProgress_label`} key="label-1">
      <div>{steps[1].label}</div>
      <div className={styles.itemDescription}>{!!tyre ? `${tyreTitle} ${tyreDescription}` : <T id="Nothing selected"/>}</div>
      {!!tyreRear && (
        <div className={styles.itemDescription}>{!!tyreRear ? `${tyreTitleRear} ${tyreDescriptionRear}` : <T id="Nothing selected"/>}</div>
      )}
    </div>
  )
  const wheelTitle = <T id="Confirm selection"/>
  const wheelDescription = <T id="and add to cart"/>

  progressItems.push(
    <WheelProgressItem
      key='wheel'
      title={wheelTitle}
      description={wheelDescription}
      step={steps[2]}
      isClickable={!!wheelBuilder.rim && !!tyre}
    />
  )
  labels.push(
    <div className={`${styles.label} wheelProgress_label`} key="label-2">
      <div>{steps[2].label}</div>
      <br/>
    </div>
  )
  return (
    <div className={`${styles.wrapper} wheel-progress text-colored`}>
      <div className={styles.progress}>{progressItems}</div>
      <div className={`${styles.labels} wheelProgress_labels`}>{labels}</div>
    </div>
  )
}
const mapStateToProps = state => {
  let tyre = state.wheelBuilder.tyre
  let tyreRear
  if (Array.isArray(state.wheelBuilder.tyre)) {
    tyre = state.wheelBuilder.tyre[0]
    tyreRear = state.wheelBuilder.tyre[1]
  }
  return {
    wheelBuilder: state.wheelBuilder,
    tyre,
    tyreRear,
    shop: state.shop
  }
}

export default connect(
  mapStateToProps,
)(WheelProgress)
