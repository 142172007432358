import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col } from 'react-bootstrap'
import NewMarginModal from '../NewMarginModal'
import { FormattedNumber } from 'react-intl'
import { T } from 'components/components/i18n'
import { editProfitMargin, deleteProfitMargin } from '../../../../../actions/profitMargins'
import { getCategoryById } from '../../../../../helpers/categories'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import styles from './styles.less'
import classnames from 'classnames'

class ProfitMargin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showEditModal: false
    }
    this.handleModalOpen = this.onModalOpen.bind(this)
    this.handleModalClose = this.onModalClose.bind(this)
    this.handleModalSaveClick = this.onSaveProfitMargin.bind(this)
    this.handleDelete = this.onDelete.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isEdited && this.props.isEdited) {
      this.onModalClose()
    }
  }

  onModalOpen() {
    this.setState({
      showEditModal: true
    })
  }

  onModalClose() {
    this.setState({
      showEditModal: false
    })
  }

  onSaveProfitMargin(params, margin) {
    const { editProfitMargin, shop, type } = this.props
    params.id = margin.id
    editProfitMargin(params, type, shop)
  }

  onDelete() {
    const { deleteProfitMargin, margin, shop, type } = this.props
    deleteProfitMargin(margin.id, type, shop)
  }

  render() {
    const { margin, type, editErrorMessage, isEditing, currency } = this.props
    let modal
    if (this.state.showEditModal)
      modal = (
        <NewMarginModal
          errorMessage={editErrorMessage}
          isLoading={isEditing}
          closeModal={this.handleModalClose}
          selectedType={type}
          margin={margin}
          onButtonClick={this.handleModalSaveClick}/>
      )
    const category = getCategoryById(type)
    const group = category.types.find(g => g.value === margin.group)
    const allGroupsLabel = <T id="All" />
    const label = margin.group ? ( group ? group.label : allGroupsLabel ) : allGroupsLabel
    return (
      <Row className={classnames(styles.profitMargin, 'profit-margin')}>
        <Col sm={2} xs={3} className={styles.profitMarginCell}>{label}</Col>
        <Col sm={2} xs={2} className={styles.profitMarginCellRight}>{margin.diameter}</Col>
        <Col sm={2} xs={2} className={styles.profitMarginCellRight}>
          <FormattedNumber
            minimumFractionDigits={0}
            maximumFractionDigits={2}
            value={margin.percentage / 100}
            style={`percent`}
            currency={currency}/>
        </Col>
        <Col sm={2} xs={2} className={styles.profitMarginCellRight}>
          <FormattedNumber
            minimumFractionDigits={0}
            maximumFractionDigits={2}
            value={margin.amount}
            style={`currency`}
            currency={currency}/>
        </Col>
        <Col sm={2} xs={12}  className={styles.profitMarginCellRight}>
          <div onClick={this.handleModalOpen} className={styles.iconButton}><FontAwesomeIcon icon={faPen}/></div>
          <div onClick={this.handleDelete} className={styles.iconButton}>
          <FontAwesomeIcon icon={faTrashAlt}/></div>
        </Col>
        {modal}
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    shop: state.shop,
    editErrorMessage: state.profitMargins.editErrorMessage,
    isEdited: state.profitMargins.isEdited,
    isEditing: state.profitMargins.isEditing,
    currency: state.intl.currency
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  editProfitMargin: (params, category, shop) => editProfitMargin(params, category, shop),
  deleteProfitMargin: (marginId, category, shop) => deleteProfitMargin(marginId, category, shop)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfitMargin)
