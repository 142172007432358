import {
  VEHICLE_RETRIEVED_BY_ID,
  MODELS_RETRIEVED,
  VARIANTS_RETRIEVED,
  SELECT_MANUFACTURER,
  SELECT_MODEL,
  SELECT_VARIANT,
  CAR_PICKER_INITIALIZED,
  INITIALIZING_CAR_PICKER,
  VEHICLE_SEARCH_ERROR
} from '../actions/carPicker'
import { RESET_VEHICLE } from '../actions/vehicle'
import { UPDATE_VEHICLE, SEARCH_MODE_CHANGED } from '../actions/search'
import { CATEGORY_CHANGED } from '../actions/category'
import { addDiametersToVehicle } from '../helpers/vehicle'

const carPicker = (state = {
  vehicle: {},
  manufacturers: [],
  selectedManufacturer: null,
  selectedModel: null,
  selectedVariant: null,
  models: [],
  variants: [],
  isInitialized: false,
  isComplete: false,
}, action) => {
  switch (action.type) {
    case INITIALIZING_CAR_PICKER:
      return Object.assign({}, state, {
        loadingVariants: true,
        loadingModels: true,
        loadingManufacturers: true,
        loadingVehicle: true,
        errorMessage: null
      })
    case CAR_PICKER_INITIALIZED:
      const { vehicle, manufacturers, models, variants, selectedManufacturer, selectedModel, selectedVariant } = action
      let selectedManufacturerFormatted, selectedModelFormatted, selectedVariantFormatted
      if (selectedManufacturer && manufacturers) {
        selectedManufacturerFormatted = manufacturers.find(man => man.id.toString() === selectedManufacturer.toString())
        if (selectedManufacturerFormatted)
          selectedManufacturerFormatted = { label: selectedManufacturerFormatted.name, value: selectedManufacturerFormatted.id }
      }

      if (selectedModel && models) {
        selectedModelFormatted = models.find(model => model.id.toString() === selectedModel.toString())
        if (selectedModelFormatted)
          selectedModelFormatted = { label: selectedModelFormatted.name, value: selectedModelFormatted.id, hasVehicleId: !!selectedModelFormatted.vehicleId, vehicleId: selectedModelFormatted.vehicleId }
      }

      if (selectedVariant && variants) {
        selectedVariantFormatted = variants.find(variant => variant.id.toString() === selectedVariant)
        if (selectedVariantFormatted) {
          selectedVariantFormatted = { label: selectedVariantFormatted.name, value: selectedVariantFormatted.id }
        }
      }
      return Object.assign({}, state, {
        vehicle: addDiametersToVehicle(vehicle),
        manufacturers: manufacturers || [],
        models: models || [],
        variants: variants || [],
        selectedManufacturer: selectedManufacturerFormatted || null,
        selectedModel: selectedModelFormatted || null,
        selectedVariant: selectedVariantFormatted || null,
        loadingManufacturers: false,
        loadingModels: false,
        loadingVariants: false,
        loadingVehicle: false,
        isInitialized: true,
        errorMessage: null,
        isComplete: selectedManufacturer && selectedModel && selectedVariant,
      })
    case UPDATE_VEHICLE:
      const updateVehicle = action.vehicle && !action.vehicle.diameters
        ? addDiametersToVehicle(action.vehicle)
        : action.vehicle
      return Object.assign({}, state, { vehicle: updateVehicle })
    case VEHICLE_RETRIEVED_BY_ID:
      return Object.assign({}, state, {
        vehicle: addDiametersToVehicle(action.vehicle),
        errorMessage: null,
        loadingVehicle: false
      })
    case MODELS_RETRIEVED:
      return Object.assign({}, state, {
        models: action.models,
        errorMessage: null,
        loadingModels: false
      })
    case VARIANTS_RETRIEVED:
      return Object.assign({}, state, {
        variants: action.variants,
        errorMessage: null,
        loadingVariants: false
      })
    case SELECT_MANUFACTURER:
      return Object.assign({}, state, {
        selectedManufacturer: action.selectedManufacturer,
        loadingModels: true,
        selectedModel: null,
        selectedVariant: null,
        models: [],
        errorMessage: null,
        isComplete: false,
      })
    case SELECT_MODEL:
      const loadingVariants = !action.selectedModel.vehicleId
      return Object.assign({}, state, {
        selectedModel: action.selectedModel,
        loadingVehicle: !loadingVariants,
        loadingVariants,
        selectedVariant: null,
        variants: [],
        errorMessage: null,
        isComplete: action.selectedModel && action.selectedModel.vehicleId,
      })
    case SELECT_VARIANT:
      return Object.assign({}, state, {
        selectedVariant: action.selectedVariant,
        loadingVehicle: true,
        vehicle: {},
        errorMessage: null,
        isComplete: true,
      })
    case SEARCH_MODE_CHANGED:
    case CATEGORY_CHANGED:
      return Object.assign({}, state, { vehicle: {}, selectedModel: null, selectedManufacturer: null, selectedVariant: null, isInitialized: false, errorMessage: null })
    case VEHICLE_SEARCH_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
        loadingManufacturers: false,
        loadingModels: false,
        loadingVariants: false,
        loadingVehicle: false,
        isInitialized: true
      })
    case RESET_VEHICLE:
      return { ...state, vehicle: {} }
    default:
      return state
  }
}

export default carPicker
