import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import { retrieveBrands } from '../../../../actions/brands'
import { T } from 'components/components/i18n'
const styles = {
  control: (base) => ({ ...base, borderRadius: 0, border: '1px solid #ccc', minHeight: '34px' }),
  valueContainer: (base) => ({ ...base, maxHeight: '68px', overflow: 'auto', width: '100%', paddingRight: '20px' }),
  clearIndicator: (base) => ({ ...base, position: 'absolute', right: 0, paddingLeft: 0 }),
  dropdownIndicator: () => ({ display: 'none' }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided) => ({ ...provided, zIndex: 1000 })
}

class BrandsSelection extends React.Component {
  componentDidMount() {
    const { retrieveBrands, category, shop } = this.props
    retrieveBrands(category, shop)
  }

  render () {
    const { selectedValues, options, onBrandSelect } = this.props

    return  (
      <T id="All">
        {
          msg => (
            <Select
              isMulti
              joinValues
              isSearchable
              onChange={onBrandSelect}
              options={options}
              value={selectedValues}
              styles={styles}
              placeholder={msg}
            />
          )
        }
      </T>
    )
  }
}

const mapStateToProps = state => {
  return {
    shop: state.shop
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  retrieveBrands: (category, shop) => retrieveBrands(category, shop)
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BrandsSelection)
