import React from 'react'
import { connect } from 'react-redux'
import { FormattedNumber } from 'react-intl'
import { T } from 'components/components/i18n'
import { Button } from 'react-bootstrap'
import styles from './styles.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'

export const CheckoutAction = ({
  includeVat, total, onClick, totalInclVat, error, currency,
  submitting, buttonText, isDisabled, shop, className,
}) => {
  let totalMessage = ""
  let totalVatMessage = ""
  if (includeVat) {
    totalMessage = (
      <FormattedNumber
        minimumFractionDigits={0}
        maximumFractionDigits={0}
        value={totalInclVat}
        style={`currency`}
        currency={currency}
      />
    )
    totalVatMessage = <T id="Total amount incl. VAT" />
  } else {
    totalMessage = (
      <FormattedNumber
        minimumFractionDigits={0}
        maximumFractionDigits={0}
        value={total}
        style={`currency`}
        currency={currency}
      />
    )
    totalVatMessage = <T id="Total amount excl. VAT" />
  }
  let buttonStyle
  if (shop.style) {
    buttonStyle = { background: shop.style.color1 }
  }

  return (
    <section className={classnames(className, 'checkout-action')}>
      {error && (
        <div className={classnames(styles.errorMsg, 'checkout-error-message')}>
          <T id="Error when ordering" />: {error}
        </div>
      )}
      <div className={styles.checkoutAction}>
        <div className={classnames(styles.totalMessage, 'checokut-total-message')}>
          <h1 className={styles.title}>
            {totalMessage}
          </h1>
          {totalVatMessage}
        </div>
        <Button style={buttonStyle} onClick={onClick} className={classnames(styles.checkoutButton, 'checkout-button')} bsStyle="primary" bsSize="large" disabled={isDisabled}>
          {submitting && <FontAwesomeIcon icon={faSpinner} spin />}
          {buttonText || <T id="Continue with checkout" />}
        </Button>
      </div>
    </section>
  )
}

const mapStateToProps = (state, props) => {
  let isDisabled = false
  for (let update in state.cart.updateInProgress) {
    if (state.cart.updateInProgress[update]) {
      isDisabled = true
      break
    }
  }

  return {
    shop: state.shop,
    includeVat: state.price.includeVat,
    total: state.cart.total,
    totalInclVat: state.cart.totalInclVat,
    error: state.order.error,
    currency: state.intl.currency,
    isDisabled: props.disabled || state.order.submitting || isDisabled || (!state.preOrder.targetId && state.cart.hasUnavailable) || state.cart.loadingExactDeliveryTimes,
    buttonText: state.shop.externalCheckout && state.shop.externalCheckout.submitButtonText,
  }
}

export default connect(
  mapStateToProps
)(CheckoutAction)
