import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormGroup } from 'react-bootstrap'
import Checkbox from '../../../Checkbox'
import styles from './styles.less'
import RadioButton from '../../../RadioButton'
import { restrictDiameters } from '../../../../../actions/diameters'
import { CATEGORIES } from '../../../../../helpers/categories'
import { getStandard, isWinter } from "helpers/tyreAlternatives"

export class Diameter extends React.Component {
  constructor(props) {
    super(props)
    this.handleCheckboxChange = this.onCheckboxChange.bind(this)
  }

  componentDidMount() {
    if (this.props.isCompleteWheel) {
      // NOTE: Load standard speed and load index, as it should be
      //       calculated from max speed for speed index, max load for load index
      //       and these are the starting index for all tyre dimension
      this.props.onSearchParamChange('speedIndex', this.props.defaultSpeedIndex)
      this.props.onSearchParamChange('loadIndex', this.props.defaultLoadIndexFront)
      this.props.onSearchParamChange('loadIndexRear', this.props.defaultLoadIndexRear)
    }
  }

  componentWillUnmount() {
    if (this.props.isCompleteWheel) {
      this.props.onSearchParamChange('tyreDimensions', [])
      this.props.onSearchParamChange('loadIndex', '')
      this.props.onSearchParamChange('loadIndexRear', '')
      this.props.onSearchParamChange('speedIndex', '')
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultSpeedIndex !== this.props.defaultSpeedIndex) {
      this.props.onSearchParamChange('speedIndex', this.props.defaultSpeedIndex)
    }
  }

  onRadioButtonChange(option) {
    const { restrictDiameters, allDiameters, selectedOption } = this.props
    if (option.label !== selectedOption && option.type === 'RESTRICT_DIAMETER')
      restrictDiameters(option, allDiameters)
  }

  onCheckboxChange(value) {
    let  selectedDiameters
    const checked = this.props.selectedDiameters.indexOf(value) !== -1
    if (checked)
      selectedDiameters = this.props.selectedDiameters.filter(diameter => diameter !== value)
    else {
      selectedDiameters = this.props.selectedDiameters.slice()
      selectedDiameters.push(value)
    }
    if (!selectedDiameters.length)
      selectedDiameters = [...this.props.diameters]
    this.props.onSearchParamChange('diameter', selectedDiameters)
  }

  render () {
    let restrictDiametersOptionsComponents
    if (this.props.restrictDiametersOptions) {
      restrictDiametersOptionsComponents = this.props.restrictDiametersOptions.map(option => {
        return (
          <RadioButton
            inline
            onChange={(name, value) => this.onRadioButtonChange(option)}
            value={option.label}
            name={option.label}
            key={option.label}
            label={option.label}
            className={styles.checkbox}
            isChecked={this.props.selectedOption === option.label}
            small
          />
        )
      })
    }
    let diametersComponent
    if (this.props.diameters)
      diametersComponent = this.props.diameters.map(diameter => {
        return (
          <Checkbox
            inline
            onChange={this.handleCheckboxChange}
            value={diameter}
            name={diameter}
            key={diameter}
            label={`${diameter}"`}
            className={styles.checkbox}
            isChecked={this.props.selectedDiameters.indexOf(diameter) > -1}
          />
        )
      })
    return (
      <div className="diameters">
        <FormGroup>{restrictDiametersOptionsComponents}</FormGroup>
        <FormGroup className={styles.wrapper}>{diametersComponent}</FormGroup>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  restrictDiameters: (option, allDiameters) => restrictDiameters(option, allDiameters)
}, dispatch)

const mapStateToProps = (state, ownProps) => {
  const searchParams = state.categories.activeCategory === CATEGORIES.COMPLETE_WHEEL ? state.completeWheelSearchParams : state.rimSearchParams
  const selectedDiameters = ownProps.allDiameters.filter(diameter => searchParams.diameter.includes(diameter))
  const { vehicle } = state.vehicle.vehicle ? state.vehicle : state.carPicker
  const _isWinter = isWinter(searchParams.tyreType)
  const _isAll = Array.isArray(searchParams.tyreType) ? (searchParams.tyreType.length === 1 && !searchParams.tyreType[0]) : !searchParams.tyreType

  // TODO: Refactor using helper or etc.
  const standard = getStandard(state)
  let defaultSpeedIndex = standard && standard.front && standard.front.tyre && standard.front.tyre.speedIndex
  let defaultLoadIndexFront = standard && standard.front && standard.front.tyre && standard.front.tyre.loadIndex
  let defaultLoadIndexRear = standard && standard.rear && standard.rear.tyre && standard.rear.tyre.loadIndex

  try {
    const urlQuery = new URLSearchParams(window.location.search)
    const speedIndexFromCriteria = urlQuery.get('speedIndex')
    const _prevTyreType = urlQuery.get('tyreType')
    const _prevIsWinter = isWinter([_prevTyreType])

    if (speedIndexFromCriteria && (String(searchParams.tyreType[0]) === String(_prevTyreType) || _isWinter === _prevIsWinter)) {
      defaultSpeedIndex = speedIndexFromCriteria && speedIndexFromCriteria.toUpperCase()
    } else if ((_isWinter || _isAll) && vehicle && vehicle.tyreStandardSize && vehicle.tyreStandardSize.speedIndexWinter) {
      defaultSpeedIndex = vehicle.tyreStandardSize.speedIndexWinter
    }

    if (urlQuery.get('loadIndex')) {
      defaultLoadIndexFront = parseInt(urlQuery.get('loadIndex'), 10)
    }
    if (urlQuery.get('loadIndexRear')) {
      defaultLoadIndexRear = parseInt(urlQuery.get('loadIndexRear'), 10)
    }
  } catch(e) { /* Fail-safe */ }

  return {
    diameters: state.diameters.isRestricted ? state.diameters.diameters : ownProps.allDiameters,
    selectedDiameters: ownProps.allDiameters.length !== selectedDiameters.length ? selectedDiameters : (
      // NOTE: Default is all diameters
      ownProps.allDiameters.length
        ? ownProps.allDiameters
        : []
    ),
    restrictDiametersOptions: state.diameters.restrictDiametersOptions,
    selectedOption: state.diameters.selectedOption,
    defaultSpeedIndex,
    defaultLoadIndexFront,
    defaultLoadIndexRear,
    isCompleteWheel: state.categories.activeCategory.id === CATEGORIES.COMPLETE_WHEEL.id
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Diameter)
