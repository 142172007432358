// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__input___LzqSa {
  width: 250px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/TyreSearch/SearchMode/styles.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF","sourcesContent":[".input {\n  width: 250px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `styles__input___LzqSa`
};
export default ___CSS_LOADER_EXPORT___;
