// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__container___q8P38 {
  max-width: 1140px !important;
  margin-top: 20px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(19, 39, 48, 0.12);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}
.styles__container___q8P38 section {
  margin-bottom: 45px;
}
.styles__addTpms___eVCC5 {
  max-width: 1140px !important;
  margin-top: 20px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(19, 39, 48, 0.12);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  border-radius: 4px;
  padding: 36px;
}
.styles__addTpms___eVCC5 h4 {
  display: inline-block;
}
.styles__addTpms___eVCC5 .styles__price-info___Z7yea {
  font-weight: bold;
}
.styles__addTpms___eVCC5 section {
  margin-bottom: 45px;
}
@media (max-width: 991px) {
  .styles__addTpms___eVCC5 {
    padding: 16px;
  }
}
.styles__radioButtonGroup___fHJO_ {
  margin-bottom: 10px;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/shared/layout.less","webpack://./src/components/components/CheckoutComponent/Tpms/styles.less"],"names":[],"mappings":"AAEA;EACE,4BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,8CAAA;EACA,kDAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;ACDF;ADTA;EAYI,mBAAA;ACAJ;AAXA;EDAE,4BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,8CAAA;EACA,kDAAA;EAEA,iBAAA;EACA,kBAAA;ECAA,gBAAA;EACA,kBAAA;EACA,aAAA;AAcF;AAzBA;EAEI,qBAAA;AA0BJ;AA5BA;EAKI,iBAAA;AA0BJ;AA/BA;EDWI,mBAAA;ACuBJ;AAtBE;EAAA;IACE,aAAA;EAyBF;AACF;AAtBA;EACE,mBAAA;EACA,gBAAA;AAwBF","sourcesContent":["@import './variables.less';\n\n.container {\n  max-width: 1140px !important;\n  margin-top: 20px;\n  margin-bottom: 15px;\n  border-radius: 3px;\n  background-color: #fff;\n  box-shadow: @eon-box-shadow;\n  transition: @eon-transition-default;\n  border-radius: 4px;\n  margin-left: auto;\n  margin-right: auto;\n  section {\n    margin-bottom: 45px;\n  }\n}\n","@import '../../../../shared/variables.less';\n@import '../../../../shared/layout.less';\n\n.addTpms {\n  h4 {\n    display: inline-block; // must not be too wide for tooltip to position itself correctly // which tooltip? -stian\n  }\n  .price-info {\n    font-weight: bold;\n  }\n  // like .mainContent in ../styles\n  .container();\n  background: @eon-checkout-section-background;\n  border-radius: 4px;\n  padding: 36px;\n  @media (max-width: 991px) {\n    padding: 16px;\n  }\n}\n\n.radioButtonGroup {\n  margin-bottom: 10px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container___q8P38`,
	"addTpms": `styles__addTpms___eVCC5`,
	"price-info": `styles__price-info___Z7yea`,
	"radioButtonGroup": `styles__radioButtonGroup___fHJO_`
};
export default ___CSS_LOADER_EXPORT___;
