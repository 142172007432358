// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__option___BML8P {
  height: 25px;
  width: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: grey;
}
.styles__selected___k8xef {
  color: #fff;
}
.styles__optionsGroup___NFKzI {
  margin-left: -4px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Form/RangeSelector/styles.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;EACA,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,WAAA;AADF;AAIA;EAEE,WAAA;AAFF;AAKA;EACE,iBAAA;EACA,eAAA;AAHF","sourcesContent":["@import '../../../../shared/variables.less';\n\n.option {\n  height: 25px;\n  width: 16px;\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: bold;\n  color: grey;\n}\n\n.selected {\n  composes: option;\n  color: @eon-header-text-color;\n}\n\n.optionsGroup {\n  margin-left: -4px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": `styles__option___BML8P`,
	"selected": `styles__selected___k8xef styles__option___BML8P`,
	"optionsGroup": `styles__optionsGroup___NFKzI`
};
export default ___CSS_LOADER_EXPORT___;
