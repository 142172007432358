import {
    CHECKOUT_START,
    ORDER_CREATED,
    CHECKOUT_ERROR
} from '../actions/checkout'

const order = (state = {}, action) => {
    switch (action.type) {
        case CHECKOUT_START:
          return { submitting: true }
        case ORDER_CREATED:
        	return Object.assign({}, action.order)
        case CHECKOUT_ERROR:
          return { error: action.error, submitting: false }
        default:
          return state
    }
}

export default order
