import { api } from '../helpers/api'
import config from '../config'
import { setUser } from './user'
import { fetchCart } from './cart'
import { fetchPriceOptions } from './price'
import { initializePreorderData } from './preOrder'

import qs from 'query-string'

export const SET_SESSION = 'SET_SESSION'
export const SHOP_RETRIEVED = 'SHOP_RETRIEVED'
export const SHOP_INITIALIZED = 'SHOP_INITIALIZED'

const receiveShop = (shop, headless) => {
  return {
    type: SHOP_RETRIEVED,
    shop,
    headless
  }
}

const setSession = (sessionToken, settings) => {
  return  {
    type: SET_SESSION,
    sessionToken,
    settings,
  }
}

export const init = () => {
  return (dispatch, getState) => {
    // fetch shop
    return fetch(config.FETCH_SHOP_URL)
      .then(response => {
        return response.json()
      }).then(response => {
        const params = qs.parse(window.location.search)
        const headless = params.headless
        dispatch(receiveShop(response.data, headless))
        const shop = getState().shop
        // fetch session
        api(`${shop.apiUrl}/session`, 'post', {webshopId: shop.webshopId})
        .then(r => r.json())
        .then(r => {
          const sessionToken = localStorage.getItem('sessionToken');
          if (r.data.token !== sessionToken) {
            localStorage.setItem('sessionToken', r.data.token)
            localStorage.removeItem('user')
            dispatch(setUser(null))
            dispatch(setSession(r.data.token, r.data.settings))
          } else {
            dispatch(setUser(JSON.parse(localStorage.getItem('user'))))
            dispatch(setSession(r.data.token, r.data.settings))

            if (getState().user.isLoggedIn)
              dispatch(initializePreorderData())
          }
          dispatch(fetchCart())
          dispatch(fetchPriceOptions())
        })
        .catch(e => {
          console.error(e);
        })
      })
      .catch(err => console.error(err))
  }
}
