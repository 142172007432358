import React from 'react'
import { connect } from 'react-redux'
import { FormattedNumber } from 'react-intl'
import { T } from 'components/components/i18n'
import styles from './styles.less'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { removeItem, updateItem } from '../../../../actions/cart'
import InputGroup from '../../Form/InputGroup'
import { Row, Col } from 'react-bootstrap'
import CATEGORIES from '../../../../helpers/categories'
import DeliveryTime from '../../DeliveryTime'
import SupplierPopover from '../../Search/SearchResults/SupplierPopover'
import { faTruck, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExactDeliveryTime from '../../ExactDeliveryTimePopup'
import ProductInfoPopover from 'components/components/Search/SearchResults/ProductInfoPopover'
// import Selectbox from 'components/components/Form/Selectbox'
import { getUsedStockInfo } from 'helpers/order'
import classnames from 'classnames'
import debounce from 'lodash/debounce'

export const ProductItem = ({
  item,
  includeVat,
  cartItems,
  shop,
  currency,
  inProgress,
  removeItem,
  updateItem,
  deleteDisabled,
  className,
}) => {
  const formattedPrice = (
    <FormattedNumber
      minimumFractionDigits={0}
      maximumFractionDigits={0}
      value={includeVat ? item.formattedPriceInclVat : item.formattedPrice}
      style={`currency`}
      currency={currency}
    />
  )

  const imageSrc = item &&
    item.image ? item.image.webshop_thumb : '/images/default-product-image.png'


  const deleteItem = () => {
    removeItem(item, cartItems)
  }

  const updateCount = (value) => {
    if (value && value !== '' && parseInt(value, 10) > -1) {
      item.count = value
      updateItem(item, item.count, cartItems, true)
    }
  }

  const updateComment = (event) => {
    if (item.comment !== event.target.value) {
      item.comment = event.target.value
      updateItem(item, item.count, cartItems)
    }
  }

  let rimInfo, nameAndBrand, itemSummary
  if (item.autoProductId) {
    nameAndBrand = item.name
    itemSummary = <T id="- autoproduct"/>
  } else if (item.category.id === CATEGORIES.COMPLETE_WHEEL.id) {
    rimInfo = (
      <div className={classnames(styles.productDescription, 'checkout-product-description')}>
        <div className={`${styles.productTitle} text-colored`}>{item.rim.brand.name} {item.rim.model.name}</div>
        <span>{item.rim.attrs.dimension}</span>
      </div>
    )
    nameAndBrand = `${item.tyre.brand.name} ${item.tyre.model.name}`
    itemSummary = item.tyre.attrs.dimension
  } else if (item.category.id === CATEGORIES.OTHER.id) {
    nameAndBrand = item.brand.name
      ? `${item.brand.name} ${item.model.name || ''}`
      : item.name
    itemSummary = item.description
  } else {
    nameAndBrand = `${item.brand.name} ${item.model.name || ''}`
    itemSummary = item.attrs.dimension
  }

  let delivery, supplier

  if (shop.showSupplierInSearchResults) {
    const productSource = item.supplier.id ? item.supplier : item.location
    if(productSource && !productSource.hideIt)
      supplier = <SupplierPopover supplier={item.supplier} supplierNameComponent={productSource.name} />
  }

  if (item.deliveryTime && !item.deliveryTime.hideIt) {
    if (shop.deliveryDisplayMode === 1) {
      const deliveryTime = <DeliveryTime deliveryInfo={item.deliveryTime} />
      delivery = <div>{deliveryTime}</div>
    } else {
      delivery = (
        <FontAwesomeIcon
          icon={faTruck}
        />
      )
    }
  }
  delivery = <div>{delivery}</div>
  const vehicle = !!(item.car.vehicleId || item.car.licenseplate) && (
    <div className={styles.cartItemSummary}>
      <T id="Vehicle" />:&nbsp;
      {item.car.licenseplate || item.car.vehicleId}
    </div>
  )
  const productTitle = (
    <div className={classnames(styles.productTitle, 'text-colored', 'checkout-item-title', {
      [styles.productTitleLinked]: !item.autoProductId
    })}>{nameAndBrand}</div>
  )
  const productInfo = (
    <div className={styles.flexIt} style={{width:'100%'}}>
      <img src={imageSrc} alt='' className={styles.cartItemImg} />
      <div className={classnames(styles.productDescription, 'checkout-item-description')} title={nameAndBrand}>
        <ProductInfoPopover product={item} productTitleElements={productTitle} />
        <span className={classnames({
          [styles.autoProductSuffix] : item.autoProductId,
          [styles.cartItemSummary]: !item.autoProductId,
        }, 'checkout-item-summary')}>{itemSummary}  </span>
        {rimInfo}
        {vehicle}
      </div>
    </div>
  )
  const productLink = !item.autoProductId
    ? (
      <Link to={`/${item.category.slug}/${item.id}/${item.slug}`} className={styles.cartItemLink}>
        {productInfo}
      </Link>
    )
    : (
      <span className={styles.cartItemLink}>{productInfo}</span>
    )

  // NOTE: Temp solution to test how users perceive this behaviour
  //       where when USED complete wheel is low on stock (<= 4),
  //       it only allow user to buy 2 and/or 4 quantity
  const {
    isLockQuantity
  } = getUsedStockInfo(
    item?.condition?.type,
    item?.stock,
    item.count
  )


  return (
    <Row className={classnames(styles.productRow, 'checkout-item', className)}>
      <Col md={4} sm={12} className={`${styles.productCol} ${styles.leftAlign}`}>
        {productLink}
      </Col>
      <Col md={3} sm={12} className={styles.productCol}>
        <div className={classnames(styles.flexSpaceBetween, 'checkout-item-count')}>
          <span className={styles.textMuted}><T id="Items"/>:</span>
          {(!item.autoProductId && !isLockQuantity) && (
            <InputGroup
              type="number"
              onChange={debounce(updateCount, 500)}
              disabled={item.autoProductId}
              name="count"
              value={item.count}
              className={styles.count}
            />
          )}
          {(item.autoProductId || isLockQuantity) && (
            <span className={styles.count}>{item.count}</span>
          )}
          <span className={styles.productUnit}>
            <T id="pc"/> {formattedPrice}
          </span>
        </div>
        {!item.autoProductId && (
          <div className={classnames(styles.flexSpaceBetween, 'checkout-item-note')}>
            <span className={styles.textMuted}><T id="Note"/>:</span>
            <T id="Note">
              {
                msg => (
                  <InputGroup
                    onBlur={updateComment}
                    disabled={item.autoProductId}
                    name="comment"
                    value={item.comment}
                    className={styles.input}
                    placeholder={msg}
                    hintText="Add extra info to the order, eg. driver’s or customer’s name, contact details, and etc."
                    maxlength={500}
                  />
                )
              }
            </T>
          </div>
        )}
      </Col>
      <Col md={2} sm={12} className={styles.productCol}>
        {delivery}
        {shop.hasAvailabilityCheck && !item.autoProductId && <ExactDeliveryTime item={item} />}
        {supplier}
      </Col>
      <Col md={2} sm={12} className={classnames(styles.priceColumn, 'background-light', 'checkout-item-total-price')}>
        <T id="Sum"/> <span className={styles.price}>
        <FormattedNumber
          minimumFractionDigits={0}
          maximumFractionDigits={0}
          value={includeVat ? item.formattedTotalInclVat : item.formattedTotal}
          style={`currency`}
          currency={currency}
        /></span>
      </Col>
      <Col md={1} xs={12} className={classnames(styles.deleteButtonCol, 'background-light', 'checkout-item-delete-col')} onClick={!inProgress && !deleteDisabled && deleteItem}>
        {inProgress && <FontAwesomeIcon icon={faSpinner} spin />}
        {!inProgress && !deleteDisabled && (
          <React.Fragment>
            <div id="delete" className="delete-button">
              <FontAwesomeIcon icon={faTrashAlt} className="delete" />
            </div>
            <span className={styles.deleteButtonLabel}>
              <T id="Remove from cart"/>
            </span>
          </React.Fragment>
        )}
      </Col>
    </Row>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  removeItem: (item, cartItems) => removeItem(item, cartItems),
  updateItem: (item, count, cartItems, refreshDeliveryTimes) => updateItem(item, count, cartItems, refreshDeliveryTimes)
}, dispatch)

const mapStateToProps = (state, props) => {
  return {
    shop: state.shop,
    includeVat: state.price.includeVat,
    currency: state.intl.currency,
    inProgress: state.cart.updateInProgress[props.item.id],
    deleteDisabled: props.item.autoProductId && props.item.autoProductType === 'MANDATORY'
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductItem)
