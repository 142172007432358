import React from 'react'
import { bindActionCreators } from 'redux'
import { FormControl, Button, FormGroup, ControlLabel, Row, Col, HelpBlock } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { connect } from 'react-redux'
import { validate, nextStep, changeParameter, previousStep, signUp } from '../../../../actions/signUp'
import { keyCodes } from '../../../../constants/keyCodes'
import RadioButton from '../../RadioButton'
import styles from './styles.less'
import { Link } from 'react-router-dom'

class CustomerInfo extends React.Component {
  constructor(props) {
    super(props)
    this.handleNextClick = this.onNextClick.bind(this)
    this.handleBackClick = this.onBackClick.bind(this)
    this.handleInputChange = this.onChange.bind(this)
    this.handleRadioChange = this.onRadioChange.bind(this)
    this.handleKeyPress = this.onKeyPress.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { hasCustomFields, nextStep, params, shop, signUp, isValidated, isValid } = this.props
    if (isValidated && isValid) {
      if (hasCustomFields)
        nextStep()
      else
        signUp(params, shop)
    }
  }

  onNextClick() {
    const { params, shop, validate } = this.props
    validate(params, shop)
  }

  onBackClick(event) {
    event.preventDefault()
    this.props.previousStep()
  }

  onChange(event) {
    this.props.changeParameter(event.target.name, event.target.value)
  }

  onRadioChange(name, value) {
    this.props.changeParameter(name, value)
  }

  onKeyPress(event) {
    if (event.which === keyCodes.ENTER)
      this.onNextClick()
  }

  render() {
    const { fields, params, errors, hasCustomFields } = this.props
    let customerType
    if (fields.customerType && !fields.customerType.hidden)
      customerType = (
        <FormGroup>
          <Row>
            <Col md={4}><ControlLabel>Kundtyp</ControlLabel></Col>
            <Col md={8}>
              <div className={styles.radioGroup}>
                <RadioButton
                  value="business"
                  isChecked={params.customerType === 'business'}
                  name="customerType"
                  label={<T id="Business"/>}
                  onChange={this.handleRadioChange}
                />
                <RadioButton
                  value="person"
                  className={styles.inlineRadio}
                  isChecked={params.customerType === 'person'}
                  name="customerType"
                  label={<T id="Person"/>}
                  onChange={this.handleRadioChange}
                />
              </div>
            </Col>
          </Row>
        </FormGroup>
      )
    let businessCustomerFields
    if (params.customerType === 'business')
      businessCustomerFields = (
        <div>
          <FormGroup validationState={(!!errors.companyRegistrationNumber && 'error') || null}>
            <Row>
              <Col md={4}>
              <ControlLabel>
                <T id="Organization number"/></ControlLabel></Col>
              <Col md={8}><FormControl defaultValue={params.companyRegistrationNumber} name="companyRegistrationNumber" onBlur={this.handleInputChange}/></Col>
            </Row>
            <Row><Col md={8} mdOffset={4}>{errors.companyRegistrationNumber ? <HelpBlock>{errors.companyRegistrationNumber}</HelpBlock> : null}</Col></Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={4}>
                <ControlLabel>
                  <T id="Contact person"/>
                </ControlLabel>
              </Col>
              <Col md={8}><FormControl defaultValue={params.contactPerson} name="contactPerson" onBlur={this.handleInputChange}/></Col>
            </Row>
            <Row><Col md={8} mdOffset={4}>{errors.contactPerson ? <HelpBlock>{errors.contactPerson}</HelpBlock> : null}</Col></Row>
          </FormGroup>
          <FormGroup validationState={(!!errors.companyEmail && 'error') || null}>
            <Row>
              <Col md={4}>
                <ControlLabel>
                  <T id="Company email"/>
                </ControlLabel>
              </Col>
              <Col md={8}><FormControl defaultValue={params.companyEmail} name="companyEmail" onBlur={this.handleInputChange}/></Col>
            </Row>
            <Row><Col md={8} mdOffset={4}>{errors.companyEmail ? <HelpBlock>{errors.companyEmail}</HelpBlock> : null}</Col></Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={4}>
                <ControlLabel>
                  <T id="Invoice email"/></ControlLabel></Col>
              <Col md={8}><FormControl defaultValue={params.invoiceEmail} name="invoiceEmail" onBlur={this.handleInputChange}/></Col>
            </Row>
            <Row><Col md={12} className={`${styles.invoiceEmailNote} text-muted`}>*Lämna tomt för att använda företagets epost</Col></Row>
            <Row><Col md={8} mdOffset={4}>{errors.invoiceEmail ? <HelpBlock>{errors.invoiceEmail}</HelpBlock> : null}</Col></Row>
          </FormGroup>
        </div>
      )
    const name = params.customerType === 'business' ? <T id="Company name"/> : <T id="Name"/>
    return (
      <div onKeyPress={this.handleKeyPress}>
        {customerType}
        <FormGroup validationState={(!!errors.customerName && 'error') || null}>
          <Row>
            <Col md={4}><ControlLabel>{name}</ControlLabel></Col>
            <Col md={8}><FormControl defaultValue={params.customerName} name="customerName" onBlur={this.handleInputChange}/></Col>
          </Row>
          <Row><Col md={8} mdOffset={4}>{errors.customerName ? <HelpBlock>{errors.customerName}</HelpBlock> : null}</Col></Row>
        </FormGroup>
        <FormGroup validationState={(!!errors.customerAddress && 'error') || null}>
          <Row>
            <Col md={4}>
              <ControlLabel>
                <T id="Address"/>
              </ControlLabel>
            </Col>
            <Col md={8}><FormControl defaultValue={params.customerAddress} name="customerAddress" onBlur={this.handleInputChange}/></Col>
          </Row>
          <Row><Col md={8} mdOffset={4}>{errors.customerAddress ? <HelpBlock>{errors.customerAddress}</HelpBlock> : null}</Col></Row>
        </FormGroup>
        <Row>
          <Col mdOffset={4} md={4}>
            <FormGroup validationState={(!!errors.customerZipcode && 'error') || null}>
              <T id="Zipcode">
                {
                  msg => (
                    <FormControl
                      placeholder={msg}
                      defaultValue={params.customerZipcode}
                      name="customerZipcode"
                      onBlur={this.handleInputChange}
                    />
                  )
                }
              </T>
              {errors.customerZipcode ? <HelpBlock>{errors.customerZipcode}</HelpBlock> : null}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup validationState={(!!errors.customerCity && 'error') || null}>
              <T id="City">
                {
                  msg => (
                    <FormControl
                      placeholder={msg}
                      defaultValue={params.customerCity}
                      name="customerCity"
                      onBlur={this.handleInputChange}
                    />
                  )
                }
              </T>
              {errors.customerCity ? <HelpBlock>{errors.customerCity}</HelpBlock> : null}
            </FormGroup>
          </Col>
        </Row>
        <FormGroup validationState={(!!errors.customerPhoneMobile && 'error') || null}>
          <Row>
            <Col md={4}>
              <ControlLabel>
                <T id="Phone number"/>
              </ControlLabel>
            </Col>
            <Col md={4}>
              <T id="Mobile">
                {
                  msg => (
                    <FormControl
                      placeholder={msg}
                      defaultValue={params.customerPhoneMobile}
                      name="customerPhoneMobile"
                      onBlur={this.handleInputChange}
                    />
                  )
                }
              </T>
            </Col>
            <Col md={4}>
              <T id="Landline">
               {
                  msg => (
                    <FormControl
                      placeholder={msg}
                      defaultValue={params.customerPhoneLandline}
                      name="customerPhoneLandline"
                      onBlur={this.handleInputChange}
                    />
                  )
                }
              </T>
            </Col>
          </Row>
          <Row><Col md={8} mdOffset={4}>{errors.customerPhoneMobile ? <HelpBlock>{errors.customerPhoneMobile}</HelpBlock> : null}</Col></Row>
        </FormGroup>
        {businessCustomerFields}
        <div className={styles.footer}>
          <Button onClick={this.handleNextClick}>{hasCustomFields ? <T id="Next" /> : <T id="Submit" />}</Button>
          <Link to="/" onClick={this.handleBackClick}><T id="Back"/></Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    hasCustomFields: state.signUp.hasCustomFields,
    params: state.signUp.params,
    fields: state.signUp.fields,
    errors: state.signUp.errors,
    shop: state.shop,
    isValid: state.signUp.isValid,
    isValidated: state.signUp.isValidated,
    isPreOrderMode: state.preOrder.targetId,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  changeParameter: (name, value) => changeParameter(name, value),
  nextStep: () => nextStep(),
  previousStep: () => previousStep(),
  validate: (params, shop) => validate(params, shop),
  signUp: (params, shop) => signUp(params, shop)
}, dispatch)
 
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerInfo)
