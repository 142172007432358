import React from 'react'
import ProfitMargins from './ProfitMargins'
import { T } from 'components/components/i18n'

export default class CustomerPreferences extends React.Component {
  render() {
    return (
      <div className="container">
        <h1>
          <T id="My account"/>
        </h1>
        <ProfitMargins/>
      </div>
     )
  }
}
