import React from 'react'
import { T } from 'components/components/i18n'

const TYPES = {
  ALL_SEASON: {
    id: '1',
    season: 'all',
    label: <T id='All season'/>,
    description: <T id='All season tires'/>
  },
  SUMMER: {
    id: '2',
    season: 'summer',
    label: <T id='Summer'/>,
    description: <T id='Summer tires'/>
  },
  WINTER: {
    id: '3',
    season: 'winter',
    label: <T id='Winter'/>,
    description: <T id='Winter tires'/>
  },
  ALL: {
    id: null,
    season: 'all',
    label: <T id='All'/>,
    description: <T id='All tires'/>
  }
}

const SUBTYPES = {
  NORDIC_WINTER: {
    id: '4',
    season: 'winter',
    label: <T id='Nordic'/>,
    description: <T id='Nordic winter'/>
  },
  EU_WINTER: {
    id: '9',
    season: 'winter',
    label: <T id='European'/>,
    description: <T id='European winter'/>
  },
  STUDDED: {
    id: '5',
    season: 'winter',
    label: <T id='Studded'/>,
    description: <T id='Winter tires (studded)'/>
  },
  STUDDABLE: {
    id: '6',
    season: 'winter',
    label: <T id='Studdable'/>,
    description: <T id='Winter tires (studdable)'/>,
    checkedClass: 'svg-stroke-colored',
    uncheckedClass: 'svg-stroke-muted'
  }
}

const SUBTYPES_FRICTION = {
  NORDIC_WINTER: SUBTYPES.NORDIC_WINTER,
  EU_WINTER: SUBTYPES.EU_WINTER,
}

const SUBTYPES_STUD = {
  STUDDED: SUBTYPES.STUDDED,
  STUDDABLE: SUBTYPES.STUDDABLE,
}

const TYRE_TYPES = Object.assign({}, TYPES, SUBTYPES)

const getTypeById = (id) => {
  const typeKey = Object.keys(TYRE_TYPES).find(key => TYRE_TYPES[key].id === id)
  return TYRE_TYPES[typeKey]
}

const allWinterTyreTypesId = [
  SUBTYPES.NORDIC_WINTER.id,
  SUBTYPES.EU_WINTER.id,
  SUBTYPES.STUDDED.id,
  SUBTYPES.STUDDABLE.id,
]

export { TYRE_TYPES, TYPES, SUBTYPES, SUBTYPES_FRICTION, SUBTYPES_STUD, getTypeById, allWinterTyreTypesId }