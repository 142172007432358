import React from 'react'
import {
  FormattedMessage,
  injectIntl
} from 'react-intl'
import { api } from 'helpers/api'

const CONFIG = {
  server: process.env.NODE_ENV === 'development'
    ? '/api/i18n'
    : process.env.I18NSRV_URL,
  markMissing: true,
  reportMissing: true,
  reportUsage: true,
  dataset: 'webshop',
  reportInterval: 5000
}

const _formatMissing = key => {
  const text = key.indexOf('c:') === 0 ? key.split(':').pop() : key
  return CONFIG.markMissing ? '[' + text + ']' : text
}

const _reportHandler = (locale, _missing, _usage) => {
  if ( _missing.length || _usage.length ) {
    const _date = _getNowDate();
    _missing = _missing
      .filter((v, i, a) => a.indexOf(v) === i)
    _usage = _usage
      .filter((v, i, a) => a.indexOf(v) === i)
      .map(text => {
        return {
          key: text,
          date: _date
        }
      })

    api(`${CONFIG.server}/report`, 'post', {
      dataset: CONFIG.dataset,
      missing: _missing.length
        ? [{
            language: locale,
            keys: _missing
          }]
        : [],
      usage: _usage
    })
      .catch(e => {
        console.error('i18n server:', e)
      })
  }
}

const _getNowDate = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const monthStr = month < 10 ? '0' + month : '' + month
  const dayStr = day < 10 ? '0' + day : '' + day
  return year + '-' + monthStr + '-' + dayStr
}

let _report  = null,
    _missing = [],
    _usage   = []

const _FormattedMessage = props => {
  const {intl, ...componentProps} = props

  React.useEffect(() => {
    if ( CONFIG.reportMissing || CONFIG.reportUsage ) {
      clearTimeout(_report)
      _report = setTimeout(() => {
        try {
          _reportHandler(
            intl.locale,
            _missing.splice(0, _missing.length),
            _usage.splice(0, _usage.length)
          )
        } catch(e) {
          // Prevent this blocking normal webshop usage
          console.error('i18n server:', e)
        }
      }, CONFIG.reportInterval)
    }

    if (!intl.messages[props.id]) {
      intl.messages[props.id] = _formatMissing(props.id)
      _missing.push(props.id)
    } else if (_missing.findIndex(key => key === props.id) === -1) {
      _usage.push(props.id)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormattedMessage
      {...componentProps}
    />
  )
}

export const T = injectIntl(_FormattedMessage)