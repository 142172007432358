import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const FrontPage  = (props) => (
  <div>
    <Redirect to={props.frontPageConfig.url}/>
  </div>
)

const mapStateToProps = state => {
  return {
    frontPageConfig: state.menu.frontPage
  }
}

export default connect(mapStateToProps)(FrontPage)
