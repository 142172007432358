import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.less'
import { Col, Row } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import RadioButton from '../../RadioButton'
import { DELIVERY_OPTIONS } from '../../../../constants/checkout'
import classnames from 'classnames'

export const DeliveryOptions = (props) => {
  const {
    deliveryOptions,
    hasCustomerDelivery,
    hasDirectDelivery,
    hasPickupDelivery,
    hasPartnerDelivery,
    onClick,
    selectedOption,
    isB2B,
  } = props

  let deliveryOptionsSelect = []
  const DeliveryOptionsRadioButton = (props) => {
    const { radioId, labelStrong, label, value } = props
    const onOptionClick = () => {
      onClick(value)
    }
    const isChecked = selectedOption === value
    const className = isChecked ? `${styles.option} background-light` : `${styles.option} text-muted`
    return (
      <Col md={6} xs={12}>
        <div className={classnames(className, 'delivery-options-radio-button')} onClick={onOptionClick}>
          <RadioButton
            type="radio"
            name="deliveryOption"
            id={radioId}
            isChecked={isChecked}
            className={styles.noMarginBottom}/>
          <div className={styles.label}>
            <label>{labelStrong}</label>
            <div className="text-muted">{label}</div>
          </div>
        </div>
      </Col>
    )
  }

  if (hasPickupDelivery) {
    let labelStrong = isB2B ?
      <T id="Free our warehouse"/> :
      <T id="Deliver to our warehouse"/>
    let label = isB2B ?
      <T id="Products are collected at our warehouse"/> :
      <T id="Get in place with us"/>
    deliveryOptionsSelect.push(
      <DeliveryOptionsRadioButton
        key={DELIVERY_OPTIONS.PICKUP}
        value={DELIVERY_OPTIONS.PICKUP}
        radioId="deliveryOption-0"
        labelStrong={labelStrong}
        label={label} />
    )
  }

  if (hasCustomerDelivery) {
    let labelStrong = isB2B ?
      <T id="Deliver to your warehouse"/> :
      <T id="Deliver to my address"/>
    let label = isB2B ?
      <T id="We deliver the products to your workshop or warehouse"/> :
      <T id="Get delivered to your home or delivery address and assemble yourself"/>
    deliveryOptionsSelect.push(
      <DeliveryOptionsRadioButton
        key={DELIVERY_OPTIONS.CUSTOMER}
        value={DELIVERY_OPTIONS.CUSTOMER}
        radioId="deliveryOption-1"
        labelStrong={labelStrong}
        label={label} />
    )
  }

  if (hasDirectDelivery) {
    let labelStrong = isB2B ?
      <T id="Direct from supplier"/> :
      <T id="Deliver to my address"/>
    let label = isB2B ?
      <T id="Shipped directly from supplier"/> :
      <T id="Get delivered to your home or delivery address and assemble yourself"/>
    deliveryOptionsSelect.push(
      <DeliveryOptionsRadioButton
        key={DELIVERY_OPTIONS.DIRECT}
        value={DELIVERY_OPTIONS.DIRECT}
        radioId="deliveryOption-2"
        labelStrong={labelStrong}
        label={label} />
    )
  }

  if (hasPartnerDelivery) {
    let labelStrong = <T id="Deliver to garage"/>
    let label = <T id="Deliver to garage" />
    deliveryOptionsSelect.push(
      <DeliveryOptionsRadioButton
        key={DELIVERY_OPTIONS.PARTNER}
        value={DELIVERY_OPTIONS.PARTNER}
        radioId="deliveryOption-2"
        labelStrong={labelStrong}
        label={label}
      />
    )
  }

  if (deliveryOptions.length === 1) {
    return (
      <input type="hidden" name="deliveryOption" value={deliveryOptions[0]} />
    )
  }
  if (deliveryOptions.length > 1) {
    return (
      <section className="delivery-options">
        <h2><T id="Choose delivery option"/></h2>
        <div  className={styles.mainContent}>
          <Row>
            {deliveryOptionsSelect}
          </Row>
        </div>
      </section>
    )
  }

  else {
    return null
  }
}

const mapStateToProps = state => {
  return {
    deliveryOptions: state.shop.deliveryOptions,
    hasDirectDelivery: state.shop.hasDirectDelivery,
    hasPickupDelivery: state.shop.hasPickupDelivery,
    hasCustomerDelivery: state.shop.hasCustomerDelivery,
    hasPartnerDelivery: state.shop.hasPartnerDelivery,
    isB2B: state.shop.isB2B,
    user: state.user,
  }
}

export default connect(
  mapStateToProps
)(DeliveryOptions)
