// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__flexIt___XDo2_ {
  display: flex;
  align-items: center;
}
.styles__flexRowCenter___v_kpc {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.styles__cartItem___gbGuc {
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  height: 82px;
}
.styles__price___oY8DV {
  color: #ff3233;
  margin-right: 10px;
}
.styles__productDescription___fLWCo {
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
}
.styles__cartItemLink___wL8Wm {
  justify-content: space-between;
  padding: 5px 0;
  overflow: hidden;
  text-decoration: none !important;
  color: #333333 !important;
  width: 100%;
}
.styles__cartItemImg____M8xx {
  height: 36px;
  width: 36px;
  float: left;
  margin-right: 10px;
}
.styles__cartItemStrong___tZqLe {
  overflow: hidden;
  display: block;
}
.styles__cartItemRemove___g_aDQ {
  text-align: right;
  color: #d8d8d8;
  background: transparent;
  cursor: pointer;
}
.styles__title___FRNIt {
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/CartItem/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAFF;AAIA;EAEE,kBAAA;EACA,gBAAA;EACA,8BAAA;EACA,YAAA;AAFF;AAKA;EACE,cAAA;EACA,kBAAA;AAHF;AAMA;EAEE,sBAAA;EACA,uBAAA;EACA,eAAA;AAJF;AAOA;EAEE,8BAAA;EACA,cAAA;EACA,gBAAA;EACA,gCAAA;EACA,yBAAA;EACA,WAAA;AALF;AAQA;EACE,YAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;AANF;AASA;EACE,gBAAA;EACA,cAAA;AAPF;AAUA;EACE,iBAAA;EACA,cAAA;EACA,uBAAA;EACA,eAAA;AARF;AAWA;EAEE,cAAA;EACA,mBAAA;EACA,gBAAA;AATF","sourcesContent":["@import '../../../shared/variables.less';\n\n.flexIt {\n  display: flex;\n  align-items: center;\n}\n\n.flexRowCenter {\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n}\n.cartItem {\n  composes: flexIt;\n  position: relative;\n  overflow: hidden;\n  justify-content: space-between;\n  height: 82px;\n}\n\n.price {\n  color: #ff3233;\n  margin-right: 10px;\n}\n\n.productDescription {\n  composes: flexIt;\n  flex-direction: column;\n  align-items: flex-start;\n  font-size: 12px;\n}\n\n.cartItemLink {\n  composes: flexIt;\n  justify-content: space-between;\n  padding: 5px 0;\n  overflow: hidden;\n  text-decoration: none !important;\n  color: @eon-text-color-light !important;\n  width: 100%;\n}\n\n.cartItemImg {\n  height: 36px;\n  width: 36px;\n  float: left;\n  margin-right: 10px;\n}\n\n.cartItemStrong {\n  overflow: hidden;\n  display: block;\n}\n\n.cartItemRemove {\n  text-align: right;\n  color: @eon-border-color;\n  background: transparent;\n  cursor: pointer;\n}\n\n.title {\n  composes: flexIt;\n  max-width: 70%;\n  white-space: nowrap;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexIt": `styles__flexIt___XDo2_`,
	"flexRowCenter": `styles__flexRowCenter___v_kpc`,
	"cartItem": `styles__cartItem___gbGuc styles__flexIt___XDo2_`,
	"price": `styles__price___oY8DV`,
	"productDescription": `styles__productDescription___fLWCo styles__flexIt___XDo2_`,
	"cartItemLink": `styles__cartItemLink___wL8Wm styles__flexIt___XDo2_`,
	"cartItemImg": `styles__cartItemImg____M8xx`,
	"cartItemStrong": `styles__cartItemStrong___tZqLe`,
	"cartItemRemove": `styles__cartItemRemove___g_aDQ`,
	"title": `styles__title___FRNIt styles__flexIt___XDo2_`
};
export default ___CSS_LOADER_EXPORT___;
