// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__trigger___DWdBt {
  text-decoration: underline dashed;
  cursor: default;
  margin-top: 5px;
}
.styles__red___xz6rB {
  color: #d9534f;
}
.styles__green___VylCF {
  color: #1ec188;
}
.styles__orange___vrl1L {
  color: #f0ad4e;
}
.styles__table___UYlmb:first-child {
  margin-bottom: 25px;
}
.styles__table___UYlmb:not(:first-child) {
  margin-bottom: 0 !important;
}
.styles__table___UYlmb thead td {
  color: #7F7F7F;
}
.styles__table___UYlmb td {
  padding-left: 0 !important;
}
.styles__bold___JWgD7 {
  font-weight: bold;
}
.styles__rightAligned___feIMd {
  text-align: right;
}
.styles__default___x0dqD {
  color: #7F7F7F;
}
.styles__deliveryInfo___V19cJ {
  font-style: italic;
  display: flex;
  align-items: center;
}
.styles__marginBottom___WuBL5 {
  margin-bottom: 20px;
}
.styles__icon___w0h3w {
  margin-right: 10px;
}
.styles__piecesLabel___ycJAE {
  margin-left: 4px;
}
.styles__popover___S9aQy {
  min-width: 350px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/ExactDeliveryTimePopup/styles.less"],"names":[],"mappings":"AAEA;EACE,iCAAA;EACA,eAAA;EACA,eAAA;AADF;AAIA;EACE,cAAA;AAFF;AAKA;EACE,cAAA;AAHF;AAMA;EACE,cAAA;AAJF;AASE;EACE,mBAAA;AAPJ;AAUE;EACE,2BAAA;AARJ;AACA;EAYM,cAAA;AAVN;AAFA;EAiBI,0BAAA;AAZJ;AAgBA;EACE,iBAAA;AAdF;AAiBA;EACE,iBAAA;AAfF;AAkBA;EACE,cAAA;AAhBF;AAmBA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;AAjBF;AAoBA;EACE,mBAAA;AAlBF;AAqBA;EACE,kBAAA;AAnBF;AAsBA;EACE,gBAAA;AApBF;AAuBA;EACE,gBAAA;AArBF","sourcesContent":["@import '../../../shared/variables.less';\n\n.trigger {\n  text-decoration: underline dashed;\n  cursor: default;\n  margin-top: 5px;\n}\n\n.red {\n  color: @danger;\n}\n\n.green {\n  color: @success;\n}\n\n.orange {\n  color: @warning;\n}\n\n.table {\n\n  &:first-child {\n    margin-bottom: 25px;\n  }\n\n  &:not(:first-child) {\n    margin-bottom: 0 !important;\n  }\n\n  thead {\n    td {\n      color: @eon-text-color-muted;\n    }\n  }\n\n  td {\n    padding-left: 0 !important;\n  }\n}\n\n.bold {\n  font-weight: bold;\n}\n\n.rightAligned {\n  text-align: right;\n}\n\n.default {\n  color: @eon-text-color-muted;\n}\n\n.deliveryInfo {\n  font-style: italic;\n  display: flex;\n  align-items: center;\n}\n\n.marginBottom {\n  margin-bottom: 20px;\n}\n\n.icon {\n  margin-right: 10px;\n}\n\n.piecesLabel {\n  margin-left: 4px;  \n}\n\n.popover {\n  min-width: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trigger": `styles__trigger___DWdBt`,
	"red": `styles__red___xz6rB`,
	"green": `styles__green___VylCF`,
	"orange": `styles__orange___vrl1L`,
	"table": `styles__table___UYlmb`,
	"bold": `styles__bold___JWgD7`,
	"rightAligned": `styles__rightAligned___feIMd`,
	"default": `styles__default___x0dqD`,
	"deliveryInfo": `styles__deliveryInfo___V19cJ`,
	"marginBottom": `styles__marginBottom___WuBL5`,
	"icon": `styles__icon___w0h3w`,
	"piecesLabel": `styles__piecesLabel___ycJAE`,
	"popover": `styles__popover___S9aQy`
};
export default ___CSS_LOADER_EXPORT___;
