import React from 'react'
import { connect } from 'react-redux'
import { FormattedNumber } from 'react-intl'
import { T } from 'components/components/i18n'
import styles from './styles.less'
import { bindActionCreators } from 'redux'
import { addAutoProduct } from '../../../../actions/cart'
import { Row, Col, Button } from 'react-bootstrap'
import classnames from 'classnames'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const UnselectedAutoproductItem = ({
  item,
  addAutoProduct,
  inProgress,
  includeVat,
  currency,
}) => {
  const imageSrc = item &&
    item.image ? item.image.webshop_thumb : '/images/default-product-image.png'

  return (
    <Row className={classnames(styles.productRow, 'unselected-autoproduct-item')}>
      <Col md={7} className={styles.productCol}>
        <span className={styles.cartItemLink}>
          <div className={styles.flexIt}>
            <img src={imageSrc} alt='' className={styles.cartItemImg} />
            <div className={classnames(styles.productDescription, 'unselected-autoproduct-item-description')}>
              <div className={classnames(styles.productTitle, 'text-colored', 'unselected-autoproduct-item-title')}>{item.name}</div>
              <span className={classnames({
                [styles.autoProductSuffix] : item.autoProductId,
              }, 'unselected-autoproduct-item-summary')}>
                <T id="- autoproduct" />
              </span>
            </div>
          </div>
        </span>
      </Col>
      <Col md={2} className={styles.productCol}>
        <span className={styles.count}>{item.count}</span>
        &nbsp;
        <T id="pc"/>
        &nbsp;
        <FormattedNumber
          minimumFractionDigits={0}
          maximumFractionDigits={0}
          value={includeVat ? item.formattedPriceInclVat : item.formattedPrice}
          style={`currency`}
          currency={currency}
        />
      </Col>
      <Col md={2} sm={12} className={classnames(styles.priceCol, 'background-light', 'checkout-item-total-price')}>
        <T id="Sum"/>
        <span className={styles.price}>
          <FormattedNumber
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            value={includeVat ? item.formattedTotalInclVat : item.formattedTotal}
            style={`currency`}
            currency={currency}
          />
        </span>
      </Col>
      <Col md={1} className={classnames(styles.productCol, 'background-light')}>
        {inProgress && <FontAwesomeIcon icon={faSpinner} spin />}
        {!inProgress && (
          <Button onClick={() => addAutoProduct(item)}>
            <T id="Add item"/>
          </Button>
        )}
      </Col>
    </Row>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  addAutoProduct: (item) => addAutoProduct(item),
}, dispatch)
const mapStateToProps = (state, props) => ({
  inProgress: state.cart.updateInProgress[props.item.id],
  includeVat: state.price.includeVat,
  currency: state.intl.currency,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnselectedAutoproductItem)
