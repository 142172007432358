// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__checkbox___LunVy {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}
.styles__label___TS4LY {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Checkbox/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AACF;AAEA;EACE,iBAAA;AAAF","sourcesContent":[".checkbox {\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n  cursor: pointer;\n}\n\n.label {\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `styles__checkbox___LunVy`,
	"label": `styles__label___TS4LY`
};
export default ___CSS_LOADER_EXPORT___;
