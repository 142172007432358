// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__input___uLLV8 {
  width: 250px;
}
.styles__link___uUOuB {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.styles__carInfoIcon___Crzq4 {
  margin-left: 3px;
}
.styles__flexIt___XNvTe {
  display: flex;
  align-items: center;
}
.styles__popoverCarInfo___w5cND {
  min-width: 360px !important;
  max-width: 720px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/VehicleSearch/styles.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAEA;EACE,aAAA;EACA,eAAA;EACA,mBAAA;AAAF;AAGA;EACE,gBAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;AAFF;AAKA;EACE,2BAAA;EACA,2BAAA;AAHF","sourcesContent":[".input {\n  width: 250px;\n}\n\n.link {\n  display: flex;\n  cursor: pointer;\n  align-items: center;\n}\n\n.carInfoIcon {\n  margin-left: 3px;\n}\n\n.flexIt {\n  display: flex;\n  align-items: center;\n}\n\n.popoverCarInfo {\n  min-width: 360px !important;\n  max-width: 720px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `styles__input___uLLV8`,
	"link": `styles__link___uUOuB`,
	"carInfoIcon": `styles__carInfoIcon___Crzq4`,
	"flexIt": `styles__flexIt___XNvTe`,
	"popoverCarInfo": `styles__popoverCarInfo___w5cND`
};
export default ___CSS_LOADER_EXPORT___;
