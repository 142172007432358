// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__numeric___CP9PZ {
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchResults/ProductsListCompact/styles.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF","sourcesContent":[".numeric {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numeric": `styles__numeric___CP9PZ`
};
export default ___CSS_LOADER_EXPORT___;
