import React from 'react'
import Select, { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faCaretDown}/>
      </components.DropdownIndicator>
    )
  )
}

const customStyles = {
  control: (base) => ({ ...base, borderRadius: 0, border: '1px solid #ccc', minHeight: '34px' }),
  indicatorSeparator: () => ({ display: 'none' })
}

const Selectbox = (props) => {
  const {
    value,
    options,
    onChange,
    name,
    placeholder,
    ...rest
  } = props
  return  (
    <Select
      onChange={onChange}
      options={options}
      value={value}
      styles={customStyles}
      components={{ DropdownIndicator }}
      placeholder={placeholder}
      {...rest}
    />
  )
}

export default Selectbox
