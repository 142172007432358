import React from 'react'
import { bindActionCreators } from 'redux'
import { FormControl, Button, FormGroup, ControlLabel, Row, Col, Alert } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { setPassword, checkTokenValidity, setParameter, resetForgotPassword } from '../../../../actions/forgotPassword'
import { keyCodes } from '../../../../constants/keyCodes'
import styles from './styles.less'
import qs from 'query-string'

class ForgotPasswordReturn extends React.Component {
  constructor(props) {
    super(props)
    this.handleButtonClick = this.onButtonClick.bind(this)
    this.handleBlur = this.onBlur.bind(this)
  }

  componentDidMount() {
    const { location, shop, checkTokenValidity } = this.props
    const params = qs.parse(location.search)
    checkTokenValidity(params.t, shop)
  }

  componentWillUnmount() {
    this.props.resetForgotPassword()
  }

  onKeyPress(event) {
    if (event.which === keyCodes.ENTER)
      this.onButtonClick()
  }

  onButtonClick() {
    const { password, token, shop, setPassword, confirmedPassword } = this.props
    setPassword({ password, token, confirmedPassword }, shop)
  }

  onBlur(event) {
    this.props.setParameter(event.target.name, event.target.value)
  }

  render() {
    const { tokenError, error, password, isPasswordChanged, confirmedPassword } = this.props
    let component
    if (tokenError)
      component = <Alert bsStyle="danger">{tokenError}</Alert>
    else if (isPasswordChanged)
      component = (
        <h4>
          <T id="Password is changed successfully. You can now"/>
          <Link to="/login"><T id="Login"/></Link>.
        </h4>
      )
    else {
      const errorMessage = error ? <Alert bsStyle="danger" className={styles.errorMessage}>{error}</Alert> : null
      component = (
        <Row>
          <Col xs={12} sm={4} smOffset={4}>
            <FormGroup>
              <ControlLabel>
                <T id="Password"/>
              </ControlLabel>
              <FormControl onBlur={this.handleBlur} defaultValue={password} name="password" type="password"/>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} smOffset={4}>
            <FormGroup>
              <ControlLabel>
                <T id="Repeat password"/>
              </ControlLabel>
              <FormControl onBlur={this.handleBlur} defaultValue={confirmedPassword} name="confirmedPassword" type="password"/>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} smOffset={4} className={styles.actions}>
            <Button
              className={styles.button}
              onClick={this.handleButtonClick}><T id="Submit"/></Button>
            <Link to="/login"><T id="Login"/></Link>
          </Col>
          <Col xs={12} sm={4} smOffset={4}>
            {errorMessage}
          </Col>
        </Row>
      )
    }
    return (
      <div className={`${styles.container} forgot-password-return`} onKeyPress={this.handleKeyPress}>
        {component}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    shop: state.shop,
    token: state.forgotPassword.token,
    tokenError: state.forgotPassword.tokenError,
    password: state.forgotPassword.password,
    error: state.forgotPassword.error,
    email: state.forgotPassword.email,
    isPasswordChanged: state.forgotPassword.isPasswordChanged,
    confirmedPassword: state.forgotPassword.confirmedPassword
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  checkTokenValidity: (token, shop) => checkTokenValidity(token, shop),
  setPassword: (params, shop) => setPassword(params, shop),
  setParameter: (name, value) => setParameter(name, value),
  resetForgotPassword: () => resetForgotPassword()
}, dispatch)
 
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordReturn)
