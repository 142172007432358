import React from 'react'
import { RIM_MATERIAL_TYPES } from '../../../../../constants/rimMaterialTypes'
import RadioButton from '../../../RadioButton'
import { FormGroup, ControlLabel } from 'react-bootstrap'
import { T } from 'components/components/i18n'

const RimType = (props) => {
  const onChange  = (name, value) => {
    props.onChange(name, value)
  }
  const rimMaterialOptions = RIM_MATERIAL_TYPES.map(option => (
    <RadioButton
      name="rimType"
      value={option.value}
      key={option.value}
      isChecked={props.selectedType === option.value}
      onChange={onChange}
      label={option.label}
    />)
  )
  return (
    <FormGroup className="rim-type">
      <ControlLabel>
        <T id="Type"/>
      </ControlLabel>
      {rimMaterialOptions}
    </FormGroup>
  )
}

export default RimType