import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import brands from './brands'
import carPicker from './carPicker'
import cart from './cart'
import categories from './categories'
import menu from './menu'
import order from './order'
import orders from './orders'
import price from './price'
import search from './search'
import shop from './shop'
import user from './user'
import wheelBuilder from './wheelBuilder'
import tyreSearchParams from './tyreSearchParams'
import rimSearchParams from './rimSearchParams'
import completeWheelSearchParams from './completeWheelSearchParams'
import diameters from './diameters'
import forgotPassword from './forgotPassword'
import signUp from './signUp'
import profitMargins from './profitMargins'
import intl from './intl'
import vehicle from './vehicle'
import preOrder from './preOrder'

export default combineReducers({
  routing: routerReducer,
  brands,
  carPicker,
  cart,
  categories,
  completeWheelSearchParams,
  diameters,
  menu,
  order,
  orders,
  price,
  profitMargins,
  rimSearchParams,
  search,
  shop,
  tyreSearchParams,
  user,
  vehicle,
  wheelBuilder,
  forgotPassword,
  signUp,
  intl,
  preOrder,
})
