// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__buttonText___XiXpP {
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles__buttonText___XiXpP svg {
  margin-right: 8px;
}
.styles__button___NCtG_ {
  position: relative;
}
.styles__errorMessage___HIet5 {
  color: red;
  padding-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchButton/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAJA;EAKG,iBAAA;AAEH;AACA;EACE,kBAAA;AACF;AACA;EACE,UAAA;EACA,kBAAA;AACF","sourcesContent":[".buttonText {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  svg {\n  \tmargin-right: 8px;\n  }\n}\n.button {\n  position: relative;\n}\n.errorMessage {\n  color: red;\n  padding-left: 10px;\n}\n// .popoverSearchInfo {\n//   min-width: 360px !important;\n//   max-width: 720px !important;\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonText": `styles__buttonText___XiXpP`,
	"button": `styles__button___NCtG_`,
	"errorMessage": `styles__errorMessage___HIet5`
};
export default ___CSS_LOADER_EXPORT___;
