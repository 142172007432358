// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__flexIt___xMM7X {
  display: flex;
  align-items: center;
  margin-bottom: 6px !important;
}
.styles__subTypesLabel___O0C5M {
  margin-bottom: 2px !important;
  margin-right: 6px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/TyreSearch/TyreType/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;AACF;AAEA;EACE,6BAAA;EACA,4BAAA;AAAF","sourcesContent":[".flexIt {\n  display: flex;\n  align-items: center;\n  margin-bottom: 6px !important;\n}\n\n.subTypesLabel {\n  margin-bottom: 2px !important;\n  margin-right: 6px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexIt": `styles__flexIt___xMM7X`,
	"subTypesLabel": `styles__subTypesLabel___O0C5M`
};
export default ___CSS_LOADER_EXPORT___;
