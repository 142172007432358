import React from 'react'
import { bindActionCreators } from 'redux'
import { FormControl, Button, FormGroup, ControlLabel, Row, Col, Alert } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { login } from '../../../actions/user'
import styles from './styles.less'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: ''
    }
    this.handleButtonClick = this.onButtonClick.bind(this)
    this.handleInputChange = this.onInputChange.bind(this)
  }

  onButtonClick(event) {
    console.log('event:', event)
    event.preventDefault()
    const { username, password } = this.state
    const { login, shop, location } = this.props
    const path = location && location.state ? location.state.from : '/'
    login({ email: username, password }, shop, path)
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    const { username, password } = this.state
    const { shop, error, isLoggedIn, location } = this.props
    const errorMessage = error ? <Alert bsStyle="danger" className={styles.marginTop}>{error}</Alert> : null

    if (isLoggedIn) {
      const path = location.state ? location.state.from : '/'
      return <Redirect to={path}/>
    }
    let signUpLink
    if (shop.signup.enabled)
      signUpLink = <Link to="/signup"><T id="Sign up"/></Link>

    return (
      <div className={`${styles.container} login`}>
        <form method="post" action="/login" autoComplete="on" onSubmit={this.handleButtonClick}>
          <Row>
            <Col xs={12} sm={4} smOffset={4}>
              <h1><T id="Log in"/></h1>
              <FormGroup>
                <ControlLabel htmlFor="username"><T id="Email"/></ControlLabel>
                <FormControl onChange={this.handleInputChange} value={username} name="username" id="username" autoComplete="username"/>
              </FormGroup>
              <FormGroup>
                <ControlLabel htmlFor="password"><T id="Password"/></ControlLabel>
                <FormControl onChange={this.handleInputChange} value={password} name="password" type="password" autoComplete="password" id="password" />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={4} smOffset={4} className={`${styles.footer} login_footer`}>
              <Button
                type="submit"
                className={styles.button}>
                <T id="Log in"/>
              </Button>
              <Link to="/forgot-password">
                <T id="Forgot password?"/>
              </Link>
            </Col>
          </Row>
          <Row className={styles.marginTop}>
            <Col xs={12} sm={4} smOffset={4}>
              {signUpLink}
            </Col>
            <Col xs={12} sm={4} smOffset={4}>
              {errorMessage}
            </Col>
          </Row>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  let error = state.user.error
  if (state.user.domainUrl) {
    let errorParts = []
    error.split("%domain%").forEach(part => {
      if (part !== "")
        errorParts.push(<span>{part} <a href={state.user.domainUrl}>{state.user.domainTitle}</a></span>)
    })
    error = <React.Fragment>{errorParts}</React.Fragment>
  }

  return {
    shop: state.shop,
    error,
    isLoggedIn: state.user.isLoggedIn,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  login: (params, shop, path) => login(params, shop, path)
}, dispatch)
 
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
