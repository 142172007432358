// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__inline___LfDpc {
  margin-bottom: 0px !important;
}
.styles__testResults___jNr6y {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.styles__flexIt___apH4O {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 991px) {
  .styles__flexIt___apH4O {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/CompleteWheelSearch/CompleteWheelSearchAdvanced/styles.less"],"names":[],"mappings":"AAEA;EACE,6BAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAFF;AAKA;EACE,aAAA;EACA,uBAAA;AAHF;AAIE;EAAA;IACE,sBAAA;EADF;AACF","sourcesContent":["@import '../../../../../shared/variables.less';\n\n.inline {\n  margin-bottom: 0px !important;\n}\n\n.testResults {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.flexIt {\n  display: flex;\n  align-items: flex-start;\n  @media (max-width: @screen-sm-max) {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inline": `styles__inline___LfDpc`,
	"testResults": `styles__testResults___jNr6y`,
	"flexIt": `styles__flexIt___apH4O`
};
export default ___CSS_LOADER_EXPORT___;
