// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__container___V7q7_ {
  max-width: 1140px !important;
  margin-top: 20px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(19, 39, 48, 0.12);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}
.styles__container___V7q7_ section {
  margin-bottom: 45px;
}
.styles__orderHeader___day4Y {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.styles__orderInfo___xfd4e {
  padding: 8px 0 0 8px;
}
.styles__orderRow___ulgBj {
  padding-bottom: 5px;
}
.styles__downloadError___hEnzX {
  padding-left: 1em;
  color: red;
}
.styles__delivered___DokfR {
  display: flex;
  align-items: center;
}
.styles__delivered___DokfR .checkbox-input {
  margin-bottom: 0px;
}
.styles__undo___YYFpm {
  margin-left: 1em;
}
.styles__download___O8xyN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.styles__download___O8xyN svg {
  margin-left: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./src/shared/layout.less","webpack://./src/components/components/Order/styles.less"],"names":[],"mappings":"AAEA;EACE,4BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,8CAAA;EACA,kDAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;ACDF;ADTA;EAYI,mBAAA;ACAJ;AAZA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAcF;AAXA;EACE,oBAAA;AAaF;AAVA;EACE,mBAAA;AAYF;AATA;EACE,iBAAA;EACA,UAAA;AAWF;AARA;EACE,aAAA;EACA,mBAAA;AAUF;AAZA;EAKI,kBAAA;AAUJ;AANA;EACE,gBAAA;AAQF;AALA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AAOF;AAXA;EAOI,kBAAA;AAOJ","sourcesContent":["@import './variables.less';\n\n.container {\n  max-width: 1140px !important;\n  margin-top: 20px;\n  margin-bottom: 15px;\n  border-radius: 3px;\n  background-color: #fff;\n  box-shadow: @eon-box-shadow;\n  transition: @eon-transition-default;\n  border-radius: 4px;\n  margin-left: auto;\n  margin-right: auto;\n  section {\n    margin-bottom: 45px;\n  }\n}\n","@import '../../../shared/layout.less';\n\n.orderHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.orderInfo {\n  padding: 8px 0 0 8px;\n}\n\n.orderRow {\n  padding-bottom: 5px;\n}\n\n.downloadError {\n  padding-left: 1em;\n  color: red;\n}\n\n.delivered {\n  display: flex;\n  align-items: center;\n\n  :global(.checkbox-input) {\n    margin-bottom: 0px;\n  }\n}\n\n.undo {\n  margin-left: 1em;\n}\n\n.download {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n\n  svg {\n    margin-left: 0.5em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container___V7q7_`,
	"orderHeader": `styles__orderHeader___day4Y`,
	"orderInfo": `styles__orderInfo___xfd4e`,
	"orderRow": `styles__orderRow___ulgBj`,
	"downloadError": `styles__downloadError___hEnzX`,
	"delivered": `styles__delivered___DokfR`,
	"undo": `styles__undo___YYFpm`,
	"download": `styles__download___O8xyN`
};
export default ___CSS_LOADER_EXPORT___;
