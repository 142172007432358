import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import styles from './styles.less'

export const Pagination = (props) => {
  const onPageClick = (event, pageConfig) => {
    event.preventDefault()
    event.stopPropagation()
    if (pageConfig.disabled)
      return
    
    props.onPageClick(pageConfig.page)
  }

  const pages = props.pagination.map(page => {
    const style = page.active && props.shop.style ? { backgroundColor: props.shop.style.color1, borderColor: props.shop.style.color1 } : {}
    const linkStyle = page.active && props.shop.style ? { color: '#fff' } : {}
    return (
      <li className={styles.page} key={page.text} style={style}>
        <Link to='' style={linkStyle} onClick={(event) => onPageClick(event, page)}>{page.text}</Link>
      </li>
    )
  })

  return (
    <Row className="pagination-component">
      <Col smOffset={4} sm={4} xs={12}>
        <ul className={`list-unstyled ${styles.list}`}>
          {pages}
        </ul>
      </Col>
    </Row>
  )
}

export default Pagination

