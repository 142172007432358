import React from 'react'
import { ORDER_TYPE } from 'constants/orderTypes'
import {
  Page,
  Text,
  View,
  Document,
  Svg,
  Path,
  G,
  Polygon,
  StyleSheet
} from '@react-pdf/renderer'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 10
  },
  section: {
    marginBottom: 10,
    fontSize: 9
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    fontSize: 11,
    paddingRight: 6
  },
  label: {
    fontSize: 9,
    fontWeight: 'bold'
  },
  labelRowStyle: {
    flexDirection: 'row',
    marginBottom: 3,
  },
  labelHeader: {
    width: '100px',
  },
  tableRowStyle: {
    flexDirection: 'row',
    fontSize: 9,
  },
  firstTableColHeaderStyle: {
    width: '13%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderBottomColor: '#000',
    borderWidth: 1,
    backgroundColor: '#bdbdbd'
  },
  tableColHeaderStyle: {
    width: '10%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: '#bdbdbd'
  },
  tableColHeaderStyleFlexGrow: {
    flexGrow: 1,
    flex: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: '#bdbdbd'
  },
  tableColHeaderStyleBig: {
    width: '30%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: '#bdbdbd'
  },
  tableColHeaderStyleSmall: {
    width: '7%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    backgroundColor: '#bdbdbd'
  },
  tableCellHeaderStyle: {
    textAlign: 'left',
    margin: 4,
    fontSize: 9,
    fontWeight: 'bold'
  },
  tableCellHeaderStyleRight: {
    textAlign: 'right',
    margin: 4,
    fontSize: 9,
    fontWeight: 'bold'
  },
  tableColStyleFlexGrow: {
    flexGrow: 1,
    flex: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  firstTableColStyle: {
    width: '13%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderTopWidth: 0
  },
  tableColStyle: {
    width: '10%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColStyleSmall: {
    width: '7%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColStyleBig: {
    width: '30%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellStyle: {
    textAlign: 'left',
    margin: 5,
    fontSize: 9,
    flexWrap: 'wrap',
  },
  tableCellStyleRight: {
    textAlign: 'right',
    margin: 5,
    fontSize: 9
  },
  firstSummaryColStyle: {
    width: '90%',
  },
  summaryColStyle: {
    width: '10%',
  },
  totalColStyle: {
    width: '10%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0
  },
  svg: {
    fill: '#000',
  },
  euGrading: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5,
  },
  euGradingItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 4,
  },
  euGradingText: {
    marginLeft: 2,
  }
})

const FuelGradeSvg = (props) => {
  return (
    <Svg width="11" height="11" viewBox="0 0 17 18">
      <G id="eontyre-design-system" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <G id="Icons" transform="translate(-469.000000, -118.000000)">
          <G id="ic_local_gas_station" transform="translate(465.000000, 115.000000)">
            <Polygon id="Bounds" points="0 0 24 0 24 24 0 24"></Polygon>
            <Path d="M19.77,7.23 L19.78,7.22 L16.06,3.5 L15,4.56 L17.11,6.67 C16.17,7.03 15.5,7.93 15.5,9 C15.5,10.38 16.62,11.5 18,11.5 C18.36,11.5 18.69,11.42 19,11.29 L19,18.5 C19,19.05 18.55,19.5 18,19.5 C17.45,19.5 17,19.05 17,18.5 L17,14 C17,12.9 16.1,12 15,12 L14,12 L14,5 C14,3.9 13.1,3 12,3 L6,3 C4.9,3 4,3.9 4,5 L4,21 L14,21 L14,13.5 L15.5,13.5 L15.5,18.5 C15.5,19.88 16.62,21 18,21 C19.38,21 20.5,19.88 20.5,18.5 L20.5,9 C20.5,8.31 20.22,7.68 19.77,7.23 L19.77,7.23 Z M12,10 L6,10 L6,5 L12,5 L12,10 L12,10 Z M18,10 C17.45,10 17,9.55 17,9 C17,8.45 17.45,8 18,8 C18.55,8 19,8.45 19,9 C19,9.55 18.55,10 18,10 L18,10 Z" id="Icon" style={styles.svg}></Path>
          </G>
        </G>
      </G>
    </Svg>
  )
}

const NoiseGradeSvg = (props) => {
  return (
    <Svg width="11" height="11" viewBox="0 0 18 18" >
      <G id="eontyre-design-system" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <G id="Icons" transform="translate(-589.000000, -118.000000)">
          <G id="ic_volume_up" transform="translate(586.000000, 115.000000)">
            <Polygon id="Bounds" points="0 0 24 0 24 24 0 24"></Polygon>
            <Path d="M3,9 L3,15 L7,15 L12,20 L12,4 L7,9 L3,9 L3,9 Z M16.5,12 C16.5,10.23 15.48,8.71 14,7.97 L14,16.02 C15.48,15.29 16.5,13.77 16.5,12 L16.5,12 Z M14,3.23 L14,5.29 C16.89,6.15 19,8.83 19,12 C19,15.17 16.89,17.85 14,18.71 L14,20.77 C18.01,19.86 21,16.28 21,12 C21,7.72 18.01,4.14 14,3.23 L14,3.23 Z" id="Icon" style={styles.svg}></Path>
          </G>
        </G>
      </G>
    </Svg>
  )
}

const GripGradeSvg = (props) => {
  return (
    <Svg width="11" height="11" viewBox="0 0 24 24" >
      <G id="eontyre-design-system" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <G id="Icons" transform="translate(-521.000000, -115.000000)" fill-rule="nonzero" style={styles.svg}>
          <G id="ic_clouds" transform="translate(521.000000, 115.000000)">
            <Path d="M21.6538776,7.20066972 C21.7689796,6.73908833 21.84,6.25754666 21.84,5.75853975 C21.837551,2.57737069 19.3102041,0 16.1877551,0 C13.6457143,0 11.4955102,1.7115937 10.7853061,4.06441129 C10.1167347,3.45312782 9.24489796,3.07138753 8.27510204,3.07138753 C6.3722449,3.07138753 4.8122449,4.51601254 4.56,6.38728846 C1.99591837,6.67421743 0,8.9322237 0,11.6218709 C0,14.5036359 2.2922449,16.8888889 5.12081633,16.8888889 C7.95183673,16.8888889 16.0457143,16.8888889 18.8742857,16.8888889 C21.7077551,16.8888889 24,14.5036359 24,11.6218709 C24,9.78303048 23.0620408,8.13880271 21.6538776,7.20066972 Z" id="Shape"></Path>
            <Path d="M10.6666667,22.5193211 C10.6666667,23.3379078 11.2650407,24 12,24 C12.7371274,24 13.3333333,23.3355002 13.3333333,22.5193211 C13.3333333,21.0386421 12,19.5555556 12,19.5555556 C12,19.5555556 10.6666667,21.0386421 10.6666667,22.5193211 Z" id="Shape"></Path>
            <Path d="M16,19.8526544 C16,20.6712411 16.598374,21.3333333 17.3333333,21.3333333 C18.0704607,21.3333333 18.6666667,20.6688335 18.6666667,19.8526544 C18.6666667,18.3719754 17.3333333,16.8888889 17.3333333,16.8888889 C17.3333333,16.8888889 16,18.3719754 16,19.8526544 Z" id="Shape"></Path>
            <Path d="M5.33333333,19.8526544 C5.33333333,20.6712411 5.93170732,21.3333333 6.66666667,21.3333333 C7.40379404,21.3333333 8,20.6688335 8,19.8526544 C8,18.3719754 6.66666667,16.8888889 6.66666667,16.8888889 C6.66666667,16.8888889 5.33333333,18.3719754 5.33333333,19.8526544 Z" id="Shape"></Path>
          </G>
        </G>
      </G>
    </Svg>
  )
}

const SnowGripSvg = (props) => {
  return (
    <Svg viewBox="0 0 393.98 358.65" height="11" width="11" style={styles.svg}>
    <Path stroke="#1D1D1B" stroke-miterlimit={10} d="M19.48,345.11h353.14L245.58,80.55l-11.66,32.05l-47.78-99.06l-81.58,199.3c0,0-22-32.2-23.75-31.32
      C79.06,182.38,19.48,345.11,19.48,345.11z" />
    <G>
      <G>
        <G>
          <rect x="204.24" y="247.28" fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  width="5.42" height="77.19"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="186.63,317.61 182.8,313.87 205.03,292.19 208.86,295.93 			"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="227.25,317.61 205.03,295.93 208.86,292.19 231.08,313.87 			"/>
        </G>
      </G>
      <G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="274.14,288.17 205.59,249.56 208.3,244.99 276.85,283.58 			"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="254,300.96 245.87,271.36 251.11,269.99 259.25,299.59 			"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="249.19,273.22 247.79,268.11 278.15,260.18 279.55,265.29 			"/>
        </G>
      </G>
      <G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="208.3,249.56 205.59,244.99 274.14,206.39 276.85,210.97 			"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="278.15,234.38 247.79,226.44 249.19,221.33 279.55,229.26 			"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="251.11,224.57 245.87,223.21 254,193.59 259.25,194.96 			"/>
        </G>
      </G>
      <G>
        <G>
          <rect x="204.24" y="170.09" fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  width="5.42" height="77.19"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="208.86,202.36 205.03,198.62 227.25,176.95 231.08,180.69 			"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="205.03,202.36 182.8,180.69 186.63,176.95 208.86,198.62 			"/>
        </G>
      </G>
      <G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="205.59,249.56 137.05,210.97 139.76,206.39 208.3,244.99 			"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="162.78,224.57 154.65,194.96 159.88,193.59 168.01,223.21 			"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="135.75,234.38 134.33,229.26 164.69,221.33 166.1,226.44 			"/>
        </G>
      </G>
      <G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="139.76,288.17 137.05,283.58 205.59,244.99 208.3,249.56 			"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="164.69,273.22 134.33,265.29 135.75,260.18 166.1,268.11 			"/>
        </G>
        <G>
          <Polygon fill="#FFFFFF" stroke="#FFFFFF" stroke-width={10} stroke-miterlimit={10}  points="159.88,300.96 154.65,299.59 162.78,269.99 168.01,271.36 			"/>
        </G>
      </G>
    </G>
    </Svg>

  )
}

const IceGripSvg = () => {
  return (
    <Svg height="11" width="11" viewBox="0 0 393.98 358.65" style={styles.svg}>
    <Polygon className="st0" points="22.12,353.62 370.21,353.62 196.17,10.09 " fill="#ffffff" stroke="#000000" stroke-width="8" stroke-miterlimit="10" />
    <Path d="M138.66,174.13c0,0-80.33,160.66-77.48,158.38s270.04,2.33,270.04,0.02s-90.01-184.61-90.01-184.61l-5.7,64.95l-13.1-16.52
      l-10.82,35.32l-6.84-16.52l-14.24,82.61l-36.46-105.4l-26.78,26.21L138.66,174.13z"/>
    </Svg>
  )
}

const OrderInfo = (props) => {
  const { order, config } = props
  const { messages } = config.intl

  return (
    <View style={styles.section}>
      <View style={styles.labelRowStyle}>
        <View style={styles.labelHeader}>
        </View>
      </View>

      <View style={styles.labelRowStyle}>
        <View style={styles.labelHeader}>
          <Text style={styles.label}>{messages['Status']}</Text>
        </View>
        <View style={styles.labelValue}>
          <Text>{order.formattedStatus}</Text>
        </View>
      </View>

      {!!order.car_licenseplate && (
        <View style={styles.labelRowStyle}>
          <View style={styles.labelHeader}>
            <Text style={styles.label}>{messages['License plate number']}</Text>
          </View>
          <View style={styles.labelValue}>
            <Text>{order.car_licenseplate}</Text>
          </View>
        </View>
      )}

      <View style={styles.labelRowStyle}>
        <View style={styles.labelHeader}>
          <Text style={styles.label}>{messages['Reference']}</Text>
        </View>
        <View style={styles.labelValue}>
          <Text>{order.customer_reference || 'nbsp;'}</Text>
        </View>
      </View>

      {!!order.booking_start_at && (
        <View style={styles.labelRowStyle}>
          <View style={styles.labelHeader}>
            <Text style={styles.label}>{messages['Booking']}</Text>
          </View>
          <View style={styles.labelValue}>
            <Text>{order.booking_start_at}</Text>
          </View>
        </View>
      )}

      <View style={styles.labelRowStyle}>
        <View style={styles.labelHeader}>
          <Text style={styles.label}>{messages['Created at']}</Text>
        </View>
        <View style={styles.labelValue}>
          <Text>{order.created_at}</Text>
        </View>
      </View>

      {!!order.is_show_invoice && (
        <View style={styles.labelRowStyle}>
          <View style={styles.labelHeader}>
            <Text style={styles.label}>{messages['Invoices']}</Text>
          </View>
          <View style={styles.labelValue}>
            <Text>
              {!order.invoices.length && messages['Not billed yet']}

              {order.invoices.map(invoice => {
                return (
                  <Text>
                    {messages['Invoice number']} {invoice.number}
                  </Text>
                )
              })}
            </Text>
          </View>
        </View>
      )}

      {order.type === ORDER_TYPE.PREORDER && (
        <View style={styles.labelRowStyle}>
          <View style={styles.labelHeader}>
            <Text style={styles.label}>{messages['Type']}</Text>
          </View>
          <View style={styles.labelValue}>
            <Text>{messages['Pre order']}</Text>
          </View>
        </View>
      )}
    </View>
  )
}

const EuGrading = (props) => {
  const {
    rollingResistance,
    wetGrip,
    noiseDecibel,
    noiseRating,
    tyreSnowGrip,
    tyreIceGrip
  } = props.data

  return (
    <View style={styles.euGrading}>
      {!!rollingResistance && (
        <View style={styles.euGradingItem}>
          <FuelGradeSvg />
          <Text style={styles.euGradingText}>{rollingResistance}</Text>
        </View>
      )}
      {!!wetGrip && (
        <View style={styles.euGradingItem}>
          <GripGradeSvg />
          <Text style={styles.euGradingText}>{wetGrip}</Text>
        </View>
      )}
      {!!noiseDecibel && (
        <View style={styles.euGradingItem}>
          <NoiseGradeSvg />
          <Text style={styles.euGradingText}>{noiseRating}</Text>
        </View>
      )}
      {!!tyreSnowGrip && (
        <View style={styles.euGradingItem}>
          <SnowGripSvg />
        </View>
      )}
      {!!tyreIceGrip && (
        <View style={styles.euGradingItem}>
          <IceGripSvg />
        </View>
      )}
    </View>
  )
}

const OrderLine = (props) => {
  const { line, config } = props
  const { formatNumber } = config.intl

  return (
    <View style={styles.tableRowStyle}>

      <View style={styles.firstTableColStyle}>
        <Text style={styles.tableCellStyle}>{line.product_id}</Text>
      </View>

      <View style={styles.tableColStyleBig}>
        <Text style={styles.tableCellStyle}>
          {line.product_name}
        </Text>
        <EuGrading data={{
            rollingResistance: line.rolling_resistance,
            wetGrip: line.wet_grip,
            noiseDecibel: line.noise_emission_decibel,
            noiseRating: line.noise_emission_rating,
            tyreSnowGrip: line.tyre_snow_grip,
            tyreIceGrip: line.tyre_ice_grip,
          }}
        />
      </View>

      {!!config.hasDirectDelivery && (
        <View style={styles.tableColStyle}>
          <Text style={styles.tableCellStyle}>
            {
              line.shipments.map((shipment, index) => (
                <Text>{shipment.tracking_number}{index !== line.shipments.length - 1 && ', '}</Text>
              ))
            }
          </Text>
        </View>
      )}

      {!!config.showSupplier && (
        <View style={styles.tableColStyle}>
          <Text style={styles.tableCellStyle}>
            {line.supplier_name}, {line.supplier_order_id}
          </Text>
        </View>
      )}

      <View style={styles.tableColStyleFlexGrow}>
        <Text style={styles.tableCellStyle}>{line.comment}</Text>
      </View>

      <View style={styles.tableColStyleSmall}>
        <Text style={styles.tableCellStyleRight}>{line.quantity}</Text>
      </View>

      {!!config.hasDiscount && (
        <React.Fragment>
          <View style={styles.tableColStyle}>
            <Text style={styles.tableCellStyleRight}>
              {
                formatNumber(
                  line.unit_price, {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }
                )
              }
              ({
                formatNumber(
                  line.discount_value, {
                    style: line.discount_type === 'amount' ? 'decimal' : 'percent',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }
                )
              })
            </Text>
          </View>
        </React.Fragment>
      )}

      <View style={styles.tableColStyle}>
        <Text style={styles.tableCellStyleRight}>
          {
            formatNumber(
              line.nett_price, {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              }
            )
          }
        </Text>
      </View>

    </View>
  )
}

const OrderHeader = (props) => {
  const { order, config } = props
  const { formatMessage } = config.intl

  return (
    <View style={styles.tableRowStyle}>

      <View style={styles.firstTableColHeaderStyle}>
        <Text style={styles.tableCellHeaderStyle}>{formatMessage({id: 'Product number'})}</Text>
      </View>

      <View style={styles.tableColHeaderStyleBig}>
        <Text style={styles.tableCellHeaderStyle}>{formatMessage({id: 'Benämning'})}</Text>
      </View>

      {!!config.hasDirectDelivery && (
        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>{formatMessage({id: 'Shippings'})}</Text>
        </View>
      )}

      {!!config.showSupplier && (
        <View style={styles.tableColHeaderStyle}>
          <Text style={styles.tableCellHeaderStyle}>{formatMessage({id: 'Supplier'})}</Text>
        </View>
      )}

      <View style={styles.tableColHeaderStyleFlexGrow}>
        <Text style={styles.tableCellHeaderStyle}>{formatMessage({id: 'Note'})}</Text>
      </View>

      <View style={styles.tableColHeaderStyleSmall}>
        <Text style={styles.tableCellHeaderStyleRight}>{formatMessage({id: 'Items'})}</Text>
      </View>

      {!!order.hasDiscount && (
        <React.Fragment>
          <View style={styles.tableColHeaderStyle}>
            <Text style={styles.tableCellHeaderStyleRight}>{formatMessage({id: 'Discount'})}</Text>
          </View>
        </React.Fragment>
      )}

      <View style={styles.tableColHeaderStyle}>
        <Text style={styles.tableCellHeaderStyleRight}>{formatMessage({id: 'Price'})}</Text>
      </View>

    </View>
  )
}

const Pdf = (props) => {
  const { order, config } = props
  const { formatMessage, formatNumber } = config.intl

  return (
    <Document>
      <Page
        size="A4"
        orientation="portrait"
        style={styles.page}
      >
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.header}>{formatMessage({id: 'Order'})} {order.id}</Text>
            <Text style={styles.label}>
              (
                {order.delivered === 1 && (formatMessage({id: 'Delivered'}))}
                {order.delivered === 0 && (formatMessage({id: 'Not delivered'}))}
              )
            </Text>
          </View>
        </View>

        <OrderInfo order={order} config={config} />
        <OrderHeader order={order} config={config} />
        {
          order.lines.map(line => (
            <OrderLine
              key={line.id}
              line={line}
              config={{
                hasDiscount: order.hasDiscount,
                ...config
              }}
            />
          ))
        }

        <View style={styles.section}>
          <View style={styles.tableRowStyle}>

            <View style={styles.firstSummaryColStyle}>
              <Text style={styles.tableCellStyleRight}>{formatMessage({id: 'OrderLines.excludingVat'})}</Text>
            </View>
            <View style={styles.summaryColStyle}>
              <Text style={styles.tableCellStyleRight}>
                {
                  formatNumber(
                    order.nett, {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    }
                  )
                }
              </Text>
            </View>
          </View>

          <View style={styles.tableRowStyle}>
            <View style={styles.firstSummaryColStyle}>
              <Text style={styles.tableCellStyleRight}>{formatMessage({id: 'OrderLines.vat'})}</Text>
            </View>
            <View style={styles.summaryColStyle}>
              <Text style={styles.tableCellStyleRight}>
                {
                  formatNumber(
                    order.vat, {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    }
                  )
                }
              </Text>
            </View>
          </View>

          <View style={styles.tableRowStyle}>
            <View style={styles.firstSummaryColStyle}>
              <Text style={styles.tableCellStyleRight}>{formatMessage({id: 'OrderLines.includingVat'})}</Text>
            </View>
            <View style={styles.totalColStyle}>
              <Text style={styles.tableCellStyleRight}>
                {
                  formatNumber(
                    order.gross, {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    }
                  )
                }
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default Pdf
