import { api } from '../../helpers/api'

export const SET_PRICE_OPTIONS = 'SET_PRICE_OPTIONS'

const setPriceOptions = (options, getState) => {
  const { user } = getState()
  const customerType = user &&
    user.user &&
    user.user.customer &&
    user.user.customer.type
  return {
    type: SET_PRICE_OPTIONS,
    options: options,
    customerType
  }
}

export const fetchPriceOptions = () => {
  return (dispatch, getState) => {
    api(`${getState().shop.apiUrl}/store?key=priceOptions`)
      .then(response => response.json())
      .then(response => {
        let priceOptions = response.data.value;
        if (
          priceOptions &&
          !getState().shop.canUseManufacturerListPrices &&
          priceOptions.priceType === 'manufacturer'
        ) {
          priceOptions.priceType = 'purchase'
          changePriceOptions(priceOptions)
        }
        dispatch(setPriceOptions(priceOptions, getState))
      })
  }
}

export const changePriceOptions = (options) => {
  return (dispatch, getState) => {
    api(`${getState().shop.apiUrl}/store?key=priceOptions`, 'post', { key: 'priceOptions', value: options })
      .then(response => response.json())
      .then(response => {
        dispatch(fetchPriceOptions())
      })
  }
}
