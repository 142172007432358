import React from 'react'
import { bindActionCreators } from 'redux'
import { FormControl, Button, FormGroup, ControlLabel, Row, Col, Alert } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { startPasswordReset, setParameter, resetForgotPassword } from '../../../actions/forgotPassword'
import { keyCodes } from '../../../constants/keyCodes'
import styles from './styles.less'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)
    this.handleButtonClick = this.onButtonClick.bind(this)
    this.handleBlur = this.onBlur.bind(this)
  }

  componentWillUnmount() {
    this.props.resetForgotPassword()
  }

  onKeyPress(event) {
    if (event.which === keyCodes.ENTER)
      this.onButtonClick()
  }

  onButtonClick() {
    const { startPasswordReset, shop, email } = this.props
    startPasswordReset(email, shop)
  }

  onBlur(event) {
    this.props.setParameter('email', event.target.value)
  }

  render() {
    const { error, isPasswordResetStarted, email } = this.props
    if (isPasswordResetStarted)
      return (
        <div className={`${styles.container} forgot-password`}>
          <h4>
            <T id="We sent you an email with instructions on how to reset your password."/>
          </h4>
        </div>
      )

    const errorMessage = error ? <Alert bsStyle="danger" className={styles.errorMessage}>{error}</Alert> : null
    return (
      <div  onKeyPress={this.handleKeyPress} className={`${styles.container} forgot-password`}>
        <Row>
          <Col xs={12} sm={4} smOffset={4}>
            <h1><T id="Forgot password" /></h1>
            <FormGroup>
              <ControlLabel><T id="Email"/></ControlLabel>
              <FormControl onBlur={this.handleBlur} defaultValue={email} name="email"/>
            </FormGroup>
          </Col>
          <Col xs={12} sm={4} smOffset={4} className={styles.actions}>
            <Button
              className={styles.button}
              onClick={this.handleButtonClick}><T id="Submit"/></Button>
            <Link to="/login"><T id="Login"/></Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4} smOffset={4}>
            {errorMessage}
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    shop: state.shop,
    error: state.forgotPassword.error,
    isPasswordResetStarted: state.forgotPassword.isPasswordResetStarted,
    email: state.forgotPassword.email
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  startPasswordReset: (email, shop) => startPasswordReset(email, shop),
  setParameter: (name, value) => setParameter(name, value),
  resetForgotPassword: () => resetForgotPassword()
}, dispatch)
 
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword)
