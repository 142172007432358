import React from 'react'
import styles from './styles.less'

export default class RangeSelector extends React.Component {
  constructor(props) {
    super(props)
    const lastElementIndex = this.props.options.length - 1
    this.state = {
      selectedValue: this.props.value || this.props.options[lastElementIndex]
    }
  }

  onChange(value) {
    const { options } = this.props
    const mappedValue = value === options[options.length - 1] ? '' : value
    this.setState({
      selectedValue: value
    }, () => this.props.onChange(this.props.name, mappedValue))
  }

  render() {
    const options = this.props.options.map(option => {
      const isSelected = option === this.state.selectedValue
      const isLowerThanSelected = option < this.state.selectedValue
      let style, className = styles.option
      if (isSelected) {
        className = `${styles.selected} background-normal`
      }
      else if (isLowerThanSelected)
        className = `${styles.option} text-colored`

      return <span className={className} style={style} key={option} onClick={() => this.onChange(option)}>{option}</span>
    })
    return  (
      <div className={`${styles.optionsGroup} ${this.props.className} range-selector`}>{options}</div>
    )
  }
}
