// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__container___xLvpF {
  max-width: 1140px !important;
  margin-top: 20px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(19, 39, 48, 0.12);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}
.styles__container___xLvpF section {
  margin-bottom: 45px;
}
.styles__container___xLvpF {
  max-width: 1140px !important;
  margin-top: 20px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(19, 39, 48, 0.12);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  padding: 36px 36px 66px 36px;
}
.styles__container___xLvpF section {
  margin-bottom: 45px;
}
.styles__actions___qtyXQ {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/shared/layout.less","webpack://./src/components/components/ForgotPassword/ForgotPasswordReturn/styles.less"],"names":[],"mappings":"AAEA;EACE,4BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,8CAAA;EACA,kDAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;ACDF;ADTA;EAYI,mBAAA;ACAJ;AAXA;EDAE,4BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,8CAAA;EACA,kDAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;ECPA,4BAAA;AAsBF;AAxBA;EDWI,mBAAA;ACgBJ;AAtBA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAwBF","sourcesContent":["@import './variables.less';\n\n.container {\n  max-width: 1140px !important;\n  margin-top: 20px;\n  margin-bottom: 15px;\n  border-radius: 3px;\n  background-color: #fff;\n  box-shadow: @eon-box-shadow;\n  transition: @eon-transition-default;\n  border-radius: 4px;\n  margin-left: auto;\n  margin-right: auto;\n  section {\n    margin-bottom: 45px;\n  }\n}\n","@import '../../../../shared/layout.less';\n@import '../../../../shared/variables.less';\n\n.container {\n  .container();\n  padding: 36px 36px 66px 36px;\n}\n\n.actions {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container___xLvpF`,
	"actions": `styles__actions___qtyXQ`
};
export default ___CSS_LOADER_EXPORT___;
