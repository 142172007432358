// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__icon___d0Gut {
  height: 30px;
  width: 30px;
}
.styles__studdedIcon___Wit15 {
  height: 20px;
  width: 20px;
}
.styles__popover___Qu1mJ {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchResults/TyreTypeIcon/styles.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;AADF;AAIA;EACE,YAAA;EACA,WAAA;AAFF;AAKA;EACE,WAAA;AAHF","sourcesContent":["@import '../../../../../shared/variables.less';\n\n.icon {\n  height: 30px;\n  width: 30px;\n}\n\n.studdedIcon {\n  height: 20px;\n  width: 20px;\n}\n\n.popover {\n  color: @eon-header-text-color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `styles__icon___d0Gut`,
	"studdedIcon": `styles__studdedIcon___Wit15`,
	"popover": `styles__popover___Qu1mJ`
};
export default ___CSS_LOADER_EXPORT___;
