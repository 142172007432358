import React from 'react'
import { Popover, Overlay } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import ConditionPopover from '../ConditionPopover';
import styles from './styles.less'
import { SilenceSvg, ElectricVehicleSvg } from '../../../SvgIcon'

const PRODUCT_PROPERTIES = {
  isRunflat: {
    label: 'RFT',
    description: <T id="Run-flat tyre, designed to resist the effects of deflation when punctured"/>
  },
  isEnforced: {
    label: 'XL',
    description: <T id="Extra reinforced tyre with extra load (Extra load)"/>
  },
  isSilence: {
    label: <SilenceSvg vAlign="text-top" />,
    description: <T id="Acoustic tires are designed to reduce air vibrations and thus reduce noise inside the car. This results in a quieter and more comfortable driving experience for the driver and passengers." />
  },
  isElectricVehicle: {
    label: <ElectricVehicleSvg vAlign="text-top" />,
    description: <T id="Tyres that are adapted for electric and hybrid vehicles" />
  },
}

export default class ProductProperties extends React.Component {
  constructor(props) {
    super(props)
    this.popoverTargets = {}
    this.state = {
      showPopover: {
        isRunflat: false,
        isEnforced: false,
        isSilence: false,
        isElectricVehicle: false,
        condition: false,
      }
    }
  }

  onMouseEnter(event) {
    const showPopover = Object.assign({}, this.state.showPopover, {
      [event.currentTarget.getAttribute('name')]: true
    })
    this.setState({
      showPopover
    })
  }

  onMouseLeave(event) {
    const showPopover = Object.assign({}, this.state.showPopover, {
      [event.currentTarget.getAttribute('name')]: false
    })
    this.setState({
      showPopover
    })
  }

  render() {
    const { product, condition, className, hasTpms } = this.props
    const properties = []

    if (condition.type.toUpperCase() !== 'NEW') {
      const className = condition.info ? styles.popoverTarget : `${styles.property} property`
      properties.push(
        <ConditionPopover key="attrs_condition" className={className} condition={condition} />
      )
    }

    if (product.attrs.carApprovalMark) {
      properties.push(
        <div
          className={`${styles.property} property`}
          key="attrs_carApprovalMark"
          name="carApprovalMark">
          {product.attrs.carApprovalMark}
        </div>
      )
    }

    if (product.attrs.vehicleType && product.attrs.vehicleType.code) {
      properties.push(
        <div
          key="attrs_vehicleTypeCode"
          className={`${styles.property} property`}
          name="vehicleTypeCode">
          {product.attrs.vehicleType.code}
        </div>
      )
    }

    for (let key in PRODUCT_PROPERTIES) {
      if (product.attrs[key]) {
        properties.push(
          <div key={key} className={`${styles.property} property`} ref={el => this.popoverTargets[key] = el}>
            <div
              name={key}
              onMouseEnter={this.onMouseEnter.bind(this)}
              onMouseLeave={this.onMouseLeave.bind(this)}>
              {PRODUCT_PROPERTIES[key].label}
            </div>
            <Overlay placement="bottom" show={this.state.showPopover[key]} target={this.popoverTargets[key]}>
              <Popover placement="bottom" id="test-results-popover"  className="background-normal" style={{ color: 'white' }}>
                {PRODUCT_PROPERTIES[key].description}
              </Popover>
            </Overlay>
          </div>
        )
      }
    }

    if (hasTpms) {
      properties.push(
        <div
          className={`${styles.property} property`}
          name="tpms">
          TPMS
        </div>
      )
    }
    const wrapperClass = className ? `${styles.wrapper} ${className} product-properties text-colored` : `${styles.wrapper} product-properties text-colored`

    return (
      <div className={wrapperClass}>{properties}</div>
    )
  }
}
