import { SHOP_RETRIEVED, SHOP_INITIALIZED } from '../actions'
import { SET_SESSION } from '../actions/user'
import { STORE_BASE_URL, APP_URL } from '../config'
import Color from 'color'
import { DELIVERY_OPTIONS } from '../constants/checkout'

const handleCustomCss = (shop) => {
    const mainColor = Color(shop.style.color1)
    const lighterColor = mainColor.lighten(3)
    const darkerColor = mainColor.darken(0.2)
    const textColor = shop.style.color4
    const btnStyle = `.btn, .btn.btn-default, .background { background-color: ${mainColor}; }`
    const background = `.background-light { background-color: ${lighterColor}} .background-normal{ background-color: ${mainColor };} .background-dark{ background-color: ${darkerColor };}`
    const textStyle = `.text-normal { color: ${textColor} } .text-colored { color: ${mainColor}; }`
    const border = `.border-colored { border: 1px solid ${mainColor} }`
    const svg = `.svg-colored { fill: ${mainColor}; } .svg-stroke-colored { stroke: ${mainColor}; }`
    const buttonLink = `.button-link { background:none;border:none;color:var(--link-color);text-decoration:none;padding:0;cursor:pointer; } .button-link:hover { color:var(--link-hover-color);text-decoration:underline; }`
    const popOver = `
        .background-normal.popover.right>.arrow:after {border-right-color: ${mainColor};}
        .background-normal.popover.left>.arrow:after {border-left-color: ${mainColor};}
        .background-normal.popover.top>.arrow:after {border-top-color: ${mainColor};}
        .background-normal.popover.bottom>.arrow:after {border-bottom-color: ${mainColor};}
    `
    return btnStyle.concat(textStyle, background, border, svg, shop.style.css, popOver, buttonLink)
}

const shop = (state = { headless: null, isInitialized: false }, action) => {
    switch (action.type) {
        case SHOP_RETRIEVED:
            const backendUrl = STORE_BASE_URL.replace(':id', action.shop.id)
            const shop = Object.assign({},
                state,
                action.shop,
                {
                    headless: action.headless || null,
                    hasDirectDelivery: hasDirectDelivery(action.shop),
                    hasPickupDelivery: hasPickupDelivery(action.shop),
                    hasCustomerDelivery: hasCustomerDelivery(action.shop),
                    hasPartnerDelivery: !!action.shop.deliveryOptions.find(o => o.id === DELIVERY_OPTIONS.PARTNER),
                    isB2B: action.shop.webshopType === 1,
                    backendUrl,
                    apiUrl: `${backendUrl}/api/webshop`,
                    url: APP_URL,
                },
            )
            shop.customStyle = handleCustomCss(shop)
            return shop
        case SHOP_INITIALIZED:
            return { ...state, isInitialized: true }
        case SET_SESSION:
          return {
            ...state,
            ...action.settings,
          }
        default:
            return state
    }
}

const hasDirectDelivery = (shop) => {
    const deliveryOption = shop.deliveryOptions.find(option => option.id === DELIVERY_OPTIONS.DIRECT)
    return !!deliveryOption
}

const hasPickupDelivery = (shop) => {
    const deliveryOption = shop.deliveryOptions.find(option => option.id === DELIVERY_OPTIONS.PICKUP)
    return !!deliveryOption
}

const hasCustomerDelivery = (shop) => {
    const deliveryOption = shop.deliveryOptions.find(option => option.id === DELIVERY_OPTIONS.CUSTOMER)
    return !!deliveryOption
}

export default shop
