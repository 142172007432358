import { SHOP_RETRIEVED } from '../actions'

const intl = (state = {
  locale: 'sv',
  currency: 'SEK',
  messages: {}
}, action) => {
  switch(action.type) {
    case SHOP_RETRIEVED:
      if (action.shop.locale) {
        return Object.assign({}, state, {
          locale: action.shop.locale,
          currency: action.shop.currency,
          messages: action.shop.messages
        })
      }
      return state
    default:
      return state
  }
}

export default intl
