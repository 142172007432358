import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormControl, Button, FormGroup, ControlLabel, Row, Col, HelpBlock } from 'react-bootstrap'
import { nextStep, changeParameter, validateCredentials, validate } from '../../../../actions/signUp'
import { keyCodes } from '../../../../constants/keyCodes'
import { Link } from 'react-router-dom'
import styles from './styles.less'
import { T } from 'components/components/i18n'

class UserInfo extends React.Component {
  constructor(props) {
    super(props)
    this.handleButtonClick = this.onButtonClick.bind(this)
    this.handleInputChange = this.onChange.bind(this)
    this.handleKeyPress = this.onKeyPress.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { userEmail, userPassword, shop, validateCredentials, isValidated, credentialsValidated, isValid, nextStep, credentialsValid } = this.props
    if (!prevProps.isValidated && isValidated && isValid)
      validateCredentials({ userEmail, userPassword }, shop)
    if (!prevProps.credentialsValidated && credentialsValidated && isValid && credentialsValid)
      nextStep()
  }

  onButtonClick() {
    const { userEmail, userPassword, shop, validate } = this.props
    validate({ userEmail, userPassword }, shop)
  }

  onChange(event) {
    this.props.changeParameter(event.target.name, event.target.value)
  }

  onKeyPress(event) {
    if (event.which === keyCodes.ENTER)
      this.onButtonClick()
  }

  render() {
    const { userEmail, userPassword, errors, credentialsValidated, credentialsValid } = this.props
    const emailError = errors.userEmail ? <HelpBlock>{errors.userEmail}</HelpBlock> : null
    const passwordError = errors.userPassword ? <HelpBlock>{errors.userPassword}</HelpBlock> : null
    const forgotPasswordLink = credentialsValidated && !credentialsValid ? <Link to="/forgot-password"><T id="Glömt lösenord?"/></Link> : null
    return (
      <div onKeyPress={this.handleKeyPress}>
        <FormGroup validationState={(!!errors.userEmail && 'error') || null}>
          <Row>
            <Col md={4}><ControlLabel><T id="Your email"/></ControlLabel></Col>
            <Col md={8}><FormControl onChange={this.handleInputChange} defaultValue={userEmail} name="userEmail"/></Col>
          </Row>
          <Row><Col md={8} mdOffset={4}>{emailError}</Col></Row>
        </FormGroup>
        <FormGroup validationState={(!!errors.userPassword && 'error') || null}>
          <Row>
            <Col md={4}><ControlLabel><T id="New password"/></ControlLabel></Col>
            <Col md={8}><FormControl onChange={this.handleInputChange} defaultValue={userPassword} name="userPassword" type="password"/></Col>
          </Row>
          <Row><Col md={8} mdOffset={4}>{passwordError}</Col></Row>
          <Row><Col md={12} className={`${styles.forgotPasswordLink} userInfo_forgotPasswordLink`}>{forgotPasswordLink}</Col></Row>
        </FormGroup>
        <div className={`${styles.footer} userInfo_footer`}>
          <Button onClick={this.handleButtonClick}><T id="Next"/></Button>
          <Link to="/login"><T id="Log in"/></Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const errors = state.signUp.errors
  return {
    userEmail: state.signUp.params.userEmail,
    userPassword: state.signUp.params.userPassword,
    errors,
    isValid: state.signUp.isValid,
    shop: state.shop,
    credentialsValidated: state.signUp.credentialsValidated,
    isValidated: state.signUp.isValidated,
    credentialsValid: state.signUp.credentialsValid
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  changeParameter: (name, value) => changeParameter(name, value),
  nextStep: () => nextStep(),
  validateCredentials: (params, shop) => validateCredentials(params, shop),
  validate: (params, shop) => validate(params, shop)
}, dispatch)
 
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInfo)
