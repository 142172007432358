// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__cart___k_23W {
  cursor: pointer;
  text-align: right;
}
.styles__badge___AIjOM {
  position: relative;
  bottom: 10px;
  background-color: #30beca !important;
  color: #fff;
}
.styles__cartSummary___E_AOG {
  border-radius: 3px;
  height: 80px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
  float: none;
  min-width: 320px;
  min-height: inherit;
}
@media (max-width: 991px) {
  .styles__cartSummary___E_AOG {
    margin-left: auto;
    min-width: 270px;
    width: 100%;
    margin-top: 15px;
  }
}
.styles__cartContent___AIXd0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.styles__cartFont___o2lU3 {
  text-transform: uppercase;
  font-size: 11px !important;
  font-weight: bold !important;
}
.styles__cartButton___EsVox {
  background-color: #fff;
  border: none;
}
.styles__cartTitle___VdL2e {
  margin: 0 -10px 10px -10px;
  padding: 0 10px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px rgba(255, 255, 255, 0.15);
}
.styles__cartParagraph___oUGlP {
  margin-top: 14px;
}
.styles__cartPopover___LltE4 {
  max-width: none !important;
  width: 320px !important;
}
.styles__productList___HgHac {
  font-size: 13px;
  overflow: hidden;
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Cart/styles.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;AACF;AAEA;EACE,kBAAA;EACA,YAAA;EACA,oCAAA;EACA,WAAA;AAAF;AAEA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,gDAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;AAAF;AACE;EAAA;IACE,iBAAA;IACA,gBAAA;IACA,WAAA;IACA,gBAAA;EAEF;AACF;AACA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AAEA;EACE,yBAAA;EACA,0BAAA;EACA,4BAAA;AAAF;AAGA;EAEE,sBAAA;EACA,YAAA;AADF;AAIA;EAEE,0BAAA;EACA,mBAAA;EACA,4CAAA;EACA,2CAAA;AAFF;AAKA;EACE,gBAAA;AAHF;AAMA;EACE,0BAAA;EACA,uBAAA;AAJF;AAOA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AALF","sourcesContent":[".cart {\n  cursor: pointer;\n  text-align: right;\n}\n\n.badge {\n  position: relative;\n  bottom: 10px;\n  background-color: #30beca !important;\n  color: #fff;\n}\n.cartSummary {\n  border-radius: 3px;\n  height: 80px;\n  padding: 10px;\n  background: rgba(0, 0, 0, 0.1);\n  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);\n  float: none;\n  min-width: 320px;\n  min-height: inherit;\n  @media (max-width: 991px) {\n    margin-left: auto;\n    min-width: 270px;\n    width: 100%;\n    margin-top: 15px;\n  }\n}\n\n.cartContent {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.cartFont {\n  text-transform: uppercase;\n  font-size: 11px !important;\n  font-weight: bold !important;\n}\n\n.cartButton {\n  composes: cartFont;\n  background-color: #fff;\n  border: none;\n}\n\n.cartTitle {\n  composes: cartFont;\n  margin: 0 -10px 10px -10px;\n  padding: 0 10px 5px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.06);\n  box-shadow: 0 1px rgba(255, 255, 255, 0.15);\n}\n\n.cartParagraph {\n  margin-top: 14px;\n}\n\n.cartPopover {\n  max-width: none !important;\n  width: 320px !important;\n}\n\n.productList {\n  font-size: 13px;\n  overflow: hidden;\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cart": `styles__cart___k_23W`,
	"badge": `styles__badge___AIjOM`,
	"cartSummary": `styles__cartSummary___E_AOG`,
	"cartContent": `styles__cartContent___AIXd0`,
	"cartFont": `styles__cartFont___o2lU3`,
	"cartButton": `styles__cartButton___EsVox styles__cartFont___o2lU3`,
	"cartTitle": `styles__cartTitle___VdL2e styles__cartFont___o2lU3`,
	"cartParagraph": `styles__cartParagraph___oUGlP`,
	"cartPopover": `styles__cartPopover___LltE4`,
	"productList": `styles__productList___HgHac`
};
export default ___CSS_LOADER_EXPORT___;
