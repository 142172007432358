import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { has as _has } from 'lodash'
import { addItem } from '../../../actions/cart'
import { setActiveSlug } from '../../../actions/menu'
import { getProduct, resetProduct } from '../../../actions/product'
import {
  Row,
  Col,
  Alert,
  FormControl,
  FormGroup,
  Table,
  OverlayTrigger,
  Popover
} from 'react-bootstrap'
import styles from './styles.less'
import Spinner from '../Spinner'
import EuGrading from '../EuGrading'
import CATEGORIES from '../../../helpers/categories'
import Lightbox from 'react-images'
import { FormattedNumber } from 'react-intl'
import { T } from 'components/components/i18n'
import DeliveryTime from '../DeliveryTime'
import SupplierPopover from '../Search/SearchResults/SupplierPopover'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../Button'
import RimTypeLabel from '../Search/SearchResults/RimTypeLabel'
import TyreTypeIcon from '../Search/SearchResults/TyreTypeIcon'
import PriceOptions from '../Search/SearchResults/PriceOptions'
import { calculatePrice } from '../../../helpers/price'
import { setRim, setTyre } from '../../../actions/wheelBuilder'
import CommentInput from 'components/components/Form/CommentInput'
import { isShowBadge } from 'helpers/badge'
import classnames from 'classnames'
import { getUsedStockInfo } from 'helpers/order'
import { isMobile } from 'helpers/general'

class Product extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      count: this.props.initialCount,
      comment: '',
      isGalleryOpen: false,
      currentImage: 0,
      expanded: false,
      showReadMore: false,
      isLockQuantity: false,
      lockedStockValue: this.props.initialCount,
    }
    this.productDescriptionRef = React.createRef()
    this.handleButtonClick = this.onButtonClick.bind(this)
    this.handleGalleryClose = this.onGalleryClose.bind(this)
    this.handleImageClick = this.onImageClick.bind(this)
  }

  componentDidMount() {
    const { slug, shop, match, setActiveSlug, getProduct } = this.props
    setActiveSlug(slug)
    getProduct(match.params.id, shop)
  }

  componentDidUpdate(prevProps) {
    if (
      (
        prevProps.product &&
        this.props.product &&
        prevProps.product.description !== this.props.product.description
      ) || (
        this.props.product && this.props.product.description && !prevProps.product
      )
    ) {
      const height = this.productDescriptionRef.current.clientHeight
      const maxHeight = 100
      if (height > maxHeight) {
        this.setState({ showReadMore: true });
      } else {
        this.setState({ showReadMore: false });
      }
    }

    if (this.props.product && !prevProps.product) {
      // NOTE: Temp solution to test how users perceive this behaviour
      //       where when USED complete wheel is low on stock (<= 4)
      //       user can only select all quantity
      const {
        isLockQuantity,
        lockedStockValue,
      } = getUsedStockInfo(
        this.props.product?.condition?.type,
        this.props.product?.stock,
        this.props.initialCount
      )
      this.setState({
        isLockQuantity,
        lockedStockValue,
        initialCount: lockedStockValue
      });
    }
  }

  componentWillUnmount() {
    this.props.resetProduct()
  }

  onInputChange(name, event) {
    this.setState({
      [name]: event.target.value
    })
  }

  onButtonClick() {
    const { product, addToCart, shop, cartItems, isWheelBuilder, setRim, setTyre, category } = this.props
    const { comment, count } = this.state
    if (!isWheelBuilder)
      addToCart(product.id, parseInt(count, 10), shop, cartItems, comment)
    else {
      if (category.id === CATEGORIES.TYRE.id)
        setTyre(product, parseInt(count, 10), comment)
      else
        setRim(product, parseInt(count, 10), comment)
    }
  }

  onImageClick() {
    this.setState({
      isGalleryOpen: true
    })
  }

  onGalleryClose() {
    this.setState({
      isGalleryOpen: false
    })
  }

  onNextClick() {
    this.setState({
      currentImage: Math.min(this.state.currentImage + 1, this.props.product.images.length)
    })
  }

  onPreviousClick() {
    this.setState({
      currentImage: Math.max(this.state.currentImage - 1, 0)
    })
  }

  onToggleDescription() {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  render() {
    const {
      shop,
      product,
      category,
      includeVat,
      isWheelBuilder,
      cartItems,
      currency,
      selectedNumberOfPcs,
      priceType,
      inProgress
    } = this.props
    if (!product || !product.id)
      return <Spinner />

    const showBadge = isShowBadge(
      (_has(product, 'name') && product.name) || '',
      (_has(product, 'brand.name') && product.brand.name) || '',
      _has(shop, 'siteId') && shop.siteId
    )

    let freeShippingMessage
    if (shop.hasFreeShipping)
      freeShippingMessage = (
        <Alert className={`${styles.alert} border-muted text-muted`}>
          <h4><T id="Free shipping within Sweden!"/></h4>
          <div>
            <T
              id="ProductPage.shippingInfo"
              defaultMessage="This product is delivered at no extra cost directly to you or to a workshop near you."
            />
          </div>
        </Alert>
      )

    let mainImage
    let images = []
    let gallery
    if (product.images) {
      product.images.forEach((image, index) => {
        if (index === 0) {
          mainImage = (
            <img className="img-responsive" src={image.webshop_sidebar} onClick={this.handleImageClick} alt="Product" />
          )
        }
        else {
          images.push(
            <img className="img-responsive" src={image.webshop_thumb} onClick={this.handleImageClick} alt="Product" />
          )
        }
      })
      gallery = (
        <Lightbox
          images={product.images.map(image => { return { src: image.original }})}
          isOpen={this.state.isGalleryOpen}
          onClickPrev={this.onPreviousClick.bind(this)}
          onClickNext={this.onNextClick.bind(this)}
          showCloseButton={false}
          onClose={this.handleGalleryClose}
          currentImage={this.state.currentImage}
          backdropClosesModal={true}
        />
      )
    } else {
      mainImage = (
        <img className="img-responsive" src="/images/default-product-image.png" alt="Product" />
      )
    }

    let controls
    let priceValue
    if (priceType === 'consumer')
      priceValue = product.consumerPrice
    else
      priceValue = product.price
    const vatPercent = includeVat ? product.vatPercent : null
    priceValue = calculatePrice(priceValue, selectedNumberOfPcs, vatPercent)

    const vatInfo = includeVat ? <T id="incl. VAT"/> : <T id="excl. VAT"/>
    if (!isWheelBuilder)
      controls = (
        <Row className={styles.mainControls}>
          <Col md={4} sm={12}>
          <FormGroup>
            <div className={`${styles.inline} ${styles.firstColumn}`}>
              <T id="Note:"/>
              <CommentInput
                name="comment"
                value={this.state.comment}
                onChange={(event) => this.onInputChange('comment', event)}
              />
            </div>
          </FormGroup>
          </Col>
          <Col md={6} mdOffset={2} sm={12}>
            <FormGroup className={styles.quantity}>
              <div className={styles.inline}>
                <T id="Items:"/>
                {!this.state.isLockQuantity && (
                  <FormControl type="number"
                    name="count"
                    value={this.state.count}
                    onChange={(event) => this.onInputChange('count', event)}
                    min="1"
                    max="99"
                  />
                )}
                {this.state.isLockQuantity && (
                  <React.Fragment>
                    <OverlayTrigger
                      placement={isMobile() ? 'top' : 'right'}
                      overlay={
                        <Popover>
                          <T id="This item is sold in sets." />
                        </Popover>
                      }
                    >
                      <span className={styles.infoPropertyName}>
                        {this.state.lockedStockValue} <T id="pc"/>
                      </span>
                    </OverlayTrigger>
                    <input type="hidden" name="count" value={this.state.lockedStockValue} />
                  </React.Fragment>
                )}
              <span >
                <span className={`${styles.price} product_price`}>
                  <FormattedNumber
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                    value={priceValue}
                    style={`currency`}
                    currency={currency} /> / st
                </span> {vatInfo}
              </span>
              </div>
              {shop.limitPurchaseQuantityToStock && product.stock < parseInt(this.state.count, 10) && (
                <span className={`${styles.limitedQuantityMessage} limitedQuantityMessage`}>
                  <T id="Limited quantity available" />
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
      )

    let productAttributes, productTitle, tyreAttributes, rimAttributes, euGrading
    switch (category.id) {
      case CATEGORIES.RIM.id:
        productAttributes = [
          <tr key="attr_brand"><td><T id="Brand"/></td><td>{product.brand.name}</td></tr>,
          <tr key="attr_model"><td><T id="Model"/></td><td>{product.model.name}</td></tr>,
          <tr key="attr_width"><td><T id="Width (tum)"/></td><td>{product.attrs.width }</td></tr>,
          <tr key="attr_diameter"><td><T id="Diameter (tum)"/></td><td>{product.attrs.diameter }</td></tr>,
          <tr key="attr_bolt_pattern"><td><T id="Bolt pattern"/></td><td>{product.attrs.boltPatterns.join(", ")}</td></tr>,
          <tr key="attr_et"><td><T id="Et"/></td><td>{product.attrs.etOffset}</td></tr>,
          <tr key="attr_center"><td><T id="Center bore"/></td><td>{product.attrs.centerBore}</td></tr>,
          <tr key="attr_rim_type">
            <td>
              <T id="Type"/>
            </td>
            <td>
              <RimTypeLabel rimType={product.attrs.rimType.id} fullName />
            </td>
          </tr>
        ]
        productTitle = (
          <div>
            <h2 className="text-colored">
              {product.brand.name} <em>{product.model.name}</em>
            </h2>
            <div className={`${styles.flexIt} ${styles.marginBottom}`}>{product.attrs.dimension}</div>
          </div>
        )
        break
      case CATEGORIES.TYRE.id:
        productAttributes = [
          <tr key="attr_brand"><td><T id="Brand"/></td><td>{product.brand.name}</td></tr>,
          <tr key="attr_model"><td><T id="Modellnamn"/></td><td>{product.model.name}</td></tr>,
          <tr key="attr_dimension"><td><T id="Dimension"/></td><td>{`${product.attrs.width.replace('.0', '')}/${product.attrs.aspectRatio}-${product.attrs.diameter}`}</td></tr>,
          <tr key="attr_load_index"><td><T id="Load index"/></td><td>{product.attrs.loadIndex}</td></tr>,
          <tr key="attr_speed_index"><td><T id="Speed index"/></td><td>{product.attrs.speedIndex}</td></tr>,
          <tr key="attr_tyre_type"><td><T id="Type"/></td><td><TyreTypeIcon product={product} fullName /></td></tr>,
          <tr key="attr_tread_depths"><td><T id="Tread depths"/></td><td>{product.attrs.treadDepths}</td></tr>
        ]
        productTitle = (
          <div>
            <h2 className="text-colored">
              {product.brand.name} <em>{product.model.name}</em>
            </h2>
            <div className={`${styles.flexIt} ${styles.marginBottom}`}>{product.attrs.dimension}</div>
          </div>
        )
        euGrading = <EuGrading product={product} className={styles.marginBottom}/>
        break
      case CATEGORIES.COMPLETE_WHEEL.id:
        tyreAttributes = (
          <div>
            <h3><T id="Tyre"/></h3>
            <Table striped>
              <tbody>
                <tr><td><T id="Brand"/></td><td>{product.tyre.brand.name}</td></tr>
                <tr><td><T id="Model"/></td><td>{product.tyre.model.name}</td></tr>
                <tr><td><T id="Dimension"/></td><td>{`${product.tyre.attrs.width.replace('.0', '')}/${product.tyre.attrs.aspectRatio}-${product.tyre.attrs.diameter}`}</td></tr>
                <tr><td><T id="Load index"/></td><td>{product.tyre.attrs.loadIndex}</td></tr>
                <tr><td><T id="Speed index"/></td><td>{product.tyre.attrs.speedIndex}</td></tr>
                <tr><td><T id="Type"/></td><td><TyreTypeIcon product={product.tyre} fullName /></td></tr>
                <tr><td><T id="Tread depths"/></td><td>{product.tyre.attrs.treadDepths}</td></tr>
              </tbody>
            </Table>
          </div>
        )

        rimAttributes = (
          <div>
            <h3><T id="Rim"/></h3>
            <Table striped>
              <tbody>
                <tr><td><T id="Brand"/></td><td>{product.rim.brand.name}</td></tr>
                <tr><td><T id="Model"/></td><td>{product.rim.model.name}</td></tr>
                <tr><td><T id="Dimension"/></td><td>{`${product.rim.attrs.width.replace('.0', '')}x${product.rim.attrs.diameter}`}</td></tr>
                <tr><td><T id="Bolt pattern"/></td><td>{product.rim.attrs.bolt1}</td></tr>
                <tr><td><T id="Et"/></td><td>{product.rim.attrs.etOffset}</td></tr>
                <tr><td><T id="Center bore"/></td><td>{product.rim.attrs.centerBore}</td></tr>
                <tr>
                  <td>
                    <T id="Type"/>
                  </td>
                  <td>
                    <RimTypeLabel rimType={product.rim.attrs.rimType.id} fullName />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )
        productTitle = (
          <div>
            <h2 className="text-colored">
              {product.tyre.brand.name} <em>{product.tyre.model.name}</em>
            </h2>
            <div className={`${styles.flexIt} ${styles.marginBottom}`}>{product.tyre.attrs.dimension}</div>
            <h2 className="text-colored">
              {product.rim.brand.name} <em>{product.rim.model.name}</em>
            </h2>
            <div className={`${styles.flexIt} ${styles.marginBottom}`}>{product.rim.attrs.dimension}</div>
          </div>
        )
        euGrading = <EuGrading product={product.tyre} className={styles.marginBottom}/>
        break
      case CATEGORIES.OTHER.id:
        productTitle = (
          <div>
            <h2 className="text-colored">
              {
                !!product.brand.name
                  ? (
                    <React.Fragment>
                      {product.brand.name} <em>{product.model.name}</em>
                    </React.Fragment>
                  )
                  : product.name
              }
            </h2>
          </div>
        )
        productAttributes = [
          <tr key="attr_brand"><td><T id="Brand"/></td><td>{(product.brand && product.brand.name) ? product.brand.name : ''}</td></tr>,
          <tr key="attr_model"><td><T id="Modellnamn"/></td><td>{(product.model && product.model.name) ? product.model.name : ''}</td></tr>,
        ]
        break
      default:
        productAttributes = []
    }
    const productTypeLabel = (product.type && product.type.name)
      ? product.type.name
      : category.label;
    const itemsAlreadyInCart = cartItems.filter(item => item.id === product.id)
    let quantityInCartMessage = <br/>
    const sum = itemsAlreadyInCart.reduce((sum, item) => (sum + item.count), 0);
    if (sum > 0)
      quantityInCartMessage = <Link to="/kassa">{sum} <T id="pcs already in cart"/></Link>

    let button
    if (!shop.prohibitPurchase)
      button = (
        <Col sm={6} smOffset={3} xs={12}>
          <div className={styles.columnFullWidth}>
            <Button
              onClick={this.handleButtonClick}
              className={styles.button}
              label={
                <span>
                  <T id="Buy"/> {productTypeLabel}
                </span>
              }
              inProgress={inProgress}
            />
          </div>
        </Col>
      )


    let supplier
    if (!!shop.showSupplierInSearchResults) {
      const productSource = product.supplier.id ? product.supplier : product.location
      if(!productSource.hideIt)
        supplier = <SupplierPopover supplier={product.supplier} supplierNameComponent={productSource.name} />
    }

    let delivery, deliveryClass
    switch(product.estimatedDeliveryType) {
      case 0:
      case 5:
       deliveryClass = 'text-danger font-weight-bold'
       break
      case 1:
       deliveryClass = 'text-success font-weight-bold'
       break
      case 2:
       deliveryClass = 'text-info font-weight-bold'
       break
      case 3:
       deliveryClass = 'text-warning font-weight-bold'
       break
      default:
       deliveryClass = ''
    }
    if (product.deliveryTime && !product.deliveryTime.hideIt) {
      if (shop.deliveryDisplayMode === 1) {
        let deliveryTime
        deliveryTime = <DeliveryTime deliveryInfo={product.deliveryTime} />
        delivery = deliveryTime && <div className={deliveryClass}>{deliveryTime}</div>
      } else {
        delivery = (
          <FontAwesomeIcon
            className={deliveryClass}
            icon={faTruck}
          />
        )
      }
    }

    // NOTE: Show tread depths under product description if available
    let treadDepthsRender = null
    const treadDepths = (
        product.attrs && product.attrs.treadDepths
      ) || (
        product.tyre && product.tyre.attrs && product.tyre.attrs.treadDepths
      )
    if (treadDepths) {
      treadDepthsRender = (
        <span>
          <T id="Tread depths" />: {treadDepths}
        </span>
      )
    }

    return (
      <div className={classnames(styles.container, 'product-page')}>
        <PriceOptions className={`${styles.priceOptions} product_priceOptions`}/>
        <div className={`${styles.productContent} product_content product text-muted`}>
          <Row>
            <Col md={3} sm={12}>
              {mainImage}
              {images}
            </Col>
            <Col md={9} sm={12}>
              <Row className={styles.mainInfo}>
                <Col md={!!showBadge ? 6 : 8} sm={12}>
                  <div className={styles.title}>
                    {productTitle}
                    {euGrading}
                    {product.description
                      ? (
                        <div
                          className={styles.description}
                          style={{ height: this.state.expanded ? 'auto' : '100px' }}
                        >
                          <div
                            id="product-description-text"
                            ref={this.productDescriptionRef}
                          >
                            <div dangerouslySetInnerHTML={{__html: product.description }}/>
                            {treadDepthsRender}
                          </div>
                        </div>
                      )
                      : <T id="This product has no description"/>
                    }
                    {this.state.showReadMore && (
                      <div className={styles.buttonWrapper}>
                        <button
                          type="text"
                          className={styles.readMoreButton}
                          onClick={this.onToggleDescription.bind(this)}
                        >
                          {this.state.expanded ? <T id="Read less" /> : <T id="Read more" />}
                        </button>
                      </div>
                    )}
                    <div className={styles.comment}>{product.comment}</div>
                  </div>
                </Col>
                {showBadge && <Col md={!!freeShippingMessage ? 3 : 6} sm={12}>
                  <a href="https://www.bilekotires.se/satisfaction-michelin" target="_blank" className={styles.productBadge} rel="noopener noreferrer">
                    <img src={`/images/badge/michelin.png`} width={!!freeShippingMessage ? '160px' : '180px'} alt="Michelin" />
                  </a>
                </Col>}
                <Col md={!!showBadge ? 3 : 4} sm={12}>
                  {freeShippingMessage}
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} id="productMainControls">
                  <div className={`${styles.controls} background-light`}>
                    {controls}
                    <Row className={styles.buttonRow}>
                      <Col sm={3} xs={12}>
                        {delivery}
                        {supplier}
                      </Col>
                      {button}
                    </Row>
                    <div className={styles.quantityInCart}>{quantityInCartMessage}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12}>
                  <h3><T id="Egenskaper"/></h3>
                  <Table striped>
                    <tbody>
                      {product.manufacturerProductId && (
                        <tr><td><T id="Manufacturer number" /></td><td>{product.manufacturerProductId}</td></tr>
                      )}
                      <tr><td><T id="Product number"/></td><td>{product.productId}</td></tr>
                      <tr><td><T id="Product type"/></td><td>{productTypeLabel}</td></tr>
                      {productAttributes}
                      {product.eanCode && (
                        <tr><td>EAN</td><td>{product.eanCode}</td></tr>
                      )}
                      {!!(product.attrs && product.attrs.eprelLabelImage) && (
                        <tr>
                          <td><T id="Eprel label"/></td>
                          <td><a href={product.attrs.eprelFullLabelImage} target="_blank" rel="noopener noreferrer"><img src={product.attrs.eprelLabelImage} alt="EPREL" /></a></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {tyreAttributes}
                  {rimAttributes}
                </Col>
              </Row>
            </Col>
          </Row>
          {gallery}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    shop: state.shop,
    cartItems: state.cart.items,
    product: state.search.activeProduct,
    includeVat: state.price.includeVat,
    category: state.categories.activeCategory,
    currency: state.intl.currency,
    selectedNumberOfPcs: state.price.selectedNumberOfPcs,
    priceType: state.price.priceType,
    inProgress: state.search.activeProduct && state.cart.updateInProgress[state.search.activeProduct.id],
    initialCount: state.shop.limitPurchaseQuantityToStock && state.search.activeProduct ? Math.min(state.search.activeProduct.stock, 4) : 4,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  addToCart: (id, count, shopId, cartItems, comment) => addItem(id, count, shopId, cartItems, comment),
  setActiveSlug: (slug) => setActiveSlug(slug),
  getProduct: (id, shop) => getProduct(id, shop),
  resetProduct: () => resetProduct(),
  setRim: (product, count, comment) => setRim(product, count, comment),
  setTyre: (product, count, comment) => setTyre(product, count, comment)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product)
