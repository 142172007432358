const SEARCH_MODES = {
  combined: 4,
  carModel: 3,
  licensePlate: 2,
  dimension: 1
}

const getSearchModeById = (id) => {
  const keys = Object.keys(SEARCH_MODES)
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (SEARCH_MODES[key].id === id)
      return SEARCH_MODES[key]
  }
  return {}
}

const SEARCH_MODE_BY_VEHICLE_TYPE = {
  PERSONAL: 1,
  MC: 2,
  HEAVYTRUCK: 3,
  TRACTOR: 4,
}

export { SEARCH_MODES, getSearchModeById, SEARCH_MODE_BY_VEHICLE_TYPE }
