import React, { Component } from "react";
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { T } from 'components/components/i18n'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import Checkbox from '../../../Checkbox'
import styles from './styles.less'
import { getTyreAlternatives, getStandard, isWinter } from 'helpers/tyreAlternatives'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

class TyreAlternatives extends Component {
  constructor(props) {
    super(props);
    this.handleCheckboxChange = this.onCheckboxChange.bind(this)
    this.handleFrontLinkClick = this.toggleShowAllFrontAlternatives.bind(this)
    this.handleRearLinkClick  = this.toggleShowAllRearAlternatives.bind(this)
    this.handleDiameterChange = this.onChangeDiameter.bind(this)
    this.state = {
      showAllFrontAlternatives: false,
      showAllRearAlternatives: false,
      bestFrontOption: null,
      bestRearOption: null,
      otherFrontOptions: null,
      otherRearOptions: null,
      diameters: [],
      selectedDiameter: null
    }
  }

  componentDidMount() {
    if (this.props.tyreSizes.length) {
      this.prepTyreSizes()
    }
  }

  componentWillUnmount() {
    this.props.onSearchParamChange('tyreDimensions', [])
    this.props.onSearchParamChange('loadIndex', '')
    this.props.onSearchParamChange('diameter', '')
    this.props.onSearchParamChange('loadIndexRear', '')
    this.props.onSearchParamChange('speedIndex', '')
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultSpeedIndex !== this.props.defaultSpeedIndex) {
      this.props.onSearchParamChange('speedIndex', this.props.defaultSpeedIndex)
    }
  }

  prepTyreSizes() {
    const {
      tyreSizes,
      defaultSpeedIndex,
      defaultLoadIndexFront,
      defaultLoadIndexRear,
      selectedSpeedIndex,
      selectedLoadIndexFront,
      selectedLoadIndexRear,
      selectedDiameter,
      selectedTyreDimensions,
    } = this.props

    // NOTE: Make sure we get the best option for front and rear as the first option
    const minFrontDeviation = Math.min(...tyreSizes.filter(size => size.position === 'front').map(size => size.deviationPercent))
    const minRearDeviation = Math.min(...tyreSizes.filter(size => size.position === 'rear').map(size => size.deviationPercent))
    const bestFrontOption = tyreSizes.find(
      option => option.deviationPercent === minFrontDeviation && (option.position === 'front')
    )
    const bestRearOption = tyreSizes.find(
      option => option.deviationPercent === minRearDeviation && (option.position === 'rear')
    )
    const defaultDiameter = bestFrontOption && parseInt(bestFrontOption.diameter, 10)
    const diameters = [...new Set(tyreSizes.map(tyre => parseInt(tyre.diameter, 10)))]

    // TODO: What if dimension same but load or speed index not the same?
    const otherFrontOptions = bestFrontOption ? tyreSizes.filter(
      option =>
        (
          option.deviationPercent !== minFrontDeviation ||
          bestFrontOption.text !== option.text
          // ||
          // bestFrontOption.loadIndex !== option.loadIndex ||
          // bestFrontOption.speedIndex !== option.speedIndex
        ) &&
        option.position === 'front' &&
        parseInt(option.diameter, 10) === defaultDiameter
    ) : []
    const otherRearOptions = bestRearOption ? tyreSizes.filter(
      option =>
        (
          option.deviationPercent !== minRearDeviation ||
          bestRearOption.text !== option.text
          // ||
          // bestRearOption.loadIndex !== option.loadIndex ||
          // bestRearOption.speedIndex !== option.speedIndex
        ) &&
        option.position === 'rear' &&
        parseInt(option.diameter, 10) === defaultDiameter
    ) : []

    // NOTE: Make sure the rest of the options are sorted by deviationPercent
    otherFrontOptions.sort((a, b) => {
      if (a.deviationPercent < b.deviationPercent) {
        return -1
      }
      if (a.deviationPercent > b.deviationPercent) {
        return 1
      }
      return 0
    })
    otherRearOptions.sort((a, b) => {
      if (a.deviationPercent < b.deviationPercent) {
        return -1
      }
      if (a.deviationPercent > b.deviationPercent) {
        return 1
      }
      return 0
    })

    const _selectedDiameter = selectedDiameter || defaultDiameter

    this.setState({
      bestFrontOption,
      bestRearOption,
      otherFrontOptions,
      otherRearOptions,
      selectedDiameter: _selectedDiameter,
      diameters,
      showAllFrontAlternatives: !!selectedDiameter,
      showAllRearAlternatives: !!selectedDiameter,
    })

    // NOTE: Load standard speed and load index, as it should be
    //       calculated from max speed for speed index, max load for load index
    //       and these are the starting index for all tyre dimension
    this.props.onSearchParamChange('speedIndex', selectedSpeedIndex || defaultSpeedIndex)
    this.props.onSearchParamChange('loadIndex', selectedLoadIndexFront || defaultLoadIndexFront)
    this.props.onSearchParamChange('loadIndexRear', selectedLoadIndexRear || defaultLoadIndexRear)
    this.props.onSearchParamChange('loadIndexRear', selectedLoadIndexRear || defaultLoadIndexRear)
    this.props.onSearchParamChange('diameter', _selectedDiameter)

    if (selectedTyreDimensions?.length > 0) {
      this.processDiameter(_selectedDiameter, selectedTyreDimensions)
    } else {
      this.processDiameter(_selectedDiameter, false)
    }
  }

  toggleShowAllFrontAlternatives(event) {
    event.preventDefault()
    event.stopPropagation()
    const showAllFrontAlternatives = !this.state.showAllFrontAlternatives;
    this.setState({ showAllFrontAlternatives})

    // NOTE: This is to make sure the default bestOption checkbox is checked when
    //       alternative list are collapsed
    const { bestFrontOption } = this.state
    if ( !showAllFrontAlternatives) {
      const selectedTyreDimensions = [
        [bestFrontOption.width, bestFrontOption.aspectRatio, bestFrontOption.diameter],
        this.props.selectedTyreDimensions[1]
      ]

      this.props.onSearchParamChange('tyreDimensions', selectedTyreDimensions)
    }
  }

  toggleShowAllRearAlternatives(event) {
    event.preventDefault()
    event.stopPropagation()
    const showAllRearAlternatives = !this.state.showAllRearAlternatives;
    this.setState({ showAllRearAlternatives})

    // NOTE: This is to make sure the default bestOption checkbox is checked when
    //       alternative list are collapsed
    const { bestRearOption } = this.state
    if ( !showAllRearAlternatives ) {
      const selectedTyreDimensions = [
        this.props.selectedTyreDimensions[0],
        [bestRearOption.width, bestRearOption.aspectRatio, bestRearOption.diameter]
      ]

      this.props.onSearchParamChange('tyreDimensions', selectedTyreDimensions)
    }
  }

  onChangeDiameter(diameter) {
    this.processDiameter(diameter, false)
  }

  processDiameter(diameter, resetDimension) {
    const { tyreSizes } = this.props
    const tyreSizesDiameter = tyreSizes.filter(size => parseInt(size.diameter, 10) === diameter)
    const minFrontDeviation = Math.min(...tyreSizesDiameter.filter(size => size.position === 'front').map(size => size.deviationPercent))
    const minRearDeviation = Math.min(...tyreSizesDiameter.filter(size => size.position === 'rear').map(size => size.deviationPercent))
    const bestFrontOption = tyreSizesDiameter.find(
      option => option.deviationPercent === minFrontDeviation && (option.position === 'front')
    )
    const bestRearOption = tyreSizesDiameter.find(
      option => option.deviationPercent === minRearDeviation && (option.position === 'rear')
    )
    const otherFrontOptions = bestFrontOption ? tyreSizesDiameter.filter(
      option =>
        (
          option.deviationPercent !== minFrontDeviation ||
          bestFrontOption.text !== option.text
          // ||
          // bestFrontOption.loadIndex !== option.loadIndex ||
          // bestFrontOption.speedIndex !== option.speedIndex
        ) &&
        option.position === 'front' &&
        parseInt(option.diameter, 10) === diameter
    ) : []
    const otherRearOptions = bestRearOption ? tyreSizesDiameter.filter(
      option =>
        (
          option.deviationPercent !== minRearDeviation ||
          bestRearOption.text !== option.text
          // ||
          // bestRearOption.loadIndex !== option.loadIndex ||
          // bestRearOption.speedIndex !== option.speedIndex
        ) &&
        option.position === 'rear' &&
        parseInt(option.diameter, 10) === diameter
    ) : []

    const dimensionParams = []
    if (bestFrontOption) {
      dimensionParams.push([bestFrontOption.width, bestFrontOption.aspectRatio, bestFrontOption.diameter])
    }
    if (bestRearOption) {
      dimensionParams.push([bestRearOption.width, bestRearOption.aspectRatio, bestRearOption.diameter])
    }

    if (resetDimension) {
      this.props.onSearchParamChange('tyreDimensions', resetDimension)
    } else {
      this.props.onSearchParamChange('tyreDimensions', dimensionParams)
    }

    this.props.onSearchParamChange('diameter', diameter)

    this.setState({
      bestFrontOption,
      bestRearOption,
      otherFrontOptions,
      otherRearOptions,
      selectedDiameter: diameter
    })
  }

  onCheckboxChange(value, position, isChecked) {
    const selectedTyreDimensions = this.props.selectedTyreDimensions
    const selectedFront = selectedTyreDimensions[0]
    const selectedRear = selectedTyreDimensions[1]

    const front = [this.state.bestFrontOption].concat(this.state.otherFrontOptions)
    const rear = [this.state.bestRearOption].concat(this.state.otherRearOptions)

    const newTyreDimensions = []
    // let newLoadIndex = ''
    // let newLoadIndexRear = ''
    // let newSpeedIndex = ''

    if (position === 'front') {
      const shouldUnCheckFront = (
        selectedFront &&
        String(value.width) === String(selectedFront[0]) &&
        String(value.aspectRatio) === String(selectedFront[1]) &&
        String(value.diameter) === String(selectedFront[2]) &&
        isChecked
      )

      if (!shouldUnCheckFront) {
        newTyreDimensions[0] = [value.width, value.aspectRatio, value.diameter]
        // newLoadIndex = value.loadIndex
        // newSpeedIndex = value.speedIndex
      }

      if (selectedRear) {
        const idx = rear.findIndex(
          v =>
            v &&
            String(selectedRear[0]) === String(v.width) &&
            String(selectedRear[1]) === String(v.aspectRatio) &&
            String(selectedRear[2]) === String(v.diameter)
        )
        if (idx >= 0) {
          // newLoadIndexRear = rear[idx].loadIndex
          // newSpeedIndex = rear[idx].speedIndex
          newTyreDimensions[1] = [rear[idx].width, rear[idx].aspectRatio, rear[idx].diameter]
        }
      }
    } else if (position === 'rear') {
      const shouldUnCheckRear = (
        selectedRear &&
        String(value.width) === String(selectedRear[0]) &&
        String(value.aspectRatio) === String(selectedRear[1]) &&
        String(value.diameter) === String(selectedRear[2]) &&
        isChecked
      )

      if (selectedFront) {
        const idx = front.findIndex(
          v =>
            v &&
            String(selectedFront[0]) === String(v.width) &&
            String(selectedFront[1]) === String(v.aspectRatio) &&
            String(selectedFront[2]) === String(v.diameter)
        )
        if (idx >= 0) {
          // newLoadIndex = front[idx].loadIndex
          // newSpeedIndex = front[idx].speedIndex
          newTyreDimensions[0] = [front[idx].width, front[idx].aspectRatio, front[idx].diameter]
        }
      }

      if (!shouldUnCheckRear) {
        newTyreDimensions[1] = [value.width, value.aspectRatio, value.diameter]
        // newLoadIndexRear = value.loadIndex
        // newSpeedIndex = value.speedIndex
      }
    }

    if (!newTyreDimensions.length) {
      // NOTE: Always default to at least one
      if (this.state.bestFrontOption) {
        const {bestFrontOption} = this.state
        // newLoadIndex = bestFrontOption.loadIndex
        // newSpeedIndex = bestFrontOption.speedIndex
        newTyreDimensions[0] = [bestFrontOption.width, bestFrontOption.aspectRatio, bestFrontOption.diameter]
      } else if (this.state.bestRearOption) {
        const {bestRearOption} = this.state
        // newLoadIndexRear = bestRearOption.loadIndex
        // newSpeedIndex = bestRearOption.speedIndex
        newTyreDimensions[1] = [bestRearOption.width, bestRearOption.aspectRatio, bestRearOption.diameter]
      }
    }

    this.props.onSearchParamChange('tyreDimensions', newTyreDimensions)
  }

  render() {
    const { selectedTyreDimensions, isWheelBuilder } = this.props
    const {
      showAllFrontAlternatives,
      showAllRearAlternatives,
      bestFrontOption,
      otherFrontOptions,
      bestRearOption,
      otherRearOptions,
      selectedDiameter,
      diameters
    } = this.state
    if (!bestFrontOption && !bestRearOption)
      return null

    const visibleFrontSizes = !!bestFrontOption && (showAllFrontAlternatives ?
      [ bestFrontOption, ...otherFrontOptions ] : [ bestFrontOption ])

    const visibleRearSizes = !!bestRearOption && (showAllRearAlternatives ?
      [ bestRearOption, ...otherRearOptions ] : [ bestRearOption ])

    return (
      <div className={classnames({
        [styles.alternatives]: isWheelBuilder,
        [styles.alternativesContainer]: !isWheelBuilder,
      }, 'tyre-alternatives')}>

        {(diameters.length > 1) && (
          <div className={styles.diametersWrapper}>
            {diameters.map(diameter => {
              const isChecked = selectedDiameter === diameter
              let className
              if (isChecked) {
                className = `border-colored background-light text-colored`
              } else {
                className = `border-muted text-muted`
              }

              return (
                <div onClick={() => this.handleDiameterChange(diameter)} className={`${className} ${styles.diameter}`}>
                  {!!isChecked && <FontAwesomeIcon className={styles.checkedIcon} icon={faCheck} />}
                  <div className={styles.label}>{diameter}"</div>
                </div>
              )
            })}
            <OverlayTrigger
              overlay={
                <Popover>
                  <div className={styles['search-help-wrapper']}>
                    <T id="Choose a tyre size" />
                  </div>
                </Popover>
              }
            >
              <button className="button-link">
                <FontAwesomeIcon className={styles.carInfoIcon} icon={faInfoCircle} />
              </button>
            </OverlayTrigger>
          </div>
        )}

        {
          !!visibleFrontSizes && (
            <div>
              <span className={styles.label}><T id="Front" /></span>
              {otherFrontOptions.length >= 1 && (
                <span>
                  <span> - </span>
                  <Link to="" onClick={this.handleFrontLinkClick}>
                    {
                      showAllFrontAlternatives ?
                        (<T id="Hide more" />) :
                        (<T id="Show more" />)
                    }
                  </Link>
                </span>
              )}
            </div>
          )
        }

        {!!visibleFrontSizes && visibleFrontSizes.map(alternative => {
          if (!alternative) return null
          const isChecked = selectedTyreDimensions[0] &&
            String(selectedTyreDimensions[0][0]) === String(alternative.width) &&
            String(selectedTyreDimensions[0][1]) === String(alternative.aspectRatio) &&
            String(selectedTyreDimensions[0][2]) === String(alternative.diameter)

          const className = alternative.deviationPercent === 0 ? styles.best : styles[alternative.deviationClass]
          const svgClassName = alternative.deviationPercent === 0 ? styles['svg-best'] : styles[`svg-${alternative.deviationClass}`]
          const deviationPercentage = (alternative.deviationPercent === null || alternative.deviationPercent === undefined) ? 'UNKNOWN' : alternative.deviationPercent.toFixed(2)
          return (
            <Checkbox
              inline
              onChange={(val) => this.handleCheckboxChange(val, 'front', isChecked)}
              value={alternative}
              key={`${alternative.text}-${alternative.position}`}
              label={`${alternative.text} (${deviationPercentage}%)`}
              className={className}
              isChecked={isChecked}
              name={alternative}
              svgClass={svgClassName}
            />
          )}
        )}

        {
          !!visibleRearSizes && (
            <div>
              <span className={styles.label}><T id="Rear" /></span>
              {otherRearOptions.length >= 1 && (
                <span>
                  <span> - </span>
                  <Link to="" onClick={this.handleRearLinkClick}>
                    {
                      showAllRearAlternatives ?
                        (<T id="Hide more" />) :
                        (<T id="Show more" />)
                    }
                  </Link>
                </span>
              )}
            </div>
          )
        }

        {!!visibleRearSizes && visibleRearSizes.map(alternative => {
          const isChecked = selectedTyreDimensions[1] &&
            String(selectedTyreDimensions[1][0]) === String(alternative.width) &&
            String(selectedTyreDimensions[1][1]) === String(alternative.aspectRatio) &&
            String(selectedTyreDimensions[1][2]) === String(alternative.diameter)
          const className = alternative.deviationPercent === 0 ? styles.best : styles[alternative.deviationClass]
          const svgClassName = alternative.deviationPercent === 0 ? styles['svg-best'] : styles[`svg-${alternative.deviationClass}`]
          const deviationPercentage = (alternative.deviationPercent === null || alternative.deviationPercent === undefined) ? 'UNKNOWN' : alternative.deviationPercent.toFixed(2)
          return (
            <Checkbox
              inline
              onChange={(val) => this.handleCheckboxChange(val, 'rear', isChecked)}
              value={alternative}
              key={alternative.text}
              label={`${alternative.text} (${deviationPercentage}%)`}
              className={className}
              isChecked={isChecked}
              name={alternative}
              svgClass={svgClassName}
            />
          )}
        )}

        {(!visibleRearSizes || !visibleFrontSizes) && (
          <div className={styles.noInfo}><T id="We currently don't have enough info for this model." /></div>
        )}
      </div>
    );
  }
}


const mapStateToProps = state => {
  // TODO: Refactor using helper or etc.
  const standard = getStandard(state)
  const { vehicle } = state.vehicle.vehicle ? state.vehicle : state.carPicker
  const _isWinter = isWinter(state.tyreSearchParams.tyreType)
  const _isAll = Array.isArray(state.tyreSearchParams.tyreType) ? (state.tyreSearchParams.tyreType.length === 1 && !state.tyreSearchParams.tyreType[0]) : !state.tyreSearchParams.tyreType

  let defaultSpeedIndex = standard && standard.front && standard.front.tyre && standard.front.tyre.speedIndex
  let defaultLoadIndexFront = standard && standard.front && standard.front.tyre && standard.front.tyre.loadIndex
  let defaultLoadIndexRear = standard && standard.rear && standard.rear.tyre && standard.rear.tyre.loadIndex

  try {
    const urlQuery = new URLSearchParams(window.location.search)
    const speedIndexFromCriteria = urlQuery.get('speedIndex')
    const _prevTyreType = urlQuery.get('tyreType')
    const _prevIsWinter = isWinter([_prevTyreType])

    if (speedIndexFromCriteria && (String(state.tyreSearchParams.tyreType[0]) === String(_prevTyreType) || _isWinter === _prevIsWinter)) {
      defaultSpeedIndex = speedIndexFromCriteria && speedIndexFromCriteria.toUpperCase()
    } else if ((_isWinter || _isAll) && vehicle && vehicle.tyreStandardSize && vehicle.tyreStandardSize.speedIndexWinter) {
      defaultSpeedIndex = vehicle.tyreStandardSize.speedIndexWinter
    }
    // TODO: Fix scneario where update query string and then refresh
    //       will make this sticky with new search query
    if (urlQuery.get('loadIndex')) {
      defaultLoadIndexFront = parseInt(urlQuery.get('loadIndex'), 10)
    }
    if (urlQuery.get('loadIndexRear')) {
      defaultLoadIndexRear = parseInt(urlQuery.get('loadIndexRear'), 10)
    }
  } catch(e) { /* Fail-safe */ }

  return {
    defaultSpeedIndex,
    defaultLoadIndexFront,
    defaultLoadIndexRear,
    selectedTyreDimensions: state.tyreSearchParams.tyreDimensions,
    selectedLoadIndexFront: state.tyreSearchParams.loadIndex,
    selectedLoadIndexRear: state.tyreSearchParams.loadIndexRear,
    selectedSpeedIndex: state.tyreSearchParams.speedIndex,
    selectedDiameter: parseInt(state.tyreSearchParams.diameter, 10),
    tyreSizes: getTyreAlternatives(state),
    standard,
    isWheelBuilder: state.wheelBuilder.showWheelProgress,
  }
}

export default connect(mapStateToProps)(TyreAlternatives);
