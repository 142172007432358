import React from 'react'
import styles from './styles.less'
import { StarSvg } from '../SvgIcon'

class StarRatingComponent extends React.Component{
  onStarClick(index) {
    const { readOnly, value, onChange } = this.props
    if (!readOnly) {
      if (index + 1 === value)
        onChange(0)
      else
        onChange(index + 1)
    }
  }

  render() {
    const { readOnly, value, starCount, height, width, className } = this.props
    const stars = []
    for (let i = 0; i < starCount; i++) {
      if (i < value)
        stars.push(
          <span onClick={() => this.onStarClick(i)} key={i}>
            <StarSvg className={`${styles.starSelected} starRating_selectedStar`} height={height} width={width}/>
          </span>
        )
      else
        stars.push(
          <span onClick={() => this.onStarClick(i)} key={i}>
            <StarSvg className={`${styles.star} starRating_star`} height={height} width={width}/>
          </span>
        )
    }
    const componentClassName = readOnly ? `${styles.wrapper} ${className} star-rating` : `${styles.clickable} ${className} star-rating`
    return (
      <div className={componentClassName}>
        {stars}
      </div>
    )
  }
}

export default StarRatingComponent
