import {
  SET_FORGOT_PASSWORD_PARAMETER,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  PASSWORD_CHANGE_DONE,
  PASSWORD_CHANGE_ERROR,
  TOKEN_VALID,
  TOKEN_ERROR,
  RESET_FORGOT_PASSWORD
} from '../actions/forgotPassword'

const getInitialState = () => {
  return {
    isPasswordResetStarted: false,
    isPasswordChanged: false,
    error: null,
    token: null,
    tokenError: null,
    email: '',
    password: '',
    confirmedPassword: ''
  }
}

const forgotPassword = (state = getInitialState(), action) => {
  switch(action.type) {
    case RESET_FORGOT_PASSWORD:
      return getInitialState()
    case SET_FORGOT_PASSWORD_PARAMETER:
      return Object.assign({}, state, { [action.name]: action.value })
    case REQUEST_PASSWORD_RESET:
      return Object.assign({}, state, { isPasswordResetStarted: false, error: false })
    case REQUEST_PASSWORD_RESET_SUCCESS:
      return Object.assign({}, state, { isPasswordResetStarted: true })
    case PASSWORD_RESET_ERROR:
      return Object.assign({}, state, { isPasswordResetStarted: false, error: action.error })
    case PASSWORD_CHANGE_ERROR:
      return Object.assign({}, state, { error: action.error })
    case PASSWORD_CHANGE_DONE:
      return Object.assign({}, state, {
        isPasswordChanged: true
      })
    case TOKEN_VALID:
      return Object.assign({}, state, { token: action.token, tokenError: null  })
    case TOKEN_ERROR:
      return Object.assign({}, state, { token: action.token, tokenError: action.error  })      
    default:
      return state
  }
}

export default forgotPassword

