import React from 'react'
import { Popover, Overlay, ControlLabel } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import StarRatingComponent from '../../../StarRatingComponent'
import styles from './styles.less'

export default class TestResults extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPopover: false
    }
  }

  onMouseEnter() {
    this.setState({
      showPopover: true
    })
  }

  onMouseLeave() {
    this.setState({
      showPopover: false
    })
  }

  render() {
    const { product, className } = this.props
    let popover
    if (!product.attrs.hasTestResult)
      return null

    const allTestResults = product.attrs.allTestResults.map((result, index) => {
      return (
        <div className={styles.flexIt} key={index}>
          {result.source}
          <StarRatingComponent
            readOnly
            className={styles.starRating}
            starCount={5}
            name={`result${index}`}
            value={result.rating}
            height="13"
            width="13"
          />
        </div>
      )
    })

    popover = (
      <Overlay placement="bottom" show={this.state.showPopover} target={this.popoverTarget}>
        <Popover placement="bottom" id="test-results-popover" className={`${styles.popover} background-normal`}>
          <ControlLabel className={styles.title}>
            <T id="Test results"/>
          </ControlLabel>
          {allTestResults}
        </Popover>
      </Overlay>
    )

    const componentClassName = className ? className : styles.starRating
    return (
      <div ref={el => this.popoverTarget = el} onMouseEnter={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)} className="test-results">
        <StarRatingComponent
          readOnly
          starCount={5}
          name="testScore"
          value={product.attrs.testResult}
          editing={false}
          className={componentClassName}
          height="13"
          width="13"
        />
        {popover}
      </div>
    )
  }
}
