import React from 'react'
import { injectIntl } from 'react-intl'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getOrder, setDelivered, undoDelivered } from '../../../actions/orders'
import { Link } from 'react-router-dom'
import OrderLines from './OrderLines'
import Spinner from '../Spinner'
import styles from './styles.less'
import { T } from 'components/components/i18n'
import {
  Row,
  Col,
  Popover,
  OverlayTrigger,
  Button,
} from 'react-bootstrap'
import { ORDER_TYPE } from '../../../constants/orderTypes'
import { api } from 'helpers/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faCheck, faDownload } from '@fortawesome/free-solid-svg-icons'
import { Checkbox } from 'components/components/Checkbox'
import { pdf as reactPdf } from '@react-pdf/renderer';
import Pdf from './pdf'

export class Order extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      downloadError: '',
      deliveredState: 0,
      shouldRenderPdf: false,
    }
    this.handleSetDelivered = this.setDelivered.bind(this)
    this.handleUndoDelivered = this.undoDelivered.bind(this)
    this.handleDownloadPdf = this.downloadPdf.bind(this)
  }

  componentDidMount() {
    const { shop, getOrder } = this.props
    if (shop.id)
      document.title = `Mina ordrar - ${shop.title}`
      getOrder(this.props.match.params.orderId, shop)
  }

  downloadInvoice(event, invoice) {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      downloadError: ''
    })
    const url = `${this.props.shop.backendUrl}${invoice.url}`
    return api(url)
      .then( res => res.blob() )
      .then( data => {
        var downloadError = ''
        try {
          if (data && data.type === 'application/pdf') {
            var file = window.URL.createObjectURL(data)
            var a = document.createElement('a')
            a.href = file
            a.download = `${invoice.code}-${invoice.number}`
            document.body.appendChild(a) // For FF browser
            a.click()
            a.remove()
          } else if (data && data.type === 'application/json') {
            var that = this
            var reader = new FileReader()
            reader.onload = function() {
              var res = this.result && this.result
                ? JSON.parse(this.result)
                : {}
              if (res.err) {
                that.setState({
                  downloadError: res.err
                })
              } else {
                that.setState({
                  downloadError: 'Unable to download invoice'
                })
              }
            }
            reader.onerror = function() {
              that.setState({
                downloadError: 'Unable to download invoice'
              })
            }
            reader.readAsText(data)
          } else {
            throw new Error('Invoice not found')
          }
        } catch(e) {
          downloadError = 'Unable to download invoice'
        } finally {
          if (downloadError) {
            this.setState({
              downloadError
            })
          }
        }
      })
  }

  downloadPdf = async () => {
    const {
      order,
      hasDirectDelivery,
      showSupplier,
      intl,
    } = this.props

    // NOTE: Wrapping Pdf component with other providers will throw error,
    //       so pass what we need through here
    const pdfConfig = {
      hasDirectDelivery,
      showSupplier,
      intl,
    }
    const blob = await reactPdf((
      <Pdf order={order} config={pdfConfig} />
    )).toBlob()

    const a = document.createElement('a')
    a.download = `order_${order.id}.pdf`
    a.href = URL.createObjectURL(blob)
    // NOTE: Some hack to free up memory when working with blob
    a.addEventListener('click', () => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
    })
    a.click()
  }

  setDelivered(orderId) {
    this.setState((prevState) => {
      prevState.deliveredState = 1
      return prevState
    })
    this.props.setDelivered(this.props.shop, orderId)
      .then((res) => {
        if (!res) {
          this.setState((prevState) => {
            prevState.deliveredState = 0
            return prevState
          })
        } else {
          this.setState((prevState) => {
            prevState.deliveredState = 2
            return prevState
          })
        }
      })
  }

  undoDelivered(orderId) {
    this.setState((prevState) => {
      prevState.deliveredState = 1
      return prevState
    })
    this.props.order.delivered = 0
    this.props.undoDelivered(this.props.shop, orderId)
      .then((res) => {
        if (!res) {
          this.setState((prevState) => {
            prevState.deliveredState = 2
            return prevState
          })
        } else {
          this.setState((prevState) => {
            prevState.deliveredState = 0
            return prevState
          })
        }
      })
  }

  render() {
    const {
      order,
      isLoading,
    } = this.props

    if (isLoading)
      return <Spinner />
    if (!order.id)
      return <div />
    let licenseplateTitle
    if (order.car_licenseplate)
      licenseplateTitle = <T id="License plate number"/>
    let bookingStartTitle
    if (order.booking_start_at)
      bookingStartTitle = <T id="Booking"/>
    let invoices
    if (order.invoices.length> 0)
      invoices = order.invoices.map(invoice => {
        return (
          <React.Fragment>
            <Link key={invoice.id} onClick={(e) => this.downloadInvoice(e, invoice)} to="">
              <T id="Invoice number"/> {invoice.number}
            </Link>
            {!!this.state.downloadError && (
              <span className={`${styles.downloadError}`}>
                <T id={this.state.downloadError} />
              </span>

            )}
          </React.Fragment>
        )
      })
    else
      invoices = <T id="Not billed yet"/>

    return (
      <div className={`${styles.container} container order`}>
        <div className={styles.orderHeader}>
          <h1><T id="Order"/> {order.id}</h1>
          <button onClick={this.downloadPdf} className={`button-link ${styles.download}`}>
            <T id="Download" />
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
        <div className={styles.orderInfo}>
          <Row className={styles.orderRow}>
            <Col xs={2}>
              <b><T id="Delivered" /></b>
            </Col>
            <Col xs={10}>
              <div className={styles.delivered}>
                {(!!order.delivered || this.state.deliveredState === 2) && (
                  <React.Fragment>
                    <FontAwesomeIcon icon={faCheck} />
                    <Button
                      onClick={() => this.handleUndoDelivered(order.id)}
                      bsSize="xsmall"
                      className={styles.undo}
                    >
                      <T id="Undo"/>
                    </Button>
                  </React.Fragment>
                )}
                {(!order.delivered && this.state.deliveredState !== 2) && (
                  <React.Fragment>
                    <Checkbox
                      onChange={() => this.handleSetDelivered(order.id)}
                    />
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Popover>
                          <T id="Not delivered" />.&nbsp;
                          <T id="Click checkbox to mark order is delivered" />
                        </Popover>
                      }
                    >
                      <button className={`button-link ${styles['deliveredHelp']}`}>
                        <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />
                      </button>
                    </OverlayTrigger>
                  </React.Fragment>
                )}
                {this.state.deliveredState === 1 && (
                  <React.Fragment>
                    <Spinner inline={true} /> &nbsp;
                  </React.Fragment>
                )}
              </div>
            </Col>
          </Row>
          <Row className={styles.orderRow}>
            <Col xs={2}>
              <b><T id="Status" /></b>
            </Col>
            <Col xs={10}>
              {order.formattedStatus}
            </Col>
          </Row>
          {!!order.car_licenseplate && (
            <Row className={styles.orderRow}>
              <Col xs={2}>
                <b>{licenseplateTitle}</b>
              </Col>
              <Col xs={10}>
                {order.car_licenseplate}
              </Col>
            </Row>
          )}
          <Row className={styles.orderRow}>
            <Col xs={2}>
              <b><T id="Reference" /></b>
            </Col>
            <Col xs={10}>
              {order.customer_reference || 'nbsp;'}
            </Col>
          </Row>
          {!!order.booking_start_at && (
            <Row className={styles.orderRow}>
              <Col xs={2}>
                <b>{bookingStartTitle}</b>
              </Col>
              <Col xs={10}>
                {order.booking_start_at}
              </Col>
            </Row>
          )}
          <Row className={styles.orderRow}>
            <Col xs={2}>
              <b><T id="Created at"/></b>
            </Col>
            <Col xs={10}>
              {order.created_at}
            </Col>
          </Row>
          {!!order.is_show_invoice && (
            <Row className={styles.orderRow}>
              <Col xs={2}>
                <b><T id="Invoices"/></b>
              </Col>
              <Col xs={10}>
                {invoices}
              </Col>
            </Row>
          )}
          {order.type === ORDER_TYPE.PREORDER && (
            <Row className={styles.orderRow}>
              <Col xs={2}>
                <b><T id="Type"/></b>
              </Col>
              <Col xs={10}>
                {order.type === ORDER_TYPE.PREORDER && <T id="Pre order"/>}
              </Col>
            </Row>
          )}
        </div>
        <OrderLines order={order}/>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrder: (orderId, shop) => getOrder(orderId, shop),
  setDelivered: (shop, orderId) => setDelivered(shop, orderId),
  undoDelivered: (shop, orderId) => undoDelivered(shop, orderId),
}, dispatch)

const mapStateToProps = state => {
  return {
    order: state.orders.currentOrder,
    isLoading: state.orders.isCurrentOrderLoading,
    shop: state.shop,
    hasDirectDelivery: state.shop.hasDirectDelivery,
    showSupplier: state.shop.showSupplierInSearchResults,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Order))
