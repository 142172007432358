// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__blueWrapper___clde8 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
  background: #8dd7cf;
}
@media (max-width: 767px) {
  .styles__blueWrapper___clde8 {
    flex-direction: column;
    align-items: flex-start;
  }
  .styles__blueWrapper___clde8 > div {
    line-height: 1.5;
  }
}
.styles__yellowWrapper___wBscH {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
  background: #fbe192;
}
@media (max-width: 767px) {
  .styles__yellowWrapper___wBscH {
    flex-direction: column;
    align-items: flex-start;
  }
  .styles__yellowWrapper___wBscH > div {
    line-height: 1.5;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/components/PreOrderStatusBar/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;AADF;AAEE;EAAA;IACE,sBAAA;IACA,uBAAA;EACF;EAHA;IAII,gBAAA;EAEJ;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;AAAF;AACE;EAAA;IACE,sBAAA;IACA,uBAAA;EAEF;EAJA;IAII,gBAAA;EAGJ;AACF","sourcesContent":[" @import '../../../shared/variables.less';\n\n.blueWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 20px;\n  margin-top: 20px;\n  background: #8dd7cf;\n  @media(max-width: @screen-xs-max) {\n    flex-direction: column;\n    align-items: flex-start;\n    > div {\n      line-height: 1.5;\n    }\n  }\n}\n\n.yellowWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 20px;\n  margin-top: 20px;\n  background: #fbe192;\n  @media(max-width: @screen-xs-max) {\n    flex-direction: column;\n    align-items: flex-start;\n    > div {\n      line-height: 1.5;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blueWrapper": `styles__blueWrapper___clde8`,
	"yellowWrapper": `styles__yellowWrapper___wBscH`
};
export default ___CSS_LOADER_EXPORT___;
