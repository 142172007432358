import React from 'react'
import { connect } from 'react-redux'
import { Carousel } from 'react-bootstrap'
import styles from './styles.less'
import classnames from 'classnames'

export class Banner extends React.Component {
  render () {
    const { shop } = this.props
    if (!shop.style.banners || !shop.style.banners.length)
      return null
    const items = shop.style.banners.map(banner => {
      if (banner.clickLink)
        return (
          <Carousel.Item key={`banner_${banner.imageUrl}`} className="banner-item">
            <a href={banner.clickLink}>
              <img src={banner.imageUrl} className={styles.image} alt="Banner" />
            </a>
          </Carousel.Item>
        )
      return (
        <Carousel.Item key={`banner_${banner.imageUrl}`} className="banner-item">
          <img src={banner.imageUrl} className={styles.image} alt="Banner" />
        </Carousel.Item>
      )
    })
    return (
      <Carousel controls={false} indicators={false} className={classnames('banner', styles.banner)}>
        {items}
      </Carousel>
    )
  }
}

const mapStateToProps = state => {
  return {
    shop: state.shop
  }
}

export default connect(
  mapStateToProps
)(Banner)
