import React from 'react'
import { T } from 'components/components/i18n'
import RadioButton from '../../RadioButton'
import styles from './styles.less'

export const Tpms = ({ value, onChange }) => {

    return (
        <section className={styles.addTpms} >
          <div>
            <h4 data-toggle="tooltip" data-placement="top" data-trigger="hover" data-title="TPMS, Tire Pressure Monitoring System, eller aktiv däcktrycksövervakning, innebär att det sitter en sensor inne i hjulet som skickar signal med däckets lufttryck och temperatur som kan läsas av på bilens instrumentpanel.">
              <T id="Do you need TPMS?"/>
              <i className="fa fa-info-circle"></i>
            </h4>
            <div>
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <div className={`row ${styles.radioButtonGroup}`}>
                    <div className="col-xs-6">
                      <RadioButton
                        name="rimType"
                        value={1}
                        key="add-tpms-1"
                        isChecked={value === 1}
                        onChange={() => onChange(1)}
                        label={<T id="Yes thank you"/>}
                      />
                    </div>
                    <div className="col-xs-6">
                      <RadioButton
                        name="rimType"
                        value={0}
                        key="add-tpms-0"
                        isChecked={value === 0}
                        onChange={() => onChange(0)}
                        label={<T id="No thank you"/>}
                      />
                    </div>
                  </div>
                  <span className={styles.tpmsInfo}>
                    <T id="The fee for TPMS is added to the final invoice"/>
                  </span>
                </div>
              </div>
            </div>
            {/* {% if form.errors.addTpms %}
                <p class="help-block">Du måste göra ett val</p>
            {% endif %} */}
          </div>
    </section>
    )
}

export default Tpms
