import React from 'react'
import * as $ from 'jquery';
import { connect } from 'react-redux'
import { sendEmail } from '../../../actions/user'

class FeedbackWidget extends React.Component {
  constructor(props) {
    super(props)
    window.jQuery = $;
    window.$ = $;
  }
  componentDidMount() {
    let SuperFeedbackSettings = {
      icon: '//superfeedback.eonbit.com/icon.png',
      submit:  (data, next) => sendEmail(data, next),
      mailTo: 'support@eontyre.com',
      customData: {
        user: {
          id: (this.props.user && this.props.user.id) || null,
          email: (this.props.user && this.props.user.email) || '',
          name: (this.props.user && this.props.user.name) || '',
        },
        session: this.props.sessionId,
        site: document.location.hostname,
        project: 'eontyre'
      },
      annotate: {
        texts: {
          EnterComment: 'Enter comment...'
        }
      }

    };
    let s = document.createElement('script');
    s.src = '//superfeedback.eonbit.com/combined.js';
    s.onload = s.onreadystatechange = function() {
      let rs = this.readyState; if (rs) if (rs !== 'complete') if (rs !== 'loaded') return
      setTimeout(function(){
        try {
          new window.SuperFeedback(SuperFeedbackSettings);
        } catch (e) {
          console.error('sfb.load.err:', e);
        }
      }, 500);
    };
    let scr = document.getElementsByTagName('script')[0], par = scr.parentNode; par.insertBefore(s, scr);
  }

  render() {
    return (
      <div>
        <link rel="stylesheet" type="text/css" href="//superfeedback.eonbit.com/combined.css"/>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    sessionId: state.user.sessionId
  }
}

export default connect(
  mapStateToProps
)(FeedbackWidget)
