import { push } from 'react-router-redux'
import qs from 'query-string'

export const updateQueryParams = (currentQuery, newQueryParams, removeParams) => {
  const cleanQuery = Object.entries(qs.parse(currentQuery, { arrayFormat: 'bracket' }))
  .reduce((acc, [key, value]) => {
    // NOTE: Remove any keys that already have bracket notation
    //       Preventing situations where [] keeps appending e.g. params[][][]
    const cleanKey = key.replace(/\[+\]*/g, '');
    acc[cleanKey] = value;
    return acc;
  }, {})
  if (removeParams)
    removeParams.forEach(param => delete cleanQuery[param])
  const params = Object.assign({}, cleanQuery, newQueryParams)
  // NOTE: Backend handle array with bracket notation, so we use this
  return push({
    search: qs.stringify(params, { arrayFormat: 'bracket' })
  })
}