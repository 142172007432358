import React from 'react'
import { connect } from 'react-redux'
import { SEARCH_MODES } from '../../../../../constants/searchModes'
import  VehicleSearch from '../../VehicleSearch'
import styles from './styles.less'
import Query from './Query'
import classnames from 'classnames'
class SearchMode extends React.Component {
  render() {
    const {
      onFieldChange,
      query,
      selectedSearchMode,
      onEnterPress,
      urlQuery
    } = this.props

    let component
    if ([SEARCH_MODES.combined, SEARCH_MODES.dimension, SEARCH_MODES.licensePlate].indexOf(selectedSearchMode) !== -1) {
      component = <Query value={query} selectedSearchMode={selectedSearchMode} onChange={onFieldChange} onEnterPress={onEnterPress}/>
    } else {
      component = <VehicleSearch className={styles.component} inputClassName={styles.input} query={urlQuery}/>
    }

    return (
      <div className={classnames(styles.wrapper, 'search-mode')}>
        {component}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    shop: state.shop
  }
}

export default connect(
  mapStateToProps
)(SearchMode)
