// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__options___ZB4y9 {
  padding-top: 16px;
}
.styles__radioGroup___N_R4I {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .styles__radioGroup___N_R4I {
    justify-content: flex-start;
  }
}
.styles__leftOption___egRTF {
  margin-right: 8px;
}
.styles__pcsGroup___ChQzA {
  display: flex;
  margin-right: 32px;
}
.styles__priceTypeGroup___zn7t4 {
  margin-top: 6px;
}
.styles__icon___byGdl {
  font-size: 18px;
  position: relative;
  top: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchResults/PriceOptions/styles.less"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;AAIA;EACE,aAAA;EACA,yBAAA;AAFF;AAGE;EAAA;IACE,2BAAA;EAAF;AACF;AAGA;EACE,iBAAA;AADF;AAIA;EACE,aAAA;EACA,kBAAA;AAFF;AAKA;EACE,eAAA;AAHF;AAMA;EACE,eAAA;EACA,kBAAA;EACA,QAAA;AAJF","sourcesContent":["@import '../../../../../shared/variables.less';\n\n.options {\n  padding-top: 16px;\n}\n\n.radioGroup {\n  display: flex;\n  justify-content: flex-end;\n  @media (max-width: @screen-sm-max) {\n    justify-content: flex-start;\n  }\n}\n\n.leftOption {\n  margin-right: 8px;\n}\n\n.pcsGroup {\n  display: flex;\n  margin-right: 32px;\n}\n\n.priceTypeGroup {\n  margin-top: 6px;\n}\n\n.icon {\n  font-size: 18px;\n  position: relative;\n  top: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"options": `styles__options___ZB4y9`,
	"radioGroup": `styles__radioGroup___N_R4I`,
	"leftOption": `styles__leftOption___egRTF`,
	"pcsGroup": `styles__pcsGroup___ChQzA`,
	"priceTypeGroup": `styles__priceTypeGroup___zn7t4`,
	"icon": `styles__icon___byGdl`
};
export default ___CSS_LOADER_EXPORT___;
