// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__list___ecBX3 {
  display: flex;
  justify-content: center;
}
.styles__page___FpUGo {
  padding: 6px 12px;
  border: 1px solid #ddd;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchResults/Pagination/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;AAEA;EACE,iBAAA;EACA,sBAAA;AAAF","sourcesContent":[".list {\n  display: flex;\n  justify-content: center;\n}\n\n.page {\n  padding: 6px 12px;\n  border: 1px solid #ddd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `styles__list___ecBX3`,
	"page": `styles__page___FpUGo`
};
export default ___CSS_LOADER_EXPORT___;
