import { api } from '../../helpers/api'
import { fetchSupplierByTargetId } from '../../helpers/preOrder'

export const PREORDER_TARGET_ID_RETRIEVED = 'PREORDER_TARGET_ID_RETRIEVED'
export const LOADING_PREORDER_DATA = 'LOADING_PREORDER_DATA'
export const PREORDER_DATA_RETRIEVED = 'PREORDER_DATA_RETRIEVED'

export const removeTargetId = () => {
  return {
    type: PREORDER_DATA_RETRIEVED,
    targetId: null,
  }
}

export const setPreorderTargetId = id => {
  return (dispatch, getState) => {
    api(`${getState().shop.apiUrl}/store`, 'post', { key: 'preOrderTargetId', value: id })
      .then(response => response.json())
      .then(response => {
        const targetId = response.data.value
        dispatch({
          type: PREORDER_TARGET_ID_RETRIEVED,
          targetId,
        })
      })
  }
}

export const initializePreorderData = () => {
  return (dispatch, getState) => {
    dispatch({
      type: LOADING_PREORDER_DATA,
      data: true,
    })
    const { shop } = getState()
    return Promise.all([
      api(`${shop.apiUrl}/store?key=preOrderTargetId`).then(res => res.json()),
      api(`${shop.apiUrl}/pre-order/agreements`).then(res => res.json()),
    ]).then(responses => {
      if (responses.length === 2 && responses[1].data) {
        const targetId = responses[0].data.value
        const suppliers = responses[1].data.agreements
        const supplier = fetchSupplierByTargetId(suppliers, targetId)
        if (supplier)
          dispatch({
            type: PREORDER_DATA_RETRIEVED,
            targetId,
            suppliers: responses[1].data.agreements,
            header: responses[1].data.header,
            footer: responses[1].data.footer,
          })
        else {
          dispatch(setPreorderTargetId(null))
          dispatch({
            type: PREORDER_DATA_RETRIEVED,
            suppliers: responses[1].data.agreements,
            header: responses[1].data.header,
            footer: responses[1].data.footer,
          })
        }
      }
    })
  }
}
