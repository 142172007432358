import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import { FormattedNumber } from 'react-intl'
import { T } from 'components/components/i18n'
import OrderLine from '../OrderLine'
import styles from './styles.less'

const OrderLines = (props) => {
  console.log('props:',props)
  const { order, hasDirectDelivery, showSupplier } = props

  const products = order.lines.map(line => (
    <OrderLine key={line.id} line={line} hasDirectDelivery={hasDirectDelivery} hasDiscount={order.hasDiscount} showSupplier={showSupplier} />
  ))

  const headers = [
    <th key="product-number"><T id="Product number"/></th>,
    <th key="name"><T id="Benämning"/></th>,
    <th key="eu-grading" className={styles.euGradingHead}><T id="Eu Grading"/></th>
  ]

  if (hasDirectDelivery)
    headers.push(<th key="shippings"><T id="Shippings"/></th>)
  if (showSupplier) {
    headers.push(<th key="supplier-name"><T id="Supplier"/></th>)
    headers.push(<th key="supplier-id" className={styles.supplierHead}><T id="Supplier order id"/></th>)
  }
  headers.push(<th key="note"><T id="Note"/></th>)
  headers.push(<th key="quantity" className={styles.number}><T id="Items"/></th>)
  if (order.hasDiscount)
    headers.push(
      <th key="price-pc" className={styles.number}><T id="Price/pc"/></th>,
      <th key="discount" className={styles.number}><T id="Discount"/></th>
    )
  headers.push(<th key="price" className={styles.number}><T id="Price"/></th>)

  const footerColspan = headers.length - 1

  return (
    <Table className={styles.table} striped>
      <thead>
        <tr>{headers}</tr>
      </thead>
      <tbody>{products}</tbody>
      <tfoot>
        <tr key="row-1">
          <td colSpan={footerColspan} key="ex-moms" className={`${styles.footTdPrefix} ${styles.footPadding}`}>
            <T id="OrderLines.excludingVat" />
          </td>
          <td key="nett" className={`${styles.number} ${styles.footPadding}`}>
            <FormattedNumber
              value={order.nett}
              style={`decimal`}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </td>
        </tr>
        <tr key="row-2">
          <td colSpan={footerColspan} key="moms" className={`${styles.footTdPrefix} ${styles.noBorder} ${styles.footPadding}`}>
            <T id="OrderLines.vat" />
          </td>
          <td  key="vat" className={`${styles.noBorder} ${styles.number} ${styles.footPadding}`}>
            <FormattedNumber
              value={order.vat}
              style={`decimal`}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </td>
        </tr>
        <tr key="row-3">
          <td colSpan={footerColspan} key="inkl-moms" className={`${styles.footTdPrefix} ${styles.noBorder} ${styles.footPadding}`}>
            <T id="OrderLines.includingVat" />
          </td>
          <td key="gross" className={`${styles.tdGross} ${styles.footPadding}`}>
            <FormattedNumber
              value={order.gross}
              style={`decimal`}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </td>
        </tr>
      </tfoot>
    </Table>
  )
}

const mapStateToProps = state => {
  return {
    hasDirectDelivery: state.shop.hasDirectDelivery,
    showSupplier: state.shop.showSupplierInSearchResults
  }
}

export default connect(
  mapStateToProps
)(OrderLines)
