import { api } from '../../helpers/api'

import { push } from 'react-router-redux';
import { updateQueryParams } from '../../helpers/routing'
import qs from 'query-string'
import { CATEGORIES } from '../../helpers/categories'
import { SUBTYPES } from '../../constants/tyreTypes'
import { SEARCH_MODES, SEARCH_MODE_BY_VEHICLE_TYPE } from '../../constants/searchModes'
import { getBestOption } from '../../helpers/tyreAlternatives'
import { filteredSubTyreTypes } from 'helpers/tyreTypes'

export const SEARCH_RESULTS_RETRIEVED = 'SEARCH_RESULTS_RETRIEVED'
export const RETRIEVE_SEARCH_RESULTS = 'RETRIEVE_SEARCH_RESULTS'
export const SET_TYRE_SEARCH_PARAMETERS = 'SET_TYRE_SEARCH_PARAMETERS'
export const SET_TYRE_SEARCH_PARAMETER = 'SET_TYRE_SEARCH_PARAMETER'
export const SET_RIM_SEARCH_PARAMETERS = 'SET_RIM_SEARCH_PARAMETERS'
export const SET_RIM_SEARCH_PARAMETER = 'SET_RIM_SEARCH_PARAMETER'
export const SET_COMPLETE_WHEEL_SEARCH_PARAMETERS = 'SET_COMPLETE_WHEEL_PARAMETERS'
export const SET_COMPLETE_WHEEL_SEARCH_PARAMETER = 'SET_RIM_SEARCH_PARAMETER'
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE'
export const SEARCH_MODE_CHANGED = 'SEARCH_MODE_CHANGED'
export const RESET_SEARCH = 'RESET_SEARCH'
export const SEARCH_ERROR = 'SEARCH_ERROR'
export const SET_IS_SEARCH_FORM_VALID = 'SET_IS_SEARCH_FORM_VALID'
export const RESULTS_PER_PAGE = 24;

export const receiveResults = (response, page, category, sortBy) => {
  return  {
    type: SEARCH_RESULTS_RETRIEVED,
    products: response.data.products || [],
    rearProducts: response.data.rearProducts || [],
    criteria: response.data.criteria,
    count: response.count,
    page: parseInt(page, 10),
    category,
    sortBy
  }
}

export const initializeSearchParams = (query, category, currentSeason, diameter, shop, isWheelBuilder) => {
  let type
  switch(category.id){
    case CATEGORIES.RIM.id:
      type = SET_RIM_SEARCH_PARAMETERS
      break
    case CATEGORIES.TYRE.id:
      type = SET_TYRE_SEARCH_PARAMETERS
      break
    case CATEGORIES.COMPLETE_WHEEL.id:
      type = SET_COMPLETE_WHEEL_SEARCH_PARAMETERS
      break
    default:
      type = null
  }
  const params = qs.parse(query, { arrayFormat: 'bracket' })
  if (!params.diameter)
    params.diameter = diameter

  return (dispatch, getState) => {
    const { vehicle } = getState().vehicle
    if (category.id === CATEGORIES.RIM.id) {
        // NOTE: Default isFixedCentreBore to true
        params.isFixedCentreBore = true
    }
    if (category.id === CATEGORIES.RIM.id && isWheelBuilder) {
      if (!params.licenseplate) {
        params.licenseplate = vehicle ? vehicle.licenseplate : null
      }
    }
    if (category.id === CATEGORIES.TYRE.id && isWheelBuilder) {
      if (!params.query) {
        params.query = vehicle ? vehicle.licenseplate : null
      }
      // NOTE: While at wheel builder, setup tyre dimensions with speed and load index
      //       and staggered fitment where possible
      if (vehicle) {
        const bestOptionFront = getBestOption(vehicle.tyreSizes, diameter, 'front')
        if (bestOptionFront) {
          params.tyreDimensions = [
            [bestOptionFront.width, bestOptionFront.aspectRatio, bestOptionFront.diameter]
          ]
        }
        const bestOptionRear = getBestOption(vehicle.tyreSizes, diameter, 'rear')
        if (bestOptionRear) {
          params.tyreDimensions.push([bestOptionRear.width, bestOptionRear.aspectRatio, bestOptionRear.diameter])
        }
      }
    }
    if (
      isWheelBuilder &&
      !params.vehicleId &&
      vehicle &&
      (vehicle.vehicleId && !vehicle.licenseplate)
    ) {
      // NOTE: If vehicleId is not in URI and it is available from state,
      //       pass it back via params so when we init parameters again
      //       it won't be set to blank during complete wheel search
      //       except if we have a licenseplate we continue with licenseplate
      params.vehicleId = vehicle.vehicleId;
    }
    if (getState().preOrder.targetId) {
      params.minQuantityInStock = '0'
    }
    dispatch({
      type,
      parameters: params,
      currentSeason,
      category,
      shop,
      isWheelBuilder,
    })
  }
}

export const changeSearchParams = (params, category) => {
  let type
  switch(category.id){
    case CATEGORIES.RIM.id:
      type = SET_RIM_SEARCH_PARAMETER
      break
    case CATEGORIES.TYRE.id:
      type = SET_TYRE_SEARCH_PARAMETER
      break
    case CATEGORIES.COMPLETE_WHEEL.id:
      type = SET_COMPLETE_WHEEL_SEARCH_PARAMETER
      break
    default:
      type = null
  }
  return dispatch => {
    dispatch({
      type,
      params
    })
  }
}

export const search = (tyreSearchParams, rimSearchParams, completeWheelSearchParams, category, shop, sortBy, page, isWheelBuilder, rim, query, setSubmit) => {
  let searchParams, isMCVehicleType
  switch(category.id) {
    case CATEGORIES.RIM.id:
      searchParams = { ...rimSearchParams }
      break
    case CATEGORIES.TYRE.id:
      searchParams = { ...tyreSearchParams, isMCVehicleType: tyreSearchParams.vehicleType.split(',').indexOf('1') > -1 }
      break
    case CATEGORIES.COMPLETE_WHEEL.id:
      searchParams = { ...completeWheelSearchParams }
      break
    default:
      searchParams = null
  }

  return (dispatch, getState) => {
    const targetId = getState().preOrder.targetId
    if (targetId) {
      searchParams.preorder = targetId
    }
    let searchCategory = category

    // NOTE: Hacky way to fit front and rear
    // TODO: Make it less hacky
    // if (
    //   searchParams.tyreDimensions.length === 2 && (
    //     !(searchParams.tyreDimensions[0] && searchParams.tyreDimensions[0][0]) ||
    //     !(searchParams.tyreDimensions[1] && searchParams.tyreDimensions[1][0])
    // )) {
    //   if (!(searchParams.tyreDimensions[0] && searchParams.tyreDimensions[0][0])) {
    //     searchParams.tyreDimensions = [searchParams.tyreDimensions[1]]
    //   } else {
    //     searchParams.tyreDimensions = [searchParams.tyreDimensions[0]]
    //   }
    // }

    if (category.id === CATEGORIES.TYRE.id && (!searchParams.tyreDimensions || searchParams.tyreDimensions.length === 0)) {
      const { vehicle } = getState().vehicle
      if (vehicle && vehicle.tyreSizes) {
        if (tyreSearchParams.diameter)
          searchParams.tyreDimensions = vehicle.tyreSizes.filter(size => (
            size.diameter.toString() === tyreSearchParams.diameter.toString()
          )).map(size => [
            size.width.toString(),
            size.aspectRatio.toString(),
            size.diameter.toString(),
          ])
        else
          searchParams.tyreDimensions = vehicle.tyreSizes.map(size => [
            size.width.toString(),
            size.aspectRatio.toString(),
            size.diameter.toString(),
          ])
      }
    }
    if (isWheelBuilder && !!rim)
      searchParams.rimProductId = rim.productId
    const searchQuery = getSearchQuery(searchParams, searchCategory, shop, sortBy, page, isMCVehicleType)
    const submit = (setSubmit || parseInt(qs.parse(query).submit, 10) === 1 || (isWheelBuilder && !!rim)) ? 1 : 0
    if (submit) {
      dispatch({
        type: RETRIEVE_SEARCH_RESULTS,
        category
      })

      return api(`${shop.apiUrl}/products?${searchQuery}`)
        .then(response => response.json())
        .then(response=> {
          // Start: Switch to correct category page if at wrong category and when search by #XXXXX
          let isRedirect = true, canSwitchMode = false, redirectSearchParams, redirectCategory, redirectType
          const q = searchParams?.query
          if (
            !isWheelBuilder &&
            q?.startsWith('#') &&
            response?.data?.products?.length === 1 &&
            response?.data?.products?.[0]?.type?.id &&
            response?.data?.products?.[0]?.type?.id !== category?.filters?.typeId
          ) {
            switch (response.data.products[0].type.id) {
              case CATEGORIES.RIM.filters.typeId:
                redirectSearchParams = { ...rimSearchParams }
                redirectCategory = CATEGORIES.RIM
                redirectType = SET_RIM_SEARCH_PARAMETER
                canSwitchMode = shop.searchModesRims.includes(SEARCH_MODES.combined)
                break
              case CATEGORIES.TYRE.filters.typeId:
                redirectSearchParams = { ...tyreSearchParams }
                redirectCategory = CATEGORIES.TYRE
                redirectType = SET_TYRE_SEARCH_PARAMETER
                canSwitchMode = shop.searchModesTyres.includes(SEARCH_MODES.combined)
                break
              case CATEGORIES.COMPLETE_WHEEL.filters.typeId:
                redirectSearchParams = { ...completeWheelSearchParams }
                redirectCategory = CATEGORIES.COMPLETE_WHEEL
                redirectType = SET_COMPLETE_WHEEL_SEARCH_PARAMETER
                canSwitchMode = true
                break
              default:
                isRedirect = false
            }
            if (isRedirect) {
              searchParams = redirectSearchParams
              category = redirectCategory
              searchParams.query = q
              dispatch({
                type: RETRIEVE_SEARCH_RESULTS,
                category
              })
              dispatch(push(`/${category.slug}`))
            }
          } else {
            isRedirect = false
          }
          // End: Switch to correct category page if at wrong category and when search by #XXXXX

          if (
            (response.data.car && !getState().vehicle.vehicle)
            || (!response.data.car && getState().vehicle.vehicle)
            || (response.data.car && getState().vehicle.vehicle && (response.data.car.vehicleId !== getState().vehicle.vehicle.vehicleId))
          ) {
            const hasStaggeredDimensions = response?.data?.criteria?.tyre_dimensions_rear?.length === 1 &&
              response?.data?.criteria?.tyre_dimensions?.length === 1
            dispatch({
              type: UPDATE_VEHICLE,
              vehicle: response.data.car || {},
              staggeredDimensions: hasStaggeredDimensions
                ? [
                  response.data.criteria.tyre_dimensions[0],
                  response.data.criteria.tyre_dimensions_rear[0]
                ]
                : []
            })
          }
          dispatch(receiveResults(response, page || 1, category, sortBy))
          const newQueryParams = Object.assign(filterQueryParameters(searchParams), { sortBy, page, submit })
          dispatch(updateQueryParams(query, newQueryParams))
          // NOTE: Switch to correct category page if at wrong category and when search by #XXXXX
          if (isRedirect) {
            if (canSwitchMode) {
              dispatch(changeSearchMode(SEARCH_MODES.combined, newQueryParams))
            }
            dispatch({
              type: redirectType,
              params: {
                query: q
              }
            })
          }
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: SEARCH_ERROR,
            category,
            error: err.message // todo: use backend's error
          })
        })
    }
  }
}

export const changeSearchMode = (searchMode, query) => {
  const vehicleSearchParams = [ 'manufacturerId', 'modelId', 'vehicleId', 'query', 'width', 'boltPattern', 'et', 'licenseplate', 'diameter' ]
  return dispatch => {
    dispatch({
      type: SEARCH_MODE_CHANGED,
      removedParams: vehicleSearchParams,
      searchMode
    })
    dispatch(updateQueryParams(query, { searchMode }, vehicleSearchParams))
  }
}

const filterQueryParameters = (searchParams) => {
  const parameters = [
    'quality',
    'vehicleType',
    'brandId',
    'isRunflat',
    'isEnforced',
    'isSilence',
    'isElectricVehicle',
    'minQuantityInStock',
    'minimumTestScore',
    'query',
    'rollingResistance',
    'wetGrip',
    'noiseEmissionDecibel',
    'speedIndex',
    'loadIndex',
    'loadIndexRear',
    'isWinterOnly',
    'axleType',
    'tyreType',
    'tyreDimensions',
    'carApprovalMark',
    'isWinterCertified',
    'isFixedCentreBore',
    'rimType',
    'vehicleId',
    'licenseplate',
    'searchMode',
    'diameter',
    'condition',
    'diameter',
    'width',
    'boltPattern',
    'et',
    'comment',
  ]
  const filteredParams = {}
  for (let key in searchParams) {
    if (parameters.indexOf(key) !== -1)
      filteredParams[key] = searchParams[key]
  }
  return filteredParams
}

const getSearchQuery = (searchParams, category, shop, sortBy, page, isMCVehicleType) => {
  let keys = [
    'diameter',
    'width',
    'boltPattern',
    'et',
    'brandId',
    'quality',
    'tyreType',
  ]

  const params = {}

  Object.assign(params, searchParams, category.filters)
  keys.forEach(key => {
    if (params[key] && params[key].length && params[key].length === 1)
      params[key] = params[key][0]
  })

  //filter parameters by search mode
  const selectedVehicleType = shop.vehicleTypeGroups.find(type => type.types.join(',') === params.vehicleType)
  const vehicleSearchMode =  selectedVehicleType ? selectedVehicleType.searchMode : SEARCH_MODE_BY_VEHICLE_TYPE.PERSONAL
  params.minQuantityInStock = selectedVehicleType && selectedVehicleType.minimumInStock !== null
    ? selectedVehicleType.minimumInStock
    : params.minQuantityInStock
  switch (vehicleSearchMode) {
    case SEARCH_MODE_BY_VEHICLE_TYPE.PERSONAL:
      delete params.axleType
      delete params.isWinterOnly
      if (params.tyreType && !params.tyreType.length)
        params.tyreType = filteredSubTyreTypes(shop.enabledTyreTypes).map(key => SUBTYPES[key].id)
      break;
    case SEARCH_MODE_BY_VEHICLE_TYPE.HEAVYTRUCK:
    case SEARCH_MODE_BY_VEHICLE_TYPE.TRACTOR:
      if (!params.isWinterOnly) {
        delete params.tyreType
      }
      break;
    default:
      delete params.isWinterOnly
      delete params.axleType
      delete params.tyreType
  }

  params.page = page
  params.limit = RESULTS_PER_PAGE
  params.showNoimageTyres = shop.showNoimageTyres
  params.showNoimageRims = shop.showNoimageRims
  params.excludeBrands = shop.excludeBrands
  params.includeBrands = shop.includeBrands
  params.excludeSuppliers = shop.excludeSuppliers
  params.includeSuppliers = shop.includeSuppliers
  params.excludeLocations = shop.excludeLocations
  params.includeLocations = shop.includeLocations
  params.webshopId = shop.webshopId
  params.supplierProductId = searchParams.supplierProductId
  params.sortBy = sortBy
  params.version = 2
  params.searchMode = searchParams.searchMode
  params.isStaggeredFitment = true // TODO: Remember this when implement for Rim, Complete Wheels and etc.

  let queryString = qs.stringify(params, { arrayFormat: 'bracket' })
  return queryString
}

export const resetSearch = () => {
  return dispatch => {
    dispatch({
      type: RESET_SEARCH
    })
  }
}

export const setIsSearchFormValid = (isFormValid) => {
  return dispatch => {
    dispatch({
      type: SET_IS_SEARCH_FORM_VALID,
      isFormValid,
    })
  }
}
