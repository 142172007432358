import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.less'
import { T } from 'components/components/i18n'

const CancelPayment = (props) => {
    return (
      <div className={`${styles.container} container cancel-payment`}>
        <h1><T id="Payment was canceled"/></h1>
        <Link to="/kassa/betelning" className="btn btn-primary">
          <T id="Try to pay again"/>
        </Link> <T id="or"/>
        <Link to="/kassa">
          <T id="go back to cart"/>.
        </Link>
      </div>
    )
}

export default CancelPayment
