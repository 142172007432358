const CHECKOUT_ACTIONS = {
	PAYMENT: 1,
	BOOKING: 2,
	PAYMENT_SUCCESS: 3
}

const PAYMENT_PROVIDERS = {
	DIBS: '1',
	RESURS_BANK: '2'
}

const DELIVERY_OPTIONS = {
	DIRECT: 2,
	PICKUP: 0,
	CUSTOMER: 1,
  PARTNER: 3,
}

export { CHECKOUT_ACTIONS, PAYMENT_PROVIDERS, DELIVERY_OPTIONS }
