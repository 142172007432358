// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__error___VOwHI {
  color: red;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/ProductComment/styles.less"],"names":[],"mappings":"AAIA;EACE,UAAA;EACA,gBAAA;AAHF","sourcesContent":[".input {\n  \n}\n\n.error {\n  color: red;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `styles__error___VOwHI`
};
export default ___CSS_LOADER_EXPORT___;
