import React from 'react'
import { T } from 'components/components/i18n'
import {
  FormGroup,
  ControlLabel
} from 'react-bootstrap'
import Selectbox from '../../Form/Selectbox'
import { SEARCH_MODE_BY_VEHICLE_TYPE } from '../../../../constants/searchModes'

const VehicleType = ({ vehicleTypeGroups, selectedType, onChange }) => {
  let vehicleTypes
  if (vehicleTypeGroups)
    vehicleTypes = vehicleTypeGroups.map(vtg => {
      return {
        value: vtg.types.join(','),
        label: vtg.name,
        searchMode: vtg.searchMode
      }
    })
  vehicleTypes.unshift({
    value: 'alla',
    label: <T id="All types of vehicles"/>,
    searchMode: SEARCH_MODE_BY_VEHICLE_TYPE.PERSONAL
  })
  return (
    <FormGroup className="vehicle-type">
      <ControlLabel>
        <T id="Vehicle type"/>
      </ControlLabel>
      <Selectbox
        onChange={onChange}
        options={vehicleTypes}
        value={vehicleTypes.find(option => option.value === selectedType)}
        name="vehicleType"
      />
    </FormGroup>
  )
}

export default VehicleType