import {
  PREORDER_TARGET_ID_RETRIEVED,
  LOADING_PREORDER_DATA,
  PREORDER_DATA_RETRIEVED,
} from '../actions/preOrder'

const initialState = {
  suppliers: [],
  targetId: null,
  loading: false,
  initialized: false,
  selectedTarget: null,
}

const preOrder = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_PREORDER_DATA:
      return { ...state, loading: action.data }
    case PREORDER_TARGET_ID_RETRIEVED:
      return { ...state, targetId: action.targetId }
    case PREORDER_DATA_RETRIEVED:
      return { ...state, suppliers: action.suppliers || [], targetId: action.targetId, target: action.target, initialized: true, loading: false, header: action.header, footer: action.footer }
    default:
      return state
  }
}

export default preOrder
