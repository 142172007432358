// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
.styles__root___LfCbs {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/styles.less"],"names":[],"mappings":"AAAA;;;EAEE,YAAA;AAEF","sourcesContent":["html, body, .root\n{\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `styles__root___LfCbs`
};
export default ___CSS_LOADER_EXPORT___;
