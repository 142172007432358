// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__textRight___BmUE8 {
  text-align: right;
}
.styles__iconButton___txL5r {
  cursor: pointer;
  margin-left: 40px;
  background: transparent !important;
}
@media (max-width: 767px) {
  .styles__iconButton___txL5r {
    display: flex;
    justify-content: center;
    margin: 0;
    width: 50%;
  }
}
.styles__profitMarginCell___wJ6A4 {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}
.styles__profitMargin___P6jPd {
  border-bottom: 1px solid #d8d8d8;
}
.styles__profitMarginCellRight___HgiP6 {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .styles__profitMarginCellRight___HgiP6 {
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/components/CustomerPreferences/ProfitMargins/ProfitMargin/styles.less"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;AAIA;EACE,eAAA;EACA,iBAAA;EACA,kCAAA;AAFF;AAGE;EAAA;IACE,aAAA;IACA,uBAAA;IACA,SAAA;IACA,UAAA;EAAF;AACF;AAGA;EACE,iBAAA;EACA,oBAAA;EACA,aAAA;AADF;AAIA;EACE,gCAAA;AAFF;AAKA;EAEE,aAAA;EACA,yBAAA;AAHF;AAIE;EAAA;IACE,uBAAA;EADF;AACF","sourcesContent":["@import '../../../../../shared/variables.less';\n\n.textRight {\n  text-align: right;\n}\n\n.iconButton {\n  cursor: pointer;\n  margin-left: 40px;\n  background: transparent !important;\n  @media (max-width: @screen-xs-max) {\n    display: flex;\n    justify-content: center;\n    margin: 0;\n    width: 50%;\n  }\n}\n\n.profitMarginCell {\n  padding-top: 20px;\n  padding-bottom: 20px;\n  display: flex;\n}\n\n.profitMargin {\n  border-bottom: 1px solid @eon-border-color;\n}\n\n.profitMarginCellRight {\n  composes: profitMarginCell;\n  display: flex;\n  justify-content: flex-end;\n  @media (max-width: @screen-xs-max) {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textRight": `styles__textRight___BmUE8`,
	"iconButton": `styles__iconButton___txL5r`,
	"profitMarginCell": `styles__profitMarginCell___wJ6A4`,
	"profitMargin": `styles__profitMargin___P6jPd`,
	"profitMarginCellRight": `styles__profitMarginCellRight___HgiP6 styles__profitMarginCell___wJ6A4`
};
export default ___CSS_LOADER_EXPORT___;
