import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import RadioButton from '../../../RadioButton'
import { Row, Col } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { changePriceOptions } from '../../../../../actions/price'
import styles from './styles.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'

class PriceOptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpanded: false,
    }
    this.handleLinkClick = this.onLinkClick.bind(this)
    this.handlePcsSelectedChange = this.onPcsSelectedChange.bind(this)
    this.handleIncludeVatChange = this.onIncludeVatChange.bind(this)
    this.handlePriceTypeChange= this.onPriceTypeChange.bind(this)
  }

  onLinkClick(event) {
    event.preventDefault()
    this.setState({
      isExpanded: !this.state.isExpanded
    })
  }

  onPcsSelectedChange(name, value) {
    const { includeVat, selectedPriceType, changePriceOptions } = this.props
    changePriceOptions(includeVat, value, selectedPriceType)
  }

  onIncludeVatChange(name, value) {
    const { selectedNumberOfPcs, selectedPriceType, changePriceOptions } = this.props
    changePriceOptions(value === 'include', selectedNumberOfPcs, selectedPriceType)
  }

  onPriceTypeChange(name, value) {
    const { includeVat, selectedNumberOfPcs, changePriceOptions } = this.props
    changePriceOptions(includeVat, selectedNumberOfPcs, value)
  }

  render() {
    const {
      canUseConsumerPrices,
      canUseManufacturerListPrices,
      isLoggedIn,
      className
    } = this.props
    let options
    if (this.state.isExpanded) {
      let priceTypeOptions
      const showPriceTypeOptions = (canUseConsumerPrices || canUseManufacturerListPrices) && isLoggedIn
      if (showPriceTypeOptions) {
        priceTypeOptions = (
          <Col xs={12} className={`${styles.radioGroup} ${styles.priceTypeGroup}`}>
            <RadioButton
              small
              isChecked={this.props.selectedPriceType === 'purchase'}
              value="purchase"
              onChange={this.handlePriceTypeChange}
              label={<T id="Purchase price"/>}
              className={styles.leftOption}
            />
            {!!canUseConsumerPrices && (
              <RadioButton
                small
                isChecked={this.props.selectedPriceType === 'consumer'}
                value="consumer"
                onChange={this.handlePriceTypeChange}
                label={<T id="Consumer price"/>}
                className={styles.leftOption}
              />
            )}
            {!!canUseManufacturerListPrices && (
              <RadioButton
                small
                isChecked={this.props.selectedPriceType === 'manufacturer'}
                value="manufacturer"
                onChange={this.handlePriceTypeChange}
                label={<T id="Manufacturer price"/>}
              />
            )}
          </Col>
        )
      }
      options = (
        <Row className={`${styles.options} price-options`}>
          <Col xs={12} className={styles.radioGroup}>
            <div className={styles.pcsGroup}>
              <RadioButton
                small
                isChecked={this.props.selectedNumberOfPcs === 1}
                value={1}
                onChange={this.handlePcsSelectedChange}
                label={<T id="1 pc"/>}
                className={styles.leftOption}
              />
              <RadioButton
                small
                isChecked={this.props.selectedNumberOfPcs === 2}
                value={2}
                onChange={this.handlePcsSelectedChange}
                label={<T id="2 pcs"/>}
                className={styles.leftOption}
              />
              <RadioButton
                small
                isChecked={this.props.selectedNumberOfPcs === 4}
                value={4}
                onChange={this.handlePcsSelectedChange}
                label={<T id="4 pcs"/>}
              />
            </div>

            <RadioButton
              small
              isChecked={!this.props.includeVat}
              value="exclude"
              onChange={this.handleIncludeVatChange}
              label={<T id="Excl. VAT"/>}
              className={styles.leftOption}
            />
            <RadioButton
              small
              isChecked={this.props.includeVat}
              value="include"
              onChange={this.handleIncludeVatChange}
              label={<T id="Incl. VAT"/>}
            />
          </Col>
          {priceTypeOptions}
        </Row>
      )
    }
    const vatInfo = this.props.includeVat ? <T id="incl. vat"/> : <T id="excl. VAT"/>
    let noOfPcsInfo;

    switch (this.props.selectedNumberOfPcs) {
      case 2:
        noOfPcsInfo = <T id="for 2 pcs"/>;
        break;
      case 4:
        noOfPcsInfo = <T id="for 4 pcs" />;
        break;
      default:
        noOfPcsInfo = <T id="for 1 pc"/>;
    }

    return (
      <div className={classnames(className, 'price-options')}>
        <Link to="" onClick={this.handleLinkClick}>
          <T id="Show price"/> {vatInfo} ({noOfPcsInfo}) <FontAwesomeIcon  icon={this.state.isExpanded ? faCaretUp : faCaretDown} className={styles.icon} />
        </Link>
        {options}
      </div>
    )

  }
}

const mapStateToProps = state => {
  return {
    includeVat: state.price.includeVat,
    selectedNumberOfPcs: state.price.selectedNumberOfPcs,
    selectedPriceType: state.price.priceType,
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn,
    canUseConsumerPrices: state.shop.canUseConsumerPrices,
    canUseManufacturerListPrices: state.shop.canUseManufacturerListPrices
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  changePriceOptions: (includeVat, selectedNumberOfPcs, priceType) => changePriceOptions({ includeVat, selectedNumberOfPcs, priceType })
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceOptions)
