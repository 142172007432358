import React from 'react'
import styles from './styles.less'
import classnames from 'classnames'

const Spinner = (props) => {
  let className = props.light ? `${styles.spinner} ${styles.light}` : styles.spinner
  let wrapperClassName
  if (props.inline) {
    className = `${styles.small} ${styles.inline} ${className}`
  } else if (props.small) {
    className = `${styles.small} ${className}`
  } else {
    wrapperClassName = styles.wrapper
  }

  return (
    <div className={classnames(wrapperClassName, 'spinner')}>
      <div className={className}></div>
    </div>
  )
}

export default Spinner
