import { push } from 'react-router-redux'
import { CATEGORIES } from '../../helpers/categories'
import { updateCart } from '../cart'
import { v4 as uuidv4 } from 'uuid';
import { resetVehicle } from '../vehicle'

export const SET_SHOW_WHEEL_PROGRESS = 'SET_SHOW_WHEEL_PROGRESS'
export const SET_RIM = 'SET_RIM'
export const SET_TYRE = 'SET_TYRE'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const SET_TYRE_SIZES = 'SET_TYRE_SIZES'
export const INITIALIZE_WHEEL_BUILDER = 'INITIALIZE_WHEEL_BUILDER'
export const RESET_WHEEL_BUILDER = 'RESET_WHEEL_BUILDER'

export const initializeWheelBuilder = (category, search) => {
  return (dispatch, getState) => {
    const { rim } = getState().wheelBuilder
    let pathname = '/hjul/klar'
    let searchParams = ''
    if ((category !== CATEGORIES.RIM.id && !rim) || (category === CATEGORIES.RIM.id)) {
      pathname = '/hjul/falg'
      if (category === CATEGORIES.RIM.id) {
        searchParams = search
      }
    }
    else if (category === CATEGORIES.TYRE.id && !!rim)
      pathname = `/hjul/dack`
    dispatch(push({
      pathname,
      search: searchParams
    }))
    dispatch({
      type: INITIALIZE_WHEEL_BUILDER,
    })
  }
}

export const setShowWheelProgress = (showWheelProgress) => {
  return dispatch => {
    dispatch({
      type: SET_SHOW_WHEEL_PROGRESS,
      showWheelProgress
    })
  }
}

export const setRim = (rim, count, comment) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_RIM,
      rim: Object.assign(rim, { count, comment })
    })
    const { vehicle } = getState().vehicle
    dispatch({
      type: SET_TYRE_SIZES,
      tyreSizes: vehicle && vehicle.tyreSizes.map(size => {
        size.width = size.width.toString()
        size.aspectRatio = size.aspectRatio.toString()
        size.diameter = size.diameter.toString()
        return size
      }),
    })
    dispatch(push('/hjul/dack'))
  }
}

export const setTyre = (tyre, count, comment) => {
  return dispatch => {
    dispatch({
      type: SET_TYRE,
      tyre: Object.assign(tyre, { count, comment })
    })
    dispatch(push('/hjul/klar'))
  }
}

export const setTyreGroup = (items) => {
  return dispatch => {
    dispatch({
      type: SET_TYRE,
      tyre: items
    })
    dispatch(push('/hjul/klar'))
  }
}

export const confirm = (rim, tyre, tyreRear, countRim, countTyre, countTyreRear, cartItems, shop) => {
  return dispatch => {
    const wheel = uuidv4();
    cartItems.push({ ...rim, count: countRim, wheel })
    cartItems.push({ ...tyre, count: countTyre, wheel })
    if (tyreRear && countTyreRear) {
      cartItems.push({ ...tyreRear, count: countTyreRear, wheel })
    }
    dispatch(
      updateCart(cartItems)
    ).then(() => dispatch(push('/kassa')))
  }
}

export const setCurrentStep = (step, url) => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_STEP,
      step
    })
    dispatch(push(url))
  }
}

export const resetWheelBuilder = (showWheelProgress = false) => {
  return dispatch => {
    dispatch(resetVehicle())
    dispatch(setShowWheelProgress(showWheelProgress))
    dispatch({
      type: RESET_WHEEL_BUILDER
    })
  }
}