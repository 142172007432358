import { push } from 'react-router-redux'

export const ITEM_CLICKED = 'ITEM_CLICKED'

export const setActiveSlug = (slug) => {
  return dispatch => {
    dispatch({
      type: ITEM_CLICKED,
      slug
    })
  }
}

export const changeRoute = (url) => {
  return dispatch => {
    dispatch(push(url))
  }
}

export const setActiveItem = (slug, url) => {
  return dispatch => {
    dispatch({
      type: ITEM_CLICKED,
      slug
    })
    dispatch(push(url))
  }
}
