import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CartItem from '../CartItem'
import { Popover, Overlay, Badge } from 'react-bootstrap'
import styles from './styles.less'
import { changeRoute } from '../../../actions/menu'
import { CartSvg } from '../SvgIcon'
import classnames from 'classnames'

export class Cart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovered: false
    }
  }

  onMouseEnter(e) {
    this.setState({
      isHovered: true
    })
  }

  onMouseLeave() {
    this.setState({
      isHovered: false
    })
  }

  onClick() {
    this.props.changeRoute('/kassa')
  }

  render () {
    const { items, shop, className, countExcludingAutoProducts } = this.props
    const { isHovered } = this.state
    let cartContent, popover = '';
    if (!items || !items.length) {
      cartContent = <CartSvg className="cart-svg"/>
    }

    else {
      const itemsReact = items.map(item => (
        <CartItem
          key={`${item.productId}-${item.car.vehicleId}-${item.car.licenseplate}`}
          item={item}
          cartItems={items}
          shop={shop}
        />
      ))
      popover = (
        <Popover id="popover" className={classnames('cart-popover', styles.cartPopover)} placement="bottom">
          <ul className={`list-unstyled ${styles.productList}`}>
            {itemsReact}
          </ul>
        </Popover>
      )

      cartContent = (
        <div className={classnames(styles.cart, 'cart-content')} ref={el => this.popoverTarget = el} onMouseEnter={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)}>
          <div onClick={this.onClick.bind(this)}>
            <CartSvg className="cart-svg"/>
            <Badge className={classnames('cart-badge', styles.badge)}>{countExcludingAutoProducts}</Badge>
          </div>
          <Overlay id="overlay" placement="bottom" show={isHovered} target={this.popoverTarget}>
            {popover}
          </Overlay>
        </div>
      )
    }
    return (
      <div className={classnames(className, "cart")}>
        {cartContent}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    items: state.cart.items,
    shop: state.shop,
    countExcludingAutoProducts: state.cart.count,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  changeRoute: route => changeRoute(route)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart)
