import React from 'react'
import { FormGroup, ControlLabel, Alert, Tooltip, OverlayTrigger, Popover } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { connect } from 'react-redux'
import { selectManufacturer, selectModel, selectVariant, initializeVehicleSearch } from '../../../../actions/carPicker'
import { bindActionCreators } from 'redux'
import Select, { components } from 'react-select'
import styles from './styles.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import qs from 'query-string'
import Spinner from '../../Spinner'
import CarInfo from '../CarInfo'
import { isMobile } from 'helpers/general'

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faCaretDown}/>
      </components.DropdownIndicator>
    )
  );
};

const customStyles = {
  control: base => ({ ...base, borderRadius: 0, border: '1px solid #ccc', minHeight: '34px' }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: base => ({ ...base, zIndex: 100 }),
}

export class VehicleSearch extends React.Component {
  constructor(props) {
    super(props)
    this.manufacturerSelected = this.manufacturerSelected.bind(this)
    this.modelSelected = this.modelSelected.bind(this)
    this.variantSelected = this.variantSelected.bind(this)
  }

  componentDidMount() {
    const { shop, initializeVehicleSearch, query, isInitialized } = this.props
    if (!isInitialized) {
      const queryParams = qs.parse(query)
      initializeVehicleSearch(shop, queryParams.vehicleId, queryParams.manufacturerId, queryParams.modelId)
    }
  }

  componentDidUpdate(prevProps) {
    const { shop, initializeVehicleSearch, query, isInitialized, selectedManufacturer, selectedModel, selectedVariant, stopAfterModel } = this.props
    if (!isInitialized) {
      const queryParams = qs.parse(query)
      initializeVehicleSearch(shop, queryParams.vehicleId, queryParams.manufacturerId, queryParams.modelId)
    }
    if (
      selectedManufacturer && !selectedModel &&
      prevProps.selectedManufacturer !== selectedManufacturer &&
      isInitialized === prevProps.isInitialized
    )
      this.focusOnModel()
    if (
      selectedModel && !selectedVariant &&
      selectedModel !== prevProps.selectedModel &&
      !stopAfterModel && isInitialized === prevProps.isInitialized
    )
      this.focusOnVariant()
  }

  manufacturerSelected(manufacturer) {
    if (manufacturer.value)
      this.props.selectManufacturer(manufacturer, this.props.shop, this.props.query)
  }

  modelSelected(model) {
    if (model.value)
      this.props.selectModel(model, this.props.shop, this.props.query)
  }

  variantSelected(variant) {
    if (variant.value)
      this.props.selectVariant(variant, this.props.shop, this.props.query)
  }

  focusOnModel() {
    this.modelSelector.focus()
  }

  focusOnVariant() {
    this.variantSelector.focus()
  }

  render() {
    let selectModel = null
    let selectVariant = null
    if (this.props.selectedManufacturer) {
      const modelOptions = this.props.models.map(model => {
        return {
          label: model.name,
          value: model.id,
          vehicleId: model.vehicleId
        }
      })
      selectModel = (
        <Select
          instanceId="selectModel"
          name="select-model"
          ref={el => this.modelSelector = el}
          value={this.props.selectedModel}
          options={modelOptions}
          onChange={this.modelSelected}
          clearValueText={<T id="Clear selection"/>}
          noResultsText={<T id="No results"/>}
          placeholder={<T id="Select model"/>}
          isLoading={this.props.loadingModels}
          menuContainerStyle={{position: 'absolute'}}
          className={styles.input}
          openMenuOnFocus
          components={{ DropdownIndicator }}
          styles={customStyles}
          loadingMessage={() => <T id="Loading..."/>}
        />
      )
    }
    if (this.props.selectedModel && !this.props.stopAfterModel) {
      const variants = this.props.variants.map(variant => {
        return {
          label: variant.description,
          value: variant.id
        }
      })
      selectVariant = (
        <Select
          instanceId="selectVariant"
          name="select-variant"
          ref={el => this.variantSelector = el}
          value={this.props.selectedVariant}
          options={variants}
          onChange={this.variantSelected}
          clearValueText={<T id="Clear selection"/>}
          noResultsText={<T id="No results"/>}
          placeholder={<T id="Select variant"/>}
          isLoading={this.props.loadingVariants}
          menuContainerStyle={{position: 'absolute'}}
          className={styles.input}
          openMenuOnFocus
          components={{ DropdownIndicator }}
          styles={customStyles}
          loadingMessage={() => <T id="Loading..."/>}
        />
      )
    }

    const manufacturerOptions = this.props.manufacturers.map(manufacturer => {
      return {
        label: manufacturer.name,
        value: manufacturer.id
      }
    })

    let vehicleInfo
    if (this.props.loadingVehicle) {
      vehicleInfo = <div className={styles.flexIt}><Spinner small/> <T id="Loading vehicle" />...</div>
    } else if (this.props.vehicle.description) {
      const { vehicle } = this.props
      let vehicleComment = vehicle && vehicle.comment ? (
        <div style={{marginTop: '10px', marginBottom: '10px', color: 'red'}}>
          {vehicle.comment}
        </div>
      ) : ''

      if (vehicle && vehicle.description) {
        vehicleInfo = (
          <div className={styles.vehicleInfo}>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement={isMobile() ? 'bottom' : 'right'}
              overlay={<Popover className={styles.popoverCarInfo}><CarInfo /></Popover>}
            >
              <OverlayTrigger placement="top" overlay={<Tooltip id="carinfo-tooltip"><T id="Click to show more info" /></Tooltip>}>
                <button className={`button-link ${styles.link}`}>
                  {vehicle.description} <FontAwesomeIcon className={styles.carInfoIcon} icon={faInfoCircle} />
                </button>
              </OverlayTrigger>
            </OverlayTrigger>
            {vehicleComment}
          </div>
        )
      }
    }

    const errorMessage = this.props.errorMessage ? <Alert bsStyle="danger">{this.props.errorMessage}</Alert> : null
    return (
      <div>
        <FormGroup className={`${this.props.className} car-picker`}>
          <ControlLabel>
            <T id="Car picker"/>
          </ControlLabel>
          <Select
            instanceId="selectManufacturer"
            name="select-manufacturer"
            ref={el => this.manufacturerSelector = el}
            value={this.props.selectedManufacturer}
            options={manufacturerOptions}
            onChange={this.manufacturerSelected}
            loadingMessage={() => <T id="Loading..."/>}
            clearValueText={<T id="Clear selection"/>}
            noResultsText={<T id="No results"/>}
            placeholder={<T id="Select manufacturer"/>}
            isLoading={this.props.loadingManufacturers}
            menuContainerStyle={{position: 'absolute'}}
            className={styles.input}
            openMenuOnFocus
            components={{ DropdownIndicator }}
            styles={customStyles}
          />
          {selectModel}
          {selectVariant}
        </FormGroup>
        {vehicleInfo}
        {errorMessage}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const selectedModel = state.carPicker.selectedModel
  return {
    manufacturers: state.carPicker.manufacturers,
    models: state.carPicker.models,
    variants: state.carPicker.variants,
    shop: state.shop,
    loadingManufacturers: state.carPicker.loadingManufacturers,
    loadingModels: state.carPicker.loadingModels,
    loadingVariants: state.carPicker.loadingVariants,
    selectedManufacturer: state.carPicker.selectedManufacturer,
    selectedModel: selectedModel,
    selectedVariant: state.carPicker.selectedVariant,
    vehicle: state.carPicker.vehicle,
    isInitialized: state.carPicker.isInitialized,
    stopAfterModel: selectedModel && selectedModel.vehicleId,
    loadingVehicle: state.carPicker.loadingVehicle,
    errorMessage: state.carPicker.errorMessage
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  selectManufacturer: (manufacturer, shop, query) => selectManufacturer(manufacturer, shop, query),
  selectModel: (model, shop, query) => selectModel(model, shop, query),
  selectVariant: (variant, shop, query) => selectVariant(variant, shop, query),
  initializeVehicleSearch: (shop, vehicleId, manufacturerId, modelId) => initializeVehicleSearch(shop, vehicleId, manufacturerId, modelId)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleSearch)
