// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__footer___eYtYe {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.styles__footer___eYtYe button {
  width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/SignUp/CustomFields/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;AACF;AAJA;EAKI,YAAA;AAEJ","sourcesContent":[".footer {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 32px;\n  button {\n    width: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `styles__footer___eYtYe`
};
export default ___CSS_LOADER_EXPORT___;
