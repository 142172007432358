import { api } from '../../helpers/api'

export const INITIALIZE_SIGNUP = 'INITIALIZE_SIGNUP'
export const MOVE_TO_NEXT_SIGNUP_STEP = 'MOVE_TO_NEXT_SIGNUP_STEP'
export const MOVE_TO_PREVIOUS_SIGNUP_STEP = 'MOVE_TO_PREVIOUS_SIGNUP_STEP'
export const VALIDATING_STEP_FIELDS = 'SIGNUP_IN_PROGRESS'
export const SIGNUP_PARAMETER_CHANGED = 'SIGNUP_PARAMETER_CHANGED'
export const SIGNUP_DONE = 'SIGNUP_DONE'
export const FIELDS_VALIDATION_ERROR = 'FIELDS_VALIDATION_ERROR'
export const FIELDS_VALIDATION_SUCCESS = 'FIELDS_VALIDATION_SUCCESS'
export const VALIDATING_CREDENTIALS = 'VALIDATING_CREDENTIALS'
export const CREDENTIALS_VALIDATION_SUCCESS = 'CREDENTIALS_VALIDATION_SUCCESS'
export const CREDENTIALS_VALIDATION_ERROR = 'CREDENTIALS_VALIDATION_ERROR'

export const nextStep = () => {
  return dispatch => {
    dispatch({
      type: MOVE_TO_NEXT_SIGNUP_STEP
    })
  }
}

export const previousStep = () => {
  return dispatch => {
    dispatch({
      type: MOVE_TO_PREVIOUS_SIGNUP_STEP
    })
  }
}

export const initializeSignUp = (shop) => {
  return dispatch => {
    dispatch({
      type: INITIALIZE_SIGNUP,
      shop
    })
  }
}

export const signUp = (params, shop) => {
  return dispatch => {
    params.webshopId = shop.webshopId
    return api(`${shop.apiUrl}/signup/submit`, 'post', params)
      .then(response => response.json())
      .then(response => {
        if (response.err_type === "validation" && response.data && response.data.length) {
          const errors = {}
          response.data.forEach(error => {
            if (error.field) {
              errors[error.field] = error.message
            }
          })
          dispatch(validationError(errors))
        } else {
         dispatch({
           type: SIGNUP_DONE,
           status: response.data.status
         })          
       }
    })
  }
}

export const validate = (fields) => {
  return dispatch => {
    dispatch({
      type: VALIDATING_STEP_FIELDS
    })
    dispatch({
      type: FIELDS_VALIDATION_SUCCESS,
      fields
    })
  }
}

export const changeParameter = (name, value) => {
  return dispatch => {
    dispatch({
      type: SIGNUP_PARAMETER_CHANGED,
      parameterName: name,
      parameterValue: value
    })
  }
}

export const validationError = (errors) => {
  return {
    type: FIELDS_VALIDATION_ERROR,
    errors
  }
}

export const validateCredentials = (params, shop) => {
  return dispatch => {
    dispatch({
      type: VALIDATING_CREDENTIALS
    })
    params.webshopId = shop.webshopId
    return api(`${shop.apiUrl}/signup/validate/user`, 'post', params)
      .then(response => response.json())
      .then(response => {
        const errors = {}
        if (response.err) {
          if (response.data && response.data.length > 0) {
            response.data.forEach(error => {
              errors[error.field] = error.message
            })
             dispatch({
               type: CREDENTIALS_VALIDATION_ERROR,
               errors
             })
          }
        }
        else {
          dispatch({
            type: CREDENTIALS_VALIDATION_SUCCESS
          })
        }
      })
  }
}

export const CONFIRMATION_IN_PROGRESS = 'CONFIRMATION_IN_PROGRESS'
export const CONFIRMATION_DONE = 'CONFIRMATION_DONE'
export const CONFIRMATION_ERROR = 'CONFIRMATION_ERROR'

export const confirmEmail = (token, shop) => {
  return dispatch => {
    dispatch({
      type: CONFIRMATION_IN_PROGRESS
    })
    return api(`${shop.apiUrl}/signup/confirm/${token}`, 'post', { webshopId: shop.webshopId })
      .then(response => response.json())
      .then(response => {
        if (response.err) {
           dispatch({
             type: CONFIRMATION_ERROR,
             error: response.err
           })
        }
        else {
          dispatch({
            type: CONFIRMATION_DONE
          })
        }
      })
  }
}