import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, redirectToLogin, isWheelBuilder, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return redirectToLogin ? (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        ) : (
          <Component {...props} {...rest} isWheelBuilder={isWheelBuilder}/>
        )
      }}
    />
  )
}

export default PrivateRoute
