import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import styles from './styles.less'
import {logout} from '../../../actions/user'
import {setActiveItem} from '../../../actions/menu'
import {resetWheelBuilder} from '../../../actions/wheelBuilder'
import classnames from 'classnames'
import {replaceDynamicValues} from '../../../helpers/string'

export class HeaderNavItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovered: false
    }
  }

  getOnClick() {
    const { item } = this.props
    if (item.type === 'External') {
      if (item.new_tab) {
        return () => window.open(item.url, '_blank')
      } else {
        return () => window.location.href = item.url
      }
    }
    if (item.slug === 'logout')
      return (event) => {
        event.preventDefault()
        this.props.logout()
      }
    return () => {
      this.props.setActiveItem(item.slug, item.url)
      if (item.slug === 'home.hjul') {
        this.props.resetWheelBuilder()
      }
    }
  }

  isVisible() {
    const { item, isUserLoggedIn } = this.props
    if (item.slug === 'login' && isUserLoggedIn)
      return false
    if ((item.slug === 'logout' || item.slug === 'order-list' || item.slug === 'preferences' || item.slug === 'preorder') && !isUserLoggedIn)
      return false
    return true
  }

  isSelected() {
    const { item, activeSlug } = this.props
    return activeSlug === item.slug
  }

  getTitle() {
    const { item, user, customerName } = this.props

    return !!user ? replaceDynamicValues(item.title, { email: user.email, customername: customerName }) : item.title
  }

  onMouseEnter() {
    this.setState({
      isHovered: true
    })
  }

  onMouseLeave() {
    this.setState({
      isHovered: false
    })
  }

  render() {
    if (!this.isVisible())
      return null
    const className = this.isSelected() ? `${styles.selectedNavItem} headerNavItem_hoveredNavItem selected-nav-item` : (this.state.isHovered ? `${styles.hoveredNavItem} hovered-nav-item` : styles.navItem)
    // const style = this.isSelected() && this.props.shop.style ? {
    //     backgroundColor: this.props.shop.style.color1
    // } : null
    return (
      <li
        className={classnames('header-item', className)}
        onMouseEnter={this.onMouseEnter.bind(this)}
        onMouseLeave={this.onMouseLeave.bind(this)}
        // style={style}
        onClick={this.getOnClick()}>
        <button className={`button-link ${classnames('header-item-link', styles.link)}`}>
          {this.getTitle()}
        </button>
      </li>
    )
  }
}

const mapStateToProps = state => {
  return {
    shop: state.shop,
    user: state.user && state.user.user && state.user.user.user,
    customerName: state.user && state.user.user && state.user.user.customer && state.user.user.customer.name,
    isUserLoggedIn: state.user.isLoggedIn,
    activeSlug: state.menu.activeSlug
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: () => logout(),
  setActiveItem: (slug, url) => setActiveItem(slug, url),
  resetWheelBuilder: () => resetWheelBuilder(true),
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderNavItem)
