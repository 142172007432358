// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__sortBy___XneLi {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.styles__select___ifHjL {
  border: none;
  width: 100%;
  background: transparent;
}
.styles__select___ifHjL > div > div > div > span {
  color: #337ab7;
}
.styles__select___ifHjL > div:hover {
  cursor: pointer;
}
.styles__select___ifHjL > div:hover > div > div > span {
  text-decoration: underline;
  color: #23527c;
}
.styles__sortBy___XneLi .sortyByIcon {
  color: #337ab7;
}
.styles__sortBy___XneLi:hover .sortyByIcon {
  color: #23527c;
}
.styles__icon____5nlQ {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchResults/SortBy/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AAEA;EACE,YAAA;EACA,WAAA;EACA,uBAAA;AAAF;AAHA;EASU,cAAA;AAHV;AANA;EAgBI,eAAA;AAPJ;AATA;EAqBU,0BAAA;EACA,cAAA;AATV;AAgBA;EAEI,cAAA;AAfJ;AAmBA;EAEI,cAAA;AAlBJ;AAsBA;EACE,eAAA;AApBF","sourcesContent":[".sortBy {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.select {\n  border: none;\n  width: 100%;\n  background: transparent;\n\n  > div {\n    > div {\n      > div {\n        > span {\n          color: #337ab7;\n        }\n      }\n    }\n  }\n\n  > div:hover {\n    cursor: pointer;\n\n    > div {\n      > div {\n        > span {\n          text-decoration: underline;\n          color: #23527c;\n        }\n      }\n    }\n  }\n}\n\n.sortBy {\n  :global(.sortyByIcon) {\n    color: #337ab7;\n  }\n}\n\n.sortBy:hover {\n  :global(.sortyByIcon) {\n    color: #23527c;\n  }\n}\n\n.icon {\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortBy": `styles__sortBy___XneLi`,
	"select": `styles__select___ifHjL`,
	"icon": `styles__icon____5nlQ`
};
export default ___CSS_LOADER_EXPORT___;
