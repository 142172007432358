import React from 'react'
import { FormGroup, ControlLabel } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import Checkbox from '../../../Checkbox'

const qualityOptions = [
  {
    value: 'premium',
    label: <T id="Premium"/>
  }, {
    value: 'medium',
    label: <T id="Medium"/>
  }, {
    value: 'budget',
    label: <T id="Budget"/>
  }
]

export class Quality extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOptions: this.props.defaultQualityOptions || []
    }
    this.handleCheckboxChange = this.onCheckboxChange.bind(this)
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      selectedOptions: newProps.defaultQualityOptions
    })
  }

  onCheckboxChange(value) {
    let  selectedOptions
    const checked = this.state.selectedOptions.indexOf(value) !== -1
    if (checked)
      selectedOptions = this.state.selectedOptions.filter(option => option !== value)
    else {
      selectedOptions = this.state.selectedOptions.slice()
      selectedOptions.push(value)
    }
    this.setState({
      selectedOptions
    }, this.setQualitySearchParam.bind(this))
  }

  setQualitySearchParam() {
    const selectedOptions = this.state.selectedOptions.length > 0 ? this.state.selectedOptions : qualityOptions.map(option => option.value)
    this.props.onChange('quality', selectedOptions)
  }

  render () {
    const quality = qualityOptions.map(option => {
      return (
        <Checkbox
          onChange={() => this.handleCheckboxChange(option.value)}
          value={option.value}
          key={option.value}
          label={option.label}
          isChecked={this.state.selectedOptions.indexOf(option.value) > -1}
        />
      )
    })
    return (
      <FormGroup className="tyre-quality">
        <ControlLabel>
          <T id="Quality"/>
        </ControlLabel>
        <div>{quality}</div>
      </FormGroup>
    )
  }
}

export default Quality
