// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__title___EZa9s {
  margin-top: 0px;
}
.styles__checkoutButton___hd3mu {
  margin-left: 12px;
}
@media (max-width: 991px) {
  .styles__checkoutButton___hd3mu {
    width: 100%;
  }
}
.styles__checkoutAction___esjtz {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
@media (max-width: 991px) {
  .styles__checkoutAction___esjtz {
    flex-direction: column;
  }
}
.styles__totalMessage___IghzG {
  display: flex;
  flex-direction: column;
  margin-right: 6px;
}
.styles__errorMsg___T7DFO {
  color: red;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
@media (max-width: 991px) {
  .styles__errorMsg___T7DFO {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/components/CheckoutComponent/CheckoutAction/styles.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAEA;EAIE,iBAAA;AAHF;AAAE;EAAA;IACE,WAAA;EAGF;AACF;AACA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;AAAE;EAAA;IACE,sBAAA;EAGF;AACF;AAAA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AAEF;AACA;EACE,UAAA;EACA,iBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;AAAE;EAAA;IACE,sBAAA;EAGF;AACF","sourcesContent":[".title {\n  margin-top: 0px;\n}\n\n.checkoutButton {\n  @media (max-width: 991px) {\n    width: 100%;\n  }\n  margin-left: 12px;\n}\n\n.checkoutAction {\n  display: flex;\n  justify-content: flex-end;\n  flex-direction: row;\n  @media (max-width: 991px) {\n    flex-direction: column;\n  }\n}\n\n.totalMessage {\n  display: flex;\n  flex-direction: column;\n  margin-right: 6px\n}\n\n.errorMsg {\n  color: red;\n  font-weight: bold;\n  margin-bottom: 20px;\n  display: flex;\n  justify-content: flex-end;\n  flex-direction: row;\n  @media (max-width: 991px) {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles__title___EZa9s`,
	"checkoutButton": `styles__checkoutButton___hd3mu`,
	"checkoutAction": `styles__checkoutAction___esjtz`,
	"totalMessage": `styles__totalMessage___IghzG`,
	"errorMsg": `styles__errorMsg___T7DFO`
};
export default ___CSS_LOADER_EXPORT___;
