import React from 'react'
import { Button as ButtonComponent } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'

const Button = ({
  inProgress,
  icon,
  label,
  onClick,
  disabled,
  className
}) => {
  let spinner
  if (inProgress)
    spinner = <FontAwesomeIcon icon={faSpinner} spin/>

  return (
    <ButtonComponent
      type="submit"
      className={classnames('button', className)}
      bsStyle="success"
      disabled={disabled || inProgress}
      onClick={onClick}
    >
      {spinner || icon}{label}
    </ButtonComponent>
  )
}

export default Button
