import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { T } from 'components/components/i18n'

class SignUpDone extends React.Component {
  render() {
    const { status } = this.props
    // const errorMessage = error ? <Alert bsStyle="danger" className={styles.errorMessage}>{error}</Alert> : null
    let message
    if (status === 'completed')
      message = <h4><T id="Thanks. You can now"/> <Link to='/login'>Log in</Link></h4>
    else if (status === 'confirm-email')
      message = <h4><T id="Thanks. Please check your email address for a confirmation email."/></h4>
    else if (status === 'approval')
      message = <h4><T id="Thanks. We will approve your registration as soon as possible, and contact you when it is ready."/></h4>
    return (
      <div>
        {message}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    status: state.signUp.status
  }
}

export default connect(
  mapStateToProps
)(SignUpDone)