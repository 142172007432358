// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__sidebar___Wm38h {
  position: fixed;
  z-index: 100;
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(19, 39, 48, 0.12);
}
.styles__header___IBF57 {
  min-height: 80px;
  max-height: 150px;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  word-break: break-all;
}
.styles__item___fbD9k {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #eee;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Sidebar/styles.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,YAAA;EACA,UAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,gBAAA;EACA,8CAAA;AADF;AAIA;EACE,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,qBAAA;AAFF;AAKA;EACE,aAAA;EACA,aAAA;EACA,6BAAA;AAHF","sourcesContent":["@import '../../../shared/variables.less';\n\n.sidebar {\n  position: fixed;\n  z-index: 100;\n  width: 50%;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  background: #fff;\n  box-shadow: @eon-box-shadow;\n}\n\n.header {\n  min-height: 80px;\n  max-height: 150px;\n  padding: 1em;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: @eon-header-text-color;\n  word-break: break-all;\n}\n\n.item {\n  display: flex;\n  padding: 20px;\n  border-bottom: 1px solid #eee;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `styles__sidebar___Wm38h`,
	"header": `styles__header___IBF57`,
	"item": `styles__item___fbD9k`
};
export default ___CSS_LOADER_EXPORT___;
