// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___KYV9Q {
  display: flex;
  align-items: center;
}
.styles__wrapper___KYV9Q span {
  display: inline-flex;
  align-items: center;
}
.styles__clickable___UFfq7 {
  cursor: pointer;
}
.styles__starSelected___LNlet {
  fill: #F5A623;
}
.styles__star___dl1rJ {
  fill: #000000;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/StarRatingComponent/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAHA;EAII,oBAAA;EACA,mBAAA;AAEJ;AAEA;EAEE,eAAA;AAAF;AAGA;EACC,aAAA;AADD;AAIA;EACC,aAAA;AAFD","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  span {\n    display: inline-flex;\n    align-items: center;\n  }\n}\n\n.clickable {\n  composes: wrapper;\n  cursor: pointer;  \n}\n\n.starSelected {\n\tfill: #F5A623;\n}\n\n.star {\n\tfill: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles__wrapper___KYV9Q`,
	"clickable": `styles__clickable___UFfq7 styles__wrapper___KYV9Q`,
	"starSelected": `styles__starSelected___LNlet`,
	"star": `styles__star___dl1rJ`
};
export default ___CSS_LOADER_EXPORT___;
