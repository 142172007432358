// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___ubjuX {
  display: flex;
}
.styles__checkbox___6DUT4 {
  margin-right: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/RimSearch/Diameter/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEA;EACE,kBAAA;AAAF","sourcesContent":[".wrapper {\n  display: flex;\n}\n\n.checkbox {\n  margin-right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles__wrapper___ubjuX`,
	"checkbox": `styles__checkbox___6DUT4`
};
export default ___CSS_LOADER_EXPORT___;
