import React from 'react'
import { Popover, Overlay } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import styles from './styles.less'

export default class ProductInfoPopover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPopover: false
    }
  }

  onMouseEnter(event) {
    this.setState({
      showPopover: true
    })
  }

  onMouseLeave(event) {
    this.setState({
      showPopover: false
    })
  }

  render() {
    const { product, productTitleElements } = this.props
    const { showPopover } = this.state
    return (
      <div
        key={product.id} ref={el => this.popoverTarget = el}
        onMouseEnter={this.onMouseEnter.bind(this)}
        onMouseLeave={this.onMouseLeave.bind(this)}
        className="product-info-popover"
      >
        <div>
          {productTitleElements}
        </div>
        <Overlay placement="bottom" show={showPopover} target={this.popoverTarget}>
          <Popover placement="bottom" id={`${product.id}-info`} className={`${styles.popoverContent} productInfo_popoverContent background-normal`}>
            {!!product.eanCode &&
              <div className={`${styles.flexIt} ${styles.infoItem}`}>
                <strong>EAN</strong>
                <span>{product.eanCode}</span>
              </div>
            }
            {!!product.productId &&
              <div className={`${styles.flexIt} ${styles.infoItem}`}>
                <strong><T id="EONTYRE number" /></strong>
                <span className={styles.number}>{product.productId}</span>
              </div>
            }
            {!!product.supplierProductId &&
              <div className={`${styles.flexIt} ${styles.infoItem}`}>
                <strong><T id="Supplier number" /></strong>
                <span className={styles.number}>{product.supplierProductId}</span>
              </div>
            }
            {!!product.manufacturerProductId &&
              <div className={`${styles.flexIt} ${styles.infoItem}`}>
                <strong><T id="Manufacturer number" /></strong>
                <span className={styles.number}>{product.manufacturerProductId}</span>
              </div>
            }
          </Popover>
        </Overlay>
      </div>
    )
  }
}
