// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__red___q22JB {
  color: #d9534f;
  font-weight: bold;
}
.styles__white___wFTKj {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchResults/ConditionPopover/styles.less"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,iBAAA;AADF;AAIA;EACE,YAAA;AAFF","sourcesContent":["@import '../../../../../shared/variables.less';\n\n.red {\n  color: @danger;\n  font-weight: bold;\n}\n\n.white {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"red": `styles__red___q22JB`,
	"white": `styles__white___wFTKj`
};
export default ___CSS_LOADER_EXPORT___;
