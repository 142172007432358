import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import CompleteWheelSearchAdvanced from './CompleteWheelSearchAdvanced'
import SearchMode from '../RimSearch/SearchMode'
import styles from './styles.less'
import TyreType from '../TyreSearch/TyreType'
import RimType from '../RimSearch/RimType'
import Quality from '../TyreSearch/Quality'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { SEARCH_MODES } from '../../../../constants/searchModes'
import { changeSearchMode } from '../../../../actions/search'
import Condition from '../Condition'
import SearchButton from '../SearchButton'

export class CompleteWheelSearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAdvancedFields: false
    }
  }

  onShowAdvancedSearchClick(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      showAdvancedFields: !this.state.showAdvancedFields
    })
  }

  onSearchClick(params) {
    this.props.onSearchClick(this.props.searchParams)
  }

  onAttributeChange(event) {
    this.props.onSearchParamChange(event.target.name, event.target.value)
  }

  onBrandSelect(brands) {
    this.props.onSearchParamChange('brandId', brands.map(brand => brand.value))
  }

  onAdvancedFieldChange(name, value) {
    this.props.onSearchParamChange(name, value, this.props.category)
  }

  onLinkClick(event) {
    event.preventDefault()
    event.stopPropagation()
    let searchMode
    if (this.props.selectedSearchMode === SEARCH_MODES.carModel) {
      searchMode = SEARCH_MODES.combined
    }
    else
      searchMode = SEARCH_MODES.carModel
    this.props.changeSearchMode(searchMode, this.props.query)
  }

  render () {
    const { showAdvancedFields } = this.state
    const { shop } = this.props
    if (!shop.id)
      return null
    let additionalFields, additionalFieldsText = <T id="Show more filters"/>
    if (showAdvancedFields) {
      additionalFields = (
        <CompleteWheelSearchAdvanced
          shop={shop}
          onAdvancedFieldChange={this.onAdvancedFieldChange.bind(this)}
          searchParams={this.props.searchParams}/>
      )
      additionalFieldsText = <T id="Show less filters"/>
    }

    const linkText = this.props.selectedSearchMode === SEARCH_MODES.combined ? (
      <T id="Search car model?"/>) : (
      <T id="CompleteWheelSearch.combinedSearch"  defaultMessage="Search dimension/license plate number" />
    )
    const conditionConfig = shop.condition && shop.condition.wheel ? shop.condition.wheel : {}
    return (
      <div className="complete-wheel-search">
        <Link to="" onClick={this.onLinkClick.bind(this)}>
          {linkText}
        </Link>
        <Row className={styles.filters}>
          <Col lg={3} md={6} sm={12} xs={12}>
            <SearchMode
              query={this.props.searchParams.query}
              onEnterPress={this.onSearchClick.bind(this)}
              selectedSearchMode={this.props.selectedSearchMode}
              onFieldChange={this.onAdvancedFieldChange.bind(this)}
              onSearchParamChange={this.props.onSearchParamChange}
            />
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <TyreType
              shop={this.props.shop}
              onChange={this.onAdvancedFieldChange.bind(this)}
              selectedTypes={this.props.searchParams.tyreType}
            />
          </Col>
          <Col lg={2} md={6} sm={12} xs={12}>
          	<RimType
              selectedType={this.props.searchParams.rimType}
              onChange={this.onAdvancedFieldChange.bind(this)}
          	/>
          </Col>
          <Col lg={3} md={6} sm={12} xs={12}>
            <Condition selectedOptions={this.props.searchParams.condition} onChange={this.onAdvancedFieldChange.bind(this)} config={conditionConfig}/>
            <Quality defaultQualityOptions={this.props.searchParams.quality} onChange={this.onAdvancedFieldChange.bind(this)}/>
          </Col>
        </Row>
        {additionalFields}
        <div className={styles.link}>
          <Link to="" onClick={this.onShowAdvancedSearchClick.bind(this)}>
            {additionalFieldsText} <FontAwesomeIcon  icon={showAdvancedFields ? faCaretUp : faCaretDown} />
          </Link>
        </div>
        <SearchButton
          onClick={this.onSearchClick.bind(this)}
          disabled={this.props.searchDisabled}
          className={styles.button}
          error={this.props.error}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  changeSearchMode: (searchMode, query) => changeSearchMode(searchMode, query)
}, dispatch)
const mapStateToProps = state => {
  const selectedSearchMode = state.completeWheelSearchParams.searchMode
  return {
    shop: state.shop,
    category: state.categories.activeCategory,
    searchDisabled: state.carPicker.loadingVehicle
      || (selectedSearchMode === SEARCH_MODES.carModel && !state.carPicker.isComplete),
    searchParams: state.completeWheelSearchParams,
    selectedSearchMode: state.completeWheelSearchParams.searchMode,
    licensePlate: state.completeWheelSearchParams.licenseplate,
    error: state.search.error
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteWheelSearch)
