import React, { Component } from "react";
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import styles from './searchInfo.less'
import { TYRE_TYPES } from '../../../../constants/tyreTypes'

class SearchInfo extends Component {
  render() {
    const { tyreSearchParams, category } = this.props

    let selectedTypes = []

    Object.values(TYRE_TYPES).forEach(tyre => {
      if (tyreSearchParams.tyreType.indexOf(tyre.id) !== -1) {
        selectedTypes.push((
          <div key={`tt_${tyre.id}`}>
            {tyre.label}
          </div>
        ))
      }
    })

    if (category === 'tyre') {
      return (
        <Table striped className={styles.infoTable}>
          <tbody>
            <tr className={styles.infoLabel}>
              <td>
                <T id="Selected tire type" />
              </td>
            </tr>
            <tr className={styles.infoData}>
              <td>
                <strong>
                  {selectedTypes}
                </strong>
              </td>
            </tr>
          </tbody>
        </Table>
      )
    } else {
      return null
    }
  }
}


const mapStateToProps = state => {
  const { vehicle } = state.vehicle.vehicle ? state.vehicle : state.carPicker
  return {
    vehicle,
    tyreSearchParams: state.tyreSearchParams,
    rimSearchParams: state.rimSearchParams,
  }
}

export default connect(mapStateToProps)(SearchInfo)
