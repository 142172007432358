// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stylesNew__euGrading___wTmLQ {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.stylesNew__euGrading___wTmLQ div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stylesNew__accentPositive___cphQc {
  cursor: default;
}
.stylesNew__accentPositive___cphQc > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 6px;
  margin-bottom: 6px;
  border: 1px solid;
}
.stylesNew__accentPositive___cphQc .stylesNew__icon___i2wTC {
  padding: 2px 3px;
}
.stylesNew__accentPositive___cphQc .stylesNew__label___yEyvG {
  padding: 2px 3px;
  border-radius: 0 !important;
}
.stylesNew__extendedDescription___hzD4Y {
  color: #fff;
}
.stylesNew__hasEuGrading____V89S div:nth-child(2) {
  padding: 8px 4px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/EuGrading/stylesNew.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AADF;AAFA;EAMI,aAAA;EACA,sBAAA;EACA,mBAAA;AADJ;AAKA;EACE,eAAA;AAHF;AAEA;EAII,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;AAHJ;AANA;EAaI,gBAAA;AAJJ;AATA;EAiBI,gBAAA;EACA,2BAAA;AALJ;AASA;EACE,WAAA;AAPF;AAUA;EAEI,2BAAA;AATJ","sourcesContent":["@import '../../../shared/variables.less';\n\n.euGrading {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n\n  div {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n}\n\n.accentPositive {\n  cursor: default;\n\n  > div {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-right: 6px;\n    margin-bottom: 6px;\n    border: 1px solid;\n  }\n\n  .icon {\n    padding: 2px 3px;\n  }\n\n  .label {\n    padding: 2px 3px;\n    border-radius: 0 !important;\n  }\n}\n\n.extendedDescription {\n  color: @eon-header-text-color;\n}\n\n.hasEuGrading {\n  div:nth-child(2) {\n    padding: 8px 4px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"euGrading": `stylesNew__euGrading___wTmLQ`,
	"accentPositive": `stylesNew__accentPositive___cphQc`,
	"icon": `stylesNew__icon___i2wTC`,
	"label": `stylesNew__label___yEyvG`,
	"extendedDescription": `stylesNew__extendedDescription___hzD4Y`,
	"hasEuGrading": `stylesNew__hasEuGrading____V89S`
};
export default ___CSS_LOADER_EXPORT___;
