import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { FormGroup, ControlLabel, Row, Col, FormControl } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import Checkbox from '../../Checkbox'
import BrandsSelection from '../BrandsSelection'
import SearchMode from './SearchMode'
import styles from './styles.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { SEARCH_MODES } from '../../../../constants/searchModes'
import { changeSearchMode } from '../../../../actions/search'
import { CATEGORIES } from '../../../../helpers/categories'
import RimType from './RimType'
import SearchButton from '../SearchButton'
import Condition from '../Condition'
import ProductComment from '../ProductComment'

export class RimSearch extends React.Component {
  state = {
    isAdvancedSearch: parseInt(localStorage.getItem('isRimAdvancedSearch'), 10) === 1
  }

  componentDidMount() {
    if (this.props.shop.searchModesRims.indexOf(this.props.selectedSearchMode) === -1) {
      this.props.changeSearchMode(this.props.shop.defaultSearchModeRims, this.props.query)
    }
  }

  onSearchClick(params) {
    this.props.onSearchClick(this.props.searchParams)
  }

  onFieldChange(name, value) {
    this.props.onSearchParamChange(name, value)
  }

  onAttributeChange(event) {
    const { name, type } = event.target
    const value = type === 'checkbox' ? event.target.checked : event.target.value
    this.props.onSearchParamChange(name, value)
  }

  onBrandSelect(brands) {
    this.props.onSearchParamChange('brandId', brands.map(brand => brand.value))
  }

  onShowAdvancedClick(event) {
    event.preventDefault()
    this.setState(state => {
      const isAdvancedSearch = !state.isAdvancedSearch
      localStorage.setItem('isRimAdvancedSearch', isAdvancedSearch ? 1 : 0)
      return {
        isAdvancedSearch
      }
    })
  }

  onLinkClick(event) {
    event.preventDefault()
    event.stopPropagation()
    const unSelectedMode = this.swapSearchMode(this.props.shop.searchModesRims, this.props.selectedSearchMode)
    this.props.changeSearchMode(unSelectedMode, this.props.query)
  }

  swapSearchMode(searchModesRims, selectedSearchMode) {
    const unSelectedMode = searchModesRims.filter(mode => mode !== selectedSearchMode).pop()
    return unSelectedMode
  }

  render () {
    const brandOptions = this.props.brands.map(brand => {
      return { label: brand.name, value: brand.id.toString() }
    })
    const selectedBrandValues = brandOptions.filter(brand => this.props.searchParams.brandId.indexOf(brand.value) > -1)

    const canShowAdvancedLink = [SEARCH_MODES.dimension, SEARCH_MODES.combined].includes(this.props.selectedSearchMode)
    const advancedLinkText = this.state.isAdvancedSearch ? <T id="Simple search"/> : <T id="Advanced search"/>
    let linkText = null
    if (this.props.shop.searchModesRims.length > 1) {
      const unSelectedMode = this.swapSearchMode(this.props.shop.searchModesRims, this.props.selectedSearchMode)
      // eslint-disable-next-line default-case
      switch (unSelectedMode) {
        case SEARCH_MODES.carModel:
          linkText = <T id="Search car model" />
          break
        case SEARCH_MODES.combined:
          linkText = <T id="Search dimension/license plate number" />
          break
        case SEARCH_MODES.dimension:
          linkText = <T id="Search dimension" />
          break
        case SEARCH_MODES.licensePlate:
          linkText = <T id="Search license plate number" />
          break
      }
    }
    const conditionConfig = this.props.shop.condition && this.props.shop.condition.rim ? this.props.shop.condition.rim : {}
    return (
      <div className="rim-search">
        {this.props.shop.searchModesRims.length > 1 && (
          <Link to="" onClick={this.onLinkClick.bind(this)} className={styles.link}>{linkText}</Link>
        )}
        <Row className={styles.filters}>
          <Col md={3}>
            <SearchMode
              query={this.props.searchParams.query}
              selectedSearchMode={this.props.selectedSearchMode}
              onEnterPress={this.onSearchClick.bind(this)}
              onSelectedSearchModeChange={this.props.onSelectedSearchModeChange}
              onFieldChange={this.onFieldChange.bind(this)}
              onSearchParamChange={this.props.onSearchParamChange}
              isAdvancedSearch={this.state.isAdvancedSearch && canShowAdvancedLink}
            />
          </Col>
          <Col md={4}>
            <Row>
              <Col md={6}>
                <RimType selectedType={this.props.searchParams.rimType} onChange={this.onFieldChange.bind(this)}/>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>
                    <T id="Characteristics"/></ControlLabel>
                  <div>
                    <Checkbox
                      name="isFixedCentreBore"
                      value="isFixedCentreBore"
                      label={<T id="Fixed centre bore"/>}
                      onChange={this.onFieldChange.bind(this)}
                      isChecked={this.props.searchParams.isFixedCentreBore}
                    />
                    <Checkbox
                      name="isWinterCertified"
                      value="isWinterCertified"
                      label={<T id="Winter certified"/>}
                      onChange={this.onFieldChange.bind(this)}
                      isChecked={this.props.searchParams.isWinterCertified}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={3}>
            <FormGroup>
              <ControlLabel>
                <T id="Brand"/>
              </ControlLabel>
              <BrandsSelection
                category={CATEGORIES.RIM}
                onBrandSelect={this.onBrandSelect.bind(this)}
                options={brandOptions}
                selectedValues={selectedBrandValues}
              />
            </FormGroup>
            <ProductComment value={this.props.searchParams.comment} onChange={this.onFieldChange.bind(this)} />
          </Col>
          <Col md={2}>
            <Condition selectedOptions={this.props.searchParams.condition} onChange={this.onFieldChange.bind(this)} config={conditionConfig}/>
            <FormGroup>
              <ControlLabel>
                <T id="Items in stock"/>
              </ControlLabel>
              <FormControl
                min="1"
                max="99"
                type="number"
                name="minQuantityInStock"
                value={this.props.searchParams.minQuantityInStock}
                onChange={this.onAttributeChange.bind(this)}
                className={styles.input}
              />
          </FormGroup>
          </Col>
        </Row>
        {canShowAdvancedLink && (
          <div className={`${styles.advancedLink} rimSearch_advancedLink`}>
            <Link to="" className={styles.inlineLabel} onClick={this.onShowAdvancedClick.bind(this)}>
              {advancedLinkText} <FontAwesomeIcon  icon={faCaretRight} />
            </Link>
          </div>
        )}
        <SearchButton
          onClick={this.onSearchClick.bind(this)}
          disabled={this.props.searchDisabled}
          className={styles.button}
          error={this.props.error}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  changeSearchMode: (searchMode, query) => changeSearchMode(searchMode, query)
}, dispatch)

const mapStateToProps = state => {
  const selectedSearchMode = state.rimSearchParams.searchMode
  return {
    activeSlug: state.menu.activeSlug,
    shop: state.shop,
    brands: state.brands.rimBrands,
    category: state.categories.activeCategory,
    searchDisabled: state.carPicker.loadingVehicle
      || (selectedSearchMode === SEARCH_MODES.carModel && !state.carPicker.isComplete),
    searchParams: state.rimSearchParams,
    selectedSearchMode,
    error: state.search.error
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RimSearch)
