export const RESTRICT_DIAMETERS = 'RESTRICT_DIAMETERS'

export const restrictDiameters = (option, allDiameters) => {
  return dispatch => {
    const restrictedDiameters = []
    let diameterMin = option.diameterFrom || Math.min(...allDiameters)
    let diameterMax = option.diameterTo || Math.max(...allDiameters)
    for (let i = diameterMin; i <= diameterMax; i++)
      restrictedDiameters.push(i)
    dispatch({
      type: RESTRICT_DIAMETERS,
      restrictedDiameters,
      selectedOption: option.label
    })
  }
}
