import React from 'react'
import { bindActionCreators } from 'redux'
import { FormControl, Button, FormGroup, ControlLabel, HelpBlock, Row, Col } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { connect } from 'react-redux'
import { validate, changeParameter, previousStep, signUp } from '../../../../actions/signUp'
import { keyCodes } from '../../../../constants/keyCodes'
import Selectbox from '../../Form/Selectbox'
import RadioButton from '../../RadioButton'
import Checkbox from '../../Checkbox'
import styles from './styles.less'
import { Link } from 'react-router-dom'

class CustomFields extends React.Component {
  constructor(props) {
    super(props)
    this.handleSignUpClick = this.onSignUpClick.bind(this)
    this.handleBackClick = this.onBackClick.bind(this)
    this.handleInputChange = this.onChange.bind(this)
    this.handleKeyPress = this.onKeyPress.bind(this)
    this.handleSelectChange = this.onSelectChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { signUp, isValid, isValidated, params, shop } = this.props
    if (isValid && isValidated) {
      signUp(params, shop)
    }
  }

  onSignUpClick() {
    const { params, shop, validate } = this.props
    validate(params, shop)
  }

  onBackClick(event) {
    event.preventDefault()
    this.props.previousStep()
  }

  onChange(event) {
    this.props.changeParameter(event.target.name, event.target.value)
  }

  onSelectChange(name, value) {
    this.props.changeParameter(name, value)
  }

  onKeyPress(event) {
    if (event.which === keyCodes.ENTER)
      this.onSignUpClick()
  }

  getCustomField(field) {
    let label = field.label ? <ControlLabel>{field.label}</ControlLabel> : null
    const { params, errors } = this.props
    const error = !!errors[field.name] ? 'error' : null
    let component
    switch(field.type) {
      case 'dropdown':
        const selectedOption = field.options.find(option => option.value === this.props.params[field.name])
        component = <Selectbox name={field.name} options={field.options} value={selectedOption} onChange={option => this.onSelectChange(field.name, option.value)}/>
        break
      case 'number':
        component = <FormControl type="number" name={field.name} onChange={this.handleInputChange}  defaultValue={params[field.name]}/>
        break
      case 'checkbox':
        const checkboxes = field.options.map(option => {
          const isCheckboxChecked = option.value === params[field.name]
          return (
            <Checkbox value={option.value} isChecked={isCheckboxChecked} name={field.name} label={option.label} onChange={this.handleSelectChange}/>
          )
        })
        component = {checkboxes}
        break
      case 'radio':
        const radioButtons = field.options.map(option => {
          const isRadioChecked = option.value === params[option.name]
          return (
            <RadioButton value={option.value} isChecked={isRadioChecked} label={option.label} name={field.name} onChange={this.handleSelectChange}/>
          )
        })
        component = {radioButtons}
        break
      default:
        component = <FormControl onChange={this.handleInputChange} name={field.name} defaultValue={params[field.name]}/>
    }
    return (
      <FormGroup validationState={error} key={field.name}>
        <Row>
          <Col md={4}>{label}</Col>
          <Col md={8}>
            {component}
          </Col>
        </Row>
        <Row><Col mdOffset={4} md={8}>{error ? <HelpBlock>{errors[field.name]}</HelpBlock> : null}</Col></Row>
      </FormGroup>
    )
  }

  render() {
    const { customFields } = this.props
    const customFieldsComponents = customFields.map(field => {
      return this.getCustomField(field)
    })
    // const errorMessage = error ? <Alert bsStyle="danger" className={styles.errorMessage}>{error}</Alert> : null
    return (
      <div onKeyPress={this.handleKeyPress}>
        {customFieldsComponents}
        <div className={styles.footer}>
          <Button onClick={this.handleSignUpClick}><T id="Sign up"/></Button>
          <Link to="/" onClick={this.handleBackClick}><T id="Back"/></Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    hasCustomFields: state.signUp.hasCustomFields,
    customFields: state.signUp.customFields,
    params: state.signUp.params,
    shop: state.shop,
    errors: state.signUp.errors,
    isValid: state.signUp.isValid,
    isValidated: state.signUp.isValidated
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  changeParameter: (name, value) => changeParameter(name, value),
  previousStep: () => previousStep(),
  validate: (params, shop) => validate(params, shop),
  signUp: (params, shop) => signUp(params, shop)
}, dispatch)
 
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomFields)