import { api } from '../../helpers/api'

export const CREATING_PROFIT_MARGIN = 'CREATING_PROFIT_MARGIN'
export const EDITING_PROFIT_MARGIN = 'EDITING_PROFIT_MARGIN'
export const DELETING_PROFIT_MARGIN = 'DELETING_PROFIT_MARGIN'
export const PROFIT_MARGIN_CREATED = 'PROFIT_MARGIN_CREATED'
export const PROFIT_MARGINS_RETRIEVED = 'PROFIT_MARGINS_RETRIEVED'
export const PROFIT_MARGIN_EDITED = 'PROFIT_MARGIN_EDITED'
export const PROFIT_MARGIN_DELETED = 'PROFIT_MARGIN_DELETED'
export const PROFIT_MARGIN_DELETE_ERROR = 'PROFIT_MARGIN_DELETE_ERROR'
export const PROFIT_MARGIN_EDIT_ERROR = 'PROFIT_MARGIN_EDIT_ERROR'
export const PROFIT_MARGIN_CREATE_ERROR = 'PROFIT_MARGIN_CREATE_ERROR'
export const PROFIT_MARGINS_GET_ERROR = 'PROFIT_MARGINS_GET_ERROR'

const fromBackendMargin = (backendMargin) => {
  let frontendMargin = {
    id: backendMargin.id.toString(),
    type: backendMargin.productType.toLowerCase(),
    percentage: backendMargin.percentage,
    amount: backendMargin.amount,
    diameter: backendMargin.diameter,
    group: '',
    status: backendMargin.status,
    statusName: backendMargin.statusName,
    statusDescription: backendMargin.statusDescription,
  }
  switch (frontendMargin.type) {
    case 'tyre':
      frontendMargin.group = (backendMargin.tyreType && backendMargin.tyreType.toString()) || ''
      break
    case 'rim':
      frontendMargin.group = (backendMargin.rimType && backendMargin.rimType.toString()) || ''
      break
    default:
      // do nothing
  }
  return frontendMargin
}

const toBackendMargin = (frontendMargin) => {
  let backendMargin = {
    id: frontendMargin.id,
    productType: frontendMargin.type,
    percentage: frontendMargin.percentage,
    amount: frontendMargin.amount,
    diameter: frontendMargin.diameter,
  }
  switch (frontendMargin.type) {
    case 'tyre':
      backendMargin.tyreType = frontendMargin.group
      break
    case 'rim':
      backendMargin.rimType = frontendMargin.group
      break
    default:
      // do nothing
  }
  return backendMargin
}

export const getProfitMargins = (shop) => {
  return dispatch => {
    api(`${shop.apiUrl}/consumer-prices`)
      .then(response => response.json())
      .then(response => {
        let margins = []
        if (response.err || !response.data) {
          dispatch({
            type: PROFIT_MARGINS_GET_ERROR,
            error: response.err
          })
          console.error('Failed to fetch profit margins:', response.err)
        } else {
          margins = response.data.map((result) => {
            return fromBackendMargin(result)
          })
        }
        dispatch({
          type: PROFIT_MARGINS_RETRIEVED,
          margins
        })
      })
      .catch(err => {
        dispatch({
          type: PROFIT_MARGINS_GET_ERROR,
          error: 'Unknown error'
        })
      })
    }
}

export const createProfitMargin = (params, category, shop) => {
  params.type = category
  return dispatch => {
    dispatch({
      type: CREATING_PROFIT_MARGIN
    })
    api(`${shop.apiUrl}/consumer-prices`, 'post', toBackendMargin(params))
      .then(response => response.json())
      .then(response => {
        console.log('createProfitMargin response:', response)
        if (response.err)
          dispatch({
            type: PROFIT_MARGIN_CREATE_ERROR,
            error: response.err
          })
        else {
          params.id = response.data.id
          dispatch({
            type: PROFIT_MARGIN_CREATED,
            margin: fromBackendMargin(response.data),
            category
          })
        }
      })
      .catch(err => {
        dispatch({
          type: PROFIT_MARGIN_CREATE_ERROR,
          error: 'Unknown error'
        })
      })
  }
}

export const editProfitMargin = (params, category, shop) => {
  params.type = category
  return dispatch => {
    dispatch({
      type: EDITING_PROFIT_MARGIN
    })
    api(`${shop.apiUrl}/consumer-prices/${params.id}`, 'post', toBackendMargin(params))
      .then(response => response.json())
      .then(response => {
        console.log('editProfitMargin response:', response)
        if (response.err)
          dispatch({
            type: PROFIT_MARGIN_EDIT_ERROR,
            error: response.err
          })
        else {
          dispatch({
            type: PROFIT_MARGIN_EDITED,
            margin: fromBackendMargin(response.data),
            category
          })

        }
      })
      .catch(err => {
        dispatch({
          type: PROFIT_MARGIN_EDIT_ERROR,
          error: 'Unknown error'
        })
      })
  }
}

export const deleteProfitMargin = (marginId, category, shop) => {
  console.log('id:', marginId)
  return dispatch => {
    dispatch({
      type: DELETING_PROFIT_MARGIN
    })
    api(`${shop.apiUrl}/consumer-prices/${marginId}/delete`, 'post')
      .then(response => response.json())
      .then(response => {
        console.log('deleteProfitMargin response:', response)
        if (response.err)
          dispatch({
            type: PROFIT_MARGIN_DELETE_ERROR,
            error: response.err
          })
        else
          dispatch({
            type: PROFIT_MARGIN_DELETED,
            marginId,
            category
          })
      })
      .catch(err => {
        dispatch({
          type: PROFIT_MARGIN_DELETE_ERROR,
          error: 'Unknown error'
        })
      })
  }
}
