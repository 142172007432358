import React from 'react'
import { T } from 'components/components/i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import styles from './styles.less'
import classnames from 'classnames'

const StepsList = () => (
  <div className="preorder-steps-list">
    <h4 className={classnames(styles.preorderStepsTitle, 'preorder-steps-list-title')}><T id="How it works" /></h4>
    <ul className={styles.list}>
      <li className={styles.listItem}>
        <FontAwesomeIcon icon={faCheckCircle} className={styles.icon} />
        <T id="Select supplier" />
      </li>
      <li className={styles.listItem}>
        <FontAwesomeIcon icon={faCheckCircle} className={styles.icon} />
        <T id="Select target" />
      </li>
      <li className={styles.listItem}>
        <FontAwesomeIcon icon={faCheckCircle} className={styles.icon} />
        <T id="Search and add to cart as normal" />
      </li>
      <li className={styles.listItem}>
        <FontAwesomeIcon icon={faCheckCircle} className={styles.icon} />
        <T id="Submit the order in checkout" />
      </li>
    </ul>
  </div>
)

export default StepsList
