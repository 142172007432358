// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wheelProgress___elpH3 {
  margin-top: 15px;
  margin-bottom: -15px;
  padding: 0;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  overflow: hidden;
}
.styles__progress___A0vp4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.styles__label___c3brS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 95px;
  text-align: center;
}
.styles__wrapper___CJz0t {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: 20px;
}
.styles__labels___sZKox {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.styles__itemDescription___P9wnc {
  max-width: 155px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #7F7F7F;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/WheelProgress/styles.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,oBAAA;EACA,UAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AAFF;AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;AAHF;AAMA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;AAJF;AAOA;EACE,aAAA;EACA,8BAAA;EACA,qBAAA;AALF;AAQA;EACE,gBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AANF","sourcesContent":["@import '../../../shared/variables.less';\n\n.wheelProgress {\n  margin-top: @eon-space-default;\n  margin-bottom: -@eon-space-default;\n  padding: 0;\n  border-radius: 3px;\n  border: 1px solid @eon-border-color;\n  overflow: hidden;\n}\n\n.progress {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-left: 20px;\n  margin-right: 20px;\n  margin-bottom: 10px;\n}\n\n.label {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  max-width: 95px;\n  text-align: center;\n}\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  font-size: 12px;\n  margin-top: 20px;\n}\n\n.labels {\n  display: flex;\n  justify-content: space-between;\n  align-items: baseline;\n}\n\n.itemDescription {\n  max-width: 155px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  color: @eon-text-color-muted;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wheelProgress": `styles__wheelProgress___elpH3`,
	"progress": `styles__progress___A0vp4`,
	"label": `styles__label___c3brS`,
	"wrapper": `styles__wrapper___CJz0t`,
	"labels": `styles__labels___sZKox`,
	"itemDescription": `styles__itemDescription___P9wnc`
};
export default ___CSS_LOADER_EXPORT___;
