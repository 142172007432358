import {
    SEARCH_MODE_CHANGED,
    UPDATE_VEHICLE,
} from '../actions/search'
import { VEHICLE_RETRIEVED_BY_ID } from '../actions/carPicker'
import { RESET_VEHICLE } from '../actions/vehicle'
import { addDiametersToVehicle } from '../helpers/vehicle'
import { COMBINED_SEARCH_INITIALIZED } from '../actions/combinedSearch'

const vehicle = (state = { vehicle: null }, action) => {
    switch (action.type) {
        case VEHICLE_RETRIEVED_BY_ID:
        case UPDATE_VEHICLE:
            const vehicle = addDiametersToVehicle(action.vehicle)
            if (vehicle && vehicle.tyreSizes) {
                vehicle.tyreSizes.map(size => {
                    const { width, aspectRatio, diameter } = size
                    if (width && aspectRatio && diameter) {
                        size.width = width.toString()
                        size.aspectRatio = aspectRatio.toString()
                        size.diameter = size.diameter.toString()
                    }
                    return size
                })
            }
          return { ...state, vehicle }
        case SEARCH_MODE_CHANGED:
        case COMBINED_SEARCH_INITIALIZED:
        case RESET_VEHICLE:
            return { ...state, vehicle: null }
        default:
          return state
    }
}

export default vehicle
