// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__popover___pfTjb {
  color: #fff;
  width: 250px;
}
.styles__flexIt____VDSP {
  display: flex;
  justify-content: space-between;
}
.styles__trigger___XLfga {
  cursor: default;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchResults/SupplierPopover/styles.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;AADF;AAIA;EACE,aAAA;EACA,8BAAA;AAFF;AAKA;EACE,eAAA;AAHF","sourcesContent":["@import '../../../../../shared/variables.less';\n\n.popover {\n  color: @eon-header-text-color;\n  width: 250px;\n}\n\n.flexIt {\n  display: flex;\n  justify-content: space-between;\n}\n\n.trigger {\n  cursor: default;  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": `styles__popover___pfTjb`,
	"flexIt": `styles__flexIt____VDSP`,
	"trigger": `styles__trigger___XLfga`
};
export default ___CSS_LOADER_EXPORT___;
