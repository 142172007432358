// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__container___e4bgE {
  max-width: 1140px !important;
  margin-top: 20px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(19, 39, 48, 0.12);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}
.styles__container___e4bgE section {
  margin-bottom: 45px;
}
.styles__option___X0mYa {
  background: #eeeeee;
  padding: 32px 20px;
  display: flex;
  align-items: center;
  border: 1px solid #d8d8d8;
  margin-bottom: 20px;
  cursor: pointer;
}
.styles__label___XGx2P {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles__label___XGx2P label {
  font-size: 20px;
  font-weight: normal;
}
.styles__mainContent___yF3hx {
  max-width: 1140px !important;
  margin-top: 20px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(19, 39, 48, 0.12);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 4px;
  padding: 36px;
  padding-bottom: 20px;
}
.styles__mainContent___yF3hx section {
  margin-bottom: 45px;
}
@media (max-width: 991px) {
  .styles__mainContent___yF3hx {
    padding: 16px;
    padding-bottom: 0px;
  }
}
.styles__noMarginBottom___iFXbs {
  margin-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/shared/layout.less","webpack://./src/components/components/CheckoutComponent/DeliveryOptions/styles.less"],"names":[],"mappings":"AAEA;EACE,4BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,8CAAA;EACA,kDAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;ACDF;ADTA;EAYI,mBAAA;ACAJ;AAXA;EACE,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;AAaF;AAVA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAYF;AAfA;EAKI,eAAA;EACA,mBAAA;AAaJ;AATA;EDpBE,4BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,8CAAA;EACA,kDAAA;EAEA,iBAAA;EACA,kBAAA;ECaA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,oBAAA;AAmBF;AAxBA;EDTI,mBAAA;ACoCJ;AArBE;EAAA;IACE,aAAA;IACA,mBAAA;EAwBF;AACF;AArBA;EACE,kBAAA;AAuBF","sourcesContent":["@import './variables.less';\n\n.container {\n  max-width: 1140px !important;\n  margin-top: 20px;\n  margin-bottom: 15px;\n  border-radius: 3px;\n  background-color: #fff;\n  box-shadow: @eon-box-shadow;\n  transition: @eon-transition-default;\n  border-radius: 4px;\n  margin-left: auto;\n  margin-right: auto;\n  section {\n    margin-bottom: 45px;\n  }\n}\n","@import '../../../../shared/variables.less';\n@import '../../../../shared/layout.less';\n\n.option {\n  background: #eeeeee;\n  padding: 32px 20px;\n  display: flex;\n  align-items: center;\n  border: 1px solid @eon-border-color;\n  margin-bottom: 20px;\n  cursor: pointer;\n}\n\n.label {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  label {\n    font-size: 20px;\n    font-weight: normal;\n  }\n}\n\n.mainContent {\n  .container();\n  background: white;\n  border-radius: 4px;\n  padding: 36px;\n  padding-bottom: 20px;\n  @media (max-width: 991px) {\n    padding: 16px;\n    padding-bottom: 0px;\n  }\n}\n\n.noMarginBottom {\n  margin-bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container___e4bgE`,
	"option": `styles__option___X0mYa`,
	"label": `styles__label___XGx2P`,
	"mainContent": `styles__mainContent___yF3hx`,
	"noMarginBottom": `styles__noMarginBottom___iFXbs`
};
export default ___CSS_LOADER_EXPORT___;
