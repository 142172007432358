import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col } from 'react-bootstrap'
import { search } from '../../../actions/search'
import { setActiveSlug } from '../../../actions/menu'
import { changeSearchParams, initializeSearchParams, resetSearch } from '../../../actions/search'
import { CATEGORIES } from '../../../helpers/categories'
import SearchResults from './SearchResults'
import TyreSearch from './TyreSearch'
import RimSearch from './RimSearch'
import CompleteWheelSearch from './CompleteWheelSearch'
import PreOrderStatusBar from '../PreOrderStatusBar'
import styles from './styles.less'
import qs from 'query-string'
import Banner from '../Banner'

export class Search extends React.Component {
  constructor(props) {
    super(props)
    const query = this.props.location.search
    const searchParams = qs.parse(query)
    this.state = {
      sortBy: searchParams.sortBy,
      currentPage: searchParams.page
    }
  }

  componentDidMount() {
    const {
      location,
      isWheelBuilder,
      setActiveSlug,
      shop,
      category,
      slug,
      rim
    } = this.props
    let diameter
    if (isWheelBuilder) {
      diameter = rim && rim.attrs ? rim.attrs.diameter : null
    }
    this.props.initializeSearchParams(location.search, category, shop.currentSeason, parseInt(diameter, 10), shop, isWheelBuilder)
    setActiveSlug(slug)
  }

  componentWillReceiveProps(newProps) {
    const {
      isWheelBuilder,
      shop,
      rim,
      category,
      search,
      tyreSearchParams,
      rimSearchParams,
      completeWheelSearchParams,
      location,
      searchParamsInitialized,
    } = newProps
    const { sortBy, currentPage } = this.state
    if (category !== this.props.category || searchParamsInitialized !== this.props.searchParamsInitialized) {
      let diameter
      if (isWheelBuilder) {
        diameter = rim && rim.attrs ? rim.attrs.diameter : null
      }
      this.props.initializeSearchParams(location.search, category, shop.currentSeason, parseInt(diameter, 10), shop, isWheelBuilder)
    }
    if (!newProps.isLoading && newProps.searchParamsInitialized && !newProps.initialSearchDone) {
      search(
        tyreSearchParams,
        rimSearchParams,
        completeWheelSearchParams,
        category,
        shop,
        sortBy,
        currentPage,
        isWheelBuilder,
        rim,
        location.search
      )
    }
  }

  componentWillUnmount() {
    const { resetSearch } = this.props
    resetSearch()
  }

  onSearchParamChange(name, value) {
    this.props.changeSearchParams({ [name]: value }, this.props.category)
  }

  onLicensePlateChange(regNum) {
    this.props.changeSearchParams({ regNum }, this.props.category)
  }

  onSearchClick() {
    this.setState({
      currentPage: 1
    }, () =>
      this.props.search(
        this.props.tyreSearchParams,
        this.props.rimSearchParams,
        this.props.completeWheelSearchParams,
        this.props.category,
        this.props.shop,
        this.state.sortBy,
        this.state.currentPage,
        this.props.isWheelBuilder,
        this.props.rim,
        this.props.location.search,
        true
      )
    )
  }

  onPageClick(page) {
    window.scrollTo(0, 0)
    this.setState({
      currentPage: page
    }, () => {
      this.props.search(
        this.props.tyreSearchParams,
        this.props.rimSearchParams,
        this.props.completeWheelSearchParams,
        this.props.category,
        this.props.shop,
        this.state.sortBy,
        this.state.currentPage,
        this.props.isWheelBuilder,
        this.props.rim,
        this.props.location.search,
        true
      )
    })
  }

  onSortByClick(sortBy) {
    this.setState({
      sortBy
    }, () => {
      this.props.search(
        this.props.tyreSearchParams,
        this.props.rimSearchParams,
        this.props.completeWheelSearchParams,
        this.props.category,
        this.props.shop,
        sortBy,
        this.state.currentPage,
        this.props.isWheelBuilder,
        this.props.rim,
        this.props.location.search,
        true
      )
    })
  }

  render () {
    if (!this.props.category || !this.props.searchParamsInitialized)
      return null
    let searchType
    switch(this.props.category.slug) {
      case CATEGORIES.TYRE.slug:
        searchType = (
          <TyreSearch
            query={this.props.location.search}
            onSearchClick={this.onSearchClick.bind(this)}
            onLicensePlateChange={this.onLicensePlateChange.bind(this)}
            defaultDiameter={this.props.rim && this.props.rim.attrs.diameter}
            onSearchParamChange={this.onSearchParamChange.bind(this)}
          />
        )
        break
      case CATEGORIES.RIM.slug:
        searchType = (
          <RimSearch
            query={this.props.location.search}
            onSearchClick={this.onSearchClick.bind(this)}
            onLicensePlateChange={this.onLicensePlateChange.bind(this)}
            onSearchParamChange={this.onSearchParamChange.bind(this)}
          />
        )
        break
      case CATEGORIES.COMPLETE_WHEEL.slug:
        searchType = (
          <CompleteWheelSearch
            query={this.props.location.search}
            onSearchClick={this.onSearchClick.bind(this)}
            onSearchParamChange={this.onSearchParamChange.bind(this)}
          />
        )
        break
      default:
        searchType = null
    }
    const footer = this.props.shop.startPageFooterHtml ? <div dangerouslySetInnerHTML={{ __html: this.props.shop.startPageFooterHtml }}/> : null
    return (
      <div className={`container ${styles.page} search`}>
        <Banner/>
        <Row className={`${styles.attributes} search_attributes filters`}>
          <Col xs={12} sm={12}>
            {searchType}
          </Col>
        </Row>
        {this.props.isPreorderMode && <PreOrderStatusBar />}
        <SearchResults
          currentPage={this.state.currentPage}
          selectedSortOption={this.state.sortBy}
          onPageClick={this.onPageClick.bind(this)}
          onSortByClick={this.onSortByClick.bind(this)}/>
        {footer}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  search: (tyreSearchParams, rimSearchParams, completeWheelSearchParams, category, shop, sortBy, page, isWheelBuilder, rim, query, setSubmit) => search(tyreSearchParams, rimSearchParams, completeWheelSearchParams, category, shop, sortBy, page, isWheelBuilder, rim, query, setSubmit),
  setActiveSlug: (slug) => setActiveSlug(slug),
  changeSearchParams: (params, category) => changeSearchParams(params, category),
  initializeSearchParams: (params, category, currentSeason, diameter, shop, isWheelBuilder) => initializeSearchParams(params, category, currentSeason, diameter, shop, isWheelBuilder),
  resetSearch: () => resetSearch()
}, dispatch)

const mapStateToProps = (state, ownProps) => {
  const searchState = state.search.resultsByCategory[state.categories.activeCategory.id]
  return {
    shop: state.shop,
    category: state.categories.activeCategory,
    rim: state.wheelBuilder.rim,
    isLoading: searchState.isLoading,
    tyreSearchParams: state.tyreSearchParams,
    rimSearchParams: state.rimSearchParams,
    completeWheelSearchParams: state.completeWheelSearchParams,
    searchParamsInitialized: searchState.searchParamsInitialized,
    initialSearchDone: searchState.initialSearchDone,
    currentWheelBuilderStep: state.wheelBuilder.currentStep,
    isPreorderMode: state.preOrder.targetId,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search)
