import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { T } from 'components/components/i18n'
import { fetchSupplierByTargetId } from '../../../helpers/preOrder'
import styles from './styles.less'
import classnames from 'classnames'

const PreOrderStatusBar = ({ supplier, quantity, quantityInCart, target }) => {
  const isTargetReached = quantityInCart >= quantity
  const className = isTargetReached ? styles.blueWrapper : styles.yellowWrapper

  return (
    <div className={classnames(className, 'preorder-status-bar')}>
      <div>
        <T id="Pre season order: " />
        <span>{supplier.supplier.title}</span>
      </div>
      <div>
        {!isTargetReached && (
          <T
            id="PreOrderStatusBar.targetNotReached"
            defaultMessage="{quantityInCart} items in cart {quantityRemaining} more needed to reach target"
            values={{
              quantityInCart,
              quantityRemaining: quantity - quantityInCart
            }}
          />
        )}
        {isTargetReached && (
          <T
            id="PreOrderStatusBar.targetReached"
            defaultMessage="{quantityInCart} items in cart - target is reached"
            values={{
              quantityInCart,
            }}
          />
        )}
      </div>
      <div>
        <T id="Target: " />
        <span>{target}</span>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

const mapStateToProps = state => {
  const supplier = fetchSupplierByTargetId(state.preOrder.suppliers, state.preOrder.targetId)
  const target = supplier.targets.find(target => target.id === state.preOrder.targetId)
  return {
    supplier,
    quantity: target.quantity,
    quantityInCart: state.cart.count,
    target: target.title,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreOrderStatusBar)
