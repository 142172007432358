// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___X5smK {
  display: flex;
  height: 20px;
}
.styles__property___NpV8Q {
  margin-right: 12px;
  text-decoration: underline;
  cursor: default;
}
.styles__popoverTarget____HYBG {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchResults/ProductProperties/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;AAEA;EACE,kBAAA;EACA,0BAAA;EACA,eAAA;AAAF;AAGA;EAEE,eAAA;AADF","sourcesContent":[".wrapper {\n  display: flex;\n  height: 20px;\n}\n\n.property {\n  margin-right: 12px;\n  text-decoration: underline;\n  cursor: default;\n}\n\n.popoverTarget {\n  composes: property;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles__wrapper___X5smK`,
	"property": `styles__property___NpV8Q`,
	"popoverTarget": `styles__popoverTarget____HYBG styles__property___NpV8Q`
};
export default ___CSS_LOADER_EXPORT___;
