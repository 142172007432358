import React from 'react'
import { Popover, Overlay, Table } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { connect } from 'react-redux'
import DeliveryTimeIcon from "./icon"
import styles from './styles.less'
import classnames from 'classnames'

class ExactDeliveryTime extends React.Component {
  constructor(props) {
    super(props)
    this.handleMouseEnter = this.onMouseEnter.bind(this)
    this.handleMouseLeave = this.onMouseLeave.bind(this)
    this.state = {
      showPopover: false,
    }
  }

  onMouseEnter() {
    this.setState({
      showPopover: true,
    })
  }

  onMouseLeave() {
    this.setState({
      showPopover: false,
    })
  }

  render() {
    const { isLoading, exactDeliveryTime } = this.props
    return (
      <div>
        {isLoading && (
          <div><T id="Loading..." /></div>
        )}
        {!isLoading && exactDeliveryTime && (
          <div  onMouseLeave={this.handleMouseLeave} ref={el => this.target = el}>
            <div onMouseEnter={this.handleMouseEnter} className={classnames(styles.trigger, styles[exactDeliveryTime.color], 'exact-delivery-time-text')}>
              {exactDeliveryTime && exactDeliveryTime.text}
            </div>
            {
              <Overlay placement="bottom" show={this.state.showPopover} target={this.target}>
                <Popover placement="bottom" id="exact-delivery-time-popover" className={classnames(styles.popover, 'exact-delivery-time-popover')}>
                  <Table className={`${styles.table} ${styles.marginBottom}`}>
                    <thead>
                      <tr>
                        <td><T id="Status" /></td>
                        <td className={styles.rightAligned}><T id="Source" /></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className={styles[exactDeliveryTime.color]}>{exactDeliveryTime.status}</td>
                        <td className={`${styles.bold} ${styles.rightAligned}`}>{exactDeliveryTime.source}</td>
                      </tr>
                    </tbody>
                  </Table>
                  {(!!exactDeliveryTime.deliveries.length || !!exactDeliveryTime.deliveryInformation) &&  (
                    <Table className={styles.table}>
                      {!!exactDeliveryTime.deliveries.length && (
                        <thead>
                          <tr>
                            <td colSpan={2}><T id="Delivery schedule" /></td>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {exactDeliveryTime.deliveries.map(delivery => (
                          <tr><td>{delivery.date}</td><td>{delivery.quantity} <T className={styles.piecesLabel} id="ExactDeliveryTime.piecesLabel" defaultMessage="pcs" /></td></tr>
                        ))}
                        {exactDeliveryTime.deliveryInformation && (
                          <tr>
                            <td
                              colSpan={2}
                              className={
                                exactDeliveryTime.deliveryInformationColor
                                ? `${styles.deliveryInfo} ${styles[exactDeliveryTime.deliveryInformationColor]}`
                                : `${styles.deliveryInfo} ${styles.default}`
                              }
                            >
                              {!!exactDeliveryTime.deliveryInformationIcon && <DeliveryTimeIcon icon={exactDeliveryTime.deliveryInformationIcon} />}
                              {exactDeliveryTime.deliveryInformation}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                  {(!!exactDeliveryTime.deliveries.length || exactDeliveryTime.deliveryInformation) && !!exactDeliveryTime.information && <br />}
                  {!!exactDeliveryTime.information && <div>{exactDeliveryTime.information}</div>}
                </Popover>
              </Overlay>
            }
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const exactDeliveryTime = state.cart.exactDeliveryTimes.find(item => (
    item.supplierId === props.item.supplier.id &&
    item.locationId === props.item.location.id &&
    item.productId === props.item.productId
  ))

  return ({
    exactDeliveryTime,
    isLoading: state.cart.loadingExactDeliveryTimes || state.cart.deliveryTimesInProgress[props.item.id],
  });
}

export default connect(mapStateToProps)(ExactDeliveryTime)
