import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from './styles.less'
import { WheelBuilderNormalItemSvg, WheelBuilderActiveItemSvg, WheelBuilderSelectedItemSvg } from '../SvgIcon'
import { setCurrentStep } from '../../../actions/wheelBuilder'
import classnames from 'classnames'

const WheelProgressItem = (props) => {
  const {
    hasProductAssigned,
    currentStep,
    step,
    setCurrentStep,
    isClickable
  } = props

  const isActive = currentStep === step.step
  const isLast = step.step === 2

  let icon, border, className
  if (isActive) {
    icon = <WheelBuilderActiveItemSvg className="svg-colored" strokeClass="svg-stroke-colored"/>
  } else if (hasProductAssigned) {
    icon = <WheelBuilderSelectedItemSvg className="svg-colored svg-stroke-colored"/>
  } else {
    icon = <WheelBuilderNormalItemSvg />
  }

  if (!isLast) {
    if (hasProductAssigned && !isActive) {
      border = <div className={`${styles.border} border-colored`}/>
    } else {
      border = <div className={`${styles.border} border-muted`}/>
    }
    className = `${styles.last} text-colored`
  } else {
    className = `${styles.item} text-colored`
  }
  const onClick = () => {
    if (isClickable)
      setCurrentStep(step.step, step.url)
  }
  return (
    <div className={classnames(className, 'wheel-progress-item')} onClick={onClick}>
      <div className={styles.wrapper}>{icon}{border}</div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    shop: state.shop,
    currentStep: state.wheelBuilder.currentStep || 0
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  setCurrentStep: (step, url) => setCurrentStep(step, url)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WheelProgressItem)
