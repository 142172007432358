// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__container___gMhBz {
  max-width: 1140px !important;
  margin-top: 20px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(19, 39, 48, 0.12);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}
.styles__container___gMhBz section {
  margin-bottom: 45px;
}
.styles__page___vIXt9 {
  margin-left: auto;
  margin-right: auto;
}
.styles__attributes___aBEkJ {
  max-width: 1140px !important;
  margin-top: 20px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(19, 39, 48, 0.12);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 0;
  position: relative;
  z-index: 12;
}
.styles__attributes___aBEkJ section {
  margin-bottom: 45px;
}
.styles__results___swK6D {
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/shared/layout.less","webpack://./src/components/components/Search/styles.less"],"names":[],"mappings":"AAEA;EACE,4BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,8CAAA;EACA,kDAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;ACDF;ADTA;EAYI,mBAAA;ACAJ;AAXA;EACE,iBAAA;EACA,kBAAA;AAaF;AAVA;EDLE,4BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,8CAAA;EACA,kDAAA;EAEA,iBAAA;EACA,kBAAA;ECFA,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AAoBF;AA1BA;EDMI,mBAAA;ACuBJ;AApBA;EACE,gBAAA;AAsBF","sourcesContent":["@import './variables.less';\n\n.container {\n  max-width: 1140px !important;\n  margin-top: 20px;\n  margin-bottom: 15px;\n  border-radius: 3px;\n  background-color: #fff;\n  box-shadow: @eon-box-shadow;\n  transition: @eon-transition-default;\n  border-radius: 4px;\n  margin-left: auto;\n  margin-right: auto;\n  section {\n    margin-bottom: 45px;\n  }\n}\n","@import '../../../shared/layout.less';\n@import '../../../shared/variables.less';\n\n.page {\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.attributes {\n  .container();\n  border-radius: 4px;\n  padding: 16px;\n  margin-bottom: 0;\n  position: relative;\n  z-index: 12;\n}\n\n.results {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container___gMhBz`,
	"page": `styles__page___vIXt9`,
	"attributes": `styles__attributes___aBEkJ`,
	"results": `styles__results___swK6D`
};
export default ___CSS_LOADER_EXPORT___;
