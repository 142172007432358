import React from 'react'
import {
  FormGroup,
  ControlLabel,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap'
import { T } from 'components/components/i18n'
import TypeOption from './TypeOption'
import styles from './styles.less'
import {
  TYRE_TYPES,
  TYPES,
  SUBTYPES,
  SUBTYPES_FRICTION,
  SUBTYPES_STUD
} from '../../../../../constants/tyreTypes'
import {
  filteredMainTyreTypes,
  filteredSubTyreTypesFriction,
  filteredSubTyreTypesStud
} from 'helpers/tyreTypes'

export class TyreType extends React.Component {
  constructor(props) {
    super(props)
    this.handleRadioButtonChange = this.onRadioButtonChange.bind(this)
    this.handleCheckboxChange = this.onCheckboxChange.bind(this)

    this.subTypesFriction = []
    this.subTypesStud = []
  }

  componentDidMount() {
    this.subTypesFriction = filteredSubTyreTypesFriction(this.props.shop.enabledTyreTypes)
    this.subTypesStud = filteredSubTyreTypesStud(this.props.shop.enabledTyreTypes)
    // this.defaultSubTypes = this.subTypesFriction.length
    //   ? this.subTypesFriction
    //   : this.subTypesStud
    this.defaultSubTypes = [
      (
        this.subTypesFriction.length
          ? this.subTypesFriction
          : this.subTypesStud
      ).map(
        key => SUBTYPES[key].id
      )[0]
    ]
  }

  onRadioButtonChange(value) {
    const selectedTypes =
      (
        value === TYRE_TYPES.SUMMER.id ||
        value === TYRE_TYPES.ALL.id ||
        value === TYRE_TYPES.ALL_SEASON.id
      )
        ? [value] : this.defaultSubTypes

    this.props.onChange('tyreType', selectedTypes)
  }

  onCheckboxChange(value) {
    let selectedTypes
    const checked = this.props.selectedTypes.indexOf(value) !== -1
    if (checked) {
      selectedTypes = this.props.selectedTypes.filter(type => type !== value)
    } else {
      selectedTypes = this.props.selectedTypes.slice()
      selectedTypes.push(value)

      // NOTE: If friction is selected then un-select all stud, else vice versa
      const key = Object.keys(SUBTYPES)[Object.values(SUBTYPES).findIndex(v => v.id === value)]
      if (SUBTYPES_FRICTION[key]) {
        Object.values(SUBTYPES_STUD).forEach((v) => {
          const idx = selectedTypes.findIndex(val => val === v.id)
          if (idx >= 0) selectedTypes.splice(idx, 1)
        })
      } else if (SUBTYPES_STUD[key]) {
        Object.values(SUBTYPES_FRICTION).forEach((v) => {
          const idx = selectedTypes.findIndex(val => val === v.id)
          if (idx >= 0) selectedTypes.splice(idx, 1)
        })
      }
    }

    if (!selectedTypes || !selectedTypes.length) {
      selectedTypes = this.defaultSubTypes
    }

    this.props.onChange('tyreType', selectedTypes)
  }

  render () {
    const mainTypes = {}
    filteredMainTyreTypes(this.props.shop.enabledTyreTypes)
      .forEach(key => {
        const selectedType = this.props.selectedTypes[0] === TYPES[key].id ||
          // NOTE: If any of the winter subtypes are selected
          (
            key === 'WINTER' &&
            (
              Object.keys(SUBTYPES).map(key => SUBTYPES[key].id).includes(this.props.selectedTypes[0]) || !this.props.selectedTypes.length
            )
          ) ||
          // NOTE: Fallback to all
          (
            key === 'ALL' &&
            !this.props.selectedTypes.length
          )
        mainTypes[key] = {
          selected: selectedType,
          COMPONENT: (
            <TypeOption
              key={TYPES[key].id}
              isChecked={selectedType}
              onChange={this.handleRadioButtonChange}
              option={TYPES[key]}
              shop={this.props.shop}
            />
          )
        }
      })

    const subTypesFriction = mainTypes['WINTER'] && mainTypes['WINTER'].selected
      ? this.subTypesFriction && this.subTypesFriction.map(key => {
          return (
            <TypeOption
              isChecked={this.props.selectedTypes.find(selectedType => selectedType === SUBTYPES[key].id)}
              key={SUBTYPES[key].id}
              onChange={this.handleCheckboxChange}
              option={SUBTYPES[key]}
              shop={this.props.shop}
              componentType="checkbox"
            />
          )
        })
      : []

    const subTypesStud = mainTypes['WINTER'] && mainTypes['WINTER'].selected
      ? this.subTypesStud && this.subTypesStud.map(key => {
          return (
            <TypeOption
              isChecked={this.props.selectedTypes.find(selectedType => selectedType === SUBTYPES[key].id)}
              key={SUBTYPES[key].id}
              onChange={this.handleCheckboxChange}
              option={SUBTYPES[key]}
              shop={this.props.shop}
              componentType="checkbox"
            />
          )
        })
      : []

    let winterTypes = null
    if (subTypesFriction.length && subTypesStud.length) {
      winterTypes = (
        <OverlayTrigger
          placement="bottom"
          trigger={['hover', 'focus']}
          overlay={
            <Tooltip id="winter-tooltip">
              <T id="Select between friction type or stud option" />
            </Tooltip>
          }
        >
          <FormGroup className={styles.flexIt}>
            <div>
              <ControlLabel className={styles.subTypesLabel}>
                <T id="Friction Type" />
              </ControlLabel>
              <div className={styles.flexIt}>
                {subTypesFriction}
              </div>
            </div>
            <div>
              <ControlLabel className={styles.subTypesLabel}>
                <T id="Stud Option" />
              </ControlLabel>
              <div className={styles.flexIt}>
                {subTypesStud}
              </div>
            </div>
          </FormGroup>
        </OverlayTrigger>
      )
    } else if (subTypesFriction.length) {
      winterTypes = (
        <FormGroup className={styles.flexIt}>
          <div>
            <ControlLabel className={styles.subTypesLabel}>
              <T id="Friction Type" />
            </ControlLabel>
            <div className={styles.flexIt}>
              {subTypesFriction}
            </div>
          </div>
        </FormGroup>
      )
    } else if (subTypesStud.length) {
      winterTypes = (
        <FormGroup className={styles.flexIt}>
          <div>
            <ControlLabel className={styles.subTypesLabel}>
              <T id="Stud Option" />
            </ControlLabel>
            <div className={styles.flexIt}>
              {subTypesStud}
            </div>
          </div>
        </FormGroup>
      )
    }

    return (
      <div className="tyre-types">
        <ControlLabel><T id="Tire type"/></ControlLabel>
        <FormGroup className={styles.flexIt}>
          {Object.entries(mainTypes).map(mainType => mainType[1].COMPONENT)}
        </FormGroup>
        {winterTypes}
      </div>
    )
  }
}

export default TyreType
