import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from './styles.less'
import { Link } from 'react-router-dom'
import {T} from "../i18n";

const Page = (props) => {
  const { config } = props
  const content = <div dangerouslySetInnerHTML={{ __html: config.body }}></div>
  document.title = config.title

  return (
    <div className="container page">
      <Row>
        <Col xs={12}>
          <ol className={`list-unstyled ${styles.list}`}>
            <li className={styles.listItem}><Link to="/" className={styles.link}><T id="Home"/></Link></li>
            <li className={styles.listItemDivider}>/</li>
            <li className={styles.listItemActive}>{config.title}</li>
          </ol>
          {content}
        </Col>
      </Row>
    </div>
  )
}

export default Page
