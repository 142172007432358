import React from 'react'
import { FormattedNumber } from 'react-intl'
import { T } from 'components/components/i18n'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, FormControl, Button, Alert } from 'react-bootstrap'
import { /* setWheelBuilder, */ confirm } from '../../../../actions/wheelBuilder'
import EuGrading from '../../EuGrading'
import SupplierPopover from '../SearchResults/SupplierPopover'
import styles from './styles.less'
import DeliveryTime from '../../DeliveryTime'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class WheelBuilderConfirm extends React.Component {
  constructor(props) {
    super(props)
    let count = Math.max(this.props.rim.count || 0, this.props.tyre.count || 0, 4)
    let countTyre = (this.props.tyre.count || 2)
    let countTyreRear = ((this.props.tyreRear && this.props.tyreRear.count) || 2)
    this.state = {
      count,
      countTyre,
      countTyreRear,
      canProceed: false,
      showAlert: false
    }
  }

  getProductComponent(product, shopId) {
    const { shop, includeVat, currency } = this.props
    let stockInfo = <span className="text-success"><T id="Available in stock"/></span>
    if (product.stock <= 0)
      stockInfo = <span className="text-danger"><T id="None in stock"/></span>
    else if (product.stock <= 10)
      stockInfo = <span className="text-warning"><T id="Limited amount"/></span>

    let delivery, supplier
    if (shop.showSupplierInSearchResults) {
      const productSource = product.supplier.id ? product.supplier : product.location
      if(!productSource.hideIt)
        supplier = <SupplierPopover supplier={product.supplier} supplierNameComponent={productSource.name} />
    }
    if (product.deliveryTime && !product.deliveryTime.hideIt) {
      if (shop.deliveryDisplayMode === 1) {
        let deliveryTime
        deliveryTime = <DeliveryTime deliveryInfo={product.deliveryTime} />
        delivery = <div>{deliveryTime}</div>
      } else {
        delivery = (
          <FontAwesomeIcon
            icon={faTruck}
          />
        )
      }
    }

    return (
      <Row className={styles.product}>
        <Col md={5} sm={12} className={styles.titleColumn}>
          <img src={product.image ? product.image.webshop_thumb : '/images/default-product-thumbnail.png'} alt="" />
          <div className={styles.title}>
            <div className={`${styles.productTitle} text-colored`}>{product.brand.name} {product.model.name}</div>
            <div>{product.attrs.dimension}</div>
          </div>
        </Col>
        <Col md={3} sm={12} className={styles.column}>
          <EuGrading product={product}/>
        </Col>
        <Col md={2} sm={12} className={styles.column}>
          {stockInfo}
          {delivery}
          {supplier}
        </Col>
        <Col md={2} sm={12} className={`${styles.price} background-light`}>
          <strong>
            <FormattedNumber
              style={`currency`}
              value={includeVat ? product.formattedPriceInclVat : product.formattedPrice}
              currency={currency}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />/<T id="pc"/>
          </strong>
          {product.bonus !== 0 && (
            <div>
              <FormattedNumber
                style={`percent`}
                value={product.bonus/100}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />&nbsp;<T id="bonus"/>
            </div>
          )}
        </Col>
      </Row>
    )
  }

  onCountChange(countType, value) {
    this.setState({
      [countType]: value
    }, () => {
      if (this.state.showAlert) {
        const countRim = parseInt(this.state.count, 10)
        const countTyre = parseInt(this.state.countTyre, 10)
        const countTyreRear = parseInt(this.state.countTyreRear, 10)
        if (countRim === (countTyre + countTyreRear)) {
          this.setState({
            showAlert: false
          })
        }
      }
    })
  }

  onAddToCartClick(force = false) {
    const { isStaggeredFitment, confirm, rim, tyre, tyreRear, cartItems, shop } = this.props
    let countRim = parseInt(this.state.count, 10)
    let countTyre = parseInt(this.state.countTyre, 10)
    let countTyreRear = parseInt(this.state.countTyreRear, 10)

    if (isStaggeredFitment) {
      let canProceed = countRim === (countTyre + countTyreRear)
      if (!canProceed && !this.state.showAlert && !force) {
        return this.setState({
          showAlert: true
        })
      }
    } else {
      countTyre = countRim
      countTyreRear = 0
    }

    confirm(
      rim,
      tyre,
      tyreRear,
      countRim,
      countTyre,
      countTyreRear,
      cartItems,
      shop
    )
  }

  render() {
    const { isStaggeredFitment, tyre, tyreRear, rim, shop } = this.props
    if (!tyre || !rim)
      return null

    return (
      <div className="wheel-builder-confirmation">
        <div className={styles.section}>
          <Row>
            <Col>
              <b><T id="Selected tyres"/></b>
            </Col>
          </Row>
          {this.getProductComponent(tyre, shop.siteId)}
          {!!tyreRear && this.getProductComponent(tyreRear, shop.siteId)}
        </div>
        <div className={styles.section}>
          <Row>
            <Col>
              <b><T id="Selected rims"/></b>
            </Col>
          </Row>
          {this.getProductComponent(rim, shop.siteId)}
        </div>
        <div className={styles.section}>
          <Row>
            <Col md={9} sm={12}>
              {shop.completeWheelBuilderFinalText && (
               <div className={styles.text} dangerouslySetInnerHTML={{ __html: shop.completeWheelBuilderFinalText}} />
              )}
            </Col>
            <Col md={3} sm={12} className={styles.textRight}>
              {!!isStaggeredFitment && (
                <React.Fragment>
                  <div className={styles.flexEnd}>
                    <span>
                      <T id="Rim"/>:
                    </span>
                    <FormControl
                      bsClass={styles.input}
                      type="number"
                      name="count"
                      value={this.state.count}
                      onChange={(event) => this.onCountChange('count', event.target.value)}/> <T id="pcs"/>
                  </div>
                  <div className={styles.flexEnd}>
                    <span>
                      <T id="Front tyre"/>:
                    </span>
                    <FormControl
                      bsClass={styles.input}
                      type="number"
                      name="countTyre"
                      value={this.state.countTyre}
                      onChange={(event) => this.onCountChange('countTyre', event.target.value)}/> <T id="pcs"/>
                  </div>
                  <div className={styles.flexEnd}>
                    <span>
                      <T id="Rear tyre"/>:
                    </span>
                    <FormControl
                      bsClass={styles.input}
                      type="number"
                      name="countTyreRear"
                      value={this.state.countTyreRear}
                      onChange={(event) => this.onCountChange('countTyreRear', event.target.value)}/> <T id="pcs"/>
                  </div>
                </React.Fragment>
              )}

              {!isStaggeredFitment && (
                <div className={styles.flexEnd}>
                  <span>
                    <T id="Items"/>:
                  </span>
                  <FormControl
                    bsClass={styles.input}
                    type="number"
                    name="count"
                    value={this.state.count}
                    onChange={(event) => this.onCountChange('count', event.target.value)}/> <T id="pcs"/>
                </div>
              )}

              {!!this.state.showAlert && (
                <div>
                  <Alert bsStyle="danger" className={styles.alert}>
                    <h4><T id="Reminder" /></h4>
                    <p>
                      <T id="The number of rims doesn't match the number of tyres. Adjust the quantity or if this is want you want please continue by clicking 'Add to cart' button." />
                    </p>
                    <p>
                      <Button bsStyle="danger" onClick={() => this.onAddToCartClick(true)}>
                        <T id="Add to cart"/>
                      </Button>
                    </p>
                  </Alert>
                </div>
              )}

              {!this.state.showAlert && (
                <Button className={styles.button} onClick={() => this.onAddToCartClick()}>
                  <T id="Add to cart"/>
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  let tyre, tyreRear
  let isStaggeredFitment = Array.isArray(state.wheelBuilder.tyre)
  if (Array.isArray(state.wheelBuilder.tyre)) {
    tyre = state.wheelBuilder.tyre[0]
    tyreRear = state.wheelBuilder.tyre[1]
  } else {
    tyre = state.wheelBuilder.tyre
  }

  return {
    tyre,
    tyreRear,
    isStaggeredFitment,
    rim: state.wheelBuilder.rim,
    shop: state.shop,
    user: state.user.user,
    cartItems: state.cart.items,
    category: state.categories.activeCategory,
    includeVat: state.price.includeVat,
    currency: state.intl.currency
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  confirm: (rim, tyre, tyreRear, countRim, countTyre, countTyreRear, cartItems, shop) =>
    confirm(rim, tyre, tyreRear, countRim, countTyre, countTyreRear, cartItems, shop),
  // setWheelBuilder: isWheelBuilder => setWheelBuilder(isWheelBuilder),
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WheelBuilderConfirm)
