import React from 'react'
import { bindActionCreators } from 'redux'
import { Row, Col } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styles from './styles.less'
import UserInfo from './UserInfo'
import CustomerInfo from './CustomerInfo'
import CustomFields from './CustomFields'
import SignUpDone from './SignUpDone'
import { initializeSignUp } from '../../../actions/signUp'
import classnames from 'classnames'

const STEPS = {
  USER_INFO: 0,
  CUSTOMER_INFO: 1,
  CUSTOM_FIELDS: 2
}

class SignUp extends React.Component {
  constructor(props) {
    super(props)
    this.handleInputChange = this.onInputChange.bind(this)
  }

  componentDidMount() {
    const { initializeSignUp, shop } = this.props
    initializeSignUp(shop)
  }
  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    const { step, isEnabled, status } = this.props
    let component
    if (!isEnabled)
      return <Redirect to='/login'/>
    if (status)
      component = <SignUpDone />
    else if (step === STEPS.USER_INFO)
      component = <UserInfo/>
    else if (step === STEPS.CUSTOMER_INFO)
      component = <CustomerInfo/>
    else
      component = <CustomFields/>
    return (
      <div className={classnames(styles.container, 'sign-up')}>
        <Row>
          <Col md={8} mdOffset={2}>
            <h1><T id="Register new account"/></h1>
          </Col>
          <Col md={8} mdOffset={2}>
            <div className={styles.fields}>{component}</div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isEnabled: state.signUp.enabled,
    shop: state.shop,
    step: state.signUp.step,
    params: state.signUp.params,
    error: state.user.error,
    status: state.signUp.status
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  initializeSignUp: (shop) => initializeSignUp(shop)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp)
