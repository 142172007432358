import {
  RETRIEVE_ORDERS,
  ORDERS_RETRIEVED,
  RETRIEVE_ORDER,
  ORDER_RETRIEVED,
  RESULTS_PER_PAGE,
  SET_ORDER_SEARCH_PARAMS,
} from '../actions/orders'

const formatStatus = (order) => {
  switch (order.status) {
    // Product(s) are not stockable
    case 'unlimited':
      return 'Ingen transport';
    // Products have arrived from supplier to garage, or was already in local garage stock
    case 'local':
      return 'Redo att skicka';
    // Products have been shipped from supplier to garage
    case 'transit':
      //return 'Transport';
      //return 'Bearbetas';
      return 'Beställd';
    // Products have been shipped from supplier to customer
    case 'sent':
    case 'transit-to-customer':
      return 'Skickat';
    // Order has been cancelled
    case 'cancelled':
      return 'Annulerad';
    // Supplier order(s) has been created
    case 'ordered':
      //return 'Bearbetas';
      return 'Beställd';
    // Default is "we're preparing something"
    default:
      if (order.is_reservation) {
        return 'Reservation';
      } else {
        //return 'Ny order';
        return 'Beställd';
      }
  }
}

const getPaginationOptions = (count, currentPage) => {
  const pagination = []
  const maxPage = Math.ceil(count / RESULTS_PER_PAGE)
  const start = Math.max(1, currentPage - 3)
  const end = Math.min(start + 7, maxPage)

  pagination.push({
    text: '«',
    page: currentPage - 1,
    disabled: currentPage <= 1
  })
  for (let i = start; i <= end; i++) {
    pagination.push({
      text: i,
      page: i,
      active: currentPage === i
    })
  }
  pagination.push({
    text: '»',
    page: currentPage + 1,
    disabled: currentPage >= maxPage
  })
  return pagination
}

const formatOrder = (order) => {
  const formattedOrder = {}
  formattedOrder.formattedStatus = formatStatus(order)
  if (order.supplierOrderIds)
    formattedOrder.formattedSupplierOrderIds = order.supplierOrderIds.join(', ')
  formatOrder.hasDiscount = order.lines.findIndex(line => line.discount_value !== 0) > -1
  return Object.assign({}, order, formattedOrder)
}

const orders = (state = { orders: [], currentOrder: {}, areOrdersLoading: false, isCurrentOrderLoading: false, pagination: [] }, action) => {
  switch (action.type) {
    case SET_ORDER_SEARCH_PARAMS: {
      const search = {
        page: action.page,
        query: action.query,
        delivered: action.delivered,
        isInitialize: action.isInitialize
      }
      return Object.assign({}, state, {search})
    }
    case RETRIEVE_ORDERS:
      return Object.assign({}, state, {
        areOrdersLoading: true
      })
    case ORDERS_RETRIEVED:
      return Object.assign({}, state, {
        orders: action.orders.map(order => formatOrder(order)),
        areOrdersLoading: false,
        pagination:  getPaginationOptions(action.count, action.page),
      })
    case RETRIEVE_ORDER:
      return Object.assign({}, state, {
        isCurrentOrderLoading: true
      })
    case ORDER_RETRIEVED:
      return Object.assign({}, state, {
        currentOrder: formatOrder(action.order),
        isCurrentOrderLoading: false
      })
    default:
      return state
  }
}

export default orders
