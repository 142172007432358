// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__radioButtonLabel___l3U8A {
  border-radius: 0;
  border: 1px solid #d8d8d8 !important;
  font-weight: normal;
  background: #f0f0f0;
  color: #666;
}
.styles__radioButtonCheckedLabel___jQKwj {
  border-radius: 0;
  background: #fff;
  border: 1px solid #888 !important;
  font-weight: bold;
  opacity: 1;
  color: #666;
}
.styles__type___uPOeW {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/CheckoutComponent/CollectCustomerInfo/styles.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,oCAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;AACF;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,iCAAA;EACA,iBAAA;EACA,UAAA;EACA,WAAA;AAAF;AAGA;EACE,cAAA;AADF","sourcesContent":[".radioButtonLabel {\n  border-radius: 0;\n  border: 1px solid #d8d8d8 !important;\n  font-weight: normal;\n  background: #f0f0f0;\n  color: #666;\n}\n\n.radioButtonCheckedLabel {\n  border-radius: 0;\n  background: #fff;\n  border: 1px solid #888 !important;\n  font-weight: bold;\n  opacity: 1;\n  color: #666;\n}\n\n.type {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioButtonLabel": `styles__radioButtonLabel___l3U8A`,
	"radioButtonCheckedLabel": `styles__radioButtonCheckedLabel___jQKwj`,
	"type": `styles__type___uPOeW`
};
export default ___CSS_LOADER_EXPORT___;
