// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__placeholder___KpN57 {
  color: #999 !important;
}
.styles__inputDisplay___IOtnT {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border-radius: 0px;
  border: 1px solid #ccc;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  cursor: text;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Form/InputDecimal/styles.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AAEA;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,uBAAA;EACA,cAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;EACA,sBAAA;EACA,wEAAA;EACA,YAAA;AAAF","sourcesContent":[".placeholder {\n  color: #999 !important;\n}\n\n.inputDisplay {\n  display: block;\n  width: 100%;\n  height: 34px;\n  padding: 6px 12px;\n  font-size: 14px;\n  line-height: 1.42857143;\n  color: #555555;\n  background-color: #fff;\n  background-image: none;\n  border-radius: 0px;\n  border: 1px solid #ccc;\n  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;\n  cursor: text;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `styles__placeholder___KpN57`,
	"inputDisplay": `styles__inputDisplay___IOtnT`
};
export default ___CSS_LOADER_EXPORT___;
