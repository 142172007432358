import {
  SET_PRICE_OPTIONS
} from '../actions/price'

const initialState = {
  includeVat: true,
  selectedNumberOfPcs: 1,
  priceType: 'purchase'
}

const price = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRICE_OPTIONS:
      if (action.options) {
        const { includeVat, selectedNumberOfPcs, priceType } = action.options
        return { ...state, includeVat, selectedNumberOfPcs, priceType }
      } else {
        return {
          ...state,
          ...initialState,
          includeVat: action.customerType !== 'Business'
        }
      }
    default:
      return state
  }
}

export default price
