import React from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { T } from 'components/components/i18n'

const styles = {
  control: (base) => ({ ...base, borderRadius: 0, border: '1px solid #ccc', minHeight: '34px' }),
  valueContainer: (base) => ({ ...base, maxHeight: '68px', overflow: 'auto', width: '100%', paddingRight: '20px' }),
  clearIndicator: (base) => ({ ...base, position: 'absolute', right: 0, paddingLeft: 0 }),
  dropdownIndicator: () => ({ display: 'none' }),
  indicatorSeparator: () => ({ display: 'none' })
}

const CarApprovalMarkSelect = (props) => {
  const { selectedValue, onChange, name, carApprovalMarks } = props
  let options = carApprovalMarks.map(carApprovalMark => (
    {
      value: carApprovalMark.id,
      label: carApprovalMark.name
    }
  ))
  options.unshift({
    value: '',
    label: <T id="All"/>
  })

  const onSelectChange = (option) => {
    onChange(name,  option.value)
  }

  const selectedOption = options.find(option => option.value === selectedValue)
  return  (
    <T id="All">
      {
        msg => (
          <Select
            joinValues
            isSearchable
            onChange={onSelectChange}
            options={options}
            value={selectedOption}
            styles={styles}
            placeholder={msg}
          />
        )
      }
    </T>
  )
}

const mapStateToProps = state => {
  return {
    carApprovalMarks: state.shop.carApprovalMarks || []
  }
}

export default connect(
  mapStateToProps
)(CarApprovalMarkSelect)
