// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__app___Xg_qI {
  padding-bottom: 40px;
  min-height: 100%;
  background-color: #eee;
}
.styles__noContent___jwav_ {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles.less"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,gBAAA;EACA,sBAAA;AACF;AAEA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAF","sourcesContent":[".app {\n  padding-bottom: 40px;\n  min-height: 100%;\n  background-color: #eee;\n}\n\n.noContent {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `styles__app___Xg_qI`,
	"noContent": `styles__noContent___jwav_`
};
export default ___CSS_LOADER_EXPORT___;
