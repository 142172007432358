// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__list___NZrU3 {
  padding-top: 12px;
}
.styles__listItem___IkrYz {
  display: inline-block;
  color: #999;
}
.styles__listItemDivider___BOgVF {
  margin-left: 6px;
  margin-right: 6px;
}
.styles__link___uOUzE {
  color: #999;
  text-decoration: underline;
}
.styles__listItemActive___N03d8 {
  color: #777;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Page/styles.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAEA;EACE,qBAAA;EACA,WAAA;AAAF;AAGA;EAEE,gBAAA;EACA,iBAAA;AADF;AAIA;EACE,WAAA;EACA,0BAAA;AAFF;AAKA;EAEE,WAAA;AAHF","sourcesContent":[".list {\n  padding-top: 12px;\n}\n\n.listItem {\n  display: inline-block;\n  color: #999;\n}\n\n.listItemDivider {\n  composes: listItem;\n  margin-left: 6px;\n  margin-right: 6px;\n}\n\n.link {\n  color: #999;\n  text-decoration: underline;\n}\n\n.listItemActive {\n  composes: listItem;\n  color: #777;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `styles__list___NZrU3`,
	"listItem": `styles__listItem___IkrYz`,
	"listItemDivider": `styles__listItemDivider___BOgVF styles__listItem___IkrYz`,
	"link": `styles__link___uOUzE`,
	"listItemActive": `styles__listItemActive___N03d8 styles__listItem___IkrYz`
};
export default ___CSS_LOADER_EXPORT___;
