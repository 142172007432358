// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__flexIt___y8ueD {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.styles__popoverContent___NG67b {
  color: #fff;
  max-width: 320px !important;
}
.styles__popoverContent___NG67b .styles__infoItem___p7Gej {
  margin-bottom: 8px;
}
.styles__popoverContent___NG67b .styles__infoItem___p7Gej:last-child {
  margin-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchResults/ProductInfoPopover/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AADF;AAIA;EACE,WAAA;EACA,2BAAA;AAFF;AAAA;EAKI,kBAAA;AAFJ;AAHA;EASI,kBAAA;AAHJ","sourcesContent":["@import '../../../../../shared/variables.less';\n\n.flexIt {\n  display: flex;\n  flex-direction: column;\n  white-space: nowrap;\n}\n\n.popoverContent {\n  color: @eon-header-text-color;\n  max-width: 320px !important;\n\n  .infoItem {\n    margin-bottom: 8px;\n  }\n\n  .infoItem:last-child {\n    margin-bottom: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexIt": `styles__flexIt___y8ueD`,
	"popoverContent": `styles__popoverContent___NG67b`,
	"infoItem": `styles__infoItem___p7Gej`
};
export default ___CSS_LOADER_EXPORT___;
