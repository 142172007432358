import { api } from '../../helpers/api'
import { updateQueryParams } from '../../helpers/routing'
import { RESET_VEHICLE } from '../vehicle'

export const VEHICLE_RETRIEVED_BY_ID = 'VEHICLE_RETRIEVED_BY_ID'
export const MODELS_RETRIEVED = 'MODELS_RETRIEVED'
export const VARIANTS_RETRIEVED = 'VARIANTS_RETRIEVED'
export const SELECT_MANUFACTURER = 'SELECT_MANUFACTURER'
export const SELECT_MODEL = 'SELECT_MODEL'
export const SELECT_VARIANT = 'SELECT_VARIANT'
export const CAR_PICKER_INITIALIZED = 'CAR_PICKER_INITIALIZED'
export const INITIALIZING_CAR_PICKER = 'INITIALIZING_CAR_PICKER'
export const VEHICLE_SEARCH_ERROR = 'VEHICLE_SEARCH_ERROR'

const vehicleSearchError = response => {
  return {
    type: VEHICLE_SEARCH_ERROR,
    errorMessage: response.err
  }
}

export const initializeVehicleSearch = (shop, vehicleId, manufacturerId, modelId) => {
  const firstLevelPromises = [api(`${shop.backendUrl}/api/vehicle/manufacturer?customVehicleDatabase=${shop.customVehicleDatabase}`)]
  const selectedManufacturer = manufacturerId || shop.defaultVehicleManufacturer
  if (selectedManufacturer)
    firstLevelPromises.push(api(`${shop.backendUrl}/api/vehicle/model?manufacturer=${selectedManufacturer}&customVehicleDatabase=${shop.customVehicleDatabase}`))

  return dispatch => {
    dispatch({ type: INITIALIZING_CAR_PICKER })
    Promise.all(firstLevelPromises)
      .then(responses => Promise.all(responses.map(response => response.json())))
      .then(responses => {
        for (let i = 0; i < responses.length; i++) {
          if (responses[i].err)
            return dispatch(vehicleSearchError(responses[i])) 
        }
        const manufacturers = responses[0].data
        const models = responses[1] ? responses[1].data : []
        const selectedModel = modelId ? models.find(model => model.id.toString() === modelId.toString()) : null
        const secondLevelPromises = []
        if (selectedModel) {
          if (selectedModel.vehicleId) {
            secondLevelPromises.push(api(`${shop.apiUrl}/cars/${selectedModel.vehicleId}?isVehicleId=true&type=model&customVehicleDatabase=${shop.customVehicleDatabase}`))
          }
          else {
            if (vehicleId)
              secondLevelPromises.push(api(`${shop.apiUrl}/cars/${vehicleId}?isVehicleId=true&type=variant&customVehicleDatabase=${shop.customVehicleDatabase}`))
            secondLevelPromises.push(api(`${shop.backendUrl}/api/vehicle/variant?model=${modelId}&customVehicleDatabase=${shop.customVehicleDatabase}`))
          }
        }

        Promise.all(secondLevelPromises)
          .then(promiseResponses => Promise.all(promiseResponses.map(response => response.json())))
          .then((promiseResponses) => {
            for (let i = 0; i < promiseResponses.length; i++) {
              if (promiseResponses[i].err)
                return dispatch(vehicleSearchError(promiseResponses[i])) 
            }
            const vehicle = promiseResponses[0] && promiseResponses[0].data
            const variants = promiseResponses[1] && promiseResponses[1].data
            dispatch({
              type: CAR_PICKER_INITIALIZED,
              manufacturers,
              vehicle,
              models,
              variants,
              selectedManufacturer,
              selectedModel: selectedModel && selectedModel.id,
              selectedVariant: variants ? vehicleId : null
            })
          })
      })
  }
}

export const getVehicle = (id, type, shop) => {
  return api(`${shop.apiUrl}/cars/${id}?isVehicleId=true&type=${type}&customVehicleDatabase=${shop.customVehicleDatabase}`)
    .then(response => response.json())

}

export const selectManufacturer = (selectedManufacturer, shop, query) => {
  return dispatch => {
    dispatch({
      type: SELECT_MANUFACTURER,
      selectedManufacturer
    })
    dispatch({
      type: RESET_VEHICLE,
    })
    const manufacturerId = selectedManufacturer.value
    return api(`${shop.backendUrl}/api/vehicle/model?manufacturer=${manufacturerId}&customVehicleDatabase=${shop.customVehicleDatabase}`)
      .then(response => response.json())
      .then(response => {
        if (response.err)
          return dispatch(vehicleSearchError(response))
        dispatch(updateQueryParams(query, { manufacturerId }))
        dispatch({
          type: MODELS_RETRIEVED,
          models: response.data
        })
      })    
  }
}

export const selectModel = (selectedModel, shop, query) => {
  return dispatch => {
    dispatch({
      type: SELECT_MODEL,
      selectedModel
    })
    dispatch({
      type: RESET_VEHICLE,
    })
    if (selectedModel.vehicleId) {
      return getVehicle(selectedModel.vehicleId, 'model', shop)
        .then(response => {
          if (response.err)
            return dispatch(vehicleSearchError(response))
          const modelId = selectedModel.value
          dispatch(updateQueryParams(query, { modelId }))
          dispatch({
            type: VEHICLE_RETRIEVED_BY_ID,
            vehicle: response.data,
            vehicleId: selectedModel.vehicleId
          })
        })
    }

    return api(`${shop.backendUrl}/api/vehicle/variant?model=${selectedModel.value}&customVehicleDatabase=${shop.customVehicleDatabase}`)
      .then(response => response.json())
      .then(response => {
        if (response.err)
          return dispatch(vehicleSearchError(response))
        const modelId = selectedModel.value
        dispatch(updateQueryParams(query, { modelId }))
        dispatch({
          type: VARIANTS_RETRIEVED,
          variants: response.data
        })
      })
  }
}

export const selectVariant = (selectedVariant, shop, query) => {
  return dispatch => {
    dispatch({
      type: SELECT_VARIANT,
      selectedVariant
    })
    dispatch({
      type: RESET_VEHICLE,
    })
    return getVehicle(selectedVariant.value, 'variant', shop)
      .then(response => {
        if (response.err)
          return dispatch(vehicleSearchError(response))

        const vehicleId = selectedVariant.value
        dispatch(updateQueryParams(query, { vehicleId }))
        dispatch({
          type: VEHICLE_RETRIEVED_BY_ID,
          vehicle: response.data,
          vehicleId: selectedVariant.value
        })
      })
  }
}
