import React, { useEffect, useState} from 'react'
import { FormGroup, FormControl, ControlLabel, Row, Col } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeSearchParams } from '../../../../../../actions/search'
import InputDecimal from 'components/components/Form/InputDecimal'

const AdvancedSearch = (props) => {
  const {
    onFieldChange,
    category,
    changeSearchParams,
    width,
    diameter,
    boltPattern,
    et
  } = props

  const [timestamp, setTimestamp] = useState()

  useEffect(() => {
    setTimestamp(Date.now())
    return () => {
      changeSearchParams({
        diameter: [],
        width: '',
        boltPattern: '',
        et: ''
      }, category)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="rim-advanced" key={`rim-advanced-${timestamp}`}>
      <Row>
        <Col sm={6} xs={12}>
          <FormGroup>
            <ControlLabel>
              <T id="Width"/>
            </ControlLabel>
            <InputDecimal name="width" onChange={(event) => onFieldChange('width', event.target.value)} defaultValue={width} placeholder="6.5" />
          </FormGroup>
        </Col>
        <Col sm={6} xs={12}>
          <FormGroup>
            <ControlLabel>
              <T id="Diameter"/>
            </ControlLabel>
            <FormControl name="diameter" onChange={(event) => onFieldChange('diameter', event.target.value)} defaultValue={diameter} placeholder="16" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={12}>
          <FormGroup>
            <ControlLabel>
              <T id="Bolt pattern" />
            </ControlLabel>
            <InputDecimal name="boltPattern" onChange={(event) => onFieldChange('boltPattern', event.target.value)} defaultValue={boltPattern} placeholder="5x114.3" />
          </FormGroup>
        </Col>
        <Col sm={6} xs={12}>
          <FormGroup>
            <ControlLabel>
              <T id="Et"/>
            </ControlLabel>
            <FormControl name="et" onChange={(event) => onFieldChange('et', event.target.value)} defaultValue={et} placeholder="43" />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  changeSearchParams: (params, category) => changeSearchParams(params, category)
}, dispatch)

const mapStateToProps = state => {
  const { width, diameter, boltPattern, et } = state.rimSearchParams
  return {
    category: state.categories.activeCategory,
    width,
    diameter,
    boltPattern,
    et
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSearch)
