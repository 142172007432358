import React from 'react'
import { connect } from 'react-redux'
import { SEARCH_MODES } from '../../../../../constants/searchModes'
import  VehicleSearch from '../../VehicleSearch'
import styles from './styles.less'
import Diameter from '../Diameter'
import AdvancedSearch from './AdvancedSearch'
import Query from '../../TyreSearch/SearchMode/Query'

class SearchMode extends React.Component {
  render() {
    const {
      vehicle,
      onFieldChange,
      selectedSearchMode,
      query,
      onEnterPress,
      isAdvancedSearch
    } = this.props
    let component, diameters
    if (!selectedSearchMode)
      return null
    if (isAdvancedSearch)
      component = <AdvancedSearch onFieldChange={onFieldChange} />
    else if ([SEARCH_MODES.combined, SEARCH_MODES.dimension, SEARCH_MODES.licensePlate].indexOf(selectedSearchMode) !== -1)
      component = <Query value={query} selectedSearchMode={selectedSearchMode} onChange={onFieldChange} onEnterPress={onEnterPress} />
    else
      component = <VehicleSearch className={styles.component} query={query}/>
    if (vehicle && vehicle.description) {
      diameters = <Diameter vehicle={vehicle} onSearchParamChange={this.props.onSearchParamChange} allDiameters={vehicle.diameters}/>
    }

    return (
      <div className="rim-search-mode">
        {component}
        {diameters}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let vehicle
  if (ownProps.selectedSearchMode === SEARCH_MODES.carModel)
    vehicle = state.carPicker.vehicle
  else
    vehicle = state.vehicle && state.vehicle.vehicle

  return {
    vehicle,
    shop: state.shop
  }
}

export default connect(
  mapStateToProps
)(SearchMode)

