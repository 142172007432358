import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import styles from './styles.less'
import classnames from 'classnames'

class DeliveryTimeIcon extends React.Component {
  render() {
    const { icon } = this.props
    switch(icon) {
      case "error":
        return <FontAwesomeIcon icon={faExclamationTriangle} className={classnames(styles.icon, 'exact-delivery-time-icon')} />
      default:
        return null
    }
  }
}

export default DeliveryTimeIcon
