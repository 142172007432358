import React from 'react'
import { T } from 'components/components/i18n'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { connect } from 'react-redux'
import Button from '../../Button'
import styles from './styles.less'
import classnames from 'classnames'
import { isMobile } from '../../../../helpers/general'
import SearchInfo from './searchInfo'

const SearchButton = (props) => {
  const { onClick, isLoading, disabled, className, error, isFormValid, category } = props
  let errorMsg
  if (error)
    errorMsg = <span className={classnames(styles.errorMessage, 'error')}>{error}</span>

  const Btn = (
    <Button
      onClick={onClick}
      disabled={disabled || !isFormValid}
      className={classnames(className, styles.button, 'search-button')}
      inProgress={isLoading}
      label={<T id="Search"/>}
    />
  )
  const BtnWithHelp = (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={isMobile() ? 'bottom' : 'right'}
      overlay={
        <Popover id="popoverSearchInfo" className={styles.popoverSearchInfo}>
          <SearchInfo category={category} />
        </Popover>
      }
    >
      <span>
        {Btn}
      </span>
    </OverlayTrigger>
  )

  return (
    <div>
      {category === 'tyre' ? BtnWithHelp : Btn}
      {errorMsg}
    </div>
  )
}

const mapStateToProps = state => {
  const searchState = state.search.resultsByCategory[state.categories.activeCategory.id]
  return {
    category: state.categories.activeCategory.id,
    isLoading: searchState.isLoading,
    shop: state.shop,
    isFormValid: state.search.isFormValid
  }
}

export default connect(mapStateToProps)(SearchButton)
