// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__number___T82Dr {
  text-align: right;
}
.styles__comment___SjWkI {
  width: 100%;
  max-width: 25% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Order/OrderLine/styles.less"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD;AAEA;EACC,WAAA;EACA,yBAAA;AAAD","sourcesContent":[".number {\n\ttext-align: right;\n}\n\n.comment {\n\twidth: 100%;\n\tmax-width: 25% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"number": `styles__number___T82Dr`,
	"comment": `styles__comment___SjWkI`
};
export default ___CSS_LOADER_EXPORT___;
