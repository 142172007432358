import React from 'react'
import { connect } from 'react-redux'
import { FormGroup, Row, Col, ControlLabel, FormControl, Popover, Overlay } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import styles from './styles.less'
import RangeSelector from '../../../Form/RangeSelector'
import Checkbox from '../../../Checkbox'
import CarApprovalMarkSelect from '../CarApprovalMarkSelect'
import Selectbox from '../../../Form/Selectbox'
import ProductComment from '../../ProductComment'
import classnames from 'classnames'

const WET_GRIP_ROLLING_RESISTANCE = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G'
]

const allValuesLabel = <T id="All"/>
const LOAD_INDEX_OPTIONS =[
  ['', allValuesLabel],
  [62, '62 (265kg)'],
  [63, '63 (272kg)'],
  [64, '64 (280kg)'],
  [65, '65 (290kg)'],
  [66, '66 (300kg)'],
  [67, '67 (307kg)'],
  [68, '68 (315kg)'],
  [69, '69 (325kg)'],
  [70, '70 (335kg)'],
  [71, '71 (345kg)'],
  [72, '72 (355kg)'],
  [73, '73 (365kg)'],
  [74, '74 (375kg)'],
  [75, '75 (387kg)'],
  [76, '76 (400kg)'],
  [77, '77 (412kg)'],
  [78, '78 (425kg)'],
  [79, '79 (437kg)'],
  [80, '80 (450kg)'],
  [81, '81 (462kg)'],
  [82, '82 (475kg)'],
  [83, '83 (487kg)'],
  [84, '84 (500kg)'],
  [85, '85 (515kg)'],
  [86, '86 (530kg)'],
  [87, '87 (545kg)'],
  [88, '88 (560kg)'],
  [89, '89 (580kg)'],
  [90, '90 (600kg)'],
  [91, '91 (615kg)'],
  [92, '92 (630kg)'],
  [93, '93 (650kg)'],
  [94, '94 (670kg)'],
  [95, '95 (690kg)'],
  [96, '96 (710kg)'],
  [97, '97 (730kg)'],
  [98, '98 (750kg)'],
  [99, '99 (775kg)'],
  [100, '100 (800kg)'],
  [101, '101 (825kg)'],
  [102, '102 (850kg)'],
  [103, '103 (875kg)'],
  [104, '104 (900kg)'],
  [105, '105 (925kg)'],
  [106, '106 (950kg)'],
  [107, '107 (975kg)'],
  [108, '108 (1000kg)'],
  [109, '109 (1030kg)'],
  [110, '110 (1060kg)'],
  [111, '111 (1090kg)'],
  [112, '112 (1120kg)'],
  [113, '113 (1150kg)'],
  [114, '114 (1180kg)'],
  [115, '115 (1215kg)'],
  [116, '116 (1250kg)'],
  [117, '117 (1285kg)'],
  [118, '118 (1320kg)'],
  [119, '119 (1360kg)'],
  [120, '120 (1400kg)'],
  [121, '121 (1450kg)'],
  [122, '122 (1500kg)'],
  [123, '123 (1550kg)'],
  [124, '124 (1600kg)'],
  [125, '125 (1650kg)'],
  [126, '126 (1700kg)']
]

const SPEED_INDEX_OPTIONS = [
  ['', allValuesLabel],
  ['q', 'Q (160 km/h)'],
  ['r', 'R (170 km/h)'],
  ['s', 'S (180 km/h)'],
  ['t', 'T (190 km/h)'],
  ['u', 'U (200 km/h)'],
  ['h', 'H (210 km/h)'],
  ['v', 'V (240 km/h)'],
  ['w', 'W (270 km/h)'],
  ['y', 'Y (300 km/h)'],
  ['vr', 'VR (>210 km/h)'],
  ['zr', 'ZR (>240 km/h)']
]

const TyreSearchAdvanced = (props) => {
  const [showPopover, setShowPopover] = React.useState({
    isRunflat: false,
    isEnforced: false,
    isSilence: false,
    isElectricVehicle: false,
  })

  const onFieldChange = (name, value) => {
    props.onAdvancedFieldChange(name, value)
  }

  const onChange = (event) => {
    const { name, type } = event.target
    const value = type === 'checkbox' ? event.target.checked : event.target.value
    props.onAdvancedFieldChange(name, value)
  }

  const {
    rollingResistance,
    wetGrip,
    noiseEmissionDecibel,
    speedIndex,
    loadIndex,
    loadIndexRear,
    isRunflat,
    isSilence,
    isElectricVehicle,
    isEnforced,
    minQuantityInStock,
    carApprovalMark,
    comment,
  } = props.searchParams

  const {
    isShowRearLoadIndex,
    isRear,
  } = props

  const loadIndexOptions = LOAD_INDEX_OPTIONS.map(loadIndex => {
    return {
      value: loadIndex[0].toString(),
      label: loadIndex[1]
    }
  })
  const speedIndexOptions = SPEED_INDEX_OPTIONS.map(speedIndex => {
    return {
      value: speedIndex[0],
      label: speedIndex[1]
    }
  })

  const wetGripAndRollingResistanceOptions = WET_GRIP_ROLLING_RESISTANCE.map(option => {
    return {
      value: option,
      label: option
    }
  })
  wetGripAndRollingResistanceOptions.unshift({
    value: '',
    label: allValuesLabel
  })

  const PRODUCT_PROPERTIES = {
    isEnforced: {
      label: <T id="XL" />,
      description: <T id="Extra reinforced tyre with extra load (Extra load)" />,
      isChecked: isEnforced,
      ref: React.useRef(null),
    },
    isRunflat: {
      label: <T id="Runflat" />,
      description: <T id="Run-flat tyre, designed to resist the effects of deflation when punctured" />,
      isChecked: isRunflat,
      ref: React.useRef(null),
    },
    isSilence: {
      label: <T id="Acoustic tyre" />,
      description: <T id="Acoustic tires are designed to reduce air vibrations and thus reduce noise inside the car. This results in a quieter and more comfortable driving experience for the driver and passengers." />,
      isChecked: isSilence,
      ref: React.useRef(null),
    },
    isElectricVehicle: {
      label: <T id="Electric vehicle"/>,
      description: <T id="Tyres that are adapted for electric and hybrid vehicles" />,
      isChecked: isElectricVehicle,
      ref: React.useRef(null),
    },
  }

  const onMouseEnter = (event) => {
    if (event.currentTarget) {
      const elementName = event.currentTarget.getAttribute('name')
      setShowPopover(state => {
        return {
          ...state,
          [elementName]: true
        }
      })
    }
  }

  const onMouseLeave = (event) => {
    if (event.currentTarget) {
      const elementName = event.currentTarget.getAttribute('name')
      setShowPopover(state => {
        return {
          ...state,
          [elementName]: false
        }
      })
    }
  }

  const properties = []
  for (let key in PRODUCT_PROPERTIES) {
    properties.push(
      <Checkbox
        key={`checkbox_${key}`}
        name={key}
        onChange={onFieldChange}
        isChecked={PRODUCT_PROPERTIES[key].isChecked}
        label={
          <div ref={PRODUCT_PROPERTIES[key].ref}>
            <div
              name={key}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}>
              {PRODUCT_PROPERTIES[key].label}
            </div>
            <Overlay placement="left" show={showPopover[key]} target={PRODUCT_PROPERTIES[key].ref.current}>
              <Popover placement="left" id={`checkboxPopover_${key}`} className="background-normal" style={{ color: 'white' }}>
                {PRODUCT_PROPERTIES[key].description}
              </Popover>
            </Overlay>
          </div>
        }
      />
    )
  }

  // TODO: speedIndex, loadIndex front + rear or single
  const _speedIndex = Array.isArray(speedIndex) ? speedIndex[0] : speedIndex
  const _loadIndex = Array.isArray(loadIndex) ? loadIndex[0] : loadIndex
  const _loadIndexRear = Array.isArray(loadIndexRear) ? loadIndexRear[0] : loadIndexRear

  return (
    <div className={classnames(styles.wrapper, 'tyre-search-advanced')}>
      <Row>
        <Col md={2} xs={12}>
          <FormGroup>
            <ControlLabel><T id="Speed index"/></ControlLabel>
            <Selectbox
              name="speedIndex"
              options={speedIndexOptions}
              value={speedIndexOptions.find(option => option.value === String((_speedIndex && _speedIndex.toLowerCase()) || ''))}
              onChange={option => onFieldChange('speedIndex', option.value)}
            />
          </FormGroup>
        </Col>
        <Col md={2} xs={12}>
          <FormGroup>
            <ControlLabel><T id="Load index"/></ControlLabel>
            <div className={styles.loadIndexWrapper}>
              {!!isShowRearLoadIndex && (
                <span><T id="Front" /></span>
              )}
              <Selectbox
                name="loadIndex"
                options={loadIndexOptions}
                value={loadIndexOptions.find(option => option.value === String((isRear ? _loadIndexRear : _loadIndex) || ''))}
                onChange={option => onFieldChange('loadIndex', option.value)}
              />
            </div>
            {!!isShowRearLoadIndex && (
              <div className={styles.loadIndexWrapper}>
                <span><T id="Rear" /></span>
                <Selectbox
                  name="loadIndexRear"
                  options={loadIndexOptions}
                  value={loadIndexOptions.find(option => option.value === String(_loadIndexRear || ''))}
                  onChange={option => onFieldChange('loadIndexRear', option.value)}
                />
              </div>
            )}
          </FormGroup>
        </Col>
        <Col md={2} xs={12}>
        <FormGroup>
          <ControlLabel><T id="Rolling resistance"/></ControlLabel>
          <RangeSelector
            shop={props.shop}
            className={styles.formControl}
            name="rollingResistance"
            onChange={onFieldChange}
            value={rollingResistance === '' ? 'G' : rollingResistance}
            options={WET_GRIP_ROLLING_RESISTANCE}
          />
        </FormGroup>
        </Col>
        <Col md={2} xs={12}>
        <FormGroup>
          <ControlLabel><T id="Wet grip"/></ControlLabel>
          <RangeSelector
            shop={props.shop}
            className={styles.formControl}
            name="wetGrip"
            onChange={onFieldChange}
            value={wetGrip === '' ? 'G' : wetGrip}
            options={WET_GRIP_ROLLING_RESISTANCE}
          />
        </FormGroup>
        </Col>
        <Col md={2} xs={12}>
        <FormGroup className={styles.control}>
          <ControlLabel><T id="Noise emission (db)"/></ControlLabel>
          <T id="All">
            {
              msg => (
                <FormControl
                  type="number"
                  min={1}
                  max={300}
                  value={noiseEmissionDecibel}
                  placeholder={msg}
                  name="noiseEmissionDecibel"
                  onChange={onChange}
                />
              )
            }
          </T>
        </FormGroup>
        </Col>
        <Col md={2} xs={12}>
        <FormGroup className={styles.control}>
          <ControlLabel>
            <T id="Characteristics"/>
          </ControlLabel>
          {properties}
        </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <FormGroup>
            <ControlLabel>
              <T id="Car approval mark"/>
            </ControlLabel>
            <CarApprovalMarkSelect
              onChange={onFieldChange}
              name="carApprovalMark"
              selectedValue={carApprovalMark}
            />
          </FormGroup>
        </Col>
        <Col md={2} xs={12}>
          <FormGroup>
            <ControlLabel>
              <T id="Items in stock"/>
            </ControlLabel>
            <FormControl
              min="1"
              max="99"
              type="number"
              name="minQuantityInStock"
              value={minQuantityInStock}
              onChange={onChange}
              className={styles.input}
            />
          </FormGroup>
        </Col>
        <Col md={2} xs={12}>
          <ProductComment value={comment} onChange={onFieldChange} />
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => {
  // TODO: Rims, complete wheels...,
  const isFrontRearDimensions = !!(
    Array.isArray(state.tyreSearchParams.tyreDimensions) &&
    state.tyreSearchParams.tyreDimensions.length === 2 &&
    state.tyreSearchParams.tyreDimensions[0] &&
    state.tyreSearchParams.tyreDimensions[1]
  )
  const isContainVehicle = !!(state.vehicle.vehicle || state.carPicker.vehicle)
  const isRear = Array.isArray(state.tyreSearchParams.tyreDimensions) &&
    state.tyreSearchParams.tyreDimensions.length === 2 &&
    !state.tyreSearchParams.tyreDimensions[0]
  return {
    isShowRearLoadIndex: !!(isContainVehicle && isFrontRearDimensions),
    isRear
  }
}

export default connect(
  mapStateToProps,
)(TyreSearchAdvanced)

// export default TyreSearchAdvanced
