import { LOCATION_CHANGE } from 'react-router-redux'
import { getCategoryBySlug } from '../helpers/categories'

const categories = (state = {
  activeCategory: null
}, action) => {
  switch(action.type) {
    case LOCATION_CHANGE:
      const currentRoute = action.payload.pathname.indexOf('hjulpaket') === -1 ? action.payload.pathname.replace('/hjul', '').split('/') : action.payload.pathname.split('/')
      const activeSlug = currentRoute[1]
      const activeCategory = getCategoryBySlug(activeSlug)
      return Object.assign({}, state, { activeCategory })
    default:
      return state
  }
}

export default categories
