import React from 'react'
import styles from './styles.less'
import { FormGroup, ControlLabel, HelpBlock, Popover, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from 'helpers/general'
import { T } from 'components/components/i18n'

export default class InputGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value || ''
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      value: newProps.value || ''
    })
  }

  onChange(event) {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      value: event.target.value
    })
    if(this.props.onChange && event.target.value && event.target.value !== '')
      this.props.onChange(event.target.value)
  }

  render() {
    const { type, name, label, placeholder, onBlur, disabled, error, className, maxlength, hintText } = this.props
    const { value } = this.state
    const errorMessage = error ? <T id="You need to enter a valid value"/> : null
    const validationState = error ? 'error' : null
    let controlLabel, helpBlock
    if (label) {
      controlLabel = <ControlLabel>{label}</ControlLabel>
    }
    if (errorMessage) {
      helpBlock = <HelpBlock>{errorMessage}</HelpBlock>
    }
    const hintInput = !!hintText ? styles['hint-input'] : ''

    return  (
      <FormGroup validationState={validationState} className={`${className} input-group`}>
        {controlLabel}
        <div className={styles['input-wrapper']}>
          <input
            className={`form-control ${hintInput}`}
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onBlur={onBlur}
            onChange={this.onChange.bind(this)}
            maxlength={maxlength || null}
          />
          {!!hintText && (
            <OverlayTrigger
              placement={isMobile() ? 'top' : 'right'}
              overlay={
                <Popover>
                  <T id={hintText} />
                </Popover>
              }
            >
              <button className={`button-link ${styles['hint-icon']}`}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </button>
            </OverlayTrigger>
          )}
        </div>
        {helpBlock}
      </FormGroup>
      )
  }
}
