// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__input-wrapper___um7j3 {
  position: relative;
  display: flex;
  align-items: center;
}
.styles__search-help___KgzkU {
  margin-left: -26px;
  cursor: pointer;
}
.styles__search-help-wrapper___rlaBj {
  margin-top: 8px;
}
.styles__input___vvXgn {
  width: 239px !important;
  padding-right: 26px !important;
}
.styles__link___Wz716 {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.styles__carInfoIcon___UyEK5 {
  margin-left: 3px;
  margin-top: 1px;
}
.styles__popoverCarInfo___nGWCt {
  min-width: 360px !important;
  max-width: 720px !important;
}
@media only screen and (max-width: 1199px) {
  .styles__input___vvXgn {
    width: 250px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/TyreSearch/SearchMode/Query/styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;AACF;AAEA;EACE,kBAAA;EACA,eAAA;AAAF;AAGA;EACE,eAAA;AADF;AAIA;EACE,uBAAA;EACA,8BAAA;AAFF;AAKA;EACE,aAAA;EACA,eAAA;EACA,mBAAA;AAHF;AAMA;EACE,gBAAA;EACA,eAAA;AAJF;AAOA;EACE,2BAAA;EACA,2BAAA;AALF;AAQA;EACE;IACE,uBAAA;EANF;AACF","sourcesContent":[".input-wrapper {\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\n.search-help {\n  margin-left: -26px;\n  cursor: pointer;\n}\n\n.search-help-wrapper {\n  margin-top: 8px;\n}\n\n.input {\n  width: 239px !important;\n  padding-right: 26px !important;\n}\n\n.link {\n  display: flex;\n  cursor: pointer;\n  align-items: center;\n}\n\n.carInfoIcon {\n  margin-left: 3px;\n  margin-top: 1px;\n}\n\n.popoverCarInfo {\n  min-width: 360px !important;\n  max-width: 720px !important;\n}\n\n@media only screen and (max-width: 1199px)  {\n  .input {\n    width: 250px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-wrapper": `styles__input-wrapper___um7j3`,
	"search-help": `styles__search-help___KgzkU`,
	"search-help-wrapper": `styles__search-help-wrapper___rlaBj`,
	"input": `styles__input___vvXgn`,
	"link": `styles__link___Wz716`,
	"carInfoIcon": `styles__carInfoIcon___UyEK5`,
	"popoverCarInfo": `styles__popoverCarInfo___nGWCt`
};
export default ___CSS_LOADER_EXPORT___;
