import { allWinterTyreTypesId } from 'constants/tyreTypes'

export const getTyreAlternatives = state => {
  const { vehicle } = state.vehicle.vehicle ? state.vehicle : state.carPicker
  const selectedDiameter = state.wheelBuilder.rim && state.wheelBuilder.rim.attrs.diameter
  if (!vehicle || !vehicle.tyreSizes || vehicle.tyreSizes.length === 0)
    return []

  if (selectedDiameter) {
    return vehicle.tyreSizes.filter(size => size.diameter === selectedDiameter)
  }

  return vehicle.tyreSizes
}

export const getBestOption = (sizes, diameter, position) => {
  let filteredSizes = sizes
  if (diameter) {
    filteredSizes = filteredSizes
      .filter(size => size.diameter === diameter.toString())
  }
  const minDeviation = Math.min(...filteredSizes
    .filter(size => position ? size.position === position : true)
    .map(size => size.deviationPercent))
  const bestOption = filteredSizes
    .find(option => option.deviationPercent === minDeviation && (position ? option.position === position: true))
  return bestOption
}

export const getStandard = state => {
  const { vehicle } = state.vehicle.vehicle ? state.vehicle : state.carPicker
  return vehicle && vehicle.standard
}

export const isWinter = tyreType => {
  return tyreType && tyreType.length && allWinterTyreTypesId.includes(String(tyreType[0]))
}
