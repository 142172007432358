import React from 'react'
import { FormControl, Popover, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styles from './styles.less'
import { isMobile } from 'helpers/general'
import { T } from 'components/components/i18n'

const CommentInput = (props) => {
  const {
    placeholder,
    className,
    onChange,
    maxLength,
    value,
  } = props
  return  (
    <div className={styles['comment-input-wrapper']}>
      <FormControl
        placeholder={placeholder}
        className={`${className} ${styles['comment-input']}`}
        onChange={onChange}
        value={value || null}
        maxLength={maxLength || 500}
      />
      <OverlayTrigger
        placement={isMobile() ? 'top' : 'right'}
        overlay={
          <Popover>
            <T id="Add extra info to the order, eg. driver’s or customer’s name, contact details, and etc." />
          </Popover>
        }
      >
        <button className={`button-link ${styles['comment-input-icon']}`}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </button>
      </OverlayTrigger>
    </div>
  )
}

export default CommentInput