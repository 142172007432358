import React from 'react'
import styles from './styles.less'
import { TyreTypeSvg } from '../../../../SvgIcon'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export class TypeOption extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.option.value
    }
    this.handleCheckedChange = this.onChange.bind(this)
  }

  onChange(event) {
    this.props.onChange(this.props.option.id)
  }

  render () {
    const { option, isChecked } = this.props
    let componentClass, iconClass
    if (isChecked) {
      iconClass = option.checkedClass || 'svg-colored'
      componentClass = `${styles.typeOption} tyreSearch_typeOption border-colored background-light text-colored`
    } else {
      iconClass = option.uncheckedClass || 'svg-muted'
      componentClass = `${styles.typeOption} tyreSearch_typeOption border-muted text-muted`
    }

    return (
      <div onClick={this.handleCheckedChange} className={classnames(componentClass, 'tyre-type-option')}>
        {!!isChecked && <FontAwesomeIcon className={styles.checkedIcon} icon={faCheck} />}
        <div className={styles.icon}><TyreTypeSvg type={option.id} className={iconClass} /></div>
        <div className={styles.label}>{option.label}</div>
      </div>
    )
  }
}

export default TypeOption
