// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__container___fvFlw {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 36px;
}
.styles__address___Y5YCD {
  display: flex;
}
.styles__item___SbaFX {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #eee;
  margin-top: 20px;
}
.styles__distance___UwnJS {
  font-size: 24px;
  padding: 10px;
}
.styles__name___VvrmO {
  font-size: 24px;
}
.styles__text___rXjgg {
  padding: 10px;
}
.styles__header___yyEdD {
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/GaragesList/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;AACF;AAEA;EACE,aAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;AADF;AAIA;EACE,eAAA;EACA,aAAA;AAFF;AAKA;EACE,eAAA;AAHF;AAMA;EACE,aAAA;AAJF;AAOA;EACE,aAAA;EACA,8BAAA;AALF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  background-color: white;\n  padding: 36px;\n}\n\n.address {\n  display: flex;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  background-color: #eee;\n  margin-top: 20px;\n}\n\n.distance {\n  font-size: 24px;\n  padding: 10px;\n}\n\n.name {\n  font-size: 24px;\n}\n\n.text {\n  padding: 10px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container___fvFlw`,
	"address": `styles__address___Y5YCD`,
	"item": `styles__item___SbaFX`,
	"distance": `styles__distance___UwnJS`,
	"name": `styles__name___VvrmO`,
	"text": `styles__text___rXjgg`,
	"header": `styles__header___yyEdD`
};
export default ___CSS_LOADER_EXPORT___;
