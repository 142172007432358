// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__table___Lik9O {
  margin-top: 12px;
  width: 100%;
}
.styles__footTdPrefix___Y37n0 {
  text-align: right;
  font-weight: bold;
  border: 0;
}
.styles__number___dxOKU {
  text-align: right;
}
.styles__noBorder___I5Z3_ {
  border: 0 !important;
}
.styles__footPadding___O9XM3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.styles__tdGross___vSjUY {
  text-align: right;
  border-top: 1px solid #aaa;
  border-bottom: 3px double #aaa;
}
.styles__shipmentTrackingNumber___iCoEB {
  display: block;
}
.styles__supplierHead___tNQGd {
  min-width: 150px;
}
.styles__euGradingHead___Dar6L {
  min-width: 190px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Order/OrderLines/styles.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;AACF;AAEA;EACE,iBAAA;EACA,iBAAA;EACA,SAAA;AAAF;AAGA;EACE,iBAAA;AADF;AAIA;EACE,oBAAA;AAFF;AAKA;EACE,2BAAA;EACA,8BAAA;AAHF;AAMA;EAZE,iBAAA;EAcA,0BAAA;EACA,8BAAA;AAJF;AAOA;EACE,cAAA;AALF;AAQA;EACE,gBAAA;AANF;AASA;EACE,gBAAA;AAPF","sourcesContent":[".table {\n  margin-top: 12px;\n  width: 100%;\n}\n\n.footTdPrefix {\n  text-align: right;\n  font-weight: bold;\n  border: 0;\n}\n\n.number {\n  text-align: right;\n}\n\n.noBorder {\n  border: 0 !important;\n}\n\n.footPadding {\n  padding-top: 3px !important;\n  padding-bottom: 3px !important;\n}\n\n.tdGross {\n  .number();\n  border-top: 1px solid #aaa;\n  border-bottom: 3px double #aaa;\n}\n\n.shipmentTrackingNumber {\n  display: block;\n}\n\n.supplierHead {\n  min-width: 150px;\n}\n\n.euGradingHead {\n  min-width: 190px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `styles__table___Lik9O`,
	"footTdPrefix": `styles__footTdPrefix___Y37n0`,
	"number": `styles__number___dxOKU`,
	"noBorder": `styles__noBorder___I5Z3_`,
	"footPadding": `styles__footPadding___O9XM3`,
	"tdGross": `styles__tdGross___vSjUY`,
	"shipmentTrackingNumber": `styles__shipmentTrackingNumber___iCoEB`,
	"supplierHead": `styles__supplierHead___tNQGd`,
	"euGradingHead": `styles__euGradingHead___Dar6L`
};
export default ___CSS_LOADER_EXPORT___;
