import {
    TYRE_BRANDS_RETRIEVED,
    RIM_BRANDS_RETRIEVED
} from '../actions/brands'

const brands = (state = { rimBrands: [], tyreBrands: [] }, action) => {
    switch (action.type) {
        case TYRE_BRANDS_RETRIEVED:
        	return Object.assign({}, state, { tyreBrands: action.brands })
        case RIM_BRANDS_RETRIEVED:
        	return Object.assign({}, state, { rimBrands: action.brands })
        default:
            return state
    }
}

export default brands