import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import store from './store'
import App from './components'
import { addLocaleData } from 'react-intl';
import se from 'react-intl/locale-data/sv';
import en from "react-intl/locale-data/en";
import nb from "react-intl/locale-data/nb";
import styles from './styles.less'
import './custom_bootstrap.less'
import 'babel-polyfill'

const target = document.querySelector('#root')
target.className = styles.root
addLocaleData([...se, ...en, ...nb])

render(
  <Provider store={store}>
    <App />
  </Provider>,
  target
)
