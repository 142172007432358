import { api } from '../../helpers/api'
import { getCategoryByTypeId } from '../../helpers/categories'

export const ITEM_REMOVED = 'ITEM_REMOVED'
export const ITEM_UPDATED = 'ITEM_UPDATED'
export const ITEM_ADDED = 'ITEM_ADDED'
export const SET_CART_ITEMS = 'SET_CART_ITEMS'
export const SET_ITEM_IN_PROGRESS = 'SET_ITEM_IN_PROGRESS'
export const SET_SINGLE_DELIVERY_TIME = 'SET_SINGLE_DELIVERY_TIME'
export const SET_DELIVERY_TIME_UPDATE_IN_PROGRESS = 'SET_DELIVERY_TIME_UPDATE_IN_PROGRESS'
export const REMOVE_SINGLE_DELIVERY_TIME = 'REMOVE_SINGLE_DELIVERY_TIME'
export const setCartItems = (items) => {
  return {
    type: SET_CART_ITEMS,
    items
  }
}

export const fetchCart = () => {
  return (dispatch, getState) => {
    return api(`${getState().shop.apiUrl}/cart`)
      .then(response => response.json())
      .then(response => {
        const items = response.data.items.map(item => ({
          ...item,
          ...item.product,
          category: getCategoryByTypeId(item.product.type.id),
          count: item.quantity,
          comment: item.comment
        }))
        dispatch(setCartItems(items))
      })
  }
}

export const addItem = (itemId, count, shop, cartItems, comment) => {
  return (dispatch, getState) => {
    const { cart } = getState()
    if (count > 0) {
      dispatch(setItemUpdateInProgress(itemId, true))
      return api(`${shop.apiUrl}/products/${itemId}`)
        .then(response => {
          return response.json()
        })
        .then(response => {
          const product = response.data[0]
          const currentItems = [...getState().cart.items]
          const updatedItem = currentItems.find(item => item.productId === product.productId && item.wheel === product.wheel)
          if (updatedItem) {
            updatedItem.comment = comment
            updatedItem.count += parseInt(count, 10)
          }
          else {
            product.count = parseInt(count,10)
            product.comment = comment
            const vehicle = getState().vehicle.vehicle || {}
            product.car = { licenseplate: vehicle.licenseplate }
            if (vehicle.vehicleId)
              product.car.vehicleId = vehicle.vehicleId.toString();
            currentItems.push(product)
          }
          dispatch(updateCart(currentItems.concat(cart.unselectedAutoproducts)))
            .then(() => dispatch(setItemUpdateInProgress(itemId, false)))
        })
    }
  }
}

export const addItemsGroup = (items, shop) => {
  return (dispatch, getState) => {
    const { cart } = getState()
    const cartItems  = [...getState().cart.items]
    const inProgress = []
    const pAll       = []

    items.forEach(item => {
      const {itemId, count, comment} = item
      if (count > 0) {
        pAll.push(new Promise((resolve) => {
          dispatch(setItemUpdateInProgress(itemId, true))
          inProgress.push(itemId)
          api(`${shop.apiUrl}/products/${itemId}`)
            .then(response => {
              return response.json()
            })
            .then(response => {
              const product = response.data[0]
              const currentItems = [...getState().cart.items]
              const updatedItem = currentItems.find(item => item.productId === product.productId && item.wheel === product.wheel)
              if (updatedItem) {
                updatedItem.comment = comment
                updatedItem.count += parseInt(count, 10)
              } else {
                product.count = parseInt(count,10)
                product.comment = comment
                const vehicle = getState().vehicle.vehicle || {}
                product.car = { licenseplate: vehicle.licenseplate }
                if (vehicle.vehicleId)
                  product.car.vehicleId = vehicle.vehicleId.toString();
                cartItems.push(product)
              }
              resolve(product.id)
            }).catch(() => {
              // TODO: Ignore errors for now
              resolve()
            })
        })) // pAll.push
      } // if count > 0
    }) // items.forEach


    Promise.all(pAll)
      .then((values) => {
        const items = values.filter(value => value !== null && value !== undefined)
        if (items.length) {
          dispatch(updateCart(cartItems.concat(cart.unselectedAutoproducts)))
            .then(() => {
              inProgress.forEach(itemId => {
                dispatch(setItemUpdateInProgress(itemId, false))
              })
            })
        } else { // if currentItems.length
          inProgress.forEach(itemId => {
            dispatch(setItemUpdateInProgress(itemId, false))
          })
        }
      })
  }
}

export const updateCart = (currentItems) => {
  return (dispatch, getState) => {
    const { shop } = getState()
    const itemsToSend = currentItems
      .map(item => ({
        supplier: item.supplier ? item.supplier.id : null,
        product: item.productId,
        quantity: item.count,
        comment: item.comment,
        car: item.car,
        location: item.location ? item.location.id : null,
        wheel: item.wheel,
        autoProductId: item.autoProductId,
        included: item.included,
      }))
    return api(`${shop.apiUrl}/cart`, 'post', itemsToSend)
      .then(response => response.json())
      .then(response => {
        const items = response.data.items.map(item => ({
          ...item,
          ...item.product,
          category: getCategoryByTypeId(item.product.type.id),
          count: item.quantity,
          comment: item.comment,
        }))
        dispatch(setCartItems(items))
      })
  }
}

const isSameCartItem = i1 => i2 => (
  i1.id === i2.id &&
  i1.location && i2.location && i1.location.id === i2.location.id &&
  i1.supplier && i2.supplier && i1.supplier.id === i2.supplier.id &&
  i1.comment === i2.comment &&
  i1.autoProductId === i2.autoProductId
)

const isStaggeredFitment = (wheel, items) => {
  return items.filter(item => item.wheel === wheel && item.type.id === 1).length > 1
}

export const updateItem = (item, count, items, refreshAvailability = false) => {
  const isWheelStaggeredFitment = item.wheel && isStaggeredFitment(item.wheel, items)
  if (item.wheel && !isWheelStaggeredFitment) {
    const updatedItems = items.filter(i => i.wheel === item.wheel)
    updatedItems.forEach(item => {
      item.count = parseInt(count, 10)
    })
  } else {
    const updatedItem = items.find(isSameCartItem(item))
    updatedItem.count = parseInt(count, 10)
  }
  return (dispatch, getState) => {
    const { cart } = getState()
    if (refreshAvailability)
      dispatch(setDeliveryTimesInProgress(item.id, true))
    dispatch(setItemUpdateInProgress(item.id, true))
    dispatch(updateCart(items.concat(cart.unselectedAutoproducts)))
      .then(() => {
        if (refreshAvailability) {
          dispatch(refreshDeliveryTimes(item))
        }
      })
      .finally(() => dispatch(setItemUpdateInProgress(item.id, false)))
  }
}

export const refreshDeliveryTimes = (item) => {
  return (dispatch, getState) => {
    const { shop, cart } = getState();
    if (shop.hasAvailabilityCheck) {
      let quantity = cart.items.filter(i => i.id === item.id).reduce((acc, value) => acc + value.count, 0)
      const products = [{
        id: item.id,
        quantity,
        wheel: item.wheel,
        name: item.name,
        comment: item.comment,
        car: item.car,
      }]
      const params = getState().cart.params
      return api(`${shop.apiUrl}/availability`, 'post', { ...params, products })
        .then(response => response.json())
        .then(response => {
          const i = response && response.data[0]
          dispatch({
            type: SET_SINGLE_DELIVERY_TIME,
            data: {
              available: i.response.available,
              information: i.response.information,
              text: i.response.text,
              color: i.response.color,
              supplierId: i.request.supplierId,
              locationId: i.request.locationId,
              productId: i.request.productId,
              completeDeliveryDate: i.response.completeDeliveryDate,
              deliveries: i.response.deliveries,
              deliveryInformation: i.response.deliveryInformation,
            }
          })
          dispatch(setItemUpdateInProgress(item.id, false))
          dispatch(setDeliveryTimesInProgress(item.id, false))
        })
    }
  }
}

export const removeItem = (item, items) => {
  if (item.wheel) {
    const removedItems = items.filter(i => i.wheel === item.wheel)
    removedItems.forEach(item => {
      const removedItemIndex = items.findIndex(isSameCartItem(item))
      items.splice(removedItemIndex, 1)
    })
  } else {
    const removedItemIndex = items.findIndex(isSameCartItem(item))
    if (item.autoProductId) {
      items[removedItemIndex] = {...items[removedItemIndex], included: false }
    } else {
      items.splice(removedItemIndex, 1)
    }
  }

  return (dispatch, getState) => {
    const { shop, cart } = getState()
    dispatch(setItemUpdateInProgress(item.id, true))
    dispatch(updateCart(items.concat(cart.unselectedAutoproducts)))
      .then(() => {
        const hasSameProduct = items.find(i => !i.autoProductId && i.id === item.id)
        if (shop.hasAvailabilityCheck && !item.autoProductId) {
          if (hasSameProduct) {
            dispatch(refreshDeliveryTimes(item))
          } else {
            dispatch({
              type: REMOVE_SINGLE_DELIVERY_TIME,
              data: item,
            })
          }
        }
        dispatch(setItemUpdateInProgress(item.id, false))
      })
  }
}

export const setItemUpdateInProgress = (id, inProgress) => {
  return dispatch => {
    dispatch({
      type: SET_ITEM_IN_PROGRESS,
      id,
      inProgress,
    })
  }
}

export const setDeliveryTimesInProgress = (id, inProgress) => {
  return dispatch => {
    dispatch({
      type: SET_DELIVERY_TIME_UPDATE_IN_PROGRESS,
      id,
      inProgress,
    })
  }
}
export const addAutoProduct = (item) => {
  return (dispatch, getState) => {
    const items = getState().cart.items
    const updatedItem = getState().cart.unselectedAutoproducts.find(isSameCartItem(item))
    const unselectedItems = getState().cart.unselectedAutoproducts.filter((i) => !isSameCartItem(i)(item))
    dispatch(setItemUpdateInProgress(item.id, true))
    dispatch(updateCart([...items, ...unselectedItems, {
      ...updatedItem,
      included: true,
    }]))
    .then(res => dispatch(setItemUpdateInProgress(item.id, false)))
  }
}
