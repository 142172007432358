import React from 'react'
import { connect } from 'react-redux'
import { FormattedNumber } from 'react-intl'
import { T } from 'components/components/i18n'
import { Row, Col, ControlLabel, FormGroup, FormControl, HelpBlock, Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './styles.less'
import CheckoutAction from './CheckoutAction'
import ProductItem from './ProductItem'
import Tpms from './Tpms'
import { bindActionCreators } from 'redux'
import { removeItem } from '../../../actions/cart'
import { checkout, externalCheckout, fetchAvailableDeliveryTimes, setCheckoutParams, /* setDeliveryTimes, */ fetchGarages } from '../../../actions/checkout'
import { fetchSupplierByTargetId } from '../../../helpers/preOrder'
import DeliveryOptions from './DeliveryOptions'
import CollectCustomerInfo from './CollectCustomerInfo'
import validateOrder from '../../../helpers/order'
import { DELIVERY_OPTIONS } from '../../../constants/checkout'
import GaragesList from '../GaragesList'
import PreOrderStatusBar from '../PreOrderStatusBar'
import UnselectedAutoproductItem from './UnselectedAutoproductItem'
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import moment from 'moment'
import classnames from 'classnames'

export class CheckoutComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAdditionalAddressFields: false,
      showModal: false,
      isRequiredCheckoutReference: props.isLoggedIn && props.shop.isRequiredCheckoutReference,
      values: {
        customerType: '2',
        deliveryOption: this.props.shop.deliveryOptions[0].id,
        garageCustomerId: null,
        addTpms: 0,
        customerReference: '',
        customerCountry: this.props.shop.country,
        preorderDeliveryTime: null,
      },
      errors: {}
    }
  }

  componentDidMount() {
    const { shop, isLoggedIn, fetchDeliveryTimes, setValues, isCartRetrieved, fetchGarages, user } = this.props
    // const { deliveryOption } = this.state.values
    if (this.props.shop.id) {
      document.title = `Kassa - ${this.props.shop.title}`
    }
    if (isLoggedIn) {
      fetchGarages(user.customer.zipcode)
    }
    if (shop.hasAvailabilityCheck) {
      if (isCartRetrieved) {
        fetchDeliveryTimes(this.state.values)
      }
      setValues(this.state.values)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { shop, /* isLoggedIn, */ fetchDeliveryTimes, isCartRetrieved } = this.props
    const { deliveryOption } = this.state.values
    if ((isCartRetrieved && !prevProps.isCartRetrieved) || (isCartRetrieved && deliveryOption !== prevState.values.deliveryOption)) {
      if (shop.hasAvailabilityCheck) {
        fetchDeliveryTimes(this.state.values)
      }
    }
  }

  handleClose() {
    this.setState({ showModal: false })
  }

  render() {
    const {
      items,
      includeVat,
      total,
      totalVat,
      totalInclVat,
      suggestTpms,
      deliveryOptions,
      hasCompleteWheel,
      shop,
      currency,
      isLoggedIn,
      externalCheckout,
      setValues,
      garages,
      isPreOrderMode,
      unselectedAutoproducts,
    } = this.props

    let isVatIncludedMessage = ""
    let totalMessage = ""
    let totalVatMessage = ""
    let customerInfo = null
    let webshopOptions = []

    const onCustomerTypeSelected = (value) => {
      const values = Object.assign({}, this.state.values)
      values.customerType = value
      this.setState({
          isRequiredCheckoutReference: (isLoggedIn || value !== "2") && this.props.shop.isRequiredCheckoutReference,
          values
      }, () => setValues(values))
    }

    const onDeliveryOptionSelected = (option) => {
      const values = { ...this.state.values, deliveryOption: option, garageCustomerId: option !== DELIVERY_OPTIONS.PARTNER ? null : this.state.values.garageCustomerId }
      this.setState({
        values
      }, () => setValues(values))
    }

    const onShowAdditionalAddressFieldsClicked = (e) => {
      this.setState({
          showAdditionalAddressFields: true
      })
    }

    const onAddTpmsChange = (addTpms) => {
      const values = { ...this.state.values, addTpms }
      this.setState({
        values
      }, () => setValues(values))
    }
    const handleSubmit = (event, isReserve) => {
      if (this.props.isPreOrderMode && this.props.quantityInCart < this.props.target.quantity) {
        this.setState({ showModal: true });
        return;
      }

      const products = items.map(item => ({
        id: item.id,
        quantity: item.count,
        wheel: item.wheelId,
        name: item.name,
        comment: item.comment,
        car: item.car,
        autoProductId: item.autoProductId,
        included: item.included,
      }))
      const customerCountry = this.props.shop.country
      const order = {
        customerInfoRequired: !isLoggedIn,
        isRequiredCheckoutReference: this.state.isRequiredCheckoutReference,
        values: Object.assign({
          customerCountry,
          deliveryCountry: customerCountry,
        }, this.state.values)
      }

      const errors = validateOrder(order)
      if (this.props.isPreOrderMode && !this.state.values.preorderDeliveryTime)
        errors.preorderDeliveryTime = true
      if (!Object.keys(errors).length) {
        this.setState({
          errors: {}
        }, () => {
          this.props.checkout({
            ...this.state.values,
            webshopId: this.props.shop.webshopId,
            total: this.props.total,
            products,
            isReserve,
            preorderDeliveryTime: moment(this.state.values.preorderDeliveryTime).format("YYYY-MM-DD"),
          }, this.props.shop)
        })
      }

      else {
        console.log('Form is not valid: ', errors)
        this.setState({
          errors
        })
      }
    }

    const handleSubmitOrder = (event) => {
      handleSubmit(event, false)
    }

    const onFieldBlur = (name, value) => {
      const values = Object.assign({}, this.state.values, { [name]: value })
      this.setState({ values }, () => setValues(values))
    }

    const onSelectGarage = value => {
      const values = { ...this.state.values, garageCustomerId: value }
      this.setState({
        values
      }, () => setValues(values))
    }

    if (!isLoggedIn) {
      customerInfo = (
        <CollectCustomerInfo
          mainContentClassName={styles.mainContent}
          onFieldBlur={onFieldBlur}
          onCustomerTypeSelected={onCustomerTypeSelected}
          onShowAdditionalAddressFieldsClicked={onShowAdditionalAddressFieldsClicked}
          showAdditionalAddressFields={this.state.showAdditionalAddressFields}
          customerType={this.state.values.customerType}
          errors={this.state.errors}
          showDeliveryAddress={this.state.values.deliveryOption !== DELIVERY_OPTIONS.PICKUP}
          isRequiredCheckoutReference={this.state.isRequiredCheckoutReference}
        />
      )
    } else if (shop.showCheckoutReference || isPreOrderMode) {
      const minDate = new Date()
      minDate.setDate(minDate.getDate() + 4)
      customerInfo = (
        <section>
          <h2><T id="Beställningsinfo"/></h2>
          <div className={styles.mainContent}>
            <Row>
              {shop.showCheckoutReference && (
                <Col xs={6}>
                  <FormGroup validationState={this.state.errors['customerReference'] && 'error'}>
                    <ControlLabel><T id="Referens / märkning"/></ControlLabel>
                    <FormControl
                      type="text"
                      name="customerReference"
                      id="customerReference"
                      maxLength={100}
                      value={this.state.values.customerReference}
                      onChange={(event) => onFieldBlur('customerReference', event.target.value)}
                    />
                    <HelpBlock>
                      {!!this.state.isRequiredCheckoutReference && (
                        <span style={{color:'#a94442'}}>* <T id="This field is required"/></span>
                      )}
                    </HelpBlock>
                  </FormGroup>
                </Col>
              )}
              {isPreOrderMode && (
                <Col xs={6}>
                  <FormGroup validationState={this.state.errors['preorderDeliveryTime'] && "error"}>
                    <ControlLabel><T id="Preorder delivery time"/></ControlLabel>
                    <DatePicker
                      minDate={minDate}
                      dateFormat="yyyy-MM-dd"
                      selected={this.state.values.preorderDeliveryTime || null}
                      onChange={date => onFieldBlur('preorderDeliveryTime',date)}
                      className="form-control preorder-date-picker"
                    />
                    <HelpBlock></HelpBlock>
                  </FormGroup>
                </Col>
              )}
            </Row>
          </div>
        </section>
      )
    }

    if (includeVat) {
      isVatIncludedMessage = <T id="including VAT" />
      totalMessage = (
        <div className={classnames('checkout-total', styles.productTotal)}>
          <span><T id="Totalt varor"/>:</span>
          <span className={classnames('checkout-total-price', styles.totalPrice)}>
            <FormattedNumber
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={!totalInclVat ? 0 : totalInclVat}
              style={`currency`}
              currency={currency}
            />
          </span>
        </div>
      )
      totalVatMessage = (
        <div className="checkout-total-vat">
          <T id="VAT"/>: <FormattedNumber
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            value={!totalVat ? 0 : totalVat}
            style={`currency`}
            currency={currency}
          />
        </div>
      )
    } else {
      isVatIncludedMessage = <T id="VAT excluded" />
      totalMessage =(
        <div className={classnames('checkout-total', styles.productTotal)}>
          <span><T id="Totalt varor"/>:</span>
          <span className={classnames('checkout-total-price', styles.totalPrice)}>
            <FormattedNumber
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={!total ? 0 : total}
              style={`currency`}
              currency={currency}
            />
          </span>
        </div>
      )
      totalVatMessage = <T id="VAT excluded" />
    }

    if (suggestTpms && hasCompleteWheel) {
      webshopOptions.push(<Tpms key={0} onChange={onAddTpmsChange} value={this.state.values.addTpms} />)
    }

    if (deliveryOptions && deliveryOptions.length > 1) {
      webshopOptions.push(
        <DeliveryOptions
          key={1}
          selectedOption={this.state.values.deliveryOption}
          onClick={onDeliveryOptionSelected}
          shop={shop}
        />
      )
    }

    if (this.state.values.deliveryOption === DELIVERY_OPTIONS.PARTNER && isLoggedIn) {
      webshopOptions.push(
        <GaragesList
          defaultZipcode={this.props.user.customer.zipcode}
          searchGarages={this.props.fetchGarages}
          garages={garages}
          selectedGarage={this.state.values.garageCustomerId}
          selectGarage={onSelectGarage}
        />
      )
    }

    if (!items || !items.length) {
      return (
        <div className="container">
          <h1><T id="Checkout"/></h1>
          <p><T id="Your cart is empty" /></p>
          <p>
            <Link to="/" className='btn btn-default'>
              <T id="Back to search"/>
            </Link>
          </p>
        </div>
      )
    }

    const itemsComponents = items.map(item => (
      <ProductItem
        className={item.autoProductId ? `cart-autoproduct-${item.autoProductType.toLowerCase()}` : 'cart-product'}
        key={`${item.id} ${item.autoProductId}`}
        item={item}
        cartItems={items}
        shop={shop}
      />)
    )

    return (
      <div className="checkout container">
        <section>
          <div className="checkout-items">
            <h1><T id="Checkout"/></h1>
            <h2><T id="Products in shopping cart"/></h2>
            {isPreOrderMode && <PreOrderStatusBar />}
            <div className={classnames('checkout-main-content', styles.mainContent)}>
              <p><T id="All prices are shown"/> {isVatIncludedMessage}</p>
              <div className={styles.tableResponsive}>
                <div className={classnames(styles.productsList, 'products-list')}>
                  {itemsComponents}
                  <Row className={classnames(styles.footer, 'background-light', 'checkout-footer')}>
                    <Col mdOffset={8} md={3} className={styles.price}>
                      {totalMessage}
                      <span>{totalVatMessage}</span>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          {unselectedAutoproducts && unselectedAutoproducts.length > 0 && (
            <div>
              <h2>
                <T id="Optional addon products" />
              </h2>
              <div class={styles.mainContent}>
                {unselectedAutoproducts.map(item => (
                  <UnselectedAutoproductItem
                    key={`${item.id} ${item.autoProductId}`}
                    item={item}
                  />
                ))}
              </div>
            </div>
          )}
        </section>
        {shop.checkoutCustomText && (
          <div
            className={classnames('checkout-custom-text', styles.checkoutCustomText, styles.mainContent)}
            dangerouslySetInnerHTML={{__html: shop.checkoutCustomText}}
          />
        )}
        <div>
          {!shop.externalCheckout && webshopOptions}
          {!shop.externalCheckout && customerInfo}
          <CheckoutAction
            disabled={this.state.values.deliveryOption === DELIVERY_OPTIONS.PARTNER && !this.state.values.garageCustomerId}
            onClick={shop.externalCheckout ? externalCheckout : handleSubmitOrder}
            className={styles.transparentBackground} />
        </div>
        {isPreOrderMode && (
          <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)} className="preorder-modal">
            <Modal.Header closeButton>
              <Modal.Title><T id="Target not yet reached" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <T id="You have started preseason order, and it cannot be submitted until you've reached the selected target." />
              <br />
              <T id="If you need to cancel the preseason order you can do so at the " />
              <Link to="/preorder"><T id="pre season order page" /></Link>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                <T id="Ok" />
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>)

  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  removeItem: (item) => removeItem(item),
  checkout: (params, shop) => checkout(params, shop),
  externalCheckout: () => externalCheckout(),
  fetchDeliveryTimes: (params) => fetchAvailableDeliveryTimes(params),
  setValues: params => setCheckoutParams(params),
  fetchGarages: customerPostalCode => fetchGarages(customerPostalCode),
}, dispatch)

const mapStateToProps = state => {
  const supplier = fetchSupplierByTargetId(state.preOrder.suppliers, state.preOrder.targetId)
  const target = supplier && supplier.targets.find(target => target.id === state.preOrder.targetId)

  return {
    includeVat: state.price.includeVat,
    items: state.cart.items,
    total: state.cart.total,
    totalVat: state.cart.totalVat,
    totalInclVat: state.cart.totalInclVat,
    hasCompleteWheel: state.cart.hasCompleteWheel,
    isB2B: state.shop.webshopType === 1,
    deliveryOptions: state.shop.deliveryOptions,
    suggestTpms: state.shop.suggestTpms,
    shop: state.shop,
    currency: state.intl.currency,
    isLoggedIn: state.user.isLoggedIn,
    isCartRetrieved: state.cart.isCartRetrieved,
    garages: state.user.garages,
    user: state.user && state.user.user,
    isPreOrderMode: state.preOrder.targetId,
    target,
    quantityInCart: state.cart.count,
    unselectedAutoproducts: state.cart.unselectedAutoproducts,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutComponent)
