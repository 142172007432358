import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { setIsSearchFormValid } from '../../../../actions/search'
import styles from './styles.less'

class ProductComment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
    this.handleChange = this.onChange.bind(this)
  }

  onChange(event) {
    const { name, value } = event.target
    const { setIsSearchFormValid } = this.props
    if(value && value.length && value.length < 3)
      this.setState({ hasError: true }, () => setIsSearchFormValid(false))
    else {
      const { onChange } = this.props
      this.setState({ hasError: false }, () => {
        onChange(name, value)
        setIsSearchFormValid(true)
      })
    }
  }

  render() {
    const { value } = this.props
    const { hasError } = this.state
    return (
      <FormGroup className="product-comment-field">
        <ControlLabel>
          <T id="Comment"/>
        </ControlLabel>
        <FormControl
          name="comment"
          defaultValue={value}
          onChange={this.handleChange}
          className={styles.input}
        />
        {hasError && (
          <div className={styles.error}>
            <T id="Too short"/>
          </div>
        )}
      </FormGroup>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  setIsSearchFormValid: (isValid) => setIsSearchFormValid(isValid)
}, dispatch)

export default connect(null, mapDispatchToProps)(ProductComment)
