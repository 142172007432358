import { RIM_MATERIAL_TYPES } from '../constants/rimMaterialTypes'
import {
  SET_RIM_SEARCH_PARAMETERS,
  SET_RIM_SEARCH_PARAMETER,
  SEARCH_MODE_CHANGED,
  UPDATE_VEHICLE
} from '../actions/search'
import { RESET_VEHICLE } from '../actions/vehicle'
import { VEHICLE_RETRIEVED_BY_ID } from '../actions/carPicker'
import { RESTRICT_DIAMETERS } from '../actions/diameters'

const rimSearchParams = (state = {
  rimType: RIM_MATERIAL_TYPES[2].value,
  brandId: [],
  diameter: [],
  vehicleId: '',
  licenseplate: '',
  searchMode: null,
  condition: [],
  comment: '',
  minQuantityInStock: '4',
  query: '',
}, action) => {
  switch (action.type) {
    case SET_RIM_SEARCH_PARAMETERS:
      return { ...state, ...initializeSearchParamsValues(action.parameters, action.shop) }
    case SET_RIM_SEARCH_PARAMETER:
      return { ...state, ...action.params }
    case VEHICLE_RETRIEVED_BY_ID:
      let vehicleDiameters
      if (action.vehicle.rimSizes)
        vehicleDiameters = [...new Set(action.vehicle.rimSizes.map(size => parseInt(size.diameter, 10)))]
      else
        vehicleDiameters = []
      return { ...state, diameter: vehicleDiameters, vehicleId: action.vehicleId }
    case RESET_VEHICLE:
      return { ...state, diameter: [] }
    case UPDATE_VEHICLE:
      let diameters
      if (action.vehicle.rimSizes)
        diameters = [...new Set(action.vehicle.rimSizes.map(size => parseInt(size.diameter, 10)))]
      else
        diameters = []
      return { ...state, diameter: diameters, licenseplate: action.licensePlateNumber }
    case RESTRICT_DIAMETERS:
      return { ...state, diameter: action.restrictedDiameters }
    case SEARCH_MODE_CHANGED:
      const changedParams = { ...state }
      action.removedParams.forEach(param => delete changedParams[param])
      changedParams.searchMode = action.searchMode
      return changedParams
    default:
      return state
  }
}

const initializeSearchParamsValues = (params, shop) => {
  const {
    brandId,
    isWinterCertified,
    isFixedCentreBore,
    diameter,
    width,
    boltPattern,
    et,
    rimType,
    searchMode,
    licenseplate,
    vehicleId,
    condition,
    comment,
    minQuantityInStock,
    query,
  } = params

  let selectedCondition
  if (condition) {
    if (Array.isArray(condition))
      selectedCondition = condition
    selectedCondition = [condition]
  } else if (shop.condition && shop.condition.rim) {
    selectedCondition = Object.keys(shop.condition.rim).filter(key => shop.condition.rim[key].default)
  }

  return {
    brandId: brandId ? (Array.isArray(brandId) ? brandId : [brandId]) : [],
    isFixedCentreBore: String(isFixedCentreBore) === 'true',
    isWinterCertified: String(isWinterCertified) === 'true',
    diameter: diameter ? (Array.isArray(diameter) ? diameter.map(d => parseInt(d, 10)) : [parseInt(diameter, 10)]) : [],
    width: width || '',
    boltPattern: boltPattern || '',
    et: et || '',
    rimType: rimType || RIM_MATERIAL_TYPES[2].value,
    licenseplate: licenseplate || '',
    vehicleId: vehicleId || null,
    searchMode:!!searchMode ? parseInt(searchMode, 10) : shop.defaultSearchModeRims,
    condition: selectedCondition || [],
    comment: comment || '',
    minQuantityInStock: minQuantityInStock || shop.minQuantityInStock || '4',
    query: query || '',
  }
}

export default rimSearchParams