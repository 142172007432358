import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Image = ({ src, alt, width, height, minWidth, minHeight, color }) => {
  const [loading, setLoading] = useState(true)

  const handleImageLoad = () => {
    setLoading(false)
  }

  const spinnerColor = color || 'text-colored'

  return (
    <div style={{
      position: 'relative',
      width: width || 'auto',
      height: height || 'auto',
      minHeight: minHeight || '0',
      minWidth: minWidth || '0'
    }}>
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <FontAwesomeIcon className={spinnerColor} icon={faSpinner} spin size="2x" />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        style={loading ? { display: 'none' } : { display: 'block' }}
        width={width || 'auto'}
        height={height || 'auto'}
      />
    </div>
  )
}

export default Image