import React from 'react'
import { T } from 'components/components/i18n'

const StockAmountMessage = (props) => {
  const { showActualStockAmounts, stock } = props
  if (!!showActualStockAmounts) {
    const stockAmount = stock === 77777 ? '?' : (stock > 50 ? '50+' : stock)
    return <span>{stockAmount} <T id="in stock"/></span>
  } else if (stock <= 0) {
    return <T id="None in stock"/>
  } else if (stock <= 10 ) {
    return <T id="Limited amount"/>
  }
  return <T id="Available in stock"/>
}

export default StockAmountMessage
