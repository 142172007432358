import {
  SET_USER,
  LOGIN_ERROR
} from '../actions/user'
import { SET_GARAGES } from '../actions/checkout'
import { SET_SESSION } from '../actions'

const user = (state = {
  garages: [],
}, action) => {
  switch(action.type) {
    case LOGIN_ERROR:
      return {
        ...state,
        error: action.error,
        domainUrl: action.domainUrl,
        domainTitle: action.domainTitle,
      }
    case SET_USER:
      return {
        ...state,
        user: action.user,
        isLoggedIn: !!action.user,
        error: null,
        domainUrl: null,
        domainTitle: null,
      }
    case SET_GARAGES:
      return {
        ...state,
        garages: action.garages,
      }
    case SET_SESSION:
      return {
        ...state,
        sessionToken: action.sessionToken,
      }
    default:
      return state
  }
}

export default user
