import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { checkoutDone } from '../../../actions/checkout'
import styles from './styles.less'
import { T } from 'components/components/i18n'

export class PaymentSuccess extends React.Component {
  componentDidMount() {
    this.props.checkoutDone()
    // call purchase page script
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.innerHTML = this.props.script;
    document.head.appendChild(s);
  }
  render() {
    return (
      <div className={`${styles.container} container payment-success`}>
        <div>
          <div>
            <h1>
              <T id="Thanks for your order"/>
            </h1>
            <p>
              <T id="We have received your order with order number"/> <strong>#{this.props.order.id}</strong>.
            </p>
            <p>
              <T id="Confirmation email with more information and instructions is sent to you."/>
            </p>
            <p>
              <Link to="/" className="btn btn-default"><T id="Back to main page"/></Link>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  checkoutDone: () => checkoutDone()
}, dispatch)

const mapStateToProps = state => {
  const script = state.shop.purchasePageHtml ? state.shop.purchasePageHtml.replace("{{ order_id }}", state.order.id) : null
  return {
    order: state.order,
    script,
  }
}
 
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSuccess)
