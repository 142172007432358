import {
    SET_CART_ITEMS,
    SET_ITEM_IN_PROGRESS,
    SET_SINGLE_DELIVERY_TIME,
    SET_DELIVERY_TIME_UPDATE_IN_PROGRESS,
    REMOVE_SINGLE_DELIVERY_TIME,
} from '../actions/cart'
import {
  SET_EXACT_DELIVERY_TIMES,
  SET_LOADING_EXACT_DELIVERY_TIMES,
  SET_CHECKOUT_PARAMS,
} from '../actions/checkout'

import CATEGORIES from '../helpers/categories'
import { formatCurrency, calculatePrice } from '../helpers/price'

const cart = (state = {
  items: [],
  isCartRetrieved: false,
  totalVat: 0,
  total: 0,
  totalInclVat: 0,
  hasCompleteWheel: false,
  updateInProgress: {},
  loadingExactDeliveryTimes: false,
  exactDeliveryTimes: [],
  params: null,
  deliveryTimesInProgress: {},
}, action) => {
  let items
    switch (action.type) {
      case SET_CART_ITEMS:
        items = action.items.map(item => ensureCartItemAttributes(item))
        const itemsAutoProductsExcluded = items.filter(item => !item.autoProductId)
        const itemsInCart = items.filter(item => !item.autoProductId || item.included)
        return {...state,
          items: items ? itemsInCart.map(item => addFormattedPriceToProduct(item)) : [],
          totalVat: getTotalVat(itemsInCart),
          total: getTotal(
            itemsInCart,
            getTotalVat(itemsInCart)),
          totalInclVat: getTotalInclVat(itemsInCart),
          hasCompleteWheel: hasCompleteWheel(items),
          isCartRetrieved: true,
          count: itemsAutoProductsExcluded.reduce((sum, item) => {
            return sum + item.count
          }, 0),
          unselectedAutoproducts: items
            .filter(item => item.autoProductId && item.autoProductType !== 'MANDATORY' && !item.included)
            .map(item => addFormattedPriceToProduct(item))
        }
      case SET_ITEM_IN_PROGRESS:
        return {
          ...state,
          updateInProgress: { ...state.updateInProgress, [action.id]: action.inProgress },
        }
      case SET_DELIVERY_TIME_UPDATE_IN_PROGRESS:
        return {
          ...state,
          deliveryTimesInProgress: { ...state.deliveryTimesInProgress, [action.id]: action.inProgress },
        }
      case SET_LOADING_EXACT_DELIVERY_TIMES:
        return {
          ...state,
          loadingExactDeliveryTimes: action.loading,
        }
      case SET_EXACT_DELIVERY_TIMES:
        return {
          ...state,
          exactDeliveryTimes: action.exactDeliveryTimes,
          hasUnavailable: !!action.exactDeliveryTimes.find(item => !item.available),
        }
      case SET_SINGLE_DELIVERY_TIME:
        const oldItemIndex = state.exactDeliveryTimes.findIndex(item => (
          item.supplierId === action.data.supplierId &&
          item.locationId === action.data.locationId &&
          item.productId === action.data.productId
        ))
        const newItem = { ...state.exactDeliveryTimes[oldItemIndex], ...action.data }
        const exactDeliveryTimes = [...state.exactDeliveryTimes]
        exactDeliveryTimes[oldItemIndex] = newItem
        return {
          ...state,
          exactDeliveryTimes,
          hasUnavailable: !!exactDeliveryTimes.find(item => !item.available),
        }
      case REMOVE_SINGLE_DELIVERY_TIME:
        const index = state.exactDeliveryTimes.findIndex(item => (
          item.supplierId === (action.data.supplier && action.data.supplier.id) &&
          item.locationId === (action.data.location && action.data.location.id) &&
          item.productId === action.data.productId
        ))
        const deliveryTimes = [...state.exactDeliveryTimes]
        if (index !== -1)
          deliveryTimes.splice(index, 1)
        return {
          ...state,
          exactDeliveryTimes: deliveryTimes,
          hasUnavailable: !!deliveryTimes.find(item => !item.available),
        }
      case SET_CHECKOUT_PARAMS:
        return {
          ...state,
          params: action.params,
        }
      default:
        return state
    }
}

const addFormattedPriceToProduct = (item) => {
  const formattedPrice = formatCurrency(item.price)
  const formattedPriceInclVat = calculatePrice(item.price, 1, item.vatPercent)
  const formattedTotal = formatCurrency(item.price * item.count)
  const formattedTotalInclVat = calculatePrice(item.price, item.count, item.vatPercent)
  return Object.assign({}, item, {
    formattedTotal,
    formattedTotalInclVat,
    formattedPrice,
    formattedPriceInclVat
  })
}

const ensureCartItemAttributes = (item) => {
  if (!item.car)
    item.car = {}
  if (!item.attrs)
    item.attrs = {}
  if (!item.brand)
    item.brand = {}
  if (!item.model)
    item.model = {}
  if (!item.category)
    item.category = CATEGORIES.OTHER
  // NOTE: Just in case weird side effects when change from `==` to `===`
  // TODO: Object compare
  // eslint-disable-next-line
  if (item.category == CATEGORIES.COMPLETE_WHEEL) {
    if (!item.tyre)
      item.tyre = {}
    if (!item.tyre.attrs)
      item.tyre.attrs = {}
    if (!item.tyre.brand)
      item.tyre.brand = {}
    if (!item.tyre.model)
      item.tyre.model = {}
    if (!item.rim)
      item.rim = {}
    if (!item.rim.attrs)
      item.rim.attrs = {}
    if (!item.rim.brand)
      item.rim.brand = {}
    if (!item.rim.model)
      item.rim.model = {}
  }
  return item
}

const getTotalInclVat = (items) => {
  let t = 0, vat = 0
  for (let id in items) {
    t += items[id].price * items[id].count
    vat += items[id].price * items[id].vatPercent / 100 * items[id].count
  }
  return formatCurrency(t + vat)
}

const getTotal = (items, totalVat) => {
  let t = 0
  for (let id in items) {
    t += items[id].price * items[id].count
  }
  return formatCurrency(t)
};

const getTotalVat = (items) => {
  let t = 0
  for (let id in items) {
    t += items[id].price * items[id].vatPercent / 100 * items[id].count
  }
  return formatCurrency(t)
};

const hasCompleteWheel = (items) => {
  // If we see two items with same wheelId, assume we have a complete wheel
  let seen = {};
  for (let key in items) {
    let item = items[key];
    if (!item.wheel)
      continue;
    if (seen[item.wheel])
      return true;
    seen[item.wheel] = true;
  }
}

export default cart
