import React, { Component } from "react";
import { connect } from 'react-redux'
import { Row, Col, Table } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import styles from './styles.less'

class CarInfo extends Component {
  render() {
    const vehicle = this.props.vehicle
    const standard = (vehicle && vehicle.standard) || {}
    const front = (standard && standard.front) || {}
    const rear = (standard && standard.rear) || {}
    const frontTyre = (standard && standard.front && standard.front.tyre) || {}
    const rearTyre = (standard && standard.rear && standard.rear.tyre) || {}
    const frontRim = (standard && standard.front && standard.front.rim) || {}
    const rearRim = (standard && standard.rear && standard.rear.rim) || {}

    return (
      <div className={styles['carInfo']}>
        <Row className={styles.filters}>
          <Col xs={6}>
            <Table striped className={styles.carInfoTable}>
              <tbody>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Dimension"/> <T id="Front"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>{frontTyre.dimension || '-'}</strong>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Dimension"/> <T id="Rear"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>{rearTyre.dimension || '-'}</strong>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Speed index"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <span className={styles.front}><T id="Front"/> <strong>{frontTyre.speedIndex || '-'}</strong></span>
                    <span><T id="Rear"/> <strong>{rearTyre.speedIndex || '-'}</strong></span>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Load index"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <span className={styles.front}><T id="Front"/> <strong>{frontTyre.loadIndex || '-'}</strong></span>
                    <span><T id="Rear"/> <strong>{rearTyre.loadIndex || '-'}</strong></span>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Axle load"/> <T id="Front"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>{front.maxLoad || '-'} kg</strong>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Axle load"/> <T id="Rear"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>{rear.maxLoad || '-'} kg</strong>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Drive axles"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>{vehicle.axles || '-'}</strong>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    TPMS
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>
                      {!!vehicle.hasTpms && (
                        <T id="Yes" />
                      )}
                      {!vehicle.hasTpms && (
                        <span>-</span>
                      )}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs={6}>
            <Table striped className={styles.carInfoTable}>
              <tbody>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Rim"/> <T id="Dimension"/> <T id="Front"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>{frontRim.dimension || '-'}</strong>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Rim"/> <T id="Dimension"/> <T id="Rear"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>{rearRim.dimension || '-'}</strong>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Et"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <span className={styles.front}><T id="Front"/> <strong>{frontRim.et || '-'}</strong></span>
                    <span><T id="Rear"/> <strong>{rearRim.et || '-'}</strong></span>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Bolt pattern"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>{vehicle.rimBoltPattern || '-'}</strong>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Center bore"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>{vehicle.rimCenterBore || '-'}</strong>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Bolt size & hex"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>{vehicle.rimBoltSize || '-'}, {vehicle.rimBoltHex || '-'} mm</strong>
                  </td>
                </tr>
                <tr className={styles.infoLabel}>
                  <td>
                    <T id="Bolt torque"/>
                  </td>
                </tr>
                <tr className={styles.infoData}>
                  <td>
                    <strong>{vehicle.rimBoltTorque || '-'} Nm</strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}


const mapStateToProps = state => {
  const { vehicle } = state.vehicle.vehicle ? state.vehicle : state.carPicker
  return {
    vehicle
  }
}

export default connect(mapStateToProps)(CarInfo);
