import {
  CREATING_PROFIT_MARGIN,
  EDITING_PROFIT_MARGIN,
  DELETING_PROFIT_MARGIN,
  PROFIT_MARGIN_CREATED,
  PROFIT_MARGINS_RETRIEVED,
  PROFIT_MARGIN_EDITED,
  PROFIT_MARGIN_DELETED,
  PROFIT_MARGIN_CREATE_ERROR,
  PROFIT_MARGIN_EDIT_ERROR,
  PROFIT_MARGIN_DELETE_ERROR,
} from '../actions/profitMargins'
import { CATEGORIES } from '../helpers/categories'

const profitMargins = (state = {
  margins: {
    tyre: [],
    rim: [],
    isCreating: false,
    isCreated: false,
    isEditing: false,
    isEdited: false,
    isDeleting: false,
    isDeleted: false
  }
}, action) => {
  switch(action.type) {
    case CREATING_PROFIT_MARGIN:
      return Object.assign({}, state, {
        isCreating: true,
        isCreated: false,
        createErrorMessage: null
      })
    case EDITING_PROFIT_MARGIN:
      return Object.assign({}, state, {
        isEditing: true,
        isEdited: false,
        editErrorMessage: null
      })
    case DELETING_PROFIT_MARGIN:
      return Object.assign({}, state, {
        isDeleting: true,
        isDeleted: false,
        deleteErrorMessage: null
      })
    case PROFIT_MARGINS_RETRIEVED:
      return Object.assign({}, state, {
        margins: {
          tyre: action.margins.filter(m => m.type === CATEGORIES.TYRE.id),
          rim: action.margins.filter(m => m.type === CATEGORIES.RIM.id)
        }
      })
    case PROFIT_MARGIN_CREATED:
      const margins = state.margins[action.category].slice()
      margins.push(action.margin)
      const newMargins = Object.assign({}, state.margins, { [action.category]: margins })
      return Object.assign({}, state, { margins: newMargins, isCreating: false, isCreated: true })
    case PROFIT_MARGIN_EDITED:
      const currentMargins = state.margins[action.category].slice()
      let margin = currentMargins.find(m => m.id === action.margin.id)
      if (margin) {
        margin = Object.assign(margin, action.margin)
        const newMargins = Object.assign({}, state.margins, { [action.category]: currentMargins })
        return Object.assign({}, state, { margins: newMargins, isEdited: true, isEditing: false })
      }
      return state
    case PROFIT_MARGIN_DELETED:
      const marginsForCategory = state.margins[action.category].slice()
      const marginIndex = marginsForCategory.findIndex(margin => margin.id === action.marginId)
      marginsForCategory.splice(marginIndex, 1)
      return Object.assign({}, state, { margins: Object.assign({}, state.margins, { [action.category]: marginsForCategory }), isDeleted: true, isDeleting: false })
    case PROFIT_MARGIN_CREATE_ERROR:
      return Object.assign({}, state, {
        isCreating: false,
        isCreated: false,
        createErrorMessage: action.error
      })
    case PROFIT_MARGIN_EDIT_ERROR:
      return Object.assign({}, state, {
        isEditing: false,
        isEdited: false,
        editErrorMessage: action.error
      })
    case PROFIT_MARGIN_DELETE_ERROR:
      return Object.assign({}, state, {
        isDeleting: false,
        isDeleted: false,
        deleteErrorMessage: action.error
      })
    // case PROFIT_MARGINS_GET_ERROR:
    //   return Object.assign({}, state, {
    //     isCreating: false,
    //     isCreated: false,
    //     createErrorMessage: action.error
    //   })
    default:
      return state
  }
}

export default profitMargins
