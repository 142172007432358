import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Modal } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { setPreorderTargetId } from '../../../actions/preOrder'
import { updateCart } from '../../../actions/cart'
import { fetchSupplierByTargetId } from '../../../helpers/preOrder'
import StepsList from './StepsList'
import { push } from 'react-router-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import styles from './styles.less'
import classnames from 'classnames'

export class ChooseTargetStep extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTarget: props.targetId,
      showModal: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.targetId !== nextProps.targetId) {
      this.setState({
        selectedTarget: nextProps.targetId,
      })
    }
  }

  selectTarget(selectedTarget) {
    this.setState({ selectedTarget })
  }

  onClick() {
    const { startPreorder, numberOfCartItems } = this.props
    const { selectedTarget } = this.state
    if (numberOfCartItems > 0) {
      this.setState({ showModal: true })
    } else {
      startPreorder(selectedTarget)
    }
  }

  handleClose() {
    this.setState({ showModal: false })
  }

  changeTarget() {
    const { startPreorder } = this.props
    const { selectedTarget } = this.state
    startPreorder(selectedTarget)
  }

  cancelPreorder() {
    const { startPreorder, emptyCart } = this.props
    startPreorder(null)
    emptyCart()
  }

  render() {
    const { isStarted, targets, goBack, supplierName, targetId, quantityInCart, target, redirect } = this.props
    const { selectedTarget, showModal } = this.state
    const isTargetReached = isStarted && quantityInCart >= target.quantity
    return (
      <div className={classnames(styles.sections, 'preorder-choose-target')}>
        <div className={classnames(styles.chooseTarget)}>
          <h3><T id="Preorder season" /></h3>
          <div className={styles.supplierName}>{supplierName}</div>
          {isStarted && <div className={styles.preorderActive}><T id="Supplier order is active" /></div>}
          {isStarted && <div><T id="Products you place in cart are now added to pre season order and will not be dispatched immediately"/></div>}
          {!isStarted && <b><T id="Select target:" /></b>}
          {targets.map(target => {
            const isSelected = target.id === selectedTarget
            let targetClassName = styles.target
            if (isSelected) {
              targetClassName = isTargetReached ? styles.targetReached : styles.selectedTarget
            } else if (isStarted) {
              targetClassName = styles.unselectedTarget
            }
            return (
              <div className={styles.targetWrapper}>
                <div
                  key={target.id}
                  className={targetClassName}
                  onClick={() => this.selectTarget(target.id)}
                >
                  <span className={styles.targetTitle}>{target.title}</span>
                  <span dangerouslySetInnerHTML={{ __html: target.description.replace(/\n/g,'<br/>')}} />
                </div>
                {target.id === targetId && <FontAwesomeIcon icon={faCheck} className={styles.selectedTargetIcon} />}
              </div>
            )
          })}
          {!isStarted && (
            <React.Fragment>
              <div className={styles.actions}>
                <Button disabled={!selectedTarget} onClick={this.onClick.bind(this)} className={styles.startButton}>
                  <T id="Start Preorder" />
                </Button>
                <Button onClick={goBack}>
                  <T id="Back" />
                </Button>
              </div>
              <Modal show={showModal} onHide={this.handleClose.bind(this)}>
                <Modal.Header closeButton>
                  <Modal.Title><T id="Your cart is not empty" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <T id="You have products in your cart already." />
                  <T id="Please make sure your cart is empty before starting pre-season order." />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                    <T id="Ok" />
                  </Button>
                </Modal.Footer>
              </Modal>
            </React.Fragment>
          )}
          {isStarted && (
            <div className={styles.actions}>
              <Button className={styles.cancelButton} onClick={this.cancelPreorder.bind(this)}>
                <T id="Cancel preorder" />
              </Button>
              <Button className={styles.changeTargetButton} onClick={this.changeTarget.bind(this)} disabled={targetId === selectedTarget}>
                <T id="Change Target" />
              </Button>
            </div>
          )}
          {isStarted && (
            <Button className={styles.fullWidthButton} onClick={e => { e.preventDefault(); redirect('/dack'); }}>
              <T id="Search for products" />
            </Button>
          )}
        </div>
        <div>
          <StepsList />
          {isStarted && (
            <div className={styles.targetDescription} dangerouslySetInnerHTML={{ __html: targets.find(t => t.id === selectedTarget).details.replace(/\n/g,'<br/>')}} />
          )}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  startPreorder: targetId => setPreorderTargetId(targetId),
  emptyCart: () => updateCart([]),
  redirect: path => push(path),
}, dispatch)

const mapStateToProps = (state, props) => {
  let selectedSupplier
  if (props.supplierId)
    selectedSupplier = props.supplierId
  else {
    const targetSupplier = fetchSupplierByTargetId(state.preOrder.suppliers, state.preOrder.targetId);
    if (targetSupplier)
      selectedSupplier = targetSupplier.id
  }
  const targets = state.preOrder.suppliers.find(supplier => supplier.id === selectedSupplier).targets
  return {
    targets,
    target: state.preOrder.targetId ? targets.find(t => t.id === state.preOrder.targetId) : null,
    isStarted: !!state.preOrder.targetId,
    numberOfCartItems: state.cart.items.length,
    selectedSupplier,
    targetId: state.preOrder.targetId,
    supplierName: state.preOrder.suppliers.find(supplier => supplier.id === selectedSupplier).title,
    quantityInCart: state.cart.count,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChooseTargetStep)
