// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__customerType___HRndr {
  display: flex;
}
.styles__radioGroup___tOgCG {
  display: flex;
}
.styles__inlineRadio___LpAau {
  margin-left: 20px;
}
.styles__inlineTextbox___fBsjd {
  display: flex;
}
.styles__footer___WDtOF {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.styles__footer___WDtOF button {
  width: 150px;
}
.styles__invoiceEmailNote___M2mbP {
  text-align: right;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/SignUp/CustomerInfo/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEA;EACE,aAAA;AAAF;AAGA;EACE,iBAAA;AADF;AAIA;EACE,aAAA;AAFF;AAKA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;AAHF;AAAA;EAKI,YAAA;AAFJ;AAMA;EACE,iBAAA;EACA,eAAA;AAJF","sourcesContent":[".customerType {\n  display: flex;\n}\n\n.radioGroup {\n  display: flex;\n}\n\n.inlineRadio {\n  margin-left: 20px;\n}\n\n.inlineTextbox {\n  display: flex;\n}\n\n.footer {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 32px;\n  button {\n    width: 150px;\n  }\n}\n\n.invoiceEmailNote {\n  text-align: right;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customerType": `styles__customerType___HRndr`,
	"radioGroup": `styles__radioGroup___tOgCG`,
	"inlineRadio": `styles__inlineRadio___LpAau`,
	"inlineTextbox": `styles__inlineTextbox___fBsjd`,
	"footer": `styles__footer___WDtOF`,
	"invoiceEmailNote": `styles__invoiceEmailNote___M2mbP`
};
export default ___CSS_LOADER_EXPORT___;
