import React from 'react'
import Product from './Product'
import { Table } from 'react-bootstrap'
import Pagination from '../Pagination'
import SortBy from '../SortBy'
import styles from './styles.less'

export const ProductsListCompact = (props) => {
  const products = props.products.map(product => <Product product={product} key={product.id} />)
  const includeVat = props.includeVat ? '(ink.moms)' : '(ex.moms)'

  return (
    <div>
      <SortBy onSortByClick={props.onSortByClick} selectedOption={props.selectedSortOption}/>
      <Table className="table borderless table-striped product-list product-list-compact list-unstyled">
        <thead>
          <tr>
            <th className={styles.numeric}>Art.nr</th>
            <th>Produkt</th>
            <th className={styles.numeric}>
              Pris/st
              <br />
              {includeVat}
            </th>
            <th colSpan="2" className={styles.numeric}>Lager/Lev.</th>
          </tr>
        </thead>
        <tbody>
          {products}
        </tbody>
      </Table>
      <Pagination
        pagination={props.pagination}
        shop={props.shop}
        onPageClick={props.onPageClick}
      />
    </div>
  )
}

export default ProductsListCompact

