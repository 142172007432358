import React, { Component } from "react";
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { T } from 'components/components/i18n'
import Checkbox from '../../../Checkbox'
import styles from './styles.less'
import { getTyreAlternatives } from "../../../../../helpers/tyreAlternatives";
import classnames from 'classnames'

// NOTE: As described here in the comments https://git.eonbit.com/eontyre/application/-/issues/2095
//       Until we figure out how to handle missing data when search by ktype, we use this for
//       vechile make and model search
class TyreAlternatives extends Component {
  constructor(props) {
    super(props);
    this.handleCheckboxChange = this.onCheckboxChange.bind(this)
    this.handleLinkClick = this.toggleShowAllAlternatives.bind(this)
    this.state = {
      showAllAlternatives: false,
      bestOption: null,
      otherOptions: null,
    }
  }

  componentDidMount() {
    const { tyreSizes } = this.props
    const minDeviation = Math.min(...tyreSizes.map(size => size.deviationPercent))
    const bestOption = tyreSizes.find(option => option.deviationPercent === minDeviation)
    const otherOptions = tyreSizes.filter(option => option.deviationPercent !== minDeviation)
    this.setState({
      bestOption,
      otherOptions,
    })
  }

  toggleShowAllAlternatives(event) {
    event.preventDefault()
    event.stopPropagation()
    const showAllAlternatives = !this.state.showAllAlternatives;
    this.setState({ showAllAlternatives})

    // NOTE: This is to make sure the default bestOption checkbox is checked when
    //       alternative list are collapsed
    const { bestOption } = this.state
    const noneSelected = !this.props.selectedItems.length ||
      this.props.selectedItems.filter(item => item[0] === bestOption.width && item[1] === bestOption.aspectRatio && item[2] === bestOption.diameter).length === 0
    if ( !showAllAlternatives && noneSelected ) {
      const selectedItems = [[
        bestOption.width, bestOption.aspectRatio, bestOption.diameter
      ]]
      this.props.onSearchParamChange('tyreDimensions', selectedItems)
    }
  }

  onCheckboxChange(value) {
    let  selectedItems

    const selectedIndex = this.props.selectedItems.findIndex(item => item[0] === value.width && item[1] === value.aspectRatio && item[2] === value.diameter )
    if (selectedIndex !== -1)
      selectedItems = this.props.selectedItems.filter((item, index) => index !== selectedIndex)
    else
      selectedItems = [...this.props.selectedItems, [value.width, value.aspectRatio, value.diameter]]

    if (!selectedItems || !selectedItems.length) {
      // NOTE: This is to make ux behaviour consistent where if none is selected
      //       make it select all
      const { showAllAlternatives, bestOption, otherOptions } = this.state
      const visibleSizes = showAllAlternatives ? [ bestOption, ...otherOptions ] : [ bestOption ]
      selectedItems = visibleSizes.map(value => [
        value.width, value.aspectRatio, value.diameter
      ])
    }

    this.props.onSearchParamChange('tyreDimensions', selectedItems)
  }

  render() {
    const { selectedItems, isWheelBuilder } = this.props
    const { showAllAlternatives, bestOption, otherOptions } = this.state
    if (!bestOption)
      return null

    const visibleSizes = showAllAlternatives ? [ bestOption, ...otherOptions ] : [ bestOption ]
    return (
      <div className={classnames({
        [styles.alternatives]: isWheelBuilder,
      }, 'tyre-alternatives')}>
        {visibleSizes.map(alternative => {
          const isChecked = selectedItems.find(item => item[0] === alternative.width && item[1] === alternative.aspectRatio && item[2] === alternative.diameter )
          const className = alternative.deviationPercent === 0 ? styles.best : styles[alternative.deviationClass]
          const svgClassName = alternative.deviationPercent === 0 ? styles['svg-best'] : styles[`svg-${alternative.deviationClass}`]
          const deviationPercentage = (alternative.deviationPercent === null || alternative.deviationPercent === undefined) ? 'UNKNOWN' : alternative.deviationPercent.toFixed(2)
          return (
            <Checkbox
              inline
              onChange={this.handleCheckboxChange}
              value={alternative}
              key={alternative.text}
              label={`${alternative.text} (${deviationPercentage}%)`}
              className={className}
              isChecked={isChecked}
              name={alternative}
              svgClass={svgClassName}
            />
          )}
        )}
        {[ bestOption, ...otherOptions ].length > 1 && <Link to="" onClick={this.handleLinkClick}>
          {showAllAlternatives ? <T id="Hide tyre alternatives" /> : <T id="Show tyre alternatives" />}
        </Link>}
      </div>
    );
  }
}


const mapStateToProps = state => ({
  selectedItems: state.tyreSearchParams.tyreDimensions,
  tyreSizes: getTyreAlternatives(state),
  isWheelBuilder: state.wheelBuilder.showWheelProgress,
})

export default connect(mapStateToProps)(TyreAlternatives);
