import { api } from '../../helpers/api'

export const PRODUCT_RETRIEVED = 'PRODUCT_RETRIEVED'
export const PRODUCT_RESET = 'PRODUCT_RESET'

export const getProduct = (productId, shop) => {
  return (dispatch, getState) => {
    const targetId = getState().preOrder.targetId
    const params = targetId ? `?preorder=${targetId}` : null
    return api(`${shop.apiUrl}/products/${productId}${params || ''}`, 'get')
      .then(response => {
        return response.json()
      })
      .then(response => {
        dispatch({
          type: PRODUCT_RETRIEVED,
          product: response.data
        })
      })
  }
}

export const resetProduct = () => {
  return dispatch => {
    dispatch({
      type: PRODUCT_RESET
    })
  }
}
