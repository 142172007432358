import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Tabs, Tab, Col, Row } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import NewMarginModal from './NewMarginModal'
import { CATEGORIES } from '../../../../helpers/categories'
import { getProfitMargins, createProfitMargin } from '../../../../actions/profitMargins'
import ProfitMargin from './ProfitMargin'
import styles from './styles.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Alert } from 'react-bootstrap'
import classnames from 'classnames'

class ProfitMargins extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showNewMarginModal: false,
      selectedTab: CATEGORIES.TYRE.id
    }
    this.handleNewMarginClick = this.onModalOpen.bind(this)
    this.handleTabChange = this.onTabClick.bind(this)
    this.handleModalClose = this.onModalClose.bind(this)
    this.handleSaveProfitMargin = this.onSaveProfitMargin.bind(this)
  }

  componentDidMount() {
    const { getProfitMargins, shop } = this.props
    getProfitMargins(shop)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isCreated && this.props.isCreated)
      this.onModalClose()
  }

  onSaveProfitMargin(params) {
    const { createProfitMargin, shop } = this.props
    createProfitMargin(params, this.state.selectedTab, shop)
  }

  onTabClick(selectedTab) {
    this.setState({
      selectedTab
    })
  }

  onModalOpen() {
    this.setState({
      showNewMarginModal: true
    })
  }

  onModalClose() {
    this.setState({
      showNewMarginModal: false
    })
  }

  render() {
    const { profitMargins, isDeleting, deleteErrorMessage, isCreating, createErrorMessage } = this.props
    const tyreMarginsComponents = profitMargins[CATEGORIES.TYRE.id].map((margin, index) => {
      return (
        <ProfitMargin margin={margin} type="tyre" key={`tyre-${index}`}/>
      )
    })
    const rimMarginsComponents = profitMargins[CATEGORIES.RIM.id].map((margin, index) => {
      return (
        <ProfitMargin margin={margin} type="rim" key={`rim-${index}`}/>
      )
    })
    let modal
    if (this.state.showNewMarginModal)
      modal = (
        <NewMarginModal
          errorMessage={createErrorMessage}
          isLoading={isCreating}
          closeModal={this.handleModalClose}
          selectedType={this.state.selectedTab}
          onButtonClick={this.handleSaveProfitMargin}
        />
      )

    let alert
    if (deleteErrorMessage && !isDeleting)
      alert = (
        <Alert key="error" dismissible variant="danger">
          <T id="Could not delete profit margin. Error" />: {deleteErrorMessage}
        </Alert>
      )
    return (
      <div className="profit-margins">
        <h2><T id="Konsumentpriser"/></h2>
        <section className={classnames(styles.mainContent, 'profit-margins-content')}>
          {alert}
          <div className={styles.buttonRow}>
            <Button onClick={this.handleNewMarginClick}><FontAwesomeIcon icon={faPlus}/>
              <T id="Add new margin"/>
            </Button></div>
          <Tabs activeKey={this.state.selectedTab} onSelect={this.handleTabChange} id="profit-margins">
            <Tab eventKey={CATEGORIES.TYRE.id} title={CATEGORIES.TYRE.label}>
              <div className={classnames(styles.tabContent, 'profit-margins-tab-content')}>
                <Row className={styles.header}>
                  <Col sm={2} xs={3}><T id="Type"/></Col>
                  <Col sm={2} xs={2} className={styles.headerCellRight}><T id="Diameter"/></Col>
                  <Col sm={2} xs={2} className={styles.headerCellRight}><T id="Percent"/></Col>
                  <Col sm={2} xs={2} className={styles.headerCellRight}><T id="Amount"/></Col>
                </Row>
                <div>{tyreMarginsComponents}</div>
              </div>
            </Tab>
            <Tab eventKey={CATEGORIES.RIM.id} title={CATEGORIES.RIM.label}>
              <div className={classnames(styles.tabContent, 'profit-margins-tab-content')}>
                <Row className={styles.header}>
                  <Col sm={2} xs={3}><T id="Type"/></Col>
                  <Col sm={2} xs={2} className={styles.headerCellRight}><T id="Diameter"/></Col>
                  <Col sm={2} xs={2} className={styles.headerCellRight}><T id="Percent"/></Col>
                  <Col sm={2} xs={2} className={styles.headerCellRight}><T id="Amount"/></Col>
                </Row>
                <div>{rimMarginsComponents}</div>
              </div>
            </Tab>
          </Tabs>
        </section>
        {modal}
      </div>
     )
  }
}

const mapStateToProps = state => {
  return {
    profitMargins: state.profitMargins.margins,
    user: state.user.user,
    shop: state.shop,
    deleteErrorMessage: state.profitMargins.deleteErrorMessage,
    isDeleting: state.profitMargins.isDeleting,
    isDeleted: state.profitMargins.isDeleted,
    createErrorMessage: state.profitMargins.createErrorMessage,
    isCreating: state.profitMargins.isCreating,
    isCreated: state.profitMargins.isCreated
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createProfitMargin: (params, category, shop) => createProfitMargin(params, category, shop),
  getProfitMargins: (shop) => getProfitMargins(shop)
}, dispatch)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfitMargins)
