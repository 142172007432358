import {
  INITIALIZE_SIGNUP,
  MOVE_TO_NEXT_SIGNUP_STEP,
  MOVE_TO_PREVIOUS_SIGNUP_STEP,
  SIGNUP_PARAMETER_CHANGED,
  SIGNUP_DONE,
  VALIDATING_STEP_FIELDS,
  FIELDS_VALIDATION_ERROR,
  FIELDS_VALIDATION_SUCCESS,
  VALIDATING_CREDENTIALS,
  CREDENTIALS_VALIDATION_SUCCESS,
  CREDENTIALS_VALIDATION_ERROR,
  CONFIRMATION_IN_PROGRESS,
  CONFIRMATION_ERROR,
  CONFIRMATION_DONE
} from '../actions/signUp'
import {
  SHOP_RETRIEVED
} from '../actions'

// TODO: validate method
const signUp = (state = { step: 0, params: { }, fields: [], customFields: [], hasCustomFields: false, enabled: false, errors: {} }, action) => {
  switch(action.type) {
    case SHOP_RETRIEVED:
    case INITIALIZE_SIGNUP:
      const shop = action.shop
      const customerType = shop.signup && shop.signup.fields && shop.signup.fields.customerType && shop.signup.fields.customerType.default
      const initializedParams = { customerType }
      const hasCustomFields =  shop.signup && shop.signup.customFields && shop.signup.customFields.length > 0
      if (hasCustomFields)
        action.shop.signup.customFields.forEach(field => initializedParams[field.name] = field.default)
      return Object.assign({}, state, {
        step: 0,
        fields:  (shop.signup && shop.signup.fields) || [],
        customFields:  (shop.signup && shop.signup.customFields) || [],
        hasCustomFields,
        enabled:  shop.signup && shop.signup.enabled,
        params: initializedParams,
        isValidated: false,
        isValid: false,
        errors: {},
        status: null,
        credentialsValidated: false,
        credentialsValid: false,
        isConfirmationSuccessful: false,
        isConfirmationInProgress: false,
        confirmationError: false
      })
    case MOVE_TO_NEXT_SIGNUP_STEP:
      const initialParams = Object.assign({}, state.params)
      if (state.step === 0 && initialParams.customerType === 'business')
        initialParams.companyEmail = initialParams.userEmail
      return Object.assign({}, state, {
        step: state.step + 1,
        params: initialParams,
        errors: {},
        isValid: false,
        isValidated: false
      })
    case MOVE_TO_PREVIOUS_SIGNUP_STEP:
      return Object.assign({
      }, state, {
        step: state.step - 1,
        errors: {}
      })
    case SIGNUP_PARAMETER_CHANGED:
      const parameters = Object.assign({}, state.params, { [action.parameterName]: action.parameterValue })
      if (action.parameterName === 'customerType' && action.parameterValue === 'business')
        parameters.companyEmail = parameters.userEmail
      return Object.assign({
      }, state, {
        params: parameters,
        isValidated: false
      })
    case SIGNUP_DONE:
      return Object.assign({}, state, { status: action.status })
    case FIELDS_VALIDATION_ERROR:
      return Object.assign({}, state, { errors: action.errors, isValidated: true, isValid: false })
    case VALIDATING_STEP_FIELDS:
      return Object.assign({}, state, { isValidated: false, isValid: false })
    case FIELDS_VALIDATION_SUCCESS:
      return Object.assign({}, state, { isValidated: true, isValid: true })
    case VALIDATING_CREDENTIALS:
      return Object.assign({}, state, {
        credentialsValidated: false
      })
    case CREDENTIALS_VALIDATION_ERROR:
      return Object.assign({}, state, {
        credentialsValidated: true,
        errors: action.errors,
        credentialsValid: false
      })
    case CREDENTIALS_VALIDATION_SUCCESS:
      return Object.assign({}, state, {
        credentialsValidated: true,
        credentialsValid: true
      })
    case CONFIRMATION_IN_PROGRESS:
      return Object.assign({}, state, {
        isConfirmationInProgress: true,
        isConfirmationSuccessful: false
      })
    case CONFIRMATION_ERROR:
      return Object.assign({}, state, {
        isConfirmationInProgress: false,
        confirmationError: action.error,
        isConfirmationSuccessful: false
      }) 
    case CONFIRMATION_DONE:
      return Object.assign({}, state, {
        isConfirmationInProgress: false,
        confirmationError: null,
        isConfirmationSuccessful: true
      })
    default:
      return state
  }
}

export default signUp

