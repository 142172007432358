import React, { Component } from 'react'
import { T } from 'components/components/i18n'
import { Row, Col, FormControl } from 'react-bootstrap'
import Button from '../Button'
import styles from './styles.less'
import classnames from 'classnames'

class GaragesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      zipcode: this.props.defaultZipcode
    }
  }

  render() {
    const { garages, selectedGarage, selectGarage, searchGarages } = this.props
    const { zipcode } = this.state
    return (
      <div className={classnames(styles.container, 'garages-list')}>
        <Row>
          <Col xs={4}>
            <label><T id="Calculate distances from postal code" /></label>
            <FormControl
              type="text"
              name="zipcode"
              id="zipcode"
              value={zipcode}
              onChange={event => this.setState({ zipcode: event.target.value })}
            />
          </Col>
          <Col xs={8} style={{ textAlign: "right" }}>
            <Button
              onClick={() => searchGarages(zipcode)}
              label={<T id="Calculate" />}
            />
          </Col>
        </Row>
        {garages.map(garage => (
          <Row className={classnames(styles.item, 'garages-list-item')}>
            <Col xs={2} className={styles.distance}>{garage.distance}</Col>
            <Col xs={8} className={styles.text}>
              <div className={styles.name}>{garage.name}</div>
              <div className={styles.address}>{garage.address_1}, {garage.zipcode} {garage.city}</div>
            </Col>
            <Col xs={2} style={{ textAlign: "right" }}>
              {selectedGarage !== garage.id && (
                <Button
                  onClick={() => selectGarage(garage.id)}
                  label={<T id="Select" />}
                />
              )}
              {selectedGarage === garage.id && <T id="Selected" />}
            </Col>
          </Row>
        ))}
      </div>
    )
  }
}

export default GaragesList