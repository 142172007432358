// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__input___UMD7r {
  width: 250px;
}
.styles__link___f9H3v {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles__vehicleInfo___Llqsi {
  margin-bottom: 10px;
}
.styles__smallInput___ZaiQ4 {
  width: 150px;
}
.styles__upperCase___SFrBF {
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/RimSearch/SearchMode/styles.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAEA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAAF;AAGA;EACE,mBAAA;AADF;AAIA;EACE,YAAA;AAFF;AAKA;EACE,yBAAA;AAHF","sourcesContent":[".input {\n  width: 250px;\n}\n\n.link {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.vehicleInfo {\n  margin-bottom: 10px;\n}\n\n.smallInput {\n  width: 150px;\n}\n\n.upperCase {\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `styles__input___UMD7r`,
	"link": `styles__link___f9H3v`,
	"vehicleInfo": `styles__vehicleInfo___Llqsi`,
	"smallInput": `styles__smallInput___ZaiQ4`,
	"upperCase": `styles__upperCase___SFrBF`
};
export default ___CSS_LOADER_EXPORT___;
