import React from 'react'
import { Popover, Overlay } from 'react-bootstrap'
import styles from './styles.less'
import { getTypeById, SUBTYPES } from '../../../../../constants/tyreTypes'
import { TyreTypeSvg } from '../../../SvgIcon'
import classnames from 'classnames'

export default class TyreTypeIcon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPopover: false
    }
  }

  onMouseEnter() {
    this.setState({
      showPopover: true
    })
  }

  onMouseLeave() {
    this.setState({
      showPopover: false
    })
  }

  render() {
    const { product, fullName } = this.props
    if (product.attrs && product.attrs.tyreType) {
      const type = product.attrs.tyreType.id ? getTypeById(product.attrs.tyreType.id.toString()) : null
      if (!type)
        return product.attrs.tyreType.name

      const className = type.id === SUBTYPES.STUDDED.id ? styles.studdedIcon : styles.icon
      const iconClass = type.checkedClass || 'svg-colored'

      if (fullName)
        return <span>{type.label}</span>
      return (
        <div ref={el => this.popoverTarget = el} className={classnames(className, 'tyre-type-icon')}>
          <div
            onMouseEnter={this.onMouseEnter.bind(this)}
            onMouseLeave={this.onMouseLeave.bind(this)}
            className={className}
          >
            <TyreTypeSvg
              type={type.id}
              className={`${className} ${iconClass}`}
            />
          </div>
          <Overlay placement="bottom" show={this.state.showPopover} target={this.popoverTarget}>
            <Popover placement="bottom" id="tyre-type-popover" className={`${styles.popover} background-normal`}>
              {type.description}
            </Popover>
          </Overlay>
        </div>
      )
    }
    return null
  }
}
