import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Table,
  Button,
  FormControl,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap'
import { keyCodes } from 'constants/keyCodes'
import { FormattedNumber } from 'react-intl'
import { T } from 'components/components/i18n'
import { Checkbox } from 'components/components/Checkbox'
import { getOrders, initOrders, setDelivered } from 'actions/orders'
import { setActiveSlug } from '../../../actions/menu'
import Spinner from '../Spinner'
import Pagination from '../Search/SearchResults/Pagination'
import { ORDER_TYPE } from '../../../constants/orderTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faCheck } from '@fortawesome/free-solid-svg-icons'
import { createBrowserHistory } from 'history'

import styles from './styles.less'

export class OrdersList extends React.Component {
  constructor(props) {
    super(props)

    this.queryRef = React.createRef()
    this.deliveredRefs = Array.from({ length: 25 }, () => React.createRef())
    this.handleKeyPress = this.onKeyPress.bind(this)
    this.handleClick = this.onClick.bind(this)
    this.handleReset = this.onReset.bind(this)
    this.handleFilter = this.onFilterDelivered.bind(this)
    this.handleSetDelivered = this.setDelivered.bind(this)

    const history = createBrowserHistory()
    this.state = {
      query: '',
      filterDelivered: 0,
      deliveredState: Array.from({ length: 25 }, () => 0),
      unlisten: history.listen((location, action) => {
        if (action === "POP") {
          this.props.initOrders(this.props.shop, true)
        }
      })
    }
  }

  componentDidMount() {
    const { shop, setActiveSlug, slug, initOrders } = this.props
    document.title = `Mina ordrar - ${shop.title}`
    setActiveSlug(slug)
    initOrders(shop)
  }

  onKeyPress(event) {
    if (event.which === keyCodes.ENTER && event.target.value !== this.state.query) {
      this.search(event.target.value)
    }
  }

  onClick() {
    if (this.queryRef.current.value !== this.state.query) {
      this.search(this.queryRef.current.value)
    }
  }

  onReset() {
    const { shop, getOrders } = this.props
    this.setState({
      query: '',
      filterDelivered: 0
    })
    getOrders(shop, 1)
  }

  search(query) {
    this.props.getOrders(this.props.shop, 1, query, this.state.filterDelivered)
    this.setState({
      query
    })
  }

  onFilterDelivered(value) {
    this.props.getOrders(this.props.shop, 1, this.state.query, value)
    this.setState({
      filterDelivered: value
    })
  }

  componentWillReceiveProps(newProps) {
    const { shop, searchParams } = newProps
    if (shop.id !== this.props.shop.id) {
      document.title = `Mina ordrar - ${shop.title}`
    }
    if (searchParams.isInitialize === true) {
      this.setState({
        query: searchParams.query,
        filterDelivered: parseInt(searchParams.delivered, 10),
      })
    }
  }

  setDelivered(idx, orderId) {
    this.setState((prevState) => {
      prevState.deliveredState[idx] = 1
      return prevState
    })
    this.props.setDelivered(this.props.shop, orderId)
      .then((res) => {
        if (!res) {
          this.setState((prevState) => {
            prevState.deliveredState[idx] = 0
            return prevState
          })
        } else {
          this.setState((prevState) => {
            prevState.deliveredState[idx] = 2
            return prevState
          })
        }
      })
  }

  render() {
    const { orders, hasDirectDelivery, isLoading, pagination, shop } = this.props
    const { deliveredState } = this.state

    const showOriginColumn = shop.showOrdersOrigin
    if (isLoading) {
      return <Spinner />
    }
    const ordersRows = orders.map((order, idx) => {
      const rowCss = idx % 2 === 0 ? styles.row : null
      // const items = order.lines.map(item => item.product_name).join(', ')
      // let columnCount = 6
      let formattedSupplierOrderIds
      let formattedOrigin

      if (hasDirectDelivery) {
        formattedSupplierOrderIds = <td>{order.formattedSupplierOrderIds}</td>
        // columnCount++
      }
      if (showOriginColumn) {
        formattedOrigin = <td>{order.webshop_domain || 'EONTYRE'}</td>
        // columnCount++
      }
      return (
        <tr key={order.id} className={rowCss}>
          <td>
            <Link to={`/ordrar/${order.id}`}>{order.id}</Link>
          </td>
          <td>{order.date}</td>
          <td>
            {order.formattedStatus}
            {order.type === ORDER_TYPE.PREORDER && <T id=" (pre order)"/>}
          </td>
          <td>
            <div className={styles.center}>
              {(!order.delivered && deliveredState[idx] === 0) && (
                <Checkbox
                  inputRef={this.deliveredRefs[idx]}
                  onChange={() => this.handleSetDelivered(idx, order.id)}
                />
              )}
              {deliveredState[idx] === 1 && (
                <React.Fragment>
                  <Spinner inline={true} /> &nbsp;
                </React.Fragment>
              )}
              {(deliveredState[idx] === 2 || order.delivered === 1) && (
                <FontAwesomeIcon icon={faCheck} />
              )}
            </div>
          </td>
          {formattedOrigin}
          {formattedSupplierOrderIds}
          <td>
            <div className={styles.ellipsis} title={order.customer_reference}>
              {order.customer_reference}
            </div>
          </td>
          <td>{order.car_licenseplate}</td>
          <td className={styles.number}>
            <FormattedNumber
              value={order.nett}
              style={`decimal`}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </td>
        </tr>
      )
      // <React.Fragment>
      //   <tr key={`${order.id}_items`} className={`${rowCss} ${styles.items}`}>
      //     <td colSpan={columnCount}>
      //       <b><T id="Benämning"/ >:</b> {items}
      //     </td>
      //   </tr>
      // </React.Fragment>
    })

    let formattedSupplierOrderIdsHead
    let formattedOriginHead
    if (hasDirectDelivery) {
      formattedSupplierOrderIdsHead = <th>Kolli ref</th>
    }
    if (showOriginColumn) {
      formattedOriginHead = <th><T id="Origin"/></th>
    }
    const isQueryTooShort = this.state.query && this.state.query.length < 3

    return (
      <div className={`${styles.container} container orders-list`}>
        <h1><T id="My orders"/></h1>

        <div className={styles.searchContainer}>
          <FormControl
            name="query"
            onKeyPress={this.handleKeyPress}
            className={styles.searchInput}
            inputRef={this.queryRef}
            defaultValue={this.state.query}
          />
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover>
                <b><T id="Search tips" /></b>
                <div>
                  <T id="Search by order no., license, brand names, reference, order date (e.g. 2024-10-30)" />
                </div>
              </Popover>
            }
          >
            <button className={`button-link ${styles['searchHelp']}`}>
              <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />
            </button>
          </OverlayTrigger>
          <Button
            onClick={this.handleClick}
            inProgress={isLoading}
          >
            <T id="Search"/>
          </Button>
          <Button
            onClick={this.handleReset}
            inProgress={isLoading}
          >
            <T id="Reset"/>
          </Button>
          <Checkbox
            inline
            onChange={this.handleFilter}
            name={0}
            label={<T id="All" />}
            isChecked={this.state.filterDelivered === 0}
          />
          <Checkbox
            inline
            onChange={this.handleFilter}
            name={1}
            label={<T id="Delivered" />}
            isChecked={this.state.filterDelivered === 1}
          />
          <Checkbox
            inline
            onChange={this.handleFilter}
            name={2}
            label={<T id="Not delivered" />}
            isChecked={this.state.filterDelivered === 2}
          />

        </div>

        <Table bordered striped>
          <thead>
            <tr>
              <th><T id="Order no."/></th>
              <th><T id="Date"/></th>
              <th><T id="Status"/></th>
              <th>
                <div className={styles.center}>
                  <T id="Delivered"/>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Popover>
                        <T id="Click checkbox to mark order is delivered" />
                      </Popover>
                    }
                  >
                    <button className={`button-link ${styles['deliveredHelp']}`}>
                      <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />
                    </button>
                  </OverlayTrigger>
                </div>
              </th>
              {formattedOriginHead}
              {formattedSupplierOrderIdsHead}
              <th><T id="Reference"/></th>
              <th><T id="License plate number"/></th>
              <th className={styles.number}><T id="Price (ex. VAT)"/></th>
            </tr>
          </thead>
          <tbody>
            {ordersRows}
          </tbody>
        </Table>
        {(Array.isArray(orders) && !orders.length) && (
          <div className={styles.warning}>
            {(isQueryTooShort) && (
              <T id="Search query is too short, please allow 3 characters or more." />
            )}
            {!isQueryTooShort && (
              <T id="No orders to show." />
            )}
          </div>
        )}
        <Pagination
          shop={shop}
          pagination={pagination}
          onPageClick={
            page => this.props.getOrders(shop, page, this.state.query, this.state.filterDelivered)
          }
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrders: (shop, page, query, delivered) => getOrders(shop, page, query, delivered),
  initOrders: (shop, reload) => initOrders(shop, reload),
  setDelivered: (shop, orderId) => setDelivered(shop, orderId),
  setActiveSlug: (slug) => setActiveSlug(slug)
}, dispatch)

const mapStateToProps = state => {
  return {
    orders: state.orders.orders,
    pagination: state.orders.pagination,
    isLoading: state.orders.areOrdersLoading,
    shop: state.shop,
    searchParams: state.orders.search,
    hasDirectDelivery: state.shop.hasDirectDelivery,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersList)
