import React from 'react'
import { connect } from 'react-redux'
import { Popover, Overlay } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import styles from './styles.less'

class SupplierPopover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPopover: false
    }
  }

  onMouseEnter(event) {
    this.setState({
      showPopover: true
    })
  }

  onMouseLeave(event) {
    this.setState({
      showPopover: false
    })
  }

  render() {
    const { supplier, supplierNameComponent, isPopoverEnabled } = this.props
    const supplierNameHtml = <div dangerouslySetInnerHTML={{ __html: supplierNameComponent }}/>
    if (isPopoverEnabled && supplier && supplier.phone)
      return (
        <div
          key={supplier.id}
          ref={el => this.popoverTarget = el}
          onMouseEnter={this.onMouseEnter.bind(this)}
          onMouseLeave={this.onMouseLeave.bind(this)}
          className="supplier-popover"
        >
          <div className={styles.trigger}>
            {supplierNameHtml}
          </div>
          <Overlay placement="bottom" show={this.state.showPopover} target={this.popoverTarget}>
            <Popover placement="bottom" id={`${supplier.id}-supplier`} className={`${styles.popover} background-normal`}>
              <div className={styles.flexIt}><span><T id="Phone number"/></span><span>{supplier.phone}</span></div>
            </Popover>
          </Overlay>
        </div>
      )
    return <div>{supplierNameHtml}</div>
  }
}

const mapStateToProps = state => ({
  isPopoverEnabled: state.shop.showSupplierPhoneNumber
})

export default connect(
  mapStateToProps
)(SupplierPopover)
