// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__link___AdPgX {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.styles__button___D2YGj {
  width: 100px;
}
.styles__filters___AVZrB {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/CompleteWheelSearch/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,gCAAA;EACA,oBAAA;EACA,mBAAA;AADF;AAIA;EACE,YAAA;AAFF;AAKA;EACC,gBAAA;AAHD","sourcesContent":["@import '../../../../shared/variables.less';\n\n.link {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  border-bottom: 1px solid @eon-border-color;\n  padding-bottom: 10px;\n  margin-bottom: 10px;\n}\n\n.button {\n  width: 100px;\n}\n\n.filters {\n\tmargin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `styles__link___AdPgX`,
	"button": `styles__button___D2YGj`,
	"filters": `styles__filters___AVZrB`
};
export default ___CSS_LOADER_EXPORT___;
