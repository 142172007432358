import { RESTRICT_DIAMETERS } from '../actions/diameters'
import { SEARCH_MODE_CHANGED, UPDATE_VEHICLE } from '../actions/search'
import { RESET_VEHICLE } from '../actions/vehicle'
import { CATEGORY_CHANGED } from '../actions/category'
import { VEHICLE_RETRIEVED_BY_ID, CAR_PICKER_INITIALIZED } from '../actions/carPicker'

const diameters = (state = {
  selectedOption: null,
  isRestricted: false
}, action) => {
  switch (action.type) {
    case CATEGORY_CHANGED:
    case SEARCH_MODE_CHANGED:
      return Object.assign({}, state, {
        diameters: null,
        isRestricted: false,
        selectedOption: null,
        restrictedDiametersOptions: []
      })
    case RESTRICT_DIAMETERS:
      return Object.assign({}, state, {
        isRestricted: true,
        diameters: action.restrictedDiameters,
        selectedOption: action.selectedOption,
      })
    case UPDATE_VEHICLE:
    case VEHICLE_RETRIEVED_BY_ID:
    case CAR_PICKER_INITIALIZED:
      const options = (action.vehicle && action.vehicle.options) || []
      const selectedOption = options.find(option => !!option.default)
      return Object.assign({}, state, {
        restrictDiametersOptions: options.filter(option => option.type === 'RESTRICT_DIAMETER'),
        selectedOption: selectedOption && selectedOption.label
      })
    case RESET_VEHICLE:
      return { ...state, selectedOption: null, isRestricted: false, restrictDiametersOptions: null }
    default:
      return state
  }
}

export default diameters
