// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__typeOption___YnBmc {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  min-width: 72px;
  width: 72px;
  height: 72px;
  margin-right: 10px;
  padding: 5px 2px 2px 2px;
  font-size: 14px;
  cursor: pointer;
}
.styles__icon___gp7lF {
  display: flex;
  position: relative;
  top: 4px;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  margin-bottom: 4px;
}
.styles__label___Mnzzo {
  line-height: 1em;
  text-align: center;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.styles__checkedIcon___xN_1A {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/TyreSearch/TyreType/TypeOption/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,wBAAA;EACA,eAAA;EACA,eAAA;AACF;AAEA;EACE,aAAA;EACA,kBAAA;EACA,QAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAAF;AAGA;EACE,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;AADF;AAIA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;AAFF","sourcesContent":[".typeOption {\n  display: flex;\n  position: relative;\n  align-items: center;\n  flex-direction: column;\n  min-width: 72px;\n  width: 72px;\n  height: 72px;\n  margin-right: 10px;\n  padding: 5px 2px 2px 2px;\n  font-size: 14px;\n  cursor: pointer;\n}\n\n.icon {\n  display: flex;\n  position: relative;\n  top: 4px;\n  align-items: center;\n  justify-content: center;\n  height: 32px;\n  width: 32px;\n  margin-bottom: 4px;\n}\n\n.label {\n  line-height: 1em;\n  text-align: center;\n  width: 100%;\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n}\n\n.checkedIcon {\n  position: absolute;\n  top: 2px;\n  left: 2px;\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typeOption": `styles__typeOption___YnBmc`,
	"icon": `styles__icon___gp7lF`,
	"label": `styles__label___Mnzzo`,
	"checkedIcon": `styles__checkedIcon___xN_1A`
};
export default ___CSS_LOADER_EXPORT___;
