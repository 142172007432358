// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__input-wrapper___PsudF {
  position: relative;
}
.styles__hint-input___BaMcE {
  padding-right: 26px !important;
  z-index: 1;
}
.styles__hint-icon___szeWs {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
  z-index: 2;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Form/InputGroup/styles.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAEA;EACI,8BAAA;EACA,UAAA;AAAJ;AAGA;EACI,kBAAA;EACA,WAAA;EACA,QAAA;EACA,eAAA;EACA,UAAA;AADJ","sourcesContent":[".input-wrapper {\n    position: relative;\n}\n\n.hint-input {\n    padding-right: 26px !important;\n    z-index: 1;\n}\n\n.hint-icon {\n    position: absolute;\n    right: 10px;\n    top: 6px;\n    cursor: pointer;\n    z-index: 2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-wrapper": `styles__input-wrapper___PsudF`,
	"hint-input": `styles__hint-input___BaMcE`,
	"hint-icon": `styles__hint-icon___szeWs`
};
export default ___CSS_LOADER_EXPORT___;
