// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__starRating___o_Lrg {
  margin-left: 10px;
}
.styles__flexIt___HoE3W {
  display: flex;
}
.styles__title___JSVxy {
  margin-bottom: 10px;
}
.styles__popover___WYloC {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Search/SearchResults/TestResults/styles.less"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;AAIA;EACE,aAAA;AAFF;AAKA;EACE,mBAAA;AAHF;AAMA;EACE,WAAA;AAJF","sourcesContent":["@import '../../../../../shared/variables.less';\n\n.starRating {\n  margin-left: 10px;\n}\n\n.flexIt {\n  display: flex;\n}\n\n.title {\n  margin-bottom: 10px;\n}\n\n.popover {\n  color: @eon-header-text-color;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"starRating": `styles__starRating___o_Lrg`,
	"flexIt": `styles__flexIt___HoE3W`,
	"title": `styles__title___JSVxy`,
	"popover": `styles__popover___WYloC`
};
export default ___CSS_LOADER_EXPORT___;
