import React from 'react'
import { FormGroup, Row, Col, ControlLabel } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import InputGroup from '../../Form/InputGroup'
import styles from './styles.less'
import RadioButton from '../../RadioButton'

export class CollectCustomerInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onInputBlur(event) {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
    this.props.onFieldBlur(name, value)
  }

  onCustomerTypeSelected(name, value) {
    this.props.onCustomerTypeSelected(value)
  }

  render() {
    const {
      customerType,
      showAdditionalAddressFields,
      onShowAdditionalAddressFieldsClicked,
      errors,
      showDeliveryAddress,
    } = this.props

    let customerTypeRadioButton = []
    let customerTypeFields = []
    let additionalAddressFields

    customerTypeRadioButton = (
      <div className="customer-type-radio-button-group">
        <RadioButton
          key="1"
          name="customerType"
          onChange={this.onCustomerTypeSelected.bind(this)}
          value="2"
          isChecked={customerType === "2"}
          label={<T id="Person"/>}
        />
        <RadioButton
          key="2"
          name="customerType"
          onChange={this.onCustomerTypeSelected.bind(this)}
          value="1"
          isChecked={customerType === "1"}
          label={<T id="Business"/>}
        />
      </div>
    )

    if (customerType === "2") {
      customerTypeFields.push(
        <InputGroup
          key="customerName"
          name="customerName"
          label={<T id="First and last name"/>}
          value={this.state.customerName}
          onBlur={this.onInputBlur.bind(this)}
          error={!!errors['customerName']}
        />
      )
    } else {
      customerTypeFields.push(
        <InputGroup
          key="customerName"
          name="customerName"
          label={<T id="Company name"/>}
          value={this.state.customerName}
          onBlur={this.onInputBlur.bind(this)}
          error={!!errors['customerName']}
        />
      )
      customerTypeFields.push(
        <InputGroup
          key="customerIdNumber"
          name="customerIdNumber"
          label={<T id="Org. number (112233-XXXX)"/>}
          value={this.state.customerIdNumber}
          onBlur={this.onInputBlur.bind(this)}
          error={!!errors['customerIdNumber']}
        />
      )
      customerTypeFields.push(
        <InputGroup
          key="customerReference"
          name="customerReference"
          label={<T id="Reference"/>}
          value={this.state.customerReference}
          onBlur={this.onInputBlur.bind(this)}
          error={!!errors['customerReference']}
        />
      )
    }

    if (showDeliveryAddress) {
      if (showAdditionalAddressFields) {
        additionalAddressFields = (
          <div id="delivery-address-fields">
            <InputGroup
              key="deliveryName"
              name="deliveryName"
              label={<T id="Name"/>}
              value={this.state.deliveryName}
              onBlur={this.onInputBlur.bind(this)}
              error={!!errors['deliveryName']}
            />
            <InputGroup
              key="deliveryAddress1"
              name="deliveryAddress1"
              label={<T id="Address"/>}
              value={this.state.deliveryAddress1}
              onBlur={this.onInputBlur.bind(this)}
              error={!!errors['deliveryAddress1']}
            />
            <InputGroup
              key="deliveryAddress2"
              name="deliveryAddress2"
              label={<T id="Address 2"/>}
              value={this.state.deliveryAddress2}
              onBlur={this.onInputBlur.bind(this)}
              error={!!errors['deliveryAddress2']}
            />
            <Row>
              <Col xs={4}>
                <InputGroup
                  key="deliveryPostalCode"
                  name="deliveryPostalCode"
                  label={<T id="Zipcode"/>}
                  value={this.state.deliveryPostalCode}
                  onBlur={this.onInputBlur.bind(this)}
                  error={!!errors['deliveryPostalCode']}
                />
              </Col>
              <Col xs={8}>
                <InputGroup
                  key="deliveryCity"
                  name="deliveryCity"
                  label={<T id="City"/>}
                  value={this.state.deliveryCity}
                  onBlur={this.onInputBlur.bind(this)}
                  error={!!errors['deliveryCity']}
                />
              </Col>
            </Row>
          </div>
        )
      } else {
        additionalAddressFields = (
          <p>
            <button className="button-link" onClick={onShowAdditionalAddressFieldsClicked}>
              <T id="Deliver products to another address"/>
            </button>
          </p>
        )
      }
    }

    return (
      <section>
        <div className={`${this.props.mainContentClassName} customer-info`}>
          <Row>
            <Col sm={12} md={4}>
              <h3><T id="Customer info"/></h3>
              <FormGroup>
                <ControlLabel className={styles.type}><T id="Customer type"/></ControlLabel>
                {customerTypeRadioButton}
              </FormGroup>
              {customerTypeFields}
              <InputGroup
                key="licencePlateNum"
                name="licencePlateNum"
                label={<T id="License plate number"/>}
                value={this.state.licencePlateNum}
                onBlur={this.onInputBlur.bind(this)}
                error={!!errors['licencePlateNum']}
              />
              <T id="name@example.com">
                {
                  msg => (
                    <InputGroup
                      type="email"
                      key="customerEmail"
                      name="customerEmail"
                      label={<T id="Email"/>}
                      value={this.state.customerEmail}
                      placeholder={msg}
                      onBlur={this.onInputBlur.bind(this)}
                      error={!!errors['customerEmail']}
                    />
                  )

                }
              </T>
              <T id="0712 - 12 34 56">
                {
                  msg => (
                    <InputGroup
                      type="tel"
                      key="customerPhone"
                      name="customerPhone"
                      label={<T id="Phone number"/>}
                      value={this.state.customerPhone}
                      placeholder={msg}
                      onBlur={this.onInputBlur.bind(this)}
                      error={!!errors['customerPhone']}
                    />
                  )

                }
              </T>
            </Col>
            <Col xs={12} md={4}>
              <h3><T id="Kundadress" /></h3>
              <InputGroup
                key="customerAddress1"
                name="customerAddress1"
                label={<T id="Address"/>}
                value={this.state.customerAddress1}
                onBlur={this.onInputBlur.bind(this)}
                error={!!errors['customerAddress1']}
              />
              <InputGroup
                key="customerAddress2"
                name="customerAddress2"
                label={<T id="Address 2"/>}
                value={this.state.customerAddress2}
                onBlur={this.onInputBlur.bind(this)}
                error={!!errors['customerAddress2']}
              />
              <Row>
                <Col xs={4}>
                  <InputGroup
                  key="customerPostalCode"
                  name="customerPostalCode"
                  label={<T id="Zipcode"/>}
                  value={this.state.customerPostalCode}
                  onBlur={this.onInputBlur.bind(this)}
                  error={!!errors['customerPostalCode']}
                  />
                </Col>
                <Col xs={8}>
                  <InputGroup
                  key="customerCity"
                  name="customerCity"
                  label={<T id="City"/>}
                  value={this.state.customerCity}
                  onBlur={this.onInputBlur.bind(this)}
                  error={!!errors['customerCity']}
                  />
                </Col>
              </Row>
            </Col>
            {showDeliveryAddress && (
              <Col xs={12} md={4}>
                <h3><T id="Delivery address (optional)"/></h3>
                {additionalAddressFields}
              </Col>
            )}
          </Row>
        </div>
      </section>
    )
  }
}

export default CollectCustomerInfo
