import {
    SET_COMPLETE_WHEEL_SEARCH_PARAMETERS,
    SET_COMPLETE_WHEEL_SEARCH_PARAMETER,
    SEARCH_MODE_CHANGED,
    UPDATE_VEHICLE,
} from '../actions/search'
import { RESET_VEHICLE } from '../actions/vehicle'
import { RIM_MATERIAL_TYPES } from '../constants/rimMaterialTypes'
import { TYRE_TYPES } from '../constants/tyreTypes'
import { VEHICLE_RETRIEVED_BY_ID } from '../actions/carPicker'
import { RESTRICT_DIAMETERS } from '../actions/diameters'
import { SEARCH_MODES } from '../constants/searchModes'
import { PREORDER_TARGET_ID_RETRIEVED, PREORDER_DATA_RETRIEVED } from '../actions/preOrder'
import { filteredTyreTypes, filteredSubTyreTypesFriction, filteredSubTyreTypesStud } from 'helpers/tyreTypes'

const searchParams = (state = {
  quality: [],
  vehicleType: 'alla',
  brandId: [],
  isRunflat: false,
  isEnforced: false,
  isSilence: false,
  isElectricVehicle: false,
  minQuantityInStock: '4',
  isFixedCentreBore: true,
  isWinterCertified: false,
  minimumTestScore: 0,
  query: '',
  rollingResistance: '',
  wetGrip: '',
  noiseEmissionDecibel: '',
  speedIndex: '',
  loadIndex: '',
  loadIndexRear: '',
  width: '',
  aspectRatio: '',
  tyreType: [],
  carApprovalMark: '',
  rimType: RIM_MATERIAL_TYPES[2].value,
  rimBrandId: [],
  diameter: [],
  condition: [],
  vehicleId: '',
  licenseplate: null,
  searchMode: null,
  comment: '',
}, action) => {
  switch (action.type) {
    case SET_COMPLETE_WHEEL_SEARCH_PARAMETERS:
      return Object.assign({}, state, initializeSearchParams(action.parameters, action.currentSeason, action.shop))
    case SET_COMPLETE_WHEEL_SEARCH_PARAMETER:
      return Object.assign({}, state, action.params)
    case VEHICLE_RETRIEVED_BY_ID:
      let vehicleDiameters
      if (action.vehicle.rimSizes)
        vehicleDiameters = [...new Set(action.vehicle.rimSizes.map(size => parseInt(size.diameter, 10)))]
      else
        vehicleDiameters = []
      return Object.assign({}, state, { diameter: vehicleDiameters, vehicleId: action.vehicleId })
    case UPDATE_VEHICLE:
      let diameters
      if (action.vehicle.rimSizes)
        diameters = [...new Set(action.vehicle.rimSizes.map(size => parseInt(size.diameter, 10)))]
      else
        diameters = []
      return Object.assign({}, state, { diameter: diameters, licenseplate: action.licensePlateNumber })
    case RESET_VEHICLE:
      return { ...state, diameter: [] }
    case RESTRICT_DIAMETERS:
      return Object.assign({}, state, { diameter: action.restrictedDiameters })
    case SEARCH_MODE_CHANGED:
      const changedParams = Object.assign({}, state)
      action.removedParams.forEach(param => delete changedParams[param])
      changedParams.searchMode = action.searchMode
      return changedParams
    case PREORDER_DATA_RETRIEVED:
    case PREORDER_TARGET_ID_RETRIEVED:
      if (action.targetId)
        return {...state, minQuantityInStock: 0}
      return state
    default:
      return state
  }
}

const initializeSearchParams = (params, currentSeason, shop) => {
  const {
    quality,
    vehicleType,
    brandId,
    isRunflat,
    isEnforced,
    isSilence,
    isElectricVehicle,
    minQuantityInStock,
    minimumTestScore,
    query,
    rollingResistance,
    wetGrip,
    noiseEmissionDecibel,
    speedIndex,
    loadIndex,
    loadIndexRear,
    tyreType,
    carApprovalMark,
    rimBrandId,
    isWinterCertified,
    isFixedCentreBore,
    diameter,
    width,
    boltPattern,
    et,
    rimType,
    condition,
    searchMode,
    licenseplate,
    vehicleId,
    comment,
  } = params

  let selectedTyreTypes
  if (tyreType) {
    if (Array.isArray(tyreType))
      selectedTyreTypes = tyreType
    else
      selectedTyreTypes = [tyreType]

    // NOTE: This is to make sure even someone forced it from URI,
    //       it will still be filtered
    selectedTyreTypes = selectedTyreTypes.filter(
      tyreType => shop.enabledTyreTypes.includes(parseInt(tyreType, 10))
    )
  }

  if (!selectedTyreTypes || !selectedTyreTypes.length) {
    selectedTyreTypes = filteredTyreTypes(shop.enabledTyreTypes).filter(key => (TYRE_TYPES[key].season === currentSeason && TYRE_TYPES[key].id !== TYRE_TYPES.WINTER.id))

    if (currentSeason === 'winter') {
      // NOTE: Now in winter we have two set of subtypes 1 - friction, 2 - stud
      //       We only show one but depends on what is enabled in webshop settings
      const subTypesFriction = filteredSubTyreTypesFriction(shop.enabledTyreTypes)
      const subTypesStud = filteredSubTyreTypesStud(shop.enabledTyreTypes)
      const defaultSubTypes = [
        (
          subTypesFriction.length
            ? subTypesFriction
            : subTypesStud
        ).map(
          key => key
        )[0]
      ]
      selectedTyreTypes = defaultSubTypes
    }

    if (selectedTyreTypes)
      selectedTyreTypes = selectedTyreTypes.map(type => TYRE_TYPES[type].id)
    else
      selectedTyreTypes = [TYRE_TYPES.SUMMER.id]
  }

  let selectedCondition
  if (condition) {
    if (Array.isArray(condition))
      selectedCondition = condition
    else
      selectedCondition = [condition]
  } else if (shop.condition && shop.condition.wheel) {
    selectedCondition = Object.keys(shop.condition.wheel).filter(key => shop.condition.wheel[key].default)
  }

  return {
    quality: quality ? (Array.isArray(quality) ? quality : [quality]) : [],
    vehicleType: vehicleType || 'alla',
    brandId: brandId ? (Array.isArray(brandId) ? brandId : [brandId]) : [],
    isRunflat: isRunflat === 'true',
    isEnforced: isEnforced === 'true',
    isSilence: isSilence === 'true',
    isElectricVehicle: isElectricVehicle === 'true',
    minQuantityInStock: minQuantityInStock || shop.minQuantityInStock || '4',
    minimumTestScore: parseInt(minimumTestScore, 10) || 0,
    query: query || '',
    rollingResistance: rollingResistance || '',
    wetGrip: wetGrip || '',
    noiseEmissionDecibel: noiseEmissionDecibel || '',
    speedIndex: speedIndex || '',
    loadIndex: loadIndex || '',
    loadIndexRear: loadIndexRear || '',
    tyreType: selectedTyreTypes,
    carApprovalMark: carApprovalMark || '',
    diameter: diameter ? (Array.isArray(diameter) ? diameter.map(d => parseInt(d, 10)) : [parseInt(diameter, 10)]) : [],
    rimBrandId: rimBrandId ? (Array.isArray(rimBrandId) ? rimBrandId : [rimBrandId]) : [],
    isFixedCentreBore: isFixedCentreBore ? isFixedCentreBore === 'true' : true,
    isWinterCertified: isWinterCertified === 'true',
    width: width || '',
    boltPattern: boltPattern || '',
    et: et || '',
    rimType: rimType || RIM_MATERIAL_TYPES[2].value,
    condition: selectedCondition || ['new'],
    licenseplate: licenseplate || '',
    vehicleId: vehicleId || null,
    searchMode:!!searchMode ? parseInt(searchMode, 10) : SEARCH_MODES.carModel,
    comment: comment || '',
  }
}

export default searchParams
