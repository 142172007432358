import React from 'react'
import { Popover, Overlay } from 'react-bootstrap'
import styles from './styles.less'
import classnames from 'classnames'

class ConditionPopover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPopover: false
    }
  }

  onMouseEnter(event) {
    this.setState({
      showPopover: true
    })
  }

  onMouseLeave(event) {
    this.setState({
      showPopover: false
    })
  }

  render() {
    const { condition, className } = this.props
    if (condition.info) {
      return (
        <div key="condition" ref={el => this.popoverTarget = el}>
          <div
            className={classnames(condition.type.toUpperCase() === "OLDDOT" ? `${className} ${styles.red}` : className, 'condition-popover')}
            name="condition"
            onMouseEnter={this.onMouseEnter.bind(this)}
            onMouseLeave={this.onMouseLeave.bind(this)}>
            {condition.description}
          </div>
          <Overlay placement="bottom" show={this.state.showPopover} target={this.popoverTarget}>
            <Popover placement="bottom" id="test-results-popover"  className="background-normal">
              <span className={styles.white}>{condition.info}</span>
            </Popover>
          </Overlay>
        </div>
      )
    }
    return <div className={className}>{condition.description}</div>;
  }
}

export default ConditionPopover
