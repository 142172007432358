import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import Spinner from '../../Spinner'
import { confirmEmail } from '../../../../actions/signUp'
import { Alert } from 'react-bootstrap'
import qs from 'query-string'

class SignUpConfirmation extends React.Component {
  componentDidMount() {
    const { location, shop, confirmEmail } = this.props
    const params = qs.parse(location.search)
    confirmEmail(params.t, shop)
  }

  render() {
    const { isConfirmationInProgress, confirmationError, isConfirmationSuccessful } = this.props
    if (isConfirmationInProgress)
      return <Spinner/>
    if (isConfirmationSuccessful)
      return <Redirect to="/login" />
    const errorMessage = <Alert bsStyle="danger">{confirmationError}</Alert>
    return (
      <div className="container">
        {errorMessage}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isConfirmationInProgress: state.signUp.isConfirmationInProgress,
    confirmationError: state.signUp.confirmationError,
    isConfirmationSuccessful: state.signUp.isConfirmationSuccessful,
    shop: state.shop
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  confirmEmail: (token, shop) => confirmEmail(token, shop)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpConfirmation)