import { SHOP_RETRIEVED } from '../actions'
import { ITEM_CLICKED } from '../actions/menu'

const getItem = (itemConfig) => {
  const item = {}
    item.type = itemConfig.type
    item.new_tab = itemConfig.new_tab
    switch (itemConfig.type) {
      case 'Page':
        if (itemConfig.page_slug) {
          item.url = `/page/${itemConfig.page_slug}`
          item.slug = itemConfig.page_slug
        }
        break
      case 'Internal':
        if (itemConfig.internal_slug === 'root')
          item.url = '/'
        else {
          let parts = itemConfig.internal_slug.split('.');

          if (parts.length === 2 && parts[0] && parts[1]) {
            item.url = `/${parts[1]}`
            if (parts[1] === 'hjul')
              item.url = `/${parts[1]}/falg`
          }
          else if (parts[0] === 'order-list')
            item.url = '/ordrar'
          else if (parts[0])
            item.url = `/${parts[0]}`
          else
            item.url = '/'
        }
        item.slug = itemConfig.internal_slug
        break
      case 'External':
        if (itemConfig.external_url)
          item.url = itemConfig.external_url
        break
      default:
        item.url = '/'
    }

    item.title = itemConfig.title
    return item
}

const menu = (state = {}, action) => {
  switch(action.type) {
    case ITEM_CLICKED:
      return Object.assign({}, state, { activeSlug: action.slug })
    case SHOP_RETRIEVED:
      const itemsLeft = []
      const itemsRight = []
      action.shop.menu2.forEach(item => {
        itemsLeft.push(getItem(item))
      })
      action.shop.menu1.forEach(item => {
        itemsRight.push(getItem(item))
      })
      return Object.assign({}, state, {
        left: itemsLeft,
        right: itemsRight,
        frontPage: getItem(action.shop.frontPage)
      })
    default:
      return state
  }
}

export default menu
